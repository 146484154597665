import { FlexCol } from 'ui/loulaFlex';
import { Alert } from 'ui/components/Alert';
import { DateInput, getDateInputRegisterProps } from 'ui/components/DateInput';
import { FormBoolRadio } from 'ui/components/Form/FormBoolRadio';
import { useMemo } from 'react';
import { Divider } from 'ui/components/Divider';
import FONTS from 'ui/fonts';
import { useCreateClientContext } from '../createClient.context';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';
import {
    getFormTextInputRegisterProps,
    NewFormTextInput,
} from 'ui/components/Form/NewFormTextInput';
import { UploadedFilePreview } from 'components/FileUpload/UploadedFilePreview';
import { useSimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.context';
import { PXSTR } from 'ui/px';

export const ClientDetailsStep = () => {
    const {
        standardRadioRegisterProps,
        setDOB,
        setIsGroupIDOnCard,
        primaryInsurance,
        secondaryInsurance,
        dob,
        errors,
        register,
        hasSecondaryInsurance,
        secondary,
        primaryPayerData,
        cardPreviewSrcs,
    } = useClientDetailsStep();

    const flowCtx = useSimpleFlowContext();
    const { goToStep } = flowCtx ?? {};
    const goToInsuranceCardStep = () => {
        goToStep?.(0);
    };

    return (
        <FlexCol gap={PXSTR.XXL}>
            <Alert content="Double check to ensure the information you input below matches what is on the client’s insurance card. " />

            <FlexCol gap={PXSTR.L}>
                <NewFormTextInput
                    label="Client’s First Name"
                    fieldName="firstName"
                    errors={errors}
                    registerProps={register?.(
                        'firstName',
                        getFormTextInputRegisterProps({}),
                    )}
                />

                <NewFormTextInput
                    label="Client’s Middle Initial (optional)"
                    maxLength={1}
                    fieldName="middleInitial"
                    errors={errors}
                    registerProps={register?.(
                        'middleInitial',
                        getFormTextInputRegisterProps({ required: false }),
                    )}
                />

                <NewFormTextInput
                    label="Client’s Last Name"
                    fieldName="lastName"
                    errors={errors}
                    registerProps={register?.(
                        'lastName',
                        getFormTextInputRegisterProps({}),
                    )}
                />

                <DateInput
                    label="Client’s Date of Birth"
                    datePickerProps={{
                        dateFormat: 'MM/dd/yyyy',
                        placeholderText: 'MM/dd/yyyy',
                        maxDate: new Date(),
                    }}
                    value={dob ?? null}
                    setValue={setDOB}
                    registerProps={
                        !!register
                            ? register?.('dob', getDateInputRegisterProps())
                            : undefined
                    }
                />
            </FlexCol>

            <Divider />

            <FONTS.H6>Front of client’s insurance card</FONTS.H6>

            <UploadedFilePreview
                files={[]}
                preloadedPreviewSrc={cardPreviewSrcs?.primaryFrontSrc}
                onReset={goToInsuranceCardStep}
            />

            <FONTS.H3>{primaryPayerData?.name}</FONTS.H3>

            <NewFormTextInput
                label="Client’s Member ID Number"
                fieldName="primaryInsurance.memberId"
                errors={errors}
                registerProps={register?.(
                    'primaryInsurance.memberId',
                    getFormTextInputRegisterProps({}),
                )}
            />

            <FormBoolRadio
                fieldName="primaryInsurance.isGroupIDOnCard"
                label="Is a group ID listed on client’s insurance card?"
                errors={errors}
                defaultValue={primaryInsurance?.isGroupIDOnCard}
                onChangeBool={setIsGroupIDOnCard(true)}
                isDecorated
                isRequired={true}
                {...(register?.(
                    'primaryInsurance.isGroupIDOnCard',
                    standardRadioRegisterProps,
                ) ?? {})}
            />

            {primaryInsurance?.isGroupIDOnCard && (
                <NewFormTextInput
                    label="Client’s Group ID Number"
                    fieldName="primaryInsurance.groupId"
                    errors={errors}
                    registerProps={register?.(
                        'primaryInsurance.groupId',
                        getFormTextInputRegisterProps({}),
                    )}
                />
            )}

            {hasSecondaryInsurance && (
                <>
                    <Divider />

                    <FONTS.H6>Front of client’s insurance card</FONTS.H6>

                    <UploadedFilePreview
                        files={[]}
                        preloadedPreviewSrc={cardPreviewSrcs?.secondaryFrontSrc}
                        onReset={goToInsuranceCardStep}
                    />

                    <FONTS.H3>{secondary?.payerName}</FONTS.H3>

                    <NewFormTextInput
                        label="Client’s Member ID Number"
                        fieldName="secondaryInsurance.memberId"
                        errors={errors}
                        registerProps={register?.(
                            'secondaryInsurance.memberId',
                            getFormTextInputRegisterProps({}),
                        )}
                    />

                    <FormBoolRadio
                        fieldName="secondaryInsurance.isGroupIDOnCard"
                        label="Is a group ID listed on client’s insurance card?"
                        errors={errors}
                        defaultValue={secondaryInsurance?.isGroupIDOnCard}
                        onChangeBool={setIsGroupIDOnCard(false)}
                        isDecorated
                        isRequired={true}
                        {...(register?.(
                            'secondaryInsurance.isGroupIDOnCard',
                            standardRadioRegisterProps,
                        ) ?? {})}
                    />

                    {secondaryInsurance?.isGroupIDOnCard && (
                        <NewFormTextInput
                            label="Client’s Group ID Number"
                            fieldName="secondaryInsurance.groupId"
                            errors={errors}
                            registerProps={register?.(
                                'secondaryInsurance.groupId',
                                getFormTextInputRegisterProps({}),
                            )}
                        />
                    )}
                </>
            )}
        </FlexCol>
    );
};

const useClientDetailsStep = () => {
    const ctx = useCreateClientContext();

    const {
        stepOneForm,
        stepTwoForm,
        providerContractData,
        ...createClientCtxReturns
    } = ctx ?? {};

    const [primary, hasSecondaryInsurance, secondary] =
        stepOneForm?.watch(['primary', 'hasSecondaryInsurance', 'secondary']) ??
        [];

    const { primaryPayerData } = useMemo(() => {
        const primaryPayerData = providerContractData?.find(
            (contractData) =>
                contractData.payers.externalPayerId === primary?.payerId,
        )?.payers;

        return { primaryPayerData };
    }, [primary?.payerId, providerContractData]);

    const { formState, watch, setValue, register } = stepTwoForm ?? {};
    const { errors } = formState ?? {};

    const [primaryInsurance, secondaryInsurance, dob] =
        watch?.([
            'primaryInsurance',
            'secondaryInsurance',
            'dob',
            'firstName',
        ]) ?? [];

    const setterProps = useMemo(() => {
        return {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
        };
    }, []);

    const setIsGroupIDOnCard = (isPrimary: boolean) => (val: boolean) => {
        setValue?.(
            `${isPrimary ? 'primary' : 'secondary'}Insurance.isGroupIDOnCard`,
            val,
            setterProps,
        );
    };

    const setDOB = (date: Date | null) => {
        if (!date) return;
        setValue?.('dob', date, setterProps);
    };

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();

    return {
        standardRadioRegisterProps,
        setDOB,
        setIsGroupIDOnCard,
        primaryInsurance,
        secondaryInsurance,
        dob,
        errors,
        register,
        hasSecondaryInsurance,
        secondary,
        primaryPayerData,
        ...createClientCtxReturns,
    };
};
