import { VisitModel } from 'models/schemas';
import COLORS from 'ui/colors';
import { PayerTag } from 'ui/components/AvatarTag';
import { LINK } from 'ui/components/Link';
import { TextTag, TextTagColorSchemes } from 'ui/components/TextTag';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { VisitField } from './VisitField';
import { Divider } from 'ui/components/Divider';
import { format, parseISO } from 'date-fns';
import { CycleTag } from './CycleTag';

export const VisitMetaData = ({ visit }: { visit: VisitModel | null }) => {
    if (!visit) return null;

    return (
        <FlexCol
            flex={1}
            flexBasis="240px"
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            background={COLORS.UTIL.Gray[200]}
            gap={PXSTR.L}
            paddingX={PXSTR.M}
            paddingY={PXSTR.L}
        >
            <VisitField title="Made a mistake?">
                <LINK.Small>Contact support</LINK.Small>{' '}
                {/**TODO: this should be a real link somewhere */}
            </VisitField>

            <Divider />

            <FlexRow
                gap="inherit"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                <VisitField title="Visit ID" simpleText={`#${visit.code}`} />
                <VisitField
                    title="Submitted on"
                    simpleText={format(parseISO(visit.createdAt), 'M/d/yy')}
                />
            </FlexRow>
            <FlexRow
                gap="inherit"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                <VisitField title="Status">
                    <TextTag
                        text="TODO STATUS"
                        colorScheme={TextTagColorSchemes.BLUE_LIGHT}
                    />
                </VisitField>

                <VisitField title="Cycle">
                    <CycleTag date={visit.createdAt} />
                </VisitField>
            </FlexRow>

            <VisitField title="Managed Care Plan">
                <PayerTag />
                TODO Show Tag
            </VisitField>

            <VisitField title="Payment" simpleText="$XYZ.00" />
        </FlexCol>
    );
};
