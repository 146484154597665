import { FormControl, FormLabel } from '@chakra-ui/react';
import {
    FieldErrors,
    FieldValues,
    UseFormRegisterReturn,
} from 'react-hook-form';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import COLORS from 'ui/colors';
import { useRadioGroup } from '../hooks/useRadioGroup';
import { DecoratedRadioProps, Radio } from '../Radio';
import { forwardRef } from 'react';

export type FormBoolRadioProps = Partial<UseFormRegisterReturn> & {
    options?: (DecoratedRadioProps & {
        value: boolean;
        labelBoldPrefix?: string;
        label?: string;
    })[];
    fieldName: string;

    label?: string;
    subLabel?: string;
    'data-cy'?: string;

    errors: FieldErrors<FieldValues> | undefined;
    isRequired?: boolean;
    isDecorated?: boolean;

    defaultValue?: boolean;
    onChangeBool: (newVal: boolean) => void;
};

export const FormBoolRadio = forwardRef<FormBoolRadioProps, FormBoolRadioProps>(
    (
        {
            label,
            subLabel,
            fieldName,
            defaultValue,
            isRequired = false,
            isDecorated,
            errors,
            options,
            onChangeBool,
            ...props
        },
        forwardedRef,
    ) => {
        const { getRootProps, getRadioProps } = useRadioGroup({
            defaultValue,
            onChangeBool,
        });

        if (!options) {
            options = [
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
            ];
        }

        return (
            <FormControl
                flex={1}
                isInvalid={!!errors?.[fieldName]}
                isRequired={isRequired}
            >
                <FlexCol>
                    <FormLabel display="none" htmlFor={fieldName}>
                        {label}
                    </FormLabel>
                    <FlexCol gap={PX.SPACING.PX.S} {...getRootProps()}>
                        {label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
                        {subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
                        <input hidden {...props} />
                        {options.map((option, idx) => (
                            <FlexRow
                                key={`multiselect_option_${idx}`}
                                gap={PX.SPACING.PX.S}
                            >
                                <Radio
                                    isDecorated={
                                        option.isDecorated ?? isDecorated
                                    }
                                    {...getRadioProps({ value: option.value })}
                                    {...option}
                                />
                            </FlexRow>
                        ))}
                    </FlexCol>
                    <FONTS.P2
                        minHeight={PX.SPACING.REM.M}
                        color={COLORS.PRIMARY.Error}
                    >
                        {errors?.[fieldName] &&
                            errors?.[fieldName].message?.toString()}
                    </FONTS.P2>
                </FlexCol>
            </FormControl>
        );
    },
);
