class GlobalEventEmitter extends EventTarget {}
const portalEventEmitter = new GlobalEventEmitter();

import { useEffect, useState, useCallback, useRef } from 'react';

/**
 * THIS IS A VERY UN-REACT-Y THING
 * If it's needed, there's probably an architecting issue, but it can be useful for quick dev
 */
export const useEventEmitter = <T>(eventName: string) => {
    const [eventData, setEventData] = useState<T>();
    const skipRerender = useRef(false);

    const publishEvent = useCallback(
        (eventData: T, skipRender = true) => {
            skipRerender.current = skipRender;
            const event = new CustomEvent(eventName, { detail: eventData });
            portalEventEmitter.dispatchEvent(event);
        },
        [eventName],
    );

    useEffect(() => {
        const listener = (event: Event) => {
            if (skipRerender.current) {
                skipRerender.current = false;
                return;
            }
            setEventData((event as CustomEvent).detail);
        };

        portalEventEmitter.addEventListener(eventName, listener);

        return () => {
            portalEventEmitter.removeEventListener(eventName, listener);
        };
    }, [eventName, skipRerender]);

    return { eventData, publishEvent };
};
