import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { useEffect } from 'react';

export const EmbeddedPandadocPage = ({
    documentLink,
    onClosed,
    onCompleted,
}: {
    documentLink: string | undefined;
    onClosed: () => Promise<void>;
    onCompleted: () => Promise<void>;
    onDeclined: () => Promise<void>;
}) => {
    useEffect(
        function listenToModalEvents() {
            const onMessageEvent = (event: MessageEvent) => {
                const type = event.data && event.data.type;
                //const payload = event.data && event.data.payload;
                //TODO: dont think there are other events we can catch here? but I might be wrong
                switch (type) {
                    case 'embed.form.completed':
                        onCompleted();
                        break;
                }
            };

            window.addEventListener('message', onMessageEvent);

            return () => {
                window.removeEventListener('message', onMessageEvent);
            };
        },
        [onCompleted],
    );

    return (
        <Modal
            id="embedded-pandadoc-modal"
            isOpen={documentLink !== undefined}
            onClose={onClosed}
            size={'3xl'}
        >
            <ModalOverlay />
            <ModalContent width="90%" height="90%">
                {documentLink !== null && (
                    <iframe
                        width="100%"
                        height="100%"
                        src={documentLink}
                    ></iframe>
                )}
            </ModalContent>
        </Modal>
    );
};
