import { addDays, endOfMonth, getDate, startOfMonth, subDays } from 'date-fns';
import { StepTwoFields } from 'doulaPortal/pages/SubmitVisit/submitVisit.models';
import {
    DeliveryTypes,
    PerinatalVisitTypes,
    PregnancyConclusionTypes,
    VisitCategory,
    VisitLocation,
    VisitService,
    VisitType,
} from 'models/constants/visitConstants';
import { VisitModel } from 'models/schemas';

export const getFullVisitLocation = (visit: VisitModel | null | undefined) => {
    const { locationOfService, locationSelfDescription } = visit ?? {};

    if (
        locationOfService === VisitLocation.ClientHome ||
        locationOfService === VisitLocation.Office
    ) {
        return locationOfService;
    } else if (locationOfService === VisitLocation.Other) {
        return `Other: ${locationSelfDescription}`;
    } else if (locationOfService === VisitLocation.MedicalFacility) {
        return 'TODO FACILITY NAME';
    }

    return locationOfService;
};

export const getFullServicesString = (visit: VisitModel | null | undefined) => {
    return getFullServicesStringInDict(
        visit?.services as Record<VisitService, boolean>,
    );
};

export const getFullServicesStringInDict = (
    dict: Record<VisitService, boolean> | null | undefined,
) => {
    return Object.entries(dict ?? {})
        .filter(([, selected]) => !!selected)
        .map(([key]) => key)
        .join('; ');
};

export const determineVisitType = (
    visitTypeStepFields: StepTwoFields | undefined,
): VisitType | undefined => {
    if (!visitTypeStepFields) return;

    const { visitCategory, deliveryConclusionDesc, perinatalType } =
        visitTypeStepFields;

    if (visitCategory === VisitCategory.Initial) {
        return perinatalType === PerinatalVisitTypes.Prenatal
            ? VisitType.InitialPrenatal
            : VisitType.InitialPostpartum;
    } else if (visitCategory === VisitCategory.LaborDelivery) {
        if (deliveryConclusionDesc === PregnancyConclusionTypes.Abortion) {
            return VisitType.Abortion;
        } else if (
            deliveryConclusionDesc === PregnancyConclusionTypes.Miscarriage
        ) {
            return VisitType.Miscarriage;
        } else if (deliveryConclusionDesc === DeliveryTypes.CSection) {
            return VisitType.CSection;
        } else if (
            deliveryConclusionDesc === DeliveryTypes.VaginalAfterCSection
        ) {
            return VisitType.VaginalDeliveryAfterCSection;
        } else if (deliveryConclusionDesc === DeliveryTypes.Vaginal) {
            return VisitType.VaginalDelivery;
        }
    } else if (visitCategory === VisitCategory.Perinatal) {
        return perinatalType === PerinatalVisitTypes.Prenatal
            ? VisitType.Prenatal
            : VisitType.Postpartum;
    } else if (visitCategory === VisitCategory.PostpartumExtended) {
        return VisitType.ExtendedPostpartum;
    }
};

export const combineDateTimeFields = (
    dateObj: Date | undefined,
    timeObj: Date | undefined,
): Date | undefined => {
    if (!dateObj || !timeObj) return;

    //TODO: timezone issues with this? probably something
    const combinedDateTimeObject = new Date(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        timeObj.getHours(),
        timeObj.getMinutes(),
        timeObj.getSeconds(),
    );

    return combinedDateTimeObject;
};

export const determineCycle = (
    date: Date | null | undefined,
): [Date, Date] | null => {
    if (!date) return null;

    const isBeforeMidMonth = getDate(date) <= 14;

    if (isBeforeMidMonth) {
        //then its the first to the fourteenth
        const lastDayOfCycle = new Date(
            date.getFullYear(),
            date.getMonth(),
            14,
        );
        const startMonth = startOfMonth(lastDayOfCycle);
        return [startMonth, lastDayOfCycle];
    } else {
        //then its the fifteenth to the end of the month
        const midMonth = new Date(date.getFullYear(), date.getMonth(), 15);
        const endMonth = endOfMonth(midMonth);
        return [midMonth, endMonth];
    }
};

export const determinePrevCycle = (
    date: Date | null | undefined,
): [Date, Date] | null => {
    if (!date) return null;
    const dateCycle = determineCycle(date);
    if (!dateCycle) return null;
    const dayBefore = subDays(dateCycle[0], 1);
    return determineCycle(dayBefore);
};

export const determineNextCycle = (
    date: Date | null | undefined,
): [Date, Date] | null => {
    if (!date) return null;
    const dateCycle = determineCycle(date);
    if (!dateCycle) return null;
    const dayAfter = addDays(dateCycle[1], 1);
    return determineCycle(dayAfter);
};

export const isPrenatal = (
    visitCategory: VisitCategory | null | undefined,
    visitPerinatalType: PerinatalVisitTypes | null | undefined,
) => {
    return (
        (visitCategory === VisitCategory.Perinatal ||
            visitCategory === VisitCategory.Initial) &&
        visitPerinatalType === PerinatalVisitTypes.Prenatal
    );
};

export const isPostpartum = (
    visitCategory: VisitCategory | null | undefined,
    visitPerinatalType: PerinatalVisitTypes | null | undefined,
) => {
    return (
        visitCategory === VisitCategory.PostpartumExtended ||
        ((visitCategory === VisitCategory.Perinatal ||
            visitCategory === VisitCategory.Initial) &&
            visitPerinatalType === PerinatalVisitTypes.Postpartum)
    );
};
