import {
    FaBars,
    FaUpload,
    FaHome,
    FaUsers,
    FaReceipt,
    FaCoins,
    FaCog,
    FaTimes,
    FaCheck,
    FaTrashAlt,
    FaFile,
    FaExclamationCircle,
    FaSearch,
    FaTimesCircle,
    FaCheckCircle,
    FaCaretDown,
    FaCaretUp,
    FaCaretLeft,
    FaChevronDown,
    FaChevronUp,
    FaChevronRight,
    FaEdit,
    FaInfoCircle,
    FaLink,
    FaCalendar,
    FaClock,
    FaClone,
    FaExternalLinkAlt,
} from 'react-icons/fa';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import {
    MdArrowBackIosNew,
    MdArrowForwardIos,
    MdOutlineLaunch,
    MdEdit,
} from 'react-icons/md';
import { FaCheck as ThinFaCheck } from 'react-icons/fa6';

export const ICONS = {
    Menu: FaBars,
    ArrowBackward: IoMdArrowBack,
    ArrowForward: IoMdArrowForward,

    CaretLeft: FaCaretLeft,
    CaretDown: FaCaretDown,
    CaretUp: FaCaretUp,

    ChevronDown: FaChevronDown,
    ChevronUp: FaChevronUp,
    ChevronRight: FaChevronRight,

    ArrowBackIOS: MdArrowBackIosNew,
    ArrowForwardIOS: MdArrowForwardIos,

    Upload: FaUpload,
    Launch: MdOutlineLaunch,

    Link: FaLink,

    Edit: MdEdit,
    EditPage: FaEdit,

    Info: FaInfoCircle,

    Users: FaUsers,
    Receipt: FaReceipt,
    Home: FaHome,
    Coins: FaCoins,
    Cog: FaCog,
    File: FaFile,
    Delete: FaTrashAlt,
    Search: FaSearch,
    Close: FaTimes,

    Checkmark: FaCheck,
    ThinCheckmark: ThinFaCheck,
    CircleCheckmark: FaCheckCircle,

    WarningCircle: FaExclamationCircle,
    ErrorCircle: FaTimesCircle,

    Calendar: FaCalendar,
    Clock: FaClock,
    Copy: FaClone,
    ExternalLink: FaExternalLinkAlt,
};

export type LoulaIcons = keyof typeof ICONS;
