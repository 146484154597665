import { FlexCol } from 'ui/loulaFlex';
import { useSubmitVisitContext } from '../submitVisit.context';
import { AutoComplete } from 'ui/components/AutoComplete';
import { useAllClients } from 'hooks/dbHooks/clients.hooks';
import { useMemo } from 'react';
import { ClientOverviewCard } from '../ClientOverviewCard';
import { getNameToShow } from 'util/Utils';
import { AutoCompletePossibility } from 'ui/ui.models';
import { PXSTR } from 'ui/px';
import { UserTag } from 'ui/components/AvatarTag';
import { useSimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.context';

const useSelectClientStep = () => {
    const flowCtx = useSimpleFlowContext();
    const { unSeeSteps } = flowCtx ?? {};
    const ctx = useSubmitVisitContext();
    const { stepOneForm } = ctx ?? {};
    const { setValue, watch, register } = stepOneForm ?? {};

    const registerProps = register?.('patientUserId', {
        validate: (x) => {
            return !!x || 'Please select a client';
        },
    });

    const { allClients } = useAllClients();

    //TODO: filter for only patients with verified insurance
    const patientPossibilities: AutoCompletePossibility[] = useMemo(
        () =>
            Object.values(allClients).map((client) => {
                return {
                    label: getNameToShow(client.user),
                    value: client.userId,
                };
            }),
        [allClients],
    );

    const selectedPatientId = watch?.('patientUserId');

    const onSelectPatient = (patientId: string) => {
        setValue?.('patientUserId', patientId, { shouldValidate: true });
        resetDependentForms();
    };

    const onRemovePatient = () => {
        setValue?.('patientUserId', undefined, { shouldValidate: true });
        resetDependentForms();
    };

    const resetDependentForms = () => {
        ctx?.stepTwoForm?.reset();
        ctx?.stepThreeForm?.reset();
        ctx?.stepFourForm?.reset();
        ctx?.stepFiveForm?.reset();

        unSeeSteps?.([1, 2, 3, 4, 5]);
    };

    const selectedClient =
        !!selectedPatientId && !!allClients
            ? allClients[selectedPatientId]
            : undefined;

    return {
        patientPossibilities,
        onSelectPatient,
        onRemovePatient,
        selectedClient,
        registerProps,
    };
};

export const SelectClientStep = () => {
    const {
        patientPossibilities,
        onSelectPatient,
        onRemovePatient,
        selectedClient,
    } = useSelectClientStep();

    return (
        <FlexCol gap={PXSTR.XL}>
            <AutoComplete
                id="clientSelector"
                label="Select a client"
                possibilities={patientPossibilities}
                onClickItem={onSelectPatient}
                noResultsHeader="All Clients"
                searchIconOnRight={true}
                leftElement={
                    !!selectedClient ? (
                        <UserTag
                            user={selectedClient.user}
                            onClickClose={onRemovePatient}
                            verticallyPadded={false}
                        />
                    ) : undefined
                }
            />

            {!!selectedClient && <ClientOverviewCard client={selectedClient} />}
        </FlexCol>
    );
};
