import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import {
    CreateClientContextType,
    StepOneFields,
    StepTwoFields,
    StepThreeFields,
    StepFourFields,
    FullCreateClientFlowFields,
    StepFiveFields,
    PRIMARY_CARD_FRONT_KEY,
    PRIMARY_CARD_BACK_KEY,
    SECONDARY_CARD_FRONT_KEY,
    SECONDARY_CARD_BACK_KEY,
} from './createClient.models';
import { InsuranceCardStep } from './Steps/InsuranceCardStep';
import { ClientDetailsStep } from './Steps/ClientDetailsStep';
import { InsuredDetailsStep } from './Steps/InsuredDetailsStep';
import { ReviewClientStep } from './Steps/ReviewClientStep';
import { AdditionalDetailsStep } from './Steps/AdditionalDetailsStep';
import { usePreviewUploadedFile } from 'components/FileUpload/UploadedFilePreview';
import { useProviderContracts } from 'hooks/dbHooks';
import {
    createInsuranceDTO,
    createPatientDTO,
    createPregnancyDTO,
    createUserDTO,
    useCreateClient,
} from 'hooks/dbHooks/clients.hooks';
import { InsurerRelationship } from 'models/constants/clientConstants';
import { useNavigate } from 'react-router-dom';
import { usePatientFiles } from 'hooks/dbHooks/files.hooks';
import { useToast } from 'ui/useToast';

export const useInitialCreateClientContext = (): CreateClientContextType => {
    const navigate = useNavigate();
    const toast = useToast();

    const stepDefinitions = useMemo(() => {
        return [
            {
                title: 'Insurance Card',
                Component: InsuranceCardStep,
            },
            {
                title: 'Client Details',
                Component: ClientDetailsStep,
            },
            {
                title: 'Insured Details',
                Component: InsuredDetailsStep,
            },
            {
                title: 'Additional Details',
                Component: AdditionalDetailsStep,
            },
            {
                title: 'Review & Create',
                Component: ReviewClientStep,
            },
        ];
    }, []);

    const stepOneForm = useForm({
        defaultValues: {
            primary: {
                payerId: undefined,
                cardFront: undefined,
                cardBack: undefined,
                isMediCal: undefined,
            },
            hasSecondaryInsurance: undefined,
            secondary: {
                payerId: undefined,
                payerName: undefined,
                cardFront: undefined,
                cardBack: undefined,
            },
        } as StepOneFields,
    });

    const stepTwoForm = useForm({
        defaultValues: {
            firstName: undefined,
            lastName: undefined,
            middleInitial: undefined,
            dob: undefined,

            primaryInsurance: {
                memberId: undefined,
                isGroupIDOnCard: undefined,
                groupId: undefined,
            },

            secondaryInsurance: {
                memberId: undefined,
                isGroupIDOnCard: undefined,
                groupId: undefined,
            },
        } as StepTwoFields,
    });

    const stepThreeForm = useForm({
        defaultValues: {
            primaryRelationToInsured: undefined,
            secondaryRelationToInsured: undefined,

            insuredDetails: {
                firstName: undefined,
                lastName: undefined,
                middleInitial: undefined,
                dob: undefined,
                memberId: undefined,
                isGroupIDOnCard: undefined,
                groupId: undefined,
                sex: undefined,
                phone: undefined,
                address: undefined,
            },
        } as StepThreeFields,
    });

    const stepFourForm = useForm({
        defaultValues: {
            preferredName: undefined,
            sex: undefined,
            pronouns: {},
            isAddressSameAsInsured: undefined,
            address: undefined,
            email: undefined,
            phone: undefined,
            pregnancyStatus: undefined,
            dateOfPregnancyConclusion: undefined,
        } as StepFourFields,
    });

    const stepFiveForm = useForm({
        defaultValues: {
            attestationOfNotIndependentlyBilling: undefined,
            attestionOfAccuracy: undefined,
        } as StepFiveFields,
    });

    const allForms = useMemo(
        () => [
            stepOneForm,
            stepTwoForm,
            stepThreeForm,
            stepFourForm,
            stepFiveForm,
        ],
        [stepFourForm, stepOneForm, stepThreeForm, stepTwoForm, stepFiveForm],
    );

    const { saveTempFileToPatientFiles } = usePatientFiles();

    const isFinalClientValid = (
        allData: FullCreateClientFlowFields,
    ): boolean => {
        const userAddress = allData.isAddressSameAsInsured
            ? allData.insuredDetails?.address
            : allData.address;

        if (
            !allData.firstName ||
            !allData.lastName ||
            !allData.dob ||
            !allData.sex ||
            !allData.phone ||
            !allData.email ||
            !userAddress
        ) {
            // eslint-disable-next-line no-console
            console.error(
                'Tried to create client w/o required user fields',
                allData,
            );
            return false;
        }

        if (!allData.pregnancyStatus) {
            // eslint-disable-next-line no-console
            console.error('Tried to create client w/o pregnancy status');
            return false;
        }

        return true;
    };

    const saveInsuranceCardImages = async (
        patientUserId: string,
        allData: FullCreateClientFlowFields,
    ) => {
        const uploadPromisesArr = [];
        const primaryFront = allData.primary?.cardFront;
        const primaryBack = allData.primary?.cardBack;
        const secondaryFront = allData.secondary?.cardFront;
        const secondaryBack = allData.secondary?.cardBack;

        if (!!primaryFront?.length) {
            await saveTempFileToPatientFiles(
                PRIMARY_CARD_FRONT_KEY,
                primaryFront[0],
                patientUserId,
            );
        }

        if (!!primaryBack?.length) {
            uploadPromisesArr.push(
                saveTempFileToPatientFiles(
                    PRIMARY_CARD_BACK_KEY,
                    primaryBack[0],
                    patientUserId,
                ),
            );
        }

        if (!!secondaryFront?.length) {
            uploadPromisesArr.push(
                saveTempFileToPatientFiles(
                    SECONDARY_CARD_FRONT_KEY,
                    secondaryFront[0],
                    patientUserId,
                ),
            );
        }

        if (!!secondaryBack?.length) {
            uploadPromisesArr.push(
                saveTempFileToPatientFiles(
                    SECONDARY_CARD_BACK_KEY,
                    secondaryBack[0],
                    patientUserId,
                ),
            );
        }

        await Promise.all(uploadPromisesArr);
    };

    const { createClient } = useCreateClient();

    const createClientFromFlow = async () => {
        const stepOneVals = stepOneForm.getValues();
        const stepTwoVals = stepTwoForm.getValues();
        const stepThreeVals = stepThreeForm.getValues();
        const stepFourVals = stepFourForm.getValues();

        const allData = {
            ...stepOneVals,
            ...stepTwoVals,
            ...stepThreeVals,
            ...stepFourVals,
        };

        if (!isFinalClientValid(allData)) {
            // eslint-disable-next-line no-console
            console.error(
                'Tried to create client with incomplete data, this shouldnt be possible!',
            );
            return;
        }

        const userAddress = allData.isAddressSameAsInsured
            ? allData.insuredDetails?.address
            : allData.address;

        const user: createUserDTO = {
            preferredName: allData.preferredName,
            firstName: allData.firstName,
            lastName: allData.lastName,
            dob: allData.dob?.toISOString(),
            sex: allData.sex,
            phone: allData.phone,
            email: allData.email,
            address1: userAddress?.address1,
            address2: userAddress?.address2,
            city: userAddress?.city,
            state: userAddress?.state,
            zip: userAddress?.zip,
            country: userAddress?.country,
            pronouns: allData.pronouns,
        };

        const patient: createPatientDTO = {
            isActive: true,
        };

        const pregnancy: createPregnancyDTO = {
            status: allData.pregnancyStatus,
            additionalPostPartumStatus: 'None',
            conclusionType: undefined, //TODO should we be collecting this if it happened?
            isActive: true,
            deliveryDate: allData.dateOfPregnancyConclusion?.toISOString(),
        };

        const isPrimarySelf =
            allData.primaryRelationToInsured === InsurerRelationship.Self;

        const insurances: createInsuranceDTO[] = [
            {
                type: 'primary',
                patientRelationshipToSubscriber:
                    allData.primaryRelationToInsured,
                // planType: PlanType;
                firstName: isPrimarySelf
                    ? allData.firstName
                    : allData.insuredDetails?.firstName,
                lastName: isPrimarySelf
                    ? allData.lastName
                    : allData.insuredDetails?.lastName,
                dob: (isPrimarySelf
                    ? allData.dob
                    : allData.insuredDetails?.dob
                )?.toISOString(),
                ...(isPrimarySelf
                    ? allData.address
                    : allData.insuredDetails?.address),
                sex: isPrimarySelf ? allData.sex : allData.insuredDetails?.sex,
                memberId: isPrimarySelf
                    ? allData.primaryInsurance?.memberId
                    : allData.insuredDetails?.memberId,
                payerId: allData.primary?.payerId,
                groupNumber: isPrimarySelf
                    ? allData.primaryInsurance?.groupId
                    : allData.insuredDetails?.groupId,
                phone: isPrimarySelf
                    ? allData.phone
                    : allData.insuredDetails?.phone,
                verificationStatus: 'Under Review',
            },
        ];

        if (allData.hasSecondaryInsurance) {
            const isSecondarySelf =
                allData.secondaryRelationToInsured === InsurerRelationship.Self;

            insurances.push({
                type: 'secondary',
                patientRelationshipToSubscriber:
                    allData.secondaryRelationToInsured,
                firstName: isSecondarySelf
                    ? allData.firstName
                    : allData.insuredDetails?.firstName,
                lastName: isSecondarySelf
                    ? allData.lastName
                    : allData.insuredDetails?.lastName,
                dob: (isSecondarySelf
                    ? allData.dob
                    : allData.insuredDetails?.dob
                )?.toISOString(),
                ...(isSecondarySelf
                    ? allData.address
                    : allData.insuredDetails?.address),
                sex: isSecondarySelf
                    ? allData.sex
                    : allData.insuredDetails?.sex,
                memberId: isSecondarySelf
                    ? allData.secondaryInsurance?.memberId
                    : allData.insuredDetails?.memberId,
                payerId: allData.secondary?.payerId,
                groupNumber: isSecondarySelf
                    ? allData.secondaryInsurance?.groupId
                    : allData.insuredDetails?.groupId,
                phone: isSecondarySelf
                    ? allData.phone
                    : allData.insuredDetails?.phone,
                verificationStatus: 'Under Review',
            });
        }

        const newClientUserPromise = createClient(
            user,
            patient,
            pregnancy,
            insurances,
        );

        toast.promise(newClientUserPromise, {
            loading: {
                description: 'Creating client...',
            },
            success: {
                description: 'Success!',
                duration: 3000,
                isClosable: true,
            },
            error: (err) => ({
                description: `${err}`,
            }),
        });

        const newClientResult = await newClientUserPromise;

        if (!!newClientResult.errorMsg) {
            toast({ description: newClientResult.errorMsg });
        } else if (!!newClientResult.newClientId) {
            await saveInsuranceCardImages(newClientResult.newClientId, allData);
            navigate(`/clients/${newClientResult.newClientId}`);
        }
    };

    const getIsNextEnabled = useCallback(
        (stepIdx: number) => {
            if (stepIdx < 0 || stepIdx > allForms.length) return false;
            return allForms[stepIdx]?.formState?.isValid ?? false;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            stepOneForm?.formState,
            stepTwoForm?.formState,
            stepThreeForm?.formState,
            stepFourForm?.formState,
        ],
    );

    const primaryFront = stepOneForm?.watch?.('primary.cardFront');
    const primaryBack = stepOneForm?.watch?.('primary.cardBack');
    const secondaryFront = stepOneForm?.watch?.('secondary.cardFront');
    const secondaryBack = stepOneForm?.watch?.('secondary.cardBack');

    const { previewSrc: primaryFrontSrc } = usePreviewUploadedFile({
        files: primaryFront,
    });
    const { previewSrc: primaryBackSrc } = usePreviewUploadedFile({
        files: primaryBack,
    });
    const { previewSrc: secondaryFrontSrc } = usePreviewUploadedFile({
        files: secondaryFront,
    });
    const { previewSrc: secondaryBackSrc } = usePreviewUploadedFile({
        files: secondaryBack,
    });

    const { providerContractData } = useProviderContracts();

    const cardPreviewSrcs = {
        primaryFrontSrc,
        primaryBackSrc,
        secondaryFrontSrc,
        secondaryBackSrc,
    };

    return {
        stepDefinitions,

        createClientFromFlow,

        getIsNextEnabled,

        stepOneForm,
        stepTwoForm,
        stepThreeForm,
        stepFourForm,
        stepFiveForm,

        cardPreviewSrcs,
        providerContractData,
    };
};
