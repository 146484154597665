import { FlexCol } from 'ui/loulaFlex';
import { VisitOverview } from './VisitOverview';
import { PXSTR } from 'ui/px';
import { SingleClientVisitsTable } from './SingleClientVisitsTable';

export const VisitsInfo = () => {
    return (
        <FlexCol className="visitsInfoWrapper" gap={PXSTR.XL}>
            <VisitOverview />
            <SingleClientVisitsTable />
        </FlexCol>
    );
};
