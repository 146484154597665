import { useAuth0 } from '@auth0/auth0-react';
import { useDisclosure } from '@chakra-ui/react';
import { authorize } from 'api/API';
import {
    NpiProviderResponseItem,
    NpiFacilityResponseItem,
} from 'models/schemas';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    NPISearchProps,
    NPIFacilityType,
    IndividualSearchFields,
    FacilitySearchFields,
    NPISearchContextType,
} from './npiSearch.models';

export const useInitialNPISearchContext = (
    props: NPISearchProps,
): NPISearchContextType => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [individualNPIData, setIndividualNPIData] = useState<
        NpiProviderResponseItem[]
    >([]);

    const [facilityNPIData, setFacilityNPIData] = useState<
        NpiFacilityResponseItem[]
    >([]);

    const [selectedNpi, setSelectedNpi] = useState(props.initialValue);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showResults, setShowResults] = useState(false);

    const isIndividual = props.facilityType === NPIFacilityType.INDIVIDUAL;

    const individualForm = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            isLimitedToState: false,
        } as IndividualSearchFields,
    });

    const facilityForm = useForm({
        defaultValues: {
            isLimitedToState: false,
            city: '',
            taxonomy: '',
        } as FacilitySearchFields,
    });

    const { getAccessTokenSilently } = useAuth0();

    const firstNameWatch = individualForm.watch('firstName', '');
    const lastNameWatch = individualForm.watch('lastName', '');
    const facilityNameWatch = facilityForm.watch('facilityName', '');

    const isLimitedToStateWatch = isIndividual
        ? individualForm.watch('isLimitedToState', false)
        : facilityForm.watch('isLimitedToState', false);

    const setIsLimitedToState = (val: boolean) => {
        const setterProps = {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        };

        if (isIndividual) {
            individualForm.setValue('isLimitedToState', val, setterProps);
        } else {
            facilityForm.setValue('isLimitedToState', val, setterProps);
        }
    };

    const loadNPIs = async (
        data: IndividualSearchFields | FacilitySearchFields,
    ) => {
        const token = await getAccessTokenSilently({});

        let url = `/npi-registry/${isIndividual ? 'provider' : 'facility'}-npi?`;

        if (isIndividual) {
            const { firstName, lastName } = data as IndividualSearchFields;
            url += `firstName=${firstName}&lastName=${lastName}`;
        } else {
            const { facilityName, city, taxonomy } =
                data as FacilitySearchFields;

            url += `name=${facilityName}&city=${city}&type=${taxonomy}`;
        }

        if (data.isLimitedToState !== false) {
            url += `&stateAcronym=CA`;
        }

        authorize(token)
            .get(url)
            .then((res) => {
                setIsLoading(false);
                if (res.data.length === 0) {
                    setError('No results found');
                } else {
                    setError(null);
                    if (isIndividual) {
                        setIndividualNPIData(res.data);
                    } else {
                        setFacilityNPIData(res.data);
                    }
                    setShowResults(true);
                }
            });
    };

    const search = async () => {
        const data = isIndividual
            ? individualForm.getValues()
            : facilityForm.getValues();
        setIsLoading(true);
        setError(null);
        await loadNPIs(data);
    };

    const back = () => {
        setShowResults(false);
    };

    const selectNpi = (npi: string) => () => {
        props.setValue(npi);
        setSelectedNpi(npi);
        closeAndReset();
    };

    const closeAndReset = () => {
        setShowResults(false);
        setIndividualNPIData([]);
        setFacilityNPIData([]);
        setError(null);
        individualForm.reset();
        facilityForm.reset();
        onClose?.();
    };

    const individualResultSearchSummary = `${firstNameWatch}${lastNameWatch?.length > 0 ? ` ${lastNameWatch}` : ''}`;
    const facilityResultsSearchSummary = facilityNameWatch;
    const relavantDataArr = isIndividual ? individualNPIData : facilityNPIData;
    const resultsHeader =
        relavantDataArr.length > 0
            ? `${relavantDataArr.length} result${relavantDataArr.length > 1 ? 's' : ''} for "${isIndividual ? individualResultSearchSummary : facilityResultsSearchSummary}"`
            : 'No results found';

    return {
        isOpen,
        onOpen,
        onClose,
        search,
        back,
        selectNpi,
        selectedNpi,
        closeAndReset,

        firstNameWatch,
        lastNameWatch,
        isLimitedToStateWatch,
        setIsLimitedToState,

        isIndividual,
        individualForm,
        facilityForm,

        individualNPIData,
        facilityNPIData,

        error,
        isLoading,
        showResults,
        resultsHeader,
        facilityType: props.facilityType,
    };
};
