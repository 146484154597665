import {
    REFRESH_CLIENT_DATA,
    REFRESH_CLIENT_EVENT,
} from 'doulaPortal/hooks/events';
import { useEventEmitter } from 'doulaPortal/hooks/useEventEmitter';
import { useClient } from 'hooks/dbHooks/clients.hooks';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientDetailsContextType } from './clientDetails.models';
import { usePatientFiles } from 'hooks/dbHooks/files.hooks';
import { AddPostPartumReccFieldName } from './AdditionalPostPartumReccModal';

export const useInitialClientDetailsContext = (): ClientDetailsContextType => {
    const navigate = useNavigate();

    const { clientId } = useParams();
    const {
        client,
        refreshClientData,
        isLoaded: isClientDataLoaded,
        ...clientHookUpdaters
    } = useClient(clientId);

    const patientFiles = usePatientFiles(
        client?.userId,
        AddPostPartumReccFieldName,
    );

    const { eventData } =
        useEventEmitter<REFRESH_CLIENT_DATA>(REFRESH_CLIENT_EVENT);

    useEffect(
        function refreshOnEvent() {
            if (eventData?.clientId !== clientId) return;
            refreshClientData();
        },
        [clientId, eventData, refreshClientData],
    );

    const goToSubmitVisit = () => {
        navigate(`/visit/new${clientId === undefined ? '' : `/${clientId}`}`);
    };

    return {
        client,
        refreshClientData,
        goToSubmitVisit,
        isClientDataLoaded,
        clientHookUpdaters,
        ...patientFiles,
    };
};
