import { Box, Image } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { StepHeader, StepWrapperStyling } from './StepsWrapper';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import FONTS from 'ui/fonts';
import { ReviewStep } from './steps/ReviewStep';
import { commonStepStyling } from './AnimatedStepSwapper';
import { Zyg } from 'util/Utils';
import { Flex } from '@chakra-ui/react';
import useScreenSize from 'hooks/useScreenSize';
import { Helmet } from 'react-helmet-async';
import { OnboardingStatusContext } from 'onboarding/OnboardingContext';
import { useContext } from 'react';
import { NavBarFooter } from './NavBarFooter';

export const OnboardingCompleteScreen = () => {
    return (
        <Flex
            width="100vw"
            height="100dvh"
            position="fixed"
            className="completedOnboardingWrapperPage"
            background={COLORS.BRAND.Periwinkle[100]}
            direction={['column', null, null, 'row']}
            justifyContent="space-between"
            overflowY="scroll"
            role="main"
        >
            <Helmet>
                <title>Onboarding Complete</title>
            </Helmet>

            <SideBar />
            <ApplicatonSummary />
        </Flex>
    );
};

export const SideBar = () => {
    const statusContext = useContext(OnboardingStatusContext);
    const { practitionerData } = statusContext ?? {};

    const isApproved = practitionerData?.onboardingStatus === 'approved';
    const { isNarrowScreen } = useScreenSize();
    return (
        <FlexCol
            padding={PX.SPACING.PX.L}
            alignItems="start"
            justifyContent="space-between"
            maxWidth="700px"
            maxHeight="100%"
            overflow={Zyg(undefined, 'hidden')}
        >
            <FlexCol
                maxWidth="440px"
                margin="auto"
                textAlign="center"
                alignItems="center"
                gap={PX.SPACING.PX.L}
            >
                <Image
                    width={['60%', null, null, '80%']}
                    height="100%"
                    src={
                        isApproved
                            ? '/images/welcome.png'
                            : '/images/congratulations.png'
                    }
                    alt={
                        isApproved ? 'Welcome to Loula' : 'Onboarding Submitted'
                    }
                />
                <FONTS.H2>
                    Onboarding packet {isApproved ? 'approved' : 'submitted'}!
                </FONTS.H2>
                {isApproved ? (
                    <>
                        <FONTS.P1>
                            Your onboarding packet has been reviewed and you
                            have been approved to join Loula!
                        </FONTS.P1>
                        <FONTS.P1>
                            We are now ready to begin your credentialing process
                            with the Managed Care Plans. When you have been
                            credentialed with your first Managed Care Plan, we
                            will notify you via email so you can start seeing
                            clients for that plan right away!
                        </FONTS.P1>
                    </>
                ) : (
                    <>
                        <FONTS.P1>
                            Thank you for joining the Loula community. We will
                            review your onboarding packet and determine if we
                            need any additional information or if there are any
                            issues within 30 days.
                        </FONTS.P1>
                        <FONTS.P1>
                            If we need any clarification or additional
                            documents, we will contact you via{' '}
                            <b>{practitionerData?.user.email ?? 'email'}</b>
                        </FONTS.P1>
                    </>
                )}

                <FONTS.P1 color={COLORS.PRIMARY.Warning}>
                    Have questions? Email us at onboarding@joinloula.com.{' '}
                </FONTS.P1>
            </FlexCol>
            {!isNarrowScreen && <NavBarFooter />}{' '}
            {/**TODO-MINA: use the same component as reg onboarding just make it dynamic */}
        </FlexCol>
    );
};

export const ApplicatonSummary = () => {
    const { isNarrowScreen } = useScreenSize();

    if (isNarrowScreen)
        return (
            <Box>
                <ReviewStep commonStyling={commonStepStyling} />
            </Box>
        );

    return (
        <FlexCol
            className="desktopStepWrapper"
            flex={1}
            overflowX="hidden"
            justifyContent="space-between"
            padding={PX.SPACING.PX.L}
            {...StepWrapperStyling}
        >
            <StepHeader stepTitle="Your Information" />
            <ReviewStep commonStyling={commonStepStyling} />
        </FlexCol>
    );
};
