import { Flex } from '@chakra-ui/react';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { ReactElement, useContext } from 'react';
import AddressForm from 'components/AddressForm';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import FONTS from 'ui/fonts';
import NPISearch from 'components/NPISearch/NPISearch';
import { FormTextInput } from 'ui/components/Form/FormTextInput';
import {
    FormMultiselect,
    getFormMultiSelectRegisterProps,
    MultiselectOptionType,
} from 'ui/components/Form/FormMultiselect';
import { Pronouns, Race } from 'models/constants';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { LanguageProficiencyPicker } from '../LanguageProficiencyPicker';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { OnboardingFormDataModel } from 'models/schemas';
import { FormDateInput } from 'ui/components/Form/FormDateInput';
import { FormBoolRadio } from 'ui/components/Form/FormBoolRadio';
import { setMultiselectVal } from 'util/Utils';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';

export const InfoStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData } = onboardingContext ?? {};

    const { register, formState, setValue, watch } = formInstance ?? {};

    const { errors } = formState ?? {};

    const { hasOtherNames, setHasOtherNames } = useOtherNames(
        watch,
        formData,
        setValue,
    );

    const [pronouns, race] = watch?.(['pronouns', 'race']) ?? [];

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();

    return (
        <Flex {...commonStyling} aria-label="personal information form">
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <FONTS.H2>Let’s collect some information about you. </FONTS.H2>
                <FlexRow
                    wrap="wrap"
                    gap={PX.SPACING.REM.M}
                    marginBottom={PX.SPACING.REM.XL}
                >
                    <FormTextInput
                        register={register}
                        fieldName="firstName"
                        label="Legal First Name"
                        blockSpecialChars={true}
                        maxLength={32}
                        subLabel="Make sure this name matches what’s on your state-issued ID."
                        errors={errors}
                    />
                    <FormTextInput
                        register={register}
                        fieldName="lastName"
                        blockSpecialChars={true}
                        maxLength={32}
                        label="Legal Last Name"
                        errors={errors}
                    />
                </FlexRow>

                <FlexCol gap={PX.SPACING.REM.XL} width={['100%', null, '50%']}>
                    <FormTextInput
                        register={register}
                        fieldName="middleName"
                        blockSpecialChars={true}
                        label="Middle Name (Optional)"
                        errors={errors}
                        maxLength={32}
                        required={false}
                    />

                    <FormTextInput
                        register={register}
                        fieldName="preferredName"
                        blockSpecialChars={true}
                        required={false}
                        label="Preferred Name (Optional)"
                        maxLength={32}
                        subLabel="We’ll use this·name on our platform when we communicate with you."
                        errors={errors}
                    />

                    <FormBoolRadio
                        label="Have you been known by any other names besides the
                            one(s) listed above?"
                        defaultValue={hasOtherNames}
                        errors={errors}
                        fieldName="hasOtherNames"
                        options={[
                            { value: true, label: 'Yes', isDecorated: false },
                            { value: false, label: 'No', isDecorated: false },
                        ]}
                        onChangeBool={setHasOtherNames}
                        {...(register?.(
                            'hasOtherNames',
                            standardRadioRegisterProps,
                        ) ?? {})}
                    />

                    {hasOtherNames && (
                        <FormTextInput
                            required={true}
                            register={register}
                            fieldName="otherNames"
                            label="List all other names you have been known as:"
                            subLabel='List first and last names, separated by commas. For example, "Jane Doe, Jane Smith, Jane Doe-Smith".'
                            errors={errors}
                            requiredWarningText="This field is required"
                        />
                    )}

                    <FormMultiselect
                        data-cy="pronouns"
                        fieldName="pronouns"
                        label="Pronouns"
                        errors={errors}
                        subLabel="We strive to use gender-inclusive language. 
                We will default to gender-neutral language if no pronouns are listed."
                        value={pronouns}
                        setValue={setMultiselectVal<FieldValues>(
                            'pronouns',
                            setValue,
                        )}
                        isRequired={false}
                        options={Object.keys(Pronouns)
                            .map((pronounKey): MultiselectOptionType => {
                                return {
                                    label: pronounKey,
                                    value: pronounKey,
                                };
                            })
                            .concat([
                                //TODO-Mina: Finish ability for fully custom pronouns, and for displaying error state
                                // {
                                //     label: 'I want to self-describe',
                                //     value: 'custom',
                                //     clearsOtherChoices: true,
                                //     customPlaceholder:
                                //         'i.e. she, they, ze, he/they',
                                // },
                                {
                                    label: 'I prefer not to say',
                                    value: 'undeclared',
                                    isExclusive: true,
                                },
                            ])}
                        registerProps={
                            !!register
                                ? {
                                      ...register('pronouns'),
                                  }
                                : undefined
                        }
                    />

                    <FormDateInput
                        fieldName="dob"
                        label="Date of Birth"
                        minDate={new Date(1900, 1, 1)}
                        maxDate={new Date()}
                        required={true}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                        watch={watch}
                    />

                    <FormTextInput
                        data-cy="social-sec-field"
                        fieldName="tin"
                        isSecret
                        mask="XXX XX XXXX"
                        setValue={setValue}
                        defaultValue={formData?.tin}
                        autoComplete="off"
                        requiredWarningText="Enter a valid 9-digit Social Security Number."
                        label="Social Security Number"
                        placeholder="123 45 6789"
                        errors={errors}
                        minLength={11}
                    />

                    <NPISearch
                        label="National Provider Identification (NPI) Number"
                        subLabel="Use the lookup above to find your individual NPI number."
                        initialValue={formData?.npi?.toString() ?? ''}
                        setValue={(npi: string) =>
                            setValue?.('npi', npi, { shouldDirty: true })
                        }
                    />

                    <FormTextInput
                        data-cy="phone-field"
                        register={register}
                        fieldName="phone"
                        mask="(000) 000-0000"
                        requiredWarningText="Enter a valid 10-digit phone number (including the area code). "
                        label="Phone Number"
                        placeholder="(123) 456-7890"
                        minLength={14}
                        subLabel="If we have issues with your application, we will contact this number."
                        errors={errors}
                    />
                </FlexCol>

                <AddressForm
                    label="Personal Mailing Address"
                    subLabel="You should be able to receive packages at this address. This is the address we’ll use to mail things like Loula-specific gear. You’ll have the opportunity to provide a business address in the “Business Information” section. "
                    namePrefix="personalAddress"
                    requiredWarningText="Please enter a valid mailing address."
                    watch={watch}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                />

                <LanguageProficiencyPicker />

                <FormMultiselect
                    data-cy="race"
                    fieldName="race"
                    label="What is your race/ethnicity?"
                    errors={errors}
                    subTitle="Select as many as applicable."
                    value={race}
                    setValue={setMultiselectVal<FieldValues>('race', setValue)} //TODO-Mina: why did I set up this typescript stuff? I can simplify
                    options={Race.map((race): MultiselectOptionType => {
                        return {
                            value: race,
                        };
                    }).concat([
                        {
                            value: 'I decline to self-identify',
                            isExclusive: true,
                        },
                    ])}
                    registerProps={
                        !!register
                            ? {
                                  ...register(
                                      'race',
                                      getFormMultiSelectRegisterProps(true),
                                  ),
                              }
                            : undefined
                    }
                />
            </FlexCol>
        </Flex>
    );
};

const useOtherNames = (
    watch: UseFormWatch<FieldValues> | undefined,
    formData: OnboardingFormDataModel | undefined | null,
    setValue: UseFormSetValue<FieldValues> | undefined,
) => {
    const hasOtherNames = watch?.('hasOtherNames', formData?.hasOtherNames);

    const setHasOtherNames = (val: boolean) => {
        setValue?.('hasOtherNames', val, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
        });
    };

    return {
        hasOtherNames,
        setHasOtherNames,
    };
};
