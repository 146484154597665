import { UserModel } from 'models/schemas';

/**
 * Returns the full name based on the provided name parts.
 * If a preferred name is available, it will be used; otherwise, the first name will be used.
 * The last name will always be included in the full name.
 * @param User: The user object containing the name parts.
 * @returns The full name.
 */
export const getNameToShow = (user?: UserModel): string => {
    if (!user) return '';

    return `${user.preferredName ? user.preferredName : user.firstName} ${user.lastName}`;
};

/**
 * Takes the user object and extracts initials (can't just do this based off the full name string
 * because might have multiple words in a given name and want to specifically have first letter
 * of preferred/first and first letter of last)
 */
export const getInitials = (user: UserModel | undefined): string => {
    const initials: string[] = [];

    initials.push((user?.preferredName ?? user?.firstName ?? '').charAt(0));
    initials.push((user?.lastName ?? '').charAt(0));

    return initials.join('');
};
