import { useEffect, useState } from 'react';
import {
    Direction,
    useCustomElementScrollDetect,
    ScrollPosition,
} from './useCustomElementScrollDetect';

export const useOnScrollDirectionChange = (
    onChange: (newDirection: Direction, newPos: ScrollPosition) => void,
) => {
    const [lastScrollDirection, setLastScrollDirection] = useState<
        Direction | undefined
    >();

    const { customElementRef, scrollDir, scrollPosition } =
        useCustomElementScrollDetect();

    useEffect(() => {
        if (scrollDir !== lastScrollDirection) {
            onChange(scrollDir, scrollPosition);
            setLastScrollDirection(scrollDir);
        }
    }, [lastScrollDirection, onChange, scrollDir, scrollPosition]);

    return { customElementRef, scrollDir, scrollPosition };
};
