import { useClient } from 'hooks/dbHooks/clients.hooks';
import { VisitModel } from 'models/schemas';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
    PregnancyConclusionTypes,
    VisitLocation,
    VisitMedium,
} from 'models/constants/visitConstants';
import { useVisits } from 'hooks/dbHooks/visits.hooks';
import {
    StepOneFields,
    StepTwoFields,
    StepThreeFields,
    StepFourFields,
    StepFiveFields,
    StepSixFields,
    FullSubmitVisitFlowFields,
    SubmitVisitContextType,
} from '../submitVisit.models';
import { combineDateTimeFields, determineVisitType } from 'util/visit.utils';
import { GetPregnancy } from 'util/patient.utils';

import { SelectClientStep } from '../Steps/SelectClientStep';
import { VisitTypeStep } from '../Steps/VisitTypeStep';
import { VisitDateTimeStep } from '../Steps/VisitDateTimeStep';
import { VisitLocationStep } from '../Steps/VisitLocationStep';
import { VisitServicesStep } from '../Steps/VisitServicesStep';
import { VisitReviewStep } from '../Steps/VisitReviewStep';

const useClientForVisit = ({
    urlClientId,
    stepOneForm,
}: {
    urlClientId: string | undefined;
    stepOneForm: UseFormReturn<StepOneFields>;
}) => {
    const { watch } = stepOneForm;
    const patientUserId = watch('patientUserId', urlClientId);
    const clientHookReturn = useClient(patientUserId);
    const { client, refreshClientData } = clientHookReturn ?? {};

    useEffect(
        function updateLocalClientObjectAfterUserChoice() {
            if (patientUserId !== client?.userId) {
                // eslint-disable-next-line no-console
                console.log(
                    'Updating our local client after user choice! Going from ',
                    client?.userId,
                    ' to ',
                    patientUserId,
                );
                refreshClientData();
            }
        },
        [client?.userId, patientUserId, refreshClientData],
    );

    return clientHookReturn;
};

export const useInitialSubmitVisitContext = (): SubmitVisitContextType => {
    const stepDefinitions = useMemo(() => {
        return [
            {
                title: 'Select A Client',
                Component: SelectClientStep,
            },
            {
                title: 'Visit Type',
                Component: VisitTypeStep,
            },
            {
                title: 'Visit Date & Time',
                Component: VisitDateTimeStep,
            },
            {
                title: 'Visit Location',
                Component: VisitLocationStep,
            },
            {
                title: 'Services Provided',
                Component: VisitServicesStep,
            },
            {
                title: 'Review & Submit',
                Component: VisitReviewStep,
            },
        ];
    }, []);

    const navigate = useNavigate();

    const { clientId: urlClientId } = useParams(); //may or may not be there depending where they enter flow from

    const { createVisit } = useVisits();

    const stepOneForm = useForm({
        defaultValues: {
            patientUserId: urlClientId,
        } as StepOneFields,
    });

    const { client, updatePatientPregnancy } = useClientForVisit({
        urlClientId,
        stepOneForm,
    });

    const stepTwoForm = useForm({
        defaultValues: {
            visitCategory: undefined,
            deliveryConclusionDesc: undefined, //DeliveryConclusionDesc
            perinatalType: undefined, //Prenatal or Postpartum
            wasConclusionBillable: undefined,
            pregnancyConclusionType: undefined,
            dateOfPregnancyConclusion: undefined,
        } as StepTwoFields,
    });

    const stepThreeForm = useForm({
        defaultValues: {
            dateOfService: undefined,
            timeOfService: undefined,
            durationMinutes: undefined,
        } as StepThreeFields,
    });

    const stepFourForm = useForm({
        defaultValues: {
            locationOfService: undefined,
            mediumOfService: undefined,
            visitType: undefined,

            telehealthInHome: undefined,
            facilityNPI: undefined,
            locationSelfDescription: undefined,
        } as StepFourFields,
    });

    const stepFiveForm = useForm({
        defaultValues: {
            services: {},
        } as StepFiveFields,
    });

    const stepSixForm = useForm({
        defaultValues: {
            attestationComplete: false,
        } as StepSixFields,
    });

    const allForms = useMemo(
        () => [
            stepOneForm,
            stepTwoForm,
            stepThreeForm,
            stepFourForm,
            stepFiveForm,
            stepSixForm,
        ],
        [
            stepFiveForm,
            stepFourForm,
            stepOneForm,
            stepThreeForm,
            stepTwoForm,
            stepSixForm,
        ],
    );

    const isFinalVisitDataValid = (
        allData: FullSubmitVisitFlowFields,
        otherRequiredFields: Record<string, unknown>,
    ) => {
        if (
            !allData.patientUserId ||
            !allData.dateOfService ||
            !allData.durationMinutes ||
            !allData.mediumOfService ||
            !allData.services ||
            Object.values(otherRequiredFields).filter(
                (val) => val === undefined,
            ).length > 0
        ) {
            // eslint-disable-next-line no-console
            console.error(
                'Tried to submit visit but missing some required fields',
                allData,
                otherRequiredFields,
            );
            return false;
        }

        if (
            allData.mediumOfService === VisitMedium.InPerson &&
            allData.locationOfService === undefined
        ) {
            // eslint-disable-next-line no-console
            console.error('Tried to submit IRL visit w/o location info');
            return false;
        }

        if (
            (allData.mediumOfService === VisitMedium.Audio ||
                allData.mediumOfService === VisitMedium.Video) &&
            allData.telehealthInHome === undefined
        ) {
            // eslint-disable-next-line no-console
            console.error(
                'Tried to submit telehealth visit w/o inHomeOfClient info',
            );
            return false;
        }

        if (
            allData.locationOfService === VisitLocation.MedicalFacility &&
            allData.facilityNPI === undefined
        ) {
            // eslint-disable-next-line no-console
            console.error('Tried to submit facility visit w/o npi info');
            return false;
        }

        if (
            allData.locationOfService === VisitLocation.Other &&
            allData.locationSelfDescription === undefined
        ) {
            // eslint-disable-next-line no-console
            console.error(
                'Tried to submit other location visit w/o describing specific location',
            );
            return false;
        }

        return true;
    };

    const submitVisit = async () => {
        const stepOneVals = stepOneForm.getValues();
        const stepTwoVals = stepTwoForm.getValues();
        const stepThreeVals = stepThreeForm.getValues();
        const stepFourVals = stepFourForm.getValues();
        const stepFiveVals = stepFiveForm.getValues();

        const allData = {
            ...stepOneVals,
            ...stepTwoVals,
            ...stepThreeVals,
            ...stepFourVals,
            ...stepFiveVals,
        };

        const dateOfServiceCombined = combineDateTimeFields(
            allData.dateOfService,
            allData.timeOfService,
        );

        const visitType = determineVisitType(stepTwoVals);

        if (
            !isFinalVisitDataValid(allData, {
                dateOfServiceCombined,
                visitType,
            })
        )
            return;

        if (
            allData.wasConclusionBillable === false &&
            !!allData.pregnancyConclusionType &&
            !!allData.dateOfPregnancyConclusion
        ) {
            //then we need to update the pregnancy info
            const pregnancy = GetPregnancy(client);
            updatePatientPregnancy({
                id: pregnancy?.id,
                status: 'Postpartum',
                deliveryDate: allData.dateOfPregnancyConclusion?.toISOString(),
                conclusionType: allData.pregnancyConclusionType,
                deliveryType:
                    allData.deliveryConclusionDesc !==
                        PregnancyConclusionTypes.Abortion &&
                    allData.deliveryConclusionDesc !==
                        PregnancyConclusionTypes.Miscarriage &&
                    PregnancyConclusionTypes.Abortion
                        ? allData.deliveryConclusionDesc
                        : undefined,
            });
        }

        //I don't like using this Partial here, but typescripts not smart enough to recognize
        //that isFinalVisitDataValid did a check for undefineds :()
        const newVisit: Partial<
            Omit<
                VisitModel,
                | 'id'
                | 'practitionerUserId'
                | 'code'
                | 'placeOfService'
                | 'createdAt'
                | 'updatedAt'
            >
        > = {
            patientUserId: allData.patientUserId,

            dateOfService: dateOfServiceCombined?.toISOString(),
            durationMinutes: allData.durationMinutes,

            visitType: visitType,
            mediumOfService: allData.mediumOfService,
            locationOfService: allData.locationOfService,
            services: allData.services as VisitModel['services'], //It'd be nice to avoid this cast, but the shared types right now are freaks about enums

            telehealthInHome: allData.telehealthInHome,
            facilityNPI: allData.facilityNPI,
            locationSelfDescription: allData.locationSelfDescription,
        };

        const newVisitId = await createVisit(newVisit);

        navigate(`/visit/${newVisitId}`);
    };

    const getIsNextEnabled = useCallback(
        (stepIdx: number) => {
            if (stepIdx < 0 || stepIdx > allForms.length) return false;
            return allForms[stepIdx]?.formState?.isValid ?? false;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            stepOneForm?.formState,
            stepTwoForm?.formState,
            stepThreeForm?.formState,
            stepFourForm?.formState,
            stepFiveForm?.formState,
            stepSixForm?.formState,
        ],
    );

    return {
        stepDefinitions,

        client,

        submitVisit,

        getIsNextEnabled,

        stepOneForm,
        stepTwoForm,
        stepThreeForm,
        stepFourForm,
        stepFiveForm,
        stepSixForm,
    };
};
