import { FormLabel } from '@chakra-ui/react';
import {
    FieldErrors,
    FieldValues,
    UseFormRegisterReturn,
} from 'react-hook-form';
import { CheckBox } from '../Checkbox';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import COLORS from 'ui/colors';
import { StringBoolMapHasAtleastOne } from 'util/Utils';
import { DecoratedRadioProps } from '../Radio';

export type MultiselectOptionType = DecoratedRadioProps & {
    label?: string;
    value: string;
    isExclusive?: boolean;
    customPlaceholder?: string;
};

export const getFormMultiSelectRegisterProps = (isRequired?: boolean) => {
    return {
        validate: (val: Record<string, boolean>) =>
            !isRequired ||
            StringBoolMapHasAtleastOne(val) ||
            'Selection is required',
    };
};

export function FormMultiselect({
    fieldName,
    label,
    subTitle,
    subLabel,
    errors,
    setValue,
    value,
    options,
    registerProps,
    ...props
}: {
    label: string;
    subTitle?: string;
    subLabel?: string;

    fieldName: string;
    errors: FieldErrors<FieldValues> | undefined;
    isRequired?: boolean;
    options: MultiselectOptionType[];

    value: Record<string, boolean> | undefined;
    setValue: (val: Record<string, boolean>) => void;
    registerProps?: UseFormRegisterReturn;
    'data-cy'?: string;
}) {
    const onChangeCheckbox = (
        option: MultiselectOptionType,
        isChecked: boolean,
    ) => {
        const valueToSet: Record<string, boolean> = {
            [option.value]: isChecked,
        };

        //if its exclusive, we drop any other selections, otherwise we check for other exclusives to deselect
        if (!option.isExclusive) {
            const checkedValues = Object.entries(value ?? {})
                .filter(([val, checked]) => checked && val !== option.value)
                .map(([val]) => val);

            checkedValues.forEach((val) => {
                const fullOption = options.find((opt) => opt.value === val);

                if (!!fullOption) {
                    valueToSet[fullOption.value] = !fullOption.isExclusive;
                }
            });
        }

        setValue(valueToSet);
    };

    return (
        <FlexCol>
            <input hidden {...registerProps} />
            <FormLabel display="none" htmlFor={fieldName}>
                {label}
            </FormLabel>
            <FlexCol gap={PX.SPACING.PX.S}>
                {label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
                {subTitle && <FONTS.P2>{subTitle}</FONTS.P2>}

                {options.map((option, idx) => (
                    <FlexCol
                        key={`multiselect_option_${idx}`}
                        gap={PX.SPACING.PX.S}
                    >
                        <CheckBox
                            data-cy={`${props['data-cy']}-${option.value}`}
                            isChecked={value?.[option.value] ?? false}
                            onChange={(e) =>
                                onChangeCheckbox(option, e.target.checked)
                            }
                            {...option}
                            label={option.label ?? option.value}
                        />
                        {/*TODO-Mina finish setting up for custom write-in option ability here 
                        {option.customPlaceholder &&
                            watchField[option.value] && (
                                <TextInput marginLeft={PX.SPACING.PX.L} onChange={} />
                            )} */}
                    </FlexCol>
                ))}
                {subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
            </FlexCol>
            <FONTS.P2
                minHeight={PX.SPACING.REM.M}
                mt={PX.SPACING.PX.XS}
                color={COLORS.PRIMARY.Error}
            >
                {errors?.[fieldName] && errors?.[fieldName].message?.toString()}
            </FONTS.P2>
        </FlexCol>
    );
}
