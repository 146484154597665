import { ReactElement, useEffect, useRef, useState } from 'react';
import COLORS from 'ui/colors';
import { TextInput, TextInputProps } from 'ui/components/TextInput';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import SHADOWS from 'ui/shadows';
import { FlexProps } from '@chakra-ui/react';
import { AutoCompletePossibility } from 'ui/ui.models';
import {
    SuggestionsProps,
    useAutocompleteFocus,
    useAutocompleteSuggestions,
} from './hooks/useAutocomplete';

export const AutoComplete = ({
    possibilities,
    onClickItem,
    filterFn,
    sortFn,
    clearOnClick = true,
    label,
    subLabel,
    isLoading,
    searchIconOnRight = false,
    noResultsHeader = 'All Options',
    onInputChange,
    permitManualEntry,
    ...props
}: TextInputProps &
    SuggestionsProps & {
        isLoading?: boolean;
        searchIconOnRight?: boolean;
        noResultsHeader?: string;
        onInputChange?: (_value: string) => void;
        permitManualEntry?: boolean;
        'data-cy'?: string;
    }): React.ReactElement => {
    const [value, setValue] = useState('');
    const [isUsingMouse, setIsUsingMouse] = useState(false);

    const {
        showSuggestions,
        setShowSuggestions,
        onClickSuggestion,
        suggestions,
        containerRef,
    } = useAutocompleteSuggestions({
        value,
        filterFn,
        sortFn,
        possibilities,
        onClickItem,
        clearOnClick,
        setValue,
    });

    const { isFocused, isNoResults, onKeyDown, focusedIdx, setFocusedIdx } =
        useAutocompleteFocus({
            isLoading,
            suggestions,
            possibilities,
            value,
            onClickSuggestion,
            permitManualEntry,
        });

    const onInputBlur = () => {
        if (focusedIdx === null) {
            setShowSuggestions(false);
        }
    };

    const onInputFocus = () => {
        setShowSuggestions(true);
    };

    return (
        <FlexCol
            aria-labelledby={
                label ? `autocompleteLabel_${props.id}` : undefined
            }
            position="relative"
            width="100%"
            height="fit-content"
            gap={PX.SPACING.REM.S}
        >
            {label && (
                <FONTS.P1 id={`autocompleteLabel_${props.id}`} fontWeight={500}>
                    {label}
                </FONTS.P1>
            )}
            <FlexCol position="relative" className="inputContainer">
                <TextInput
                    data-cy={`${props['data-cy']}_autocomplete`}
                    onBlur={onInputBlur}
                    value={value}
                    onChange={(e) => {
                        onInputChange?.(e.target.value);
                        setValue(e.target.value);
                    }}
                    onFocus={onInputFocus}
                    iconLeft={searchIconOnRight ? undefined : 'Search'}
                    iconRight={searchIconOnRight ? 'Search' : undefined}
                    position="relative"
                    width="100%"
                    onKeyDown={onKeyDown}
                    {...props}
                />
                {showSuggestions && (
                    <FlexCol
                        ref={containerRef}
                        position="absolute"
                        top="42px"
                        className="autoCompleteSuggestions"
                        maxHeight={240}
                        overflowY="scroll"
                        width="100%"
                        zIndex={2}
                        boxShadow={SHADOWS.md}
                        onMouseEnter={() => setIsUsingMouse(true)}
                        onMouseLeave={() => {
                            setFocusedIdx(null);
                            setIsUsingMouse(false);
                        }}
                    >
                        {isNoResults && (
                            <>
                                {permitManualEntry ? (
                                    <SuggestionItem
                                        isUsingMouse={isUsingMouse}
                                        item={{
                                            label: `Enter: "${value}"`,
                                            value: value,
                                            disabled: false,
                                        }}
                                        idx={-1}
                                        setFocusedIdx={setFocusedIdx}
                                        borderBottom={`1px solid ${COLORS.UTIL.Gray[500]}`}
                                        onClickSuggestion={onClickSuggestion}
                                        isKeyboardFocused={isFocused(0)}
                                    />
                                ) : (
                                    <SuggestionItem
                                        isUsingMouse={isUsingMouse}
                                        item={{
                                            label: 'No results found',
                                            value: '',
                                        }}
                                        labelColor={COLORS.PRIMARY.Grey}
                                        idx={-1}
                                        setFocusedIdx={setFocusedIdx}
                                        borderBottom={`1px solid ${COLORS.UTIL.Gray[500]}`}
                                    />
                                )}

                                <SuggestionItem
                                    isUsingMouse={isUsingMouse}
                                    label={
                                        <FONTS.H6 color={COLORS.PRIMARY.Grey}>
                                            {noResultsHeader}
                                        </FONTS.H6>
                                    }
                                    labelColor={COLORS.PRIMARY.Grey}
                                    idx={-1}
                                    setFocusedIdx={setFocusedIdx}
                                />
                                {possibilities.map((item, idx) => (
                                    <SuggestionItem
                                        isUsingMouse={isUsingMouse}
                                        key={`suggestion_${item.label}_${idx}`}
                                        item={item}
                                        idx={idx}
                                        isKeyboardFocused={isFocused(idx + 1)}
                                        onClickSuggestion={onClickSuggestion}
                                        setFocusedIdx={setFocusedIdx}
                                    />
                                ))}
                            </>
                        )}
                        {!isLoading &&
                            suggestions.map((item, idx) => (
                                <SuggestionItem
                                    isUsingMouse={isUsingMouse}
                                    key={`item_${item.label}_${idx}`}
                                    item={item}
                                    idx={idx}
                                    onClickSuggestion={onClickSuggestion}
                                    setFocusedIdx={setFocusedIdx}
                                    isKeyboardFocused={isFocused(idx + 1)}
                                />
                            ))}
                        {isLoading && (
                            <SuggestionItem
                                isUsingMouse={isUsingMouse}
                                item={{ value: '', label: 'Loading...' }}
                                idx={0}
                                setFocusedIdx={setFocusedIdx}
                            />
                        )}
                    </FlexCol>
                )}
            </FlexCol>
            {subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
        </FlexCol>
    );
};

const SuggestionItem = ({
    labelColor,
    label,
    item,
    onClickSuggestion,
    setFocusedIdx,
    idx,
    isKeyboardFocused,
    isUsingMouse,
    ...props
}: FlexProps & {
    isKeyboardFocused?: boolean;
    labelColor?: string;
    label?: ReactElement;
    item?: AutoCompletePossibility;
    setFocusedIdx: (_val: number) => void;
    onClickSuggestion?: (_item: AutoCompletePossibility) => () => void;
    isUsingMouse: boolean;
    idx: number;
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const isInteractive = !item?.disabled && !!onClickSuggestion;

    const hoverFocusStyle = isInteractive
        ? { background: COLORS.UTIL.Gray[400] }
        : undefined;

    useEffect(() => {
        if (isKeyboardFocused && !isUsingMouse) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (ref.current as any).scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
    });

    return (
        <FlexRow
            ref={ref}
            key={`suggestion-auto-complete-${idx}`}
            alignItems="start"
            onMouseEnter={() => setFocusedIdx(idx)}
            background={
                item?.disabled ? COLORS.UTIL.Gray[300] : COLORS.UTIL.Gray.LIGHT
            }
            boxShadow={SHADOWS.md}
            padding={`${PX.SPACING.REM.S} ${PX.SPACING.REM.M}`}
            transition="all 0.2s ease-in"
            cursor={isInteractive ? 'pointer' : 'default'}
            _hover={hoverFocusStyle}
            {...(isKeyboardFocused && !isUsingMouse ? hoverFocusStyle : {})}
            _active={
                isInteractive
                    ? {
                          background: COLORS.UTIL.Gray[500],
                          boxShadow: SHADOWS.lg,
                      }
                    : undefined
            }
            onClick={
                !!item && !item?.disabled
                    ? onClickSuggestion?.(item)
                    : undefined
            }
            {...props}
        >
            {label || (
                <FONTS.P1
                    color={isInteractive ? labelColor : COLORS.UTIL.Gray[700]}
                >
                    {item?.label}
                </FONTS.P1>
            )}
        </FlexRow>
    );
};
