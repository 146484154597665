import { FlexProps } from '@chakra-ui/react';
import { MouseEventHandler, ReactNode } from 'react';
import COLORS from 'ui/colors';
import { FlexRow } from 'ui/loulaFlex';
import PX, { SPACING } from 'ui/px';
import SHADOWS from 'ui/shadows';

export const ClickableSheetItem = ({
    onClick,
    children,
    ...props
}: FlexProps & {
    onClick?: MouseEventHandler;
    children: ReactNode;
}) => {
    return (
        <FlexRow
            className="clickable_sheet_item"
            padding={SPACING.PX.M}
            width="100%"
            cursor="pointer"
            background={COLORS.UTIL.Gray.SHEET}
            borderRadius={PX.RADII.LG}
            boxShadow={SHADOWS.elevationOne}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            transition="all 0.3s"
            justifyContent="space-between"
            _hover={{ background: COLORS.UTIL.Gray[300] }}
            _active={{ background: COLORS.UTIL.Gray[400] }}
            onClick={(e) => {
                onClick?.(e);
                e.stopPropagation();
                e.preventDefault();
            }}
            {...props}
        >
            {children}
        </FlexRow>
    );
};
