import { createContext, useContext } from 'react';
import { EditClientDrawerContextType } from './editClientDrawer.models';

export const EditClientDrawerContext = createContext(
    {} as EditClientDrawerContextType | undefined,
);

export const useEditClientDrawerContext = ():
    | EditClientDrawerContextType
    | undefined => {
    const ctx = useContext(EditClientDrawerContext);
    return ctx;
};
