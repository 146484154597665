import { Divider } from '@chakra-ui/react';
import { InfoSheetCardField } from 'doulaPortal/pages/ClientDetails/SheetCard';
import { AlertVisualScheme, Alert } from 'ui/components/Alert';
import { LINK } from 'ui/components/Link';
import { TextTag } from 'ui/components/TextTag';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { DrawerFormLayout } from './DrawerFormLayout';
import { useEditClientDrawerContext } from './editClientDrawer.context';
import { usePregnancyInfo } from 'doulaPortal/pages/ClientDetails/clientDetails.hooks';
import { useState } from 'react';
import { MoveToPostPartumModal } from './MoveToPostPartumModal';
import { AdditionalPostPartumReccModal } from 'doulaPortal/pages/ClientDetails/AdditionalPostPartumReccModal';
import { FormDateInput } from 'ui/components/Form/FormDateInput';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';

export const PregnancyTab = () => {
    return (
        <DrawerFormLayout title="Pregnancy">
            <FlexCol gap={PXSTR.L}>
                <PregnancyStatusSection />
                <Divider />
                <ProviderReccSection />
            </FlexCol>
        </DrawerFormLayout>
    );
};

const PregnancyStatusSection = () => {
    const {
        errors,
        register,
        pregnancyStatus,
        statusColorScheme,
        isPrenatal,
        isMoveToPostPartumModalOpen,
        onOpenPostPartumModal,
        onClosePostPartumModal,
        setValue,
        watch,
    } = usePregnancyTab();

    return (
        <FlexCol gap="inherit">
            <MoveToPostPartumModal
                onClose={onClosePostPartumModal}
                isOpen={isMoveToPostPartumModalOpen}
            />
            <FlexRow justifyContent="space-between">
                <InfoSheetCardField label="Pregnancy Status">
                    <TextTag
                        width="fit-content"
                        text={pregnancyStatus}
                        colorScheme={statusColorScheme}
                    />
                </InfoSheetCardField>

                {isPrenatal && (
                    <LINK.Large onClick={onOpenPostPartumModal}>
                        Move client to Postpartum
                    </LINK.Large>
                )}
            </FlexRow>

            <FormDateInput
                fieldName="deliveryDate"
                label={
                    isPrenatal ? 'Expected Due Date' : 'Date Pregnancy Ended'
                }
                minDate={new Date(2020, 1, 1)}
                maxDate={new Date(2099, 1, 1)}
                required={true}
                errors={errors}
                setValue={setValue}
                register={register}
                watch={watch}
            />
        </FlexCol>
    );
};

const ProviderReccSection = () => {
    const {
        isAddtlPostPartumReccModalOpen,
        setIsAddtlPostPartumReccModalOpen,
        onClickAdditionalPostpartumRecc,
        isReccUploaded,
    } = usePregnancyTab();

    return (
        <FlexCol gap="inherit">
            <AdditionalPostPartumReccModal
                isOpen={isAddtlPostPartumReccModalOpen}
                onClose={() => setIsAddtlPostPartumReccModalOpen(false)}
            />
            <FONTS.H6>PROVIDER RECOMMENDATION</FONTS.H6>

            <FlexRow justifyContent="space-between" alignItems="end">
                <FlexCol gap={PXSTR.XS}>
                    <FONTS.P1 fontWeight={500}>
                        Additional Postpartum Visits?
                    </FONTS.P1>

                    <TextTag text="none" width="fit-content" />
                </FlexCol>

                <LINK.Large onClick={onClickAdditionalPostpartumRecc}>
                    {isReccUploaded ? 'View' : 'Upload'} Recommendation
                </LINK.Large>
            </FlexRow>

            <Alert
                visualScheme={AlertVisualScheme.subtle}
                contentComponents={
                    <FONTS.P1>
                        This client may receive up to 9 additional postpartum
                        visits with a provider recommendation.{' '}
                        <LINK.Large>Learn more</LINK.Large>
                        {/**TODO-Mina where should this link to */}
                    </FONTS.P1>
                }
            />
        </FlexCol>
    );
};

const usePregnancyTab = () => {
    const [isMoveToPostPartumModalOpen, setIsMoveToPostPartumModalOpen] =
        useState(false);

    const pageCtx = useClientDetailsContext();
    const { filteredFiles } = pageCtx ?? {};
    const drawerCtx = useEditClientDrawerContext();
    const { register, formState, setValue, watch, client } = drawerCtx ?? {};
    const { errors } = formState ?? {};

    const isReccUploaded = !!filteredFiles?.length;

    const pregnancyInfo = usePregnancyInfo(client);

    const onClosePostPartumModal = () => {
        setIsMoveToPostPartumModalOpen(false);
    };

    const onOpenPostPartumModal = () => {
        setIsMoveToPostPartumModalOpen(true);
    };

    return {
        register,
        setValue,
        watch,
        errors,
        isMoveToPostPartumModalOpen,
        onOpenPostPartumModal,
        onClosePostPartumModal,
        isReccUploaded,
        ...pregnancyInfo,
    };
};
