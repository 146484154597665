import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';

export type TestDrawerProps = {
    else: string;
};

export const TestDrawer = (props: TestDrawerProps) => {
    // eslint-disable-next-line no-console
    console.log('2', props);

    return (
        <FlexCol>
            <FONTS.H1>TESTESTESTET</FONTS.H1>
        </FlexCol>
    );
};
