import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from '@chakra-ui/react';
import FileUploader from 'components/FileUpload/FileUploader';
import { HIPAA_TRAINING_LINK } from 'models/constants';
import { FileUpload } from 'models/schemas';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { ReactElement, useContext } from 'react';
import { Alert } from 'ui/components/Alert';
import { LINK } from 'ui/components/Link';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';

export const DocumentationSubstepThree = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData } = onboardingContext ?? {};

    const { formState, setValue } = formInstance ?? {};
    const { errors } = formState ?? {};

    //TODO-Mina clean up just need this PR in
    const onChangeFile =
        (fieldName: string) => (val: FileUpload[] | undefined) => {
            setValue?.(fieldName, val, {
                shouldDirty: true,
                shouldValidate: true,
            });
        };

    return (
        <Flex {...commonStyling} aria-label="additional documentation">
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <Alert content="These documents were not required for Medi-Cal enrollment, but they are required to get credentialed with Managed Care Plans" />
                <FormControl
                    isInvalid={!!errors?.proofOfLiabilityInsurance}
                    mt={4}
                    isRequired
                >
                    <FormLabel display="none">
                        Proof of Liability Insurance
                    </FormLabel>

                    <FlexCol gap={PX.SPACING.PX.XL}>
                        <FileUploader
                            label="Upload a copy of your practice’s professional liability coverage (with coverage limits). "
                            sublabelComponent={
                                <FONTS.P2>
                                    Professional liability insurance coverage is
                                    a requirement to get credentialed with
                                    managed care plans through Loula. Your
                                    insurance must cover at the minimum{' '}
                                    <b>$1M per incident</b> and{' '}
                                    <b>$3M in aggregate</b>.
                                </FONTS.P2>
                            }
                            data-cy="proof-of-liability-insurance"
                            onChange={onChangeFile('proofOfLiabilityInsurance')}
                            maxFiles={1}
                            fieldValue={
                                formData?.['proofOfLiabilityInsurance'] ?? []
                            }
                        />
                        <FormErrorMessage>
                            {errors?.proofOfLiabilityInsurance &&
                                errors?.proofOfLiabilityInsurance.message?.toString()}
                        </FormErrorMessage>

                        <FormControl
                            isRequired
                            isInvalid={
                                !!formInstance?.formState.errors
                                    .hipaaCertificate
                            }
                        >
                            <FormLabel display="none">
                                Upload a certificate of completion for HIPAA
                                Training
                            </FormLabel>

                            <FileUploader
                                label="Upload a certificate of completion for HIPAA Training"
                                sublabelComponent={
                                    <FONTS.P2>
                                        Need an up-to-date HIPAA training
                                        certificate? Complete a quick, free
                                        online training{' '}
                                        <LINK.Small link={HIPAA_TRAINING_LINK}>
                                            here
                                        </LINK.Small>
                                        . You will need to create an account to
                                        complete the training.
                                    </FONTS.P2>
                                }
                                onChange={onChangeFile('proofOfHIPAATraining')}
                                fieldValue={
                                    formData?.['proofOfHIPAATraining'] ?? []
                                }
                                maxFiles={1}
                            />

                            <FormErrorMessage>
                                {formInstance?.formState.errors
                                    .hipaaCertificate &&
                                    formInstance?.formState.errors.hipaaCertificate.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>
                    </FlexCol>
                </FormControl>
            </FlexCol>
        </Flex>
    );
};
