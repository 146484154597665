import { Tooltip as BaseToolTip, TooltipProps } from '@chakra-ui/react';
import { TOOL_TIP_FLASH_LENGTH } from 'models/constants/animationConstants';
import { useState } from 'react';

export const Tooltip = ({ ...props }: TooltipProps) => {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    const [activeTimeout, setActiveTimeout] = useState<number | null>(null);

    const flashTooltip = () => {
        setShowToolTip(true);
        if (activeTimeout != null) {
            clearTimeout(activeTimeout);
        }

        setActiveTimeout(
            setTimeout(() => {
                setShowToolTip(false);
                setActiveTimeout(null);
            }, TOOL_TIP_FLASH_LENGTH),
        );
    };

    return (
        <BaseToolTip {...props} isOpen={showToolTip}>
            <span
                onClick={flashTooltip}
                onTouchStart={flashTooltip}
                onMouseEnter={flashTooltip}
            >
                {props.children}
            </span>
        </BaseToolTip>
    );
};
