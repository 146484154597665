import {
    Box,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Tab,
    TabList,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ALL_DRAWERS, AllDrawersEnum } from '../drawers/drawers';
import { usePortalContext } from 'doulaPortal/context/usePortalContext';
import { ColRow, FlexCol, FlexRow } from 'ui/loulaFlex';
import { Zyg } from 'util/Utils';
import COLORS from 'ui/colors';
import PX, { PXSTR } from 'ui/px';
import {
    useDrawerTabId,
    useOpenDrawerOnLoad,
} from 'doulaPortal/hooks/useDrawerURL';
import { DrawerTabs } from 'doulaPortal/models/layout.models';
import { ReactNode } from 'react';
import FONTS from 'ui/fonts';
import { IconButton } from 'ui/components/IconButton';
import { useCloseDrawer } from 'doulaPortal/drawers/useDrawers';

export const DrawerLayout = () => {
    const { drawerId } = useParams();

    const portalContext = usePortalContext();

    useOpenDrawerOnLoad({
        drawerId: drawerId as AllDrawersEnum,
        portalContext,
    });

    const DrawerComponent =
        !!drawerId && drawerId in ALL_DRAWERS
            ? ALL_DRAWERS[drawerId as AllDrawersEnum]
            : null;

    //I can't figure out how to not have this any here, but we had already enforced props when
    //opening the modal, so I'm not devastated by assuming it's typed correctly here
    //Plus the drawer's themselves will type it specifically
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const drawerProps = portalContext?.drawerProps as any;

    if (!DrawerComponent) return null;

    return (
        <Drawer
            variant="base"
            size="baseSize"
            isOpen={portalContext?.isDrawerOpen ?? false}
            placement={Zyg('bottom', 'right')}
            onClose={portalContext?.closeDrawer ?? (() => null)}
            //TODO this? finalFocusRef={drawerProps?.ref}
        >
            <DrawerOverlay />

            <DrawerContent>
                <ColRow width="100%" height="100%" switchSize="lg">
                    <Box
                        onClick={() => {
                            portalContext?.closeDrawer();
                        }}
                        className="minEmptyWidthSpacer"
                        width={Zyg('100%', '200px', 'lg')}
                        background="transparent"
                        height={Zyg('50px', '100%', 'lg')}
                        marginLeft={Zyg(0, 'auto')}
                    />
                    <Box
                        className="drawerContentWrapper"
                        flex={1}
                        height="100%"
                        bg={COLORS.UTIL.Gray.SHEET}
                        borderTopLeftRadius={PX.RADII.LG}
                        borderTopRightRadius={Zyg(PX.RADII.LG, 0)}
                        borderBottomLeftRadius={Zyg(0, PX.RADII.LG)}
                    >
                        <DrawerComponent {...(drawerProps ?? {})} />
                    </Box>
                </ColRow>
            </DrawerContent>
        </Drawer>
    );
};

/**
 * For Drawers that use a tab structure, this layout handles it and selects the active url if applicable
 */
export const DrawerTabsLayout = ({
    tabs,
    initialTabId,
}: {
    tabs: DrawerTabs[];
    initialTabId?: string;
}) => {
    const { tabIdx, onManualChangeTab } = useDrawerTabId({
        tabs,
        initialTabId,
    });

    return (
        <FlexCol paddingTop={PXSTR.M} height="100%">
            <Tabs
                index={tabIdx}
                onChange={onManualChangeTab}
                variant="base"
                width="100%"
                height="100%"
                maxHeight="100%"
                display="flex"
                flexDir="column"
            >
                <TabList
                    width="100%"
                    overflowX="scroll"
                    paddingLeft={PXSTR.L}
                    background={COLORS.UTIL.Gray.WARM}
                >
                    {tabs.map((tab) => {
                        return (
                            <Tab key={`tab_${tab.tabId}`} id={tab.tabId}>
                                {tab.tabTitle}
                            </Tab>
                        );
                    })}
                </TabList>
                <TabPanels
                    flex={1}
                    overflowY="hidden"
                    overscrollBehavior="none"
                    background={COLORS.UTIL.Gray.SHEET}
                >
                    {tabs.map((tab) => {
                        return tab.tabPanel;
                    })}
                </TabPanels>
            </Tabs>
        </FlexCol>
    );
};

/**
 * For Drawers that have a simple structure, just a title and body
 */
export const DrawerSimpleLayout = ({
    title,
    children,
}: {
    title: string;
    children?: ReactNode;
}) => {
    const closeDrawer = useCloseDrawer();

    return (
        <FlexCol justifyContent="start" height="100%" overflowY="scroll">
            <FlexRow
                paddingY={PXSTR.M}
                paddingX={PXSTR.L}
                justifyContent="space-between"
                borderBottom={`1px solid ${COLORS.UTIL.Gray[500]}`}
                background={COLORS.UTIL.Gray.WARM}
            >
                <FONTS.H3>{title}</FONTS.H3>
                <IconButton icon="Close" onClick={closeDrawer} />
            </FlexRow>
            <Box background={COLORS.UTIL.Gray.SHEET} padding={PXSTR.L}>
                {children}
            </Box>
        </FlexCol>
    );
};
