import AddressForm from 'components/AddressForm';
import { Pronouns } from 'models/constants';
import {
    FormMultiselect,
    MultiselectOptionType,
} from 'ui/components/Form/FormMultiselect';
import { FormTextInput } from 'ui/components/Form/FormTextInput';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { DrawerFormLayout } from './DrawerFormLayout';
import { useEditClientDrawerContext } from './editClientDrawer.context';
import { FormDateInput } from 'ui/components/Form/FormDateInput';
import { setMultiselectVal } from 'util/Utils';
import { FieldValues } from 'react-hook-form';

const usePersonalTab = () => {
    const ctxt = useEditClientDrawerContext();
    const { register, formState, setValue, watch } = ctxt ?? {};
    const { errors } = formState ?? {};

    const pronouns = watch?.('pronouns');

    return {
        register,
        setValue,
        watch,
        errors,

        pronouns,
    };
};

export const PersonalTab = () => {
    const { register, watch, setValue, errors, pronouns } = usePersonalTab();

    return (
        <DrawerFormLayout title="Personal Details">
            <FlexCol gap={PXSTR.L} maxWidth="320px">
                <FormTextInput
                    maxWidth="320px"
                    label="Legal First Name"
                    fieldName="user.firstName"
                    register={register}
                    errors={errors}
                />
                <FormTextInput
                    label="Legal Last Name"
                    fieldName="user.lastName"
                    register={register}
                    errors={errors}
                />
                <FormTextInput
                    label="Preferred First Name"
                    fieldName="user.preferredName"
                    register={register}
                    errors={errors}
                />

                <FormDateInput
                    fieldName="user.dob"
                    label="Date of Birth"
                    minDate={new Date(1900, 1, 1)}
                    maxDate={new Date()}
                    required={true}
                    errors={errors}
                    setValue={setValue}
                    register={register}
                    watch={watch}
                />
            </FlexCol>

            {/**TODO: pronoun selector should be made its own component, and stiiiiill need to add self-describe ability */}
            <FormMultiselect
                data-cy="pronouns"
                fieldName="user.pronouns"
                label="Pronouns"
                value={pronouns}
                setValue={setMultiselectVal<FieldValues>('pronouns', setValue)}
                errors={errors}
                subLabel="We strive to use gender-inclusive language. 
                We will default to gender-neutral language if no pronouns are listed."
                isRequired={false}
                options={
                    Object.keys(Pronouns).map(
                        (pronounKey): MultiselectOptionType => {
                            return {
                                label: pronounKey,
                                value: pronounKey,
                            };
                        },
                    )
                    // .concat([
                    //     //TODO-Mina: Finish ability for fully custom pronouns, and for displaying error state
                    //     // {
                    //     //     label: 'I want to self-describe',
                    //     //     value: 'custom',
                    //     //     clearsOtherChoices: true,
                    //     //     customPlaceholder:
                    //     //         'i.e. she, they, ze, he/they',
                    //     // },
                    //     {
                    //         label: 'I prefer not to say',
                    //         value: 'undeclared',
                    //         isExclusive: true,
                    //     },
                    // ])
                }
                registerProps={
                    !!register
                        ? {
                              ...register('pronouns'),
                          }
                        : undefined
                }
            />

            <AddressForm
                label="Client's Address"
                namePrefix="user"
                requiredWarningText="Please enter a valid mailing address."
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
            />
        </DrawerFormLayout>
    );
};
