import { VisitTypeCopy } from 'models/constants/visitConstants';
import { VisitModel } from 'models/schemas';
import { Divider } from 'ui/components/Divider';
import { TextTag } from 'ui/components/TextTag';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { VisitField } from './VisitField';
import { useClient } from 'hooks/dbHooks/clients.hooks';
import { UserTag } from 'ui/components/AvatarTag';
import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { getFullServicesString, getFullVisitLocation } from 'util/visit.utils';
import { useNavigate } from 'react-router-dom';

export const VisitMainData = ({ visit }: { visit: VisitModel | null }) => {
    const { client, combinedLocationString, combinedServicesString } =
        useVisitMainData(visit);

    const navigate = useNavigate();

    if (!visit) return null;

    return (
        <FlexCol flex={1} flexBasis="100%" padding={PXSTR.L} gap={PXSTR.L}>
            <FONTS.H3 mb={PXSTR.S}>{VisitTypeCopy[visit.visitType]}</FONTS.H3>
            <FlexRow justifyContent="space-between">
                <VisitField
                    title="Visit Date"
                    simpleText={format(parseISO(visit.dateOfService), 'M/d/yy')}
                />
                <VisitField title="Client">
                    <UserTag
                        user={client?.user}
                        onClick={() =>
                            navigate(`/clients/${visit.patientUserId}`)
                        }
                    />
                </VisitField>
            </FlexRow>

            <Divider />

            <FlexRow justifyContent="space-between">
                <VisitField
                    title="Visit Start Time"
                    simpleText={format(parseISO(visit.dateOfService), 'p')}
                />
                <VisitField
                    title="Visit Duration"
                    simpleText={`${visit.durationMinutes} min`}
                />
            </FlexRow>

            <Divider />

            <FlexRow justifyContent="space-between">
                <VisitField title="Visit Mode">
                    <TextTag text={visit.mediumOfService} />
                </VisitField>
                <VisitField
                    title="Visit Location"
                    simpleText={combinedLocationString}
                />
            </FlexRow>

            <Divider />

            <VisitField
                title="Services Performed"
                simpleText={combinedServicesString}
            />
        </FlexCol>
    );
};

const useVisitMainData = (visit: VisitModel | null) => {
    const { client } = useClient(visit?.patientUserId); //TODO: only the context call should have this useclient call, that'll smooth out the load

    const combinedLocationString = useMemo(
        () => getFullVisitLocation(visit),
        [visit],
    );

    const combinedServicesString = useMemo(
        () => getFullServicesString(visit),
        [visit],
    );

    return {
        client,
        combinedLocationString,
        combinedServicesString,
    };
};
