import { FlexCol } from 'ui/loulaFlex';
import { useSubmitVisitContext } from '../submitVisit.context';
import { useMemo } from 'react';
import {
    VisitCategory,
    VisitServicesCopy,
} from 'models/constants/visitConstants';
import {
    FormMultiselect,
    getFormMultiSelectRegisterProps,
} from 'ui/components/Form/FormMultiselect';
import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import { setMultiselectVal } from 'util/Utils';
import { StepFiveFields } from '../submitVisit.models';
import { determineVisitType } from 'util/visit.utils';

const useVisitServicesStep = () => {
    const ctx = useSubmitVisitContext();
    const { stepFiveForm, stepTwoForm } = ctx ?? {};
    const { register, watch, setValue, formState } = stepFiveForm ?? {};
    const { errors } = formState ?? {};

    const services = watch?.('services') ?? {};

    const { visitServicesOptions, fullVisitType } = useMemo(() => {
        const stepTwoValues = stepTwoForm?.getValues();
        const { visitCategory, perinatalType } = stepTwoValues ?? {};
        const fullVisitType = determineVisitType(stepTwoValues);

        const serviceOptionsObj =
            visitCategory === VisitCategory.LaborDelivery ||
            visitCategory === VisitCategory.PostpartumExtended
                ? VisitServicesCopy[visitCategory]
                : !!perinatalType
                  ? VisitServicesCopy[perinatalType]
                  : {};

        const visitServicesOptions = Object.entries(serviceOptionsObj).map(
            ([service, { label, desc }]) => {
                return {
                    label: label,
                    value: service,
                    isDecorated: true,
                    decorationDesc: desc,
                };
            },
        );

        return {
            visitServicesOptions,
            fullVisitType,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepTwoForm, stepTwoForm?.formState.isDirty]);

    return {
        services,
        setValue,
        visitServicesOptions,
        fullVisitType,
        register,
        watch,
        errors,
    };
};

export const VisitServicesStep = () => {
    const {
        visitServicesOptions,
        fullVisitType,
        register,
        services,
        setValue,
        errors,
    } = useVisitServicesStep();

    return (
        <FlexCol>
            <FormMultiselect
                fieldName="services"
                label={`What services did you provide for this ${fullVisitType} visit?`}
                registerProps={
                    !!register
                        ? {
                              ...register(
                                  'services',
                                  getFormMultiSelectRegisterProps(true),
                              ),
                          }
                        : undefined
                }
                errors={errors}
                value={services}
                setValue={setMultiselectVal<StepFiveFields>(
                    'services',
                    setValue,
                )}
                options={visitServicesOptions}
            />

            <Alert
                content="Your response to this question won’t affect how much you will be paid, but it is required for record-keeping purposes."
                visualScheme={AlertVisualScheme.periwinkle}
            />
        </FlexCol>
    );
};
