import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from '@chakra-ui/react';
import FileUploader from 'components/FileUpload/FileUploader';
import { FileUpload } from 'models/schemas';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { ReactElement, useContext, useState } from 'react';
import { LINK } from 'ui/components/Link';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';

export const DocumentationSubstepOne = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData } = onboardingContext ?? {};
    const { setValue } = formInstance ?? {};
    const [, setLoading] = useState(false);
    //console.log('uploading: ', loading);

    const { formState } = formInstance ?? {};

    const { errors } = formState ?? {};

    //TODO-Mina clean up just need this PR in
    const onChangeFile =
        (fieldName: string) => (val: FileUpload[] | undefined) => {
            setValue?.(fieldName, val, {
                shouldDirty: true,
                shouldValidate: true,
            });
        };

    return (
        <Flex {...commonStyling} aria-label="provider enrollment documentation">
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <FormControl
                    isInvalid={!!errors?.mediCalEnrollmentFile}
                    mt={4}
                    isRequired
                >
                    <FormLabel display="none">
                        Medicaid/Medi-Cal Approval Letter
                    </FormLabel>

                    <FileUploader
                        label="Upload the approval letter you received when you became
                        enrolled as a Medi-Cal Provider"
                        sublabelComponent={
                            <FlexRow alignItems="center" gap={PXSTR.XS}>
                                <FONTS.P2>
                                    This letter should include your “Application
                                    Identification Number” and the “Date
                                    Application Received”
                                </FONTS.P2>
                                <LINK.Small link="https://intercom.help/loula-c9161e7faea2/en/articles/10236922-loula-s-dhcs-medi-cal-enrollment-letter-requirement">
                                    View Example
                                </LINK.Small>
                            </FlexRow>
                        }
                        data-cy="medi-cal-enrollment-file"
                        onLoadingChange={setLoading}
                        maxFiles={1}
                        fieldValue={formData?.['mediCalEnrollmentFile'] ?? []}
                        onChange={onChangeFile('mediCalEnrollmentFile')}
                    />

                    <FormErrorMessage>
                        {errors?.mediCalEnrollmentFile &&
                            errors?.mediCalEnrollmentFile.message?.toString()}
                    </FormErrorMessage>
                </FormControl>

                <FormControl
                    isRequired
                    mt={4}
                    isInvalid={!!errors?.cprCertificate}
                >
                    <FormLabel display="none">
                        Upload a copy of your CPR training certificate
                    </FormLabel>

                    <FileUploader
                        label="Upload your CPR cerfication"
                        subLabel=" The CPR certification must be an adult and infant
                        certification from the American Heart Association or the
                        American Red Cross. The training has to be in person,
                        either in its entirety or hybrid (online and in person).
                        You may upload multiple certificates."
                        onChange={onChangeFile('cprCertificate')}
                        fieldValue={formData?.['cprCertificate'] ?? []}
                        onLoadingChange={setLoading}
                        maxFiles={1}
                    />

                    <FormErrorMessage>
                        {errors?.cprCertificate &&
                            errors?.cprCertificate.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </FlexCol>
        </Flex>
    );
};
