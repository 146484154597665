import { createContext, useContext } from 'react';
import { NPISearchContextType } from './npiSearch.models';

export const NPISearchContext = createContext(
    {} as NPISearchContextType | undefined,
);

export const useNPISearchContext = () => {
    const ctx = useContext(NPISearchContext);
    return ctx;
};
