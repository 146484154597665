import { Box, FlexProps } from '@chakra-ui/react';
import { useState } from 'react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import { FlexRow } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import { IconButton } from './IconButton';

export enum AlertVisualScheme {
    default = 'default',
    default_no_icon = 'default_no_icon',
    subtle = 'subtle',
    periwinkle = 'periwinkle',
    error = 'error',
}

const VisualSchemes: Record<
    AlertVisualScheme,
    { icon?: keyof typeof ICONS; background: string; color?: string }
> = {
    [AlertVisualScheme.default]: {
        icon: 'WarningCircle',
        background: COLORS.UTIL.WarningOrange[200],
    },
    [AlertVisualScheme.default_no_icon]: {
        icon: undefined,
        background: COLORS.UTIL.WarningOrange[200],
        color: COLORS.UTIL.WarningOrange[800],
    },
    [AlertVisualScheme.subtle]: {
        icon: undefined,
        background: COLORS.UTIL.Gray[300],
    },
    [AlertVisualScheme.periwinkle]: {
        icon: undefined,
        background: COLORS.BRAND.Periwinkle[200],
        color: COLORS.PRIMARY.Blue,
    },
    [AlertVisualScheme.error]: {
        icon: undefined,
        background: COLORS.UTIL.Red[200],
        color: COLORS.UTIL.Red[800],
    },
};

export const Alert = ({
    visualScheme = AlertVisualScheme.default,
    content,
    contentComponents,
    isDismissable,
    ...props
}: FlexProps & {
    visualScheme?: AlertVisualScheme;
    content?: string;
    contentComponents?: React.ReactElement;
    'data-cy'?: string;
    isDismissable?: boolean;
}) => {
    const [isDismissed, setIsDismissed] = useState(false);

    const iconSize = 24;
    const IconComponent =
        VisualSchemes[visualScheme].icon == undefined
            ? null
            : ICONS[VisualSchemes[visualScheme].icon];

    if (isDismissable && isDismissed) return null;

    return (
        <FlexRow
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            padding={PXSTR.M}
            gap={PXSTR.M}
            background={VisualSchemes[visualScheme].background}
            borderRadius={PX.RADII.MD}
            color={VisualSchemes[visualScheme].color}
            {...props}
            data-cy={`${props['data-cy']}_alert`}
        >
            {IconComponent && (
                <Box width={`${iconSize}px`} height="100%">
                    <IconComponent
                        fontSize={iconSize}
                        color={COLORS.PRIMARY.Warning}
                    />
                </Box>
            )}

            {contentComponents ? (
                contentComponents
            ) : (
                <FONTS.P1
                    color={VisualSchemes[visualScheme].color}
                    overflowWrap="break-word"
                >
                    {content}
                </FONTS.P1>
            )}

            {isDismissable && (
                <IconButton
                    color={VisualSchemes[visualScheme].color}
                    icon="Close"
                    onClick={() => setIsDismissed(true)}
                />
            )}
        </FlexRow>
    );
};
