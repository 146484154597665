import { format } from 'date-fns';
import { TextTag } from 'ui/components/TextTag';
import { determineCycle } from 'util/visit.utils';

export const CycleTag = ({
    date,
}: {
    date: string | Date | undefined | null;
}) => {
    if (!date) return null;

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const cycle = determineCycle(date);

    if (!cycle) return null;

    return (
        <TextTag
            text={`${format(cycle[0], 'M/d/yy')} - ${format(cycle[1], 'M/d/yy')}`}
        />
    );
};
