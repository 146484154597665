import { FlexProps } from '@chakra-ui/react';
import { ReactElement } from 'react';
import COLORS from 'ui/colors';
import { IconButton, IconButtonType } from 'ui/components/IconButton';
import { SheetTab } from 'ui/components/Sheet';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';

export const SheetCard = ({
    title,
    children,
    onClickEdit,
}: {
    title: string;
    children?: ReactElement;
    onClickEdit: () => void;
}) => {
    return (
        <SheetTab
            width="100%"
            minWidth="290px"
            maxWidth="332px"
            padding={PXSTR.M}
            display="flex"
            flexDirection="column"
            gap={PXSTR.M}
        >
            <FlexRow alignItems="center" justifyContent="space-between">
                <FONTS.H4>{title}</FONTS.H4>
                <IconButton
                    icon="EditPage"
                    iconBtnType={IconButtonType.decorated}
                    onClick={onClickEdit}
                />
            </FlexRow>
            {children}
        </SheetTab>
    );
};

export const InfoSheetCardField = ({
    label,
    children,
    ...props
}: FlexProps & {
    label: string;
    children?: ReactElement;
}) => {
    return (
        <FlexCol gap={PXSTR.XS} {...props}>
            <FONTS.P2 fontWeight={600} color={COLORS.PRIMARY.Grey}>
                {label}
            </FONTS.P2>
            {children}
        </FlexCol>
    );
};
