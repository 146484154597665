import {
    NpiFacilityResponseItem,
    NpiProviderResponseItem,
} from 'models/schemas';
import { UseFormReturn } from 'react-hook-form';

export interface NPISearchProps {
    facilityType?: NPIFacilityType;
    label?: string;
    subLabel?: string;
    setValue: (val: string) => void;
    initialValue: string | undefined;
    onClose?: () => void;
}

export type NPISearchContextType = {
    isIndividual: boolean;

    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    closeAndReset: () => void;
    isLimitedToStateWatch: boolean;
    isLoading: boolean;
    showResults: boolean;

    search: () => Promise<void>;
    back: () => void;
    selectNpi: (_npi: string) => () => void;

    error: string | null;
    selectedNpi: string | undefined;
    setIsLimitedToState: (val: boolean) => void;
    individualNPIData: NpiProviderResponseItem[];
    facilityNPIData: NpiFacilityResponseItem[];

    resultsHeader: string;
    facilityType?: NPIFacilityType;

    individualForm: UseFormReturn<IndividualSearchFields>;
    facilityForm: UseFormReturn<FacilitySearchFields>;

    firstNameWatch: string;
    lastNameWatch: string;
};

export enum NPIFacilityType {
    INDIVIDUAL,
    FACILITY,
}

type SearchFields = {
    isLimitedToState: boolean;
};

export type IndividualSearchFields = SearchFields & {
    firstName: string;
    lastName: string;
};

export type FacilitySearchFields = SearchFields & {
    facilityName: string;
    city: string;
    taxonomy: string;
};
