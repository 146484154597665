import { PatientReturnType } from 'models/schemas';
import { Avatar } from 'ui/components/Avatar';
import { SheetTab } from 'ui/components/Sheet';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { getInitials } from 'util/name.utils';
import { getNameToShow } from 'util/Utils';
import { VisitField } from '../VisitDetails/VisitField';
import { PregnancyStatusTag } from '../ClientDetails/PregnancyStatusTag';
import { format, parseISO } from 'date-fns';
import { SecondaryButton } from 'ui/components/Button';
import { useNavigate } from 'react-router-dom';
import { PayerStatusTag } from '../ClientDetails/PayerStatusTag';

export const ClientOverviewCard = ({
    client,
}: {
    client: PatientReturnType;
}) => {
    const navigate = useNavigate();

    const goToClient = () => {
        if (!client) return;
        navigate(`/clients/${client.userId}`);
    };

    return (
        <SheetTab
            width="100%"
            minWidth="290px"
            maxWidth="332px"
            padding={PXSTR.M}
            display="flex"
            flexDirection="column"
            gap={PXSTR.M}
        >
            <FlexRow gap={PXSTR.S} padding={PXSTR.S}>
                <Avatar initials={getInitials(client?.user)} size="xs" />
                <FONTS.H3>{getNameToShow(client?.user)}</FONTS.H3>
            </FlexRow>

            <FlexRow justifyContent="space-between" alignItems="center">
                <VisitField title="Pregnancy Status">
                    <PregnancyStatusTag status="TODO PREG STATUS" />
                </VisitField>

                {!!client?.pregnancies[0]?.deliveryDate && (
                    <VisitField
                        title="Expected Due Date"
                        simpleText={format(
                            parseISO(client.pregnancies[0].deliveryDate),
                            'M/d/yy',
                        )}
                    />
                )}
            </FlexRow>

            <FlexRow justifyContent="space-between" alignItems="center">
                <VisitField
                    title="Insurance"
                    boldText={true}
                    simpleText="TODO Insurance"
                />

                <PayerStatusTag status="TODO Insurance status" />
            </FlexRow>

            <SecondaryButton onClick={goToClient}>View client</SecondaryButton>
        </SheetTab>
    );
};
