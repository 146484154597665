import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import { ReactElement, useMemo } from 'react';
import { VisitCategory } from 'models/constants/visitConstants';
import FONTS from 'ui/fonts';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { usePractitioner, useProviderContracts } from 'hooks/dbHooks';
import { useClientVisits } from 'hooks/dbHooks/visits.hooks';
import { GetPregnancy } from 'util/patient.utils';
import { useSubmitVisitContext } from '../submitVisit.context';
import { useAllVisitSortValues } from './useAllVisitSortValues';
import { isPostpartum, isPrenatal } from 'util/visit.utils';

export const useVisitDateValidation = () => {
    const ctx = useSubmitVisitContext();
    const { stepThreeForm, stepTwoForm, client } = ctx ?? {};

    const { allVisits } = useClientVisits(client?.userId);

    const {
        recentToEarliestSortedVisits,
        earliestVisit,
        earliestVisitDate,
        initialVisit,
        initialVisitDate,
        earliestPostPartumVisit,
        earliestPostPartumDate,
        latestPrenatalVisitDate,
    } = useAllVisitSortValues(allVisits);

    const { contractData, contractStartDate } =
        useContractValuesForDateValidation();

    const visitCategory = stepTwoForm?.watch('visitCategory');
    const visitPerinatalType = stepTwoForm?.watch('perinatalType');
    const dateOfPregnancyConclusion = stepTwoForm?.watch(
        'dateOfPregnancyConclusion',
    );

    const { isPostPartum, isPreNatal } = useMemo(() => {
        const isPostPartum = isPostpartum(visitCategory, visitPerinatalType);
        const isPreNatal = isPrenatal(visitCategory, visitPerinatalType);
        return { isPreNatal, isPostPartum };
    }, [visitCategory, visitPerinatalType]);

    //Return true if valid (reaches end)
    //If any error, format and return an element
    const visitDateValidator = (
        date: Date | null | undefined,
    ): true | string | ReactElement => {
        if (!date) return 'Required';
        let component: ReactElement | null = null;

        if (
            isBefore(date, contractStartDate) &&
            !isSameDay(date, contractStartDate)
        ) {
            component = (
                <FONTS.P1>
                    Your contract with <b>{contractData?.payers.name}</b>{' '}
                    started on <b>{format(contractStartDate, 'M/d/yy')}</b>.
                    Visits must be <b>on or after</b>{' '}
                    {format(contractStartDate, 'M/d/yy')}
                </FONTS.P1>
            );
        }

        const sameDayVisits = recentToEarliestSortedVisits.filter((visit) =>
            isSameDay(new Date(visit.dateOfService), date),
        );

        if (sameDayVisits.length > 0) {
            //TODO: a perinatal visit can be claimed same day as a L&D delivery

            component = (
                <FONTS.P1>
                    You’ve submitted a <b>{sameDayVisits[0].visitType}</b> visit
                    on <b>{format(date, 'M/d/yy')}</b>. You may only submit one
                    visit per day.
                    <br />
                    Labor & Delivery visits may be submitted on the same day as
                    a Perinatal visit.
                </FONTS.P1>
            );
        }

        if (!!initialVisit && isBefore(date, initialVisitDate)) {
            component = (
                <FONTS.P1>
                    You’ve submitted a <b>Initial, 90-minute</b> visit on{' '}
                    <b>{format(initialVisitDate, 'M/d/yy')}</b>. All other
                    visits must be performed after that date.
                </FONTS.P1>
            );
        }

        if (
            visitCategory === VisitCategory.Initial &&
            !!earliestVisitDate &&
            isAfter(date, earliestVisitDate)
        ) {
            component = (
                <FONTS.P1>
                    You’ve submitted a <b>{earliestVisit?.visitType}</b> visit
                    on <b>{format(earliestVisitDate, 'M/d/yy')}</b>. Initial
                    visits must be performed before all other visits.
                </FONTS.P1>
            );
        }

        const preExistingDeliveryDate = GetPregnancy(client)?.deliveryDate;
        const deliveryDate =
            dateOfPregnancyConclusion ??
            (!!preExistingDeliveryDate
                ? new Date(preExistingDeliveryDate)
                : undefined);

        if (isPreNatal && !!deliveryDate && isAfter(date, deliveryDate)) {
            component = (
                <FONTS.P1>
                    This client’s pregnancy ended on{' '}
                    <b>{format(deliveryDate, 'M/d/yy')}</b>. Perinatal, Prenatal
                    visits must be <b>on or before</b>{' '}
                    {format(deliveryDate, 'M/d/yy')}.
                </FONTS.P1>
            );
        }

        if (
            visitCategory === VisitCategory.LaborDelivery &&
            !!latestPrenatalVisitDate &&
            isBefore(date, latestPrenatalVisitDate)
        ) {
            const formatedDate = format(latestPrenatalVisitDate, 'M/d/yy');
            component = (
                <FONTS.P1>
                    You’ve submitted a <b>Prenatal</b> visit on {formatedDate}.
                    Labor & Delivery visits must be on or after {formatedDate}.
                </FONTS.P1>
            );
        }

        if (
            visitCategory === VisitCategory.LaborDelivery &&
            !!earliestPostPartumDate &&
            isAfter(date, earliestPostPartumDate)
        ) {
            const formatedDate = format(earliestPostPartumDate, 'M/d/yy');
            component = (
                <FONTS.P1>
                    You’ve submitted a{' '}
                    <b>{earliestPostPartumVisit?.visitType}</b> visit on
                    <b>{formatedDate}</b>. Labor & Delivery visits must be on or
                    before
                    {formatedDate}.
                </FONTS.P1>
            );
        }

        if (isPostPartum && !!deliveryDate && isBefore(date, deliveryDate)) {
            component = (
                <FONTS.P1>
                    This client’s pregnancy ended on{' '}
                    <b>{format(deliveryDate, 'M/d/yy')}</b>. Postpartum visits
                    must be <b>on or after</b> {format(deliveryDate, 'M/d/yy')}.
                </FONTS.P1>
            );
        }

        //-----End of all the date logic

        if (!!component) {
            //Below is just form stuff, not logic related

            stepThreeForm?.setError?.('dateOfService', { type: 'validate' });
            return (
                <Alert
                    contentComponents={component}
                    visualScheme={AlertVisualScheme.error}
                />
            );
        }

        return true;
    };

    const formHookWrapper = (date: Date | null | undefined): true | string => {
        const fullRes = visitDateValidator(date);
        if (typeof fullRes !== 'string' && typeof fullRes !== 'boolean') {
            return 'custom error';
        }
        return fullRes;
    };

    return { visitDateValidator, formHookWrapper };
};

const useContractValuesForDateValidation = () => {
    const { practitionerData } = usePractitioner();
    const { providerContractData } = useProviderContracts();

    const vals = useMemo(() => {
        //TODO: This will need to pull the payer relavant to the patient, and check that contract
        //right now it just picks one that has an effective date (which for the demo was set to all the same one)
        const contractAssigment = !!practitionerData?.assignments?.length
            ? practitionerData?.assignments.find(
                  (assignment) =>
                      !!assignment.effectiveDate && !!assignment.expirationDate,
              )
            : undefined;

        //TODO: can we just have a contract assignment return type that includes this?
        const contractData = providerContractData.find(
            (data) => data.contracts.id === contractAssigment?.contractId,
        );

        const contractStartDate = new Date(
            contractData?.practitioner_contract_assignments?.effectiveDate ??
                '',
        );

        return {
            contractData,
            contractStartDate,
        };
    }, [practitionerData?.assignments, providerContractData]);

    return vals;
};
