import { useMemo } from 'react';

export const useGetFormRadioRegisterProps = <T>(
    fieldName?: string,
    requiredWarningText?: string,
    customValidate?: (val: unknown) => boolean,
    deps?: (keyof T)[],
) => {
    const props = useMemo(() => {
        return {
            validate: (x: unknown) =>
                (x != undefined &&
                    x?.toString()?.length > 0 &&
                    (!customValidate || customValidate(x))) ||
                (requiredWarningText ??
                    `${fieldName ?? 'Selection'} is required`),
            shouldUnregister: true,
            deps,
        };
    }, [customValidate, requiredWarningText, fieldName, deps]);

    return props;
};
