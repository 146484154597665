import { FlexRow } from 'ui/loulaFlex';
import FONTS from 'ui/fonts';
import { PXSTR } from 'ui/px';
import {
    NumberInputProps,
    NumberInput as BaseNumberInput,
    NumberInputField,
    NumberIncrementStepper,
    NumberInputStepper,
    NumberDecrementStepper,
    Box,
} from '@chakra-ui/react';
import { ICONS } from 'ui/icons';
import COLORS from 'ui/colors';
import { useMemo, useRef, useState } from 'react';
import { Sheet } from './Sheet';
import { TextInputStyling } from './TextInput';
import { useOnClickOutside } from 'usehooks-ts';

export const NumberInput = ({
    label,
    showDropdown,
    ...props
}: NumberInputProps & { label?: string; showDropdown?: boolean }) => {
    const [renderDropdown, setRenderDropdown] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(containerRef, () => setRenderDropdown(false));

    const onSelectFromDropdown =
        (val: number) => (_e: React.MouseEvent<HTMLDivElement>) => {
            props.onChange?.(val.toString(), val);
            setRenderDropdown(false);
        };

    const dropdownOptionValues = useMemo(() => {
        const { min, max, step } = props;
        const res: number[] = [];
        for (let i = min ?? 0; i <= (max ?? min ?? 0); i += step ?? 0) {
            res.push(i);
        }
        return showDropdown ? res : [];
    }, [showDropdown, props]);

    //Dropping style elements that numberInputField can't handle
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { size, ...inputStyles } = TextInputStyling;

    return (
        <FlexRow
            gap={PXSTR.SM}
            alignItems="center"
            position="relative"
            width="fit-content"
        >
            <Box position="relative" width="fit-content">
                <BaseNumberInput
                    {...props}
                    onClick={() => setRenderDropdown(true)}
                >
                    <NumberInputField {...inputStyles} />
                    <NumberInputStepper>
                        <NumberIncrementStepper
                            children={
                                <ICONS.CaretUp color={COLORS.PRIMARY.Grey} />
                            }
                        />
                        <NumberDecrementStepper
                            children={
                                <ICONS.CaretDown color={COLORS.PRIMARY.Grey} />
                            }
                        />
                    </NumberInputStepper>
                </BaseNumberInput>
                {showDropdown &&
                    renderDropdown &&
                    dropdownOptionValues.length > 1 && (
                        <Sheet
                            ref={containerRef}
                            position="absolute"
                            top="42px"
                            className="numberInputSuggestions"
                            maxHeight="200px"
                            overflowY="scroll"
                            width="100%"
                            zIndex={2}
                            // onMouseEnter={() => setIsUsingMouse(true)}
                            // onMouseLeave={() => {
                            //     setFocusedIdx(null);
                            //     setIsUsingMouse(false);
                            // }}
                        >
                            {dropdownOptionValues.map((val, idx) => (
                                <NumberOption
                                    key={`number_option_${idx}`}
                                    val={val}
                                    onClick={onSelectFromDropdown(val)}
                                    isSelected={props.value === val}
                                />
                            ))}
                        </Sheet>
                    )}
            </Box>

            {!!label && <FONTS.P1>{label}</FONTS.P1>}
        </FlexRow>
    );
};

const NumberOption = ({
    val,
    onClick,
    isSelected,
}: {
    val: number;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    isSelected?: boolean;
}) => {
    return (
        <FlexRow
            cursor="pointer"
            onClick={onClick}
            width="100%"
            height={PXSTR.XL}
            transition="all 0.3s"
            _hover={{ background: COLORS.UTIL.Gray[400] }}
            paddingX={PXSTR.M}
            paddingY={PXSTR.S}
            background={isSelected ? COLORS.UTIL.Gray[300] : undefined}
            alignItems="center"
        >
            <FONTS.P1>{val}</FONTS.P1>
        </FlexRow>
    );
};
