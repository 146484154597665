import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { TextTag } from 'ui/components/TextTag';
import { usePandadocs } from 'hooks/dbHooks';
import {
    CredentialingExternalMapping,
    CredentiallingExternalStatus,
    CredentiallingStatus,
    StatusToColorSchemeMapping,
} from 'models/constants';
import { ColRow, FlexCol, RowCol } from 'ui/loulaFlex';
import { SecondaryButton } from 'ui/components/Button';
import PX from 'ui/px';
import { ProviderContractReturnType } from 'models/schemas';
import FONTS from 'ui/fonts';
import COLORS from 'ui/colors';
import { DefaultStringifiedDate } from 'util/Utils';
import { useCallback, useState } from 'react';
import { EmbeddedPandadocPage } from './EmbeddedPandadocPage';

export const CredentialStatusTable = ({
    providerContractData,
    updateContractStatus,
}: {
    providerContractData: ProviderContractReturnType[];
    updateContractStatus: (
        assignmentId: string,
        newStatus: CredentiallingStatus,
    ) => Promise<void>;
}) => {
    const { pandadocsDocumentData, getData: refreshPandadocsData } =
        usePandadocs();

    const {
        externalPayerIdDocToOpen,
        setExternalPayerIdDocToOpen,
        ...embeddedWindowEventHandlers
    } = usePandadocsForContractSignatures({
        providerContractData,
        refreshPandadocsData,
        updateContractStatus,
    });

    return (
        <FlexCol>
            <EmbeddedPandadocPage
                documentLink={
                    externalPayerIdDocToOpen !== null
                        ? pandadocsDocumentData?.get(externalPayerIdDocToOpen)
                            ?.sharedLink
                        : undefined
                }
                {...embeddedWindowEventHandlers}
            />

            <Table variant="minimalist">
                <Thead>
                    <Tr>
                        <Th w="25%" scope="col">
                            Status
                        </Th>
                        <Th scope="col">Plan Name</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {providerContractData.map((contractData, idx) => (
                        <Tr key={`contract_status_${idx}`}>
                            <Td>
                                <TextTag
                                    width="fit-content"
                                    colorScheme={
                                        StatusToColorSchemeMapping[
                                        CredentialingExternalMapping[
                                        contractData
                                            .practitioner_contract_assignments
                                            .credentiallingStatus
                                        ]
                                        ]
                                    }
                                    text={
                                        CredentialingExternalMapping[
                                        contractData
                                            .practitioner_contract_assignments
                                            .credentiallingStatus
                                        ]
                                    }
                                />
                            </Td>
                            <Td maxW="85%">
                                <ColRow
                                    maxW="100%"
                                    justify="space-between"
                                    align="center"
                                    wrap="wrap"
                                >
                                    <RowCol gap={PX.SPACING.PX.S}>
                                        {contractData.payers.name}

                                        {CredentialingExternalMapping[
                                            contractData
                                                .practitioner_contract_assignments
                                                .credentiallingStatus
                                        ] ===
                                            CredentiallingExternalStatus.ActionNeeded &&
                                            pandadocsDocumentData?.has(
                                                contractData.payers
                                                    .externalPayerId,
                                            ) && (
                                                <SecondaryButton
                                                    iconRight="Launch"
                                                    onClick={() => {
                                                        setExternalPayerIdDocToOpen(
                                                            contractData.payers
                                                                .externalPayerId,
                                                        );
                                                    }}
                                                >
                                                    Sign & attest
                                                </SecondaryButton>
                                            )}

                                        <ExtraStatusInfo
                                            contractData={contractData}
                                        />
                                    </RowCol>

                                </ColRow>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </FlexCol>
    );
};

const ExtraStatusInfo = ({
    contractData,
}: {
    contractData: ProviderContractReturnType;
}) => {
    const practitionerContractAssignment = contractData.practitioner_contract_assignments;
    const externalStatus =
        CredentialingExternalMapping[
        practitionerContractAssignment.credentiallingStatus
        ];

    let textInfo = 'Extra Info';

    const planEffectiveEstimate = practitionerContractAssignment.contractStartEstimate && `Credentialing should take ${practitionerContractAssignment.contractStartEstimate}`;
    const planEffective = practitionerContractAssignment.effectiveDate && `Plan effective starting ${DefaultStringifiedDate(new Date(contractData.practitioner_contract_assignments.effectiveDate), false)}`;

    if (
        externalStatus === CredentiallingExternalStatus.Processing ||
        externalStatus === CredentiallingExternalStatus.ActionNeeded
    ) {
        return null;
    } else if (externalStatus === CredentiallingExternalStatus.Credentialed) {
        textInfo = planEffective || planEffectiveEstimate;
    } else if (externalStatus === CredentiallingExternalStatus.UnderReview) {
        const signaturesReceivedDate = new Date(
            contractData.practitioner_contract_assignments.signaturesReceivedDate,
        );

        textInfo = `Signatures received on ${DefaultStringifiedDate(signaturesReceivedDate, false)}`;

        return (
            <FONTS.P1 color={COLORS.PRIMARY.Grey}>
                <p>{textInfo}</p>
                {planEffective && <p>{planEffective}</p>}
                {!planEffective && planEffectiveEstimate && <p>{planEffectiveEstimate}</p>}
            </FONTS.P1>
        );
    }

    return (
        <FONTS.P1 color={COLORS.PRIMARY.Grey}>
            <p>{textInfo}</p>
        </FONTS.P1>
    );
};

const usePandadocsForContractSignatures = ({
    providerContractData,
    refreshPandadocsData,
    updateContractStatus,
}: {
    providerContractData: ProviderContractReturnType[];
    refreshPandadocsData: () => Promise<void>;
    updateContractStatus: (
        assignmentId: string,
        newStatus: CredentiallingStatus,
    ) => Promise<void>;
}) => {
    const [externalPayerIdDocToOpen, setExternalPayerIdDocToOpen] = useState<
        string | null
    >(null);

    const onClosed = useCallback(async () => {
        setExternalPayerIdDocToOpen(null);
        await refreshPandadocsData();
    }, [refreshPandadocsData]);

    const getContractIdFromPayerId = useCallback(
        (payerId: string): string | undefined => {
            return providerContractData.find(
                (contractData) =>
                    contractData.payers.externalPayerId === payerId,
            )?.practitioner_contract_assignments.id;
        },
        [providerContractData],
    );

    const onCompleted = useCallback(async () => {
        if (!externalPayerIdDocToOpen) return;

        const contractId = getContractIdFromPayerId(externalPayerIdDocToOpen);

        if (contractId === undefined) {
            // eslint-disable-next-line no-console
            console.error(
                'trying to open doc without a contract in place, something went wrong',
            );
            return;
        }

        await updateContractStatus(
            contractId,
            CredentiallingStatus.SignaturesRecieved,
        );

        onClosed();
    }, [
        externalPayerIdDocToOpen,
        getContractIdFromPayerId,
        onClosed,
        updateContractStatus,
    ]);

    //TODO-Idk if theres a way to actually hear this event, it was avail from DropboxSign but not sure bout Pandadoc
    const onDeclined = useCallback(async () => {
        if (!externalPayerIdDocToOpen) return;

        const contractId = getContractIdFromPayerId(externalPayerIdDocToOpen);

        if (contractId === undefined) {
            // eslint-disable-next-line no-console
            console.error(
                'trying to open doc without a contract in place, something went wrong',
            );
            return;
        }

        await updateContractStatus(
            contractId,
            CredentiallingStatus.SignaturesDeclined,
        );

        onClosed();
    }, [
        externalPayerIdDocToOpen,
        getContractIdFromPayerId,
        onClosed,
        updateContractStatus,
    ]);

    return {
        externalPayerIdDocToOpen,
        setExternalPayerIdDocToOpen,
        onClosed,
        onDeclined,
        onCompleted,
    };
};
