import FONTS from 'ui/fonts';
import { FlexRow } from '../loulaFlex';
import PX, { PXSTR } from 'ui/px';
import COLORS from 'ui/colors';
import { Box, FlexProps, Image } from '@chakra-ui/react';
import { IconButton } from 'ui/components/IconButton';
import { Avatar, AvatarColorSchemes, AvatarSizing } from './Avatar';
import { useMemo } from 'react';
import { PayerModel, UserModel } from 'models/schemas';
import { getNameToShow } from 'util/Utils';

type AvatarTagProps = {
    name: string;
    colorScheme?: AvatarColorSchemes;
    imageLocation?: string;
    onClick?: () => void;
    onClickClose?: () => void;
    verticallyPadded?: boolean;
};

export const UserTag = ({
    user,
    ...props
}: Omit<AvatarTagProps, 'name'> & {
    user?: UserModel;
    onClick?: () => void;
    onClickClose?: () => void;
}) => {
    if (!user) return null;

    return (
        <AvatarTag
            name={getNameToShow(user)}
            colorScheme={AvatarColorSchemes.Green} //TODO: once this is added at the user level, pull from that
            {...props}
        />
    );
};

export const PayerTag = ({
    payer,
    ...props
}: Omit<AvatarTagProps, 'name'> & {
    payer?: PayerModel;
    onClick?: () => void;
    onClickClose?: () => void;
}) => {
    if (!payer) return null;

    return (
        <AvatarTag
            name={payer.name}
            imageLocation={`/payerLogos/${'kaiser'}.png`} //TODO: once images are gathered, add those to the payer instead of Kaiser here
            {...props}
        />
    );
};

export const AvatarTag = ({
    name,
    colorScheme,
    imageLocation,
    onClick,
    onClickClose,
    verticallyPadded = true,
    ...props
}: FlexProps & AvatarTagProps) => {
    const initials = useMemo(() => {
        const splitName = name?.split(' ') ?? [];
        const initials =
            (splitName.length > 0 ? splitName[0].charAt(0) : '') +
            (splitName.length > 1 ? splitName[1].charAt(0) : '');

        return initials;
    }, [name]);

    const focusStyle = {
        background: COLORS.UTIL.Gray[500],
        outline: 'none',
    };

    return (
        <FlexRow
            align="center"
            justify="center"
            gap={PXSTR.XS}
            borderRadius={PX.RADII.ROUNDED}
            paddingY={verticallyPadded ? PXSTR.XS : 0}
            paddingX={PXSTR.S}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            background={COLORS.UTIL.Gray.LIGHT}
            _hover={focusStyle}
            _focus={focusStyle}
            tabIndex={!!onClick ? 0 : -1}
            onClick={onClick}
            cursor={!!onClick ? 'pointer' : 'default'}
            {...props}
        >
            {!!imageLocation ? (
                <Box
                    width={AvatarSizing.sm}
                    height={AvatarSizing.sm}
                    borderRadius={PX.RADII.ROUNDED}
                >
                    <Image
                        borderRadius="inherit"
                        width="100%"
                        height="100%"
                        src={imageLocation}
                        alt={`${name} Avatar`}
                    />
                </Box>
            ) : (
                <Avatar
                    initials={initials}
                    colorScheme={colorScheme}
                    size="xs"
                />
            )}

            <FONTS.P2 fontWeight={500}>{name}</FONTS.P2>

            {onClickClose && (
                <IconButton
                    marginLeft={PXSTR.XS}
                    icon="Close"
                    color={COLORS.STROKES.HEAVY}
                    onClick={onClickClose}
                    size={16}
                />
            )}
        </FlexRow>
    );
};
