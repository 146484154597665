import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ALL_DRAWERS, AllDrawersEnum } from '../drawers/drawers';
import { useCallback, useEffect, useState } from 'react';
import { PortalContextType } from 'doulaPortal/context/portalContext.model';
import { DrawerTabs } from 'doulaPortal/models/layout.models';

/**
 * Since we want drawers to be url-linked, this makes sure that if you
 * are on a url with a drawerId in it, then open the drawer accordingly
 * @param param0
 */
export const useOpenDrawerOnLoad = ({
    portalContext,
    drawerId,
}: {
    portalContext: PortalContextType | undefined;
    drawerId: AllDrawersEnum;
}) => {
    const { drawerTabId } = useParams();

    const { pathname } = useLocation();

    const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(
        portalContext?.isDrawerOpen ?? false,
    );

    useEffect(
        function openDrawerOnLoad() {
            if (
                !portalContext?.isDrawerOpen &&
                !hasBeenOpened &&
                !!drawerId &&
                drawerId in ALL_DRAWERS
            ) {
                setHasBeenOpened(true);
                portalContext?.openDrawer({
                    drawerId,
                    shouldNavigate: false,
                    baseUrl: pathname.replace(
                        `/${drawerId}${drawerTabId !== undefined ? `/${drawerTabId}` : ''}`,
                        '',
                    ),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    props: {} as any, //There's no props we know of if we're opening on load, we just wanna go to that page
                });
            }
        },
        [hasBeenOpened, portalContext, pathname, drawerId, drawerTabId],
    );
};

/**
 * Since we want drawers to be url-linked, this makes sure that if you
 * are on a url with a drawerId in it, then open the drawer accordingly
 * And also handle manually changing it, or opening the drawer imperatively
 * with a specific tab in mind
 */
export const useDrawerTabId = ({
    tabs,
    initialTabId,
}: {
    tabs: DrawerTabs[];
    initialTabId?: string;
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { drawerTabId } = useParams();

    const [tabIdx, setTabIdx] = useState(-1);
    const [hasBeenOpenedOnLoad, setHasBeenOpenedOnLoad] = useState<boolean>();
    const [hasBeenOpenedOnInitial, setHasBeenOpenedOnInitial] =
        useState<boolean>();

    const onManualChangeTab = useCallback(
        (newIdx: number) => {
            if (tabIdx === newIdx) return;
            if (newIdx < 0 || newIdx > tabs.length - 1) return;

            const openTab = tabIdx > -1 ? tabs[tabIdx] : null;
            const newTab = tabs[newIdx];

            if (!newTab) {
                return;
            }

            const currentBaseUrl = pathname.replace(`/${openTab?.tabId}`, '');
            const newUrl = currentBaseUrl + '/' + newTab.tabId;

            navigate(newUrl, {
                replace: true,
            });

            setHasBeenOpenedOnInitial(true);
            setHasBeenOpenedOnLoad(true);
            setTabIdx(newIdx);
        },
        [navigate, pathname, tabIdx, tabs],
    );

    useEffect(
        function openDrawerOnLoad() {
            if (!hasBeenOpenedOnLoad && drawerTabId !== undefined) {
                setHasBeenOpenedOnLoad(true);
                const idx = tabs.findIndex((tab) => tab.tabId === drawerTabId);
                if (idx > -1) {
                    setTabIdx(idx);
                }
            }
        },
        [hasBeenOpenedOnLoad, drawerTabId, tabs],
    );

    useEffect(
        function openInitialDrawer() {
            if (!hasBeenOpenedOnInitial) {
                onManualChangeTab(
                    tabs.findIndex((tab) => tab.tabId === initialTabId) ?? 0,
                );
                setHasBeenOpenedOnInitial(true);
            }
        },
        [hasBeenOpenedOnInitial, initialTabId, onManualChangeTab, tabs],
    );

    return {
        tabIdx,
        onManualChangeTab,
    };
};
