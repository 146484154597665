import { InfoSheetCardField, SheetCard } from './SheetCard';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { TextTag, TextTagColorSchemes } from 'ui/components/TextTag';
import FONTS from 'ui/fonts';
import { format, formatDate } from 'date-fns';
import { LINK } from 'ui/components/Link';
import { generateAddressInReadableString, nameToShow } from 'util/Utils';
import {
    useClientInsurance,
    useClientUserDetails,
    useOpenEditClientDrawer,
    usePregnancyInfo,
} from './clientDetails.hooks';
import { useClientDetailsContext } from './clientDetails.context';
import { AdditionalPostPartumReccModal } from './AdditionalPostPartumReccModal';
import { Divider } from 'ui/components/Divider';
import COLORS from 'ui/colors';
import { InsurerRelationship } from 'models/constants/clientConstants';
import { useState } from 'react';
import { InsuranceVerificationStatusTag } from './InsuranceVerificationStatusTag';

export const ClientInfo = () => {
    return (
        <FlexCol
            className="infoCardsContainer"
            height="100%"
            width="100%"
            overflowY="scroll"
            overflowX="hidden"
            gap={PXSTR.M}
            paddingBottom={PXSTR.XL}
        >
            <PregnancyCard />
            <InsuranceCard />
            <PersonalDetailsCard />
            <ContactCard />
        </FlexCol>
    );
};

const PregnancyCard = () => {
    const clientDetailsContext = useClientDetailsContext();
    const { client } = clientDetailsContext ?? {};

    const {
        pregnancyStatus,
        statusColorScheme,
        postPartumStatus,
        additionalPostPartumColorScheme,
        deliveryDate,
        onClickAdditionalPostpartumRecc,
        isAddtlPostPartumReccModalOpen,
        setIsAddtlPostPartumReccModalOpen,
    } = usePregnancyInfo(client);

    const { openDrawer } = useOpenEditClientDrawer('pregnancy');

    return (
        <SheetCard title="Pregnancy" onClickEdit={openDrawer}>
            <FlexCol gap={PXSTR.L}>
                <AdditionalPostPartumReccModal
                    isOpen={isAddtlPostPartumReccModalOpen}
                    onClose={() => setIsAddtlPostPartumReccModalOpen(false)}
                />
                <FlexRow>
                    <InfoSheetCardField flex={1} label="Status">
                        <TextTag
                            width="fit-content"
                            text={pregnancyStatus}
                            colorScheme={statusColorScheme}
                        />
                    </InfoSheetCardField>
                    <InfoSheetCardField
                        flex={1}
                        label={
                            pregnancyStatus === 'Prenatal'
                                ? 'Due Date'
                                : 'Date of Delivery'
                        }
                    >
                        <FONTS.P2>
                            {!!deliveryDate
                                ? format(new Date(deliveryDate), 'MM/d/yy')
                                : 'Unknown'}
                        </FONTS.P2>
                    </InfoSheetCardField>
                </FlexRow>

                {pregnancyStatus === 'Postpartum' && (
                    <FONTS.P2>
                        This client is eligible for postpartum care until{' '}
                        <b>UNKNOWN DATE</b>.
                    </FONTS.P2>
                )}

                {pregnancyStatus === 'Inactive' && (
                    <FONTS.P2>
                        This client’s care expired on <b>UNKNOWN DATE</b>.
                    </FONTS.P2>
                )}

                <InfoSheetCardField
                    flex={1}
                    label="Additional Postpartum Visits?"
                >
                    <FlexRow justifyContent="space-between" alignItems="center">
                        <TextTag
                            width="fit-content"
                            text={postPartumStatus}
                            colorScheme={additionalPostPartumColorScheme}
                        />
                        <LINK.Small onClick={onClickAdditionalPostpartumRecc}>
                            {postPartumStatus !== 'None' ? 'View' : 'Upload'}{' '}
                            Recommendation
                        </LINK.Small>
                    </FlexRow>
                </InfoSheetCardField>
            </FlexCol>
        </SheetCard>
    );
};

const InsuranceCard = () => {
    const { primaryInsurance, secondaryInsurance } = useClientInsurance();
    const { openDrawer } = useOpenEditClientDrawer('insurance');
    const [showSubscriberDetails, setShowSubscriberDetails] = useState(false);
    const nonSelfInsurance =
        primaryInsurance?.patientRelationshipToSubscriber !==
        InsurerRelationship.Self
            ? primaryInsurance
            : secondaryInsurance?.patientRelationshipToSubscriber !==
                InsurerRelationship.Self
              ? secondaryInsurance
              : undefined;

    //TODO: we need actual insurance status - like, they dont automatiaclly go to active, theyre in reve

    return (
        <SheetCard title="Insurance" onClickEdit={openDrawer}>
            <FlexCol gap={PXSTR.M}>
                <FlexRow justifyContent="space-between">
                    <FONTS.H5>{primaryInsurance?.payer?.name}</FONTS.H5>
                    <InsuranceVerificationStatusTag
                        status={primaryInsurance?.verificationStatus}
                    />
                </FlexRow>
                <FlexRow>
                    <InfoSheetCardField flex={1} label="Member ID">
                        <FONTS.P2>{primaryInsurance?.memberId}</FONTS.P2>
                    </InfoSheetCardField>
                    <InfoSheetCardField flex={1} label="Group ID">
                        <FONTS.P2>{primaryInsurance?.groupNumber}</FONTS.P2>
                    </InfoSheetCardField>
                </FlexRow>
                <LINK.Small onClick={openDrawer}>
                    View Insurance Card
                </LINK.Small>
                {!!secondaryInsurance && (
                    <>
                        <Divider />
                        <FONTS.TAG color={COLORS.PRIMARY.Grey}>
                            Other Insurance
                        </FONTS.TAG>
                        <FlexRow justifyContent="space-between">
                            <FONTS.H5>
                                {secondaryInsurance?.payer?.name}
                            </FONTS.H5>
                            <InsuranceVerificationStatusTag
                                status={secondaryInsurance?.verificationStatus}
                            />
                        </FlexRow>
                        <FlexRow>
                            <InfoSheetCardField flex={1} label="Member ID">
                                <FONTS.P2>
                                    {secondaryInsurance.memberId}
                                </FONTS.P2>
                            </InfoSheetCardField>
                            <InfoSheetCardField flex={1} label="Group ID">
                                <FONTS.P2>
                                    {secondaryInsurance.groupNumber}
                                </FONTS.P2>
                            </InfoSheetCardField>
                        </FlexRow>

                        <LINK.Small onClick={openDrawer}>
                            View Insurance Card
                        </LINK.Small>
                    </>
                )}
                {!!nonSelfInsurance && (
                    <>
                        <FlexRow>
                            <InfoSheetCardField
                                flex={1}
                                label="Plan Subscriber"
                            >
                                <FONTS.P2>
                                    {
                                        nonSelfInsurance.patientRelationshipToSubscriber
                                    }
                                </FONTS.P2>
                            </InfoSheetCardField>
                            <LINK.Small
                                onClick={() =>
                                    setShowSubscriberDetails((prev) => !prev)
                                }
                            >
                                {showSubscriberDetails ? 'Hide' : 'Show'}{' '}
                                Insured's Details
                            </LINK.Small>
                        </FlexRow>

                        {showSubscriberDetails && (
                            <>
                                <Divider />
                                <FlexCol gap="inherit">
                                    <InfoSheetCardField flex={1} label="Name">
                                        <FONTS.P2>
                                            {nameToShow(
                                                nonSelfInsurance.firstName,
                                                nonSelfInsurance.lastName,
                                            )}
                                        </FONTS.P2>
                                    </InfoSheetCardField>
                                    <FlexRow gap="inherit" flexWrap="wrap">
                                        <InfoSheetCardField
                                            label="Member ID"
                                            flexBasis="40%"
                                        >
                                            <FONTS.P2>
                                                {nonSelfInsurance.memberId}
                                            </FONTS.P2>
                                        </InfoSheetCardField>
                                        <InfoSheetCardField
                                            flexBasis="40%"
                                            label="Group ID"
                                        >
                                            <FONTS.P2>
                                                {nonSelfInsurance.groupNumber}
                                            </FONTS.P2>
                                        </InfoSheetCardField>

                                        <InfoSheetCardField
                                            flexBasis="40%"
                                            label="Date of Birth"
                                        >
                                            <FONTS.P2>
                                                {format(
                                                    new Date(
                                                        nonSelfInsurance.dob,
                                                    ),
                                                    'MM/dd/yy',
                                                )}
                                            </FONTS.P2>
                                        </InfoSheetCardField>

                                        <InfoSheetCardField
                                            flexBasis="40%"
                                            label="Phone Number"
                                        >
                                            <FONTS.P2>
                                                {nonSelfInsurance.phone}
                                            </FONTS.P2>
                                        </InfoSheetCardField>

                                        <InfoSheetCardField
                                            flexBasis="40%"
                                            label="Sex"
                                        >
                                            <FONTS.P2>
                                                {nonSelfInsurance.sex}
                                            </FONTS.P2>
                                        </InfoSheetCardField>
                                    </FlexRow>

                                    <InfoSheetCardField
                                        flex={1}
                                        label="Address"
                                    >
                                        <FONTS.P2>
                                            {generateAddressInReadableString(
                                                nonSelfInsurance,
                                            )}
                                        </FONTS.P2>
                                    </InfoSheetCardField>
                                </FlexCol>
                            </>
                        )}
                    </>
                )}
            </FlexCol>
        </SheetCard>
    );
};

const PersonalDetailsCard = () => {
    const {
        firstName,
        lastName,
        preferredName,
        dob,
        sex,
        pronouns,
        ...userFields
    } = useClientUserDetails();

    const { openDrawer } = useOpenEditClientDrawer('personal');

    return (
        <SheetCard title="Personal Details" onClickEdit={openDrawer}>
            <FlexCol gap={PXSTR.L}>
                <FlexRow width="100">
                    <InfoSheetCardField
                        flex={1}
                        label="Legal Name"
                        textOverflow="ellipsis"
                        maxW="50%"
                    >
                        <FONTS.P2
                            style={{
                                display: ' -webkit-box',
                                WebkitLineClamp: 2 /* Number of lines */,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitBoxOrient: 'vertical', //TODO: this is technically depracated, but a known issue since theres no alternative to achieve multiline ellipsis https://connect.mozilla.org/t5/discussions/the-use-of-webkit-box-orient/m-p/71929#:~:text=However%2C%20the%20CSS3%20documentation%2C%20along,and%20should%20not%20be%20used.
                            }}
                        >
                            {firstName + ' ' + lastName}
                        </FONTS.P2>
                    </InfoSheetCardField>
                    <InfoSheetCardField label="Date of Birth">
                        <FONTS.P2>
                            {!!dob
                                ? formatDate(new Date(dob), 'MM/dd/yyyy')
                                : 'UNKNOWN'}
                        </FONTS.P2>
                    </InfoSheetCardField>
                </FlexRow>

                <InfoSheetCardField label="Preferred First Name">
                    <FONTS.P2>{preferredName}</FONTS.P2>
                </InfoSheetCardField>

                <InfoSheetCardField label="Home Address">
                    <FONTS.P2 overflowWrap="break-word">
                        {generateAddressInReadableString({
                            address1: userFields.address1 ?? 'UNKNOWN',
                            address2: userFields.address2 ?? 'UNKNOWN',
                            city: userFields.city ?? 'UNKNOWN',
                            state: userFields.state ?? 'UNKNOWN',
                            zip: userFields.zip ?? 'UNKNOWN',
                            country: userFields.country ?? 'UNKNOWN',
                        })}
                    </FONTS.P2>
                </InfoSheetCardField>

                <InfoSheetCardField label="Sex">
                    <FONTS.P2>{sex ?? 'UNKNOWN'}</FONTS.P2>
                </InfoSheetCardField>

                <InfoSheetCardField label="Pronouns">
                    <FONTS.P2>
                        {Object.entries(pronouns ?? {})
                            .filter(([, selected]) => !!selected)
                            .map(([key]) => key)
                            .join(', ')}
                    </FONTS.P2>
                </InfoSheetCardField>
            </FlexCol>
        </SheetCard>
    );
};

const ContactCard = () => {
    const { phone, email } = useClientUserDetails();
    const { openDrawer } = useOpenEditClientDrawer('contact');

    return (
        <SheetCard title="Contact Information" onClickEdit={openDrawer}>
            <FlexCol gap={PXSTR.L}>
                <InfoSheetCardField flex={1} label="Phone Number">
                    <FONTS.P2>{phone ?? 'UNKNOWN'}</FONTS.P2>
                </InfoSheetCardField>
                <InfoSheetCardField flex={1} label="Email Address">
                    <FONTS.P2>{email ?? 'UNKNOWN'}</FONTS.P2>
                </InfoSheetCardField>
            </FlexCol>
        </SheetCard>
    );
};
