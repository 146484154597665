import { DoulaPortalLayout } from 'doulaPortal/layouts/DoulaPortalLayout';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'ui/components/Button';
import { FlexCol } from 'ui/loulaFlex';
import { AllClientsTable } from './AllClientsTable';
import { Box } from '@chakra-ui/react';
import { PXSTR } from 'ui/px';

const Page = () => {
    const header = 'All Clients';

    const navigate = useNavigate();

    return (
        <DoulaPortalLayout
            pageHeader={header}
            pageActionButtons={[
                <PrimaryButton
                    key="submitVisitBtn"
                    onClick={() => navigate('/visit/new')}
                >
                    Submit visit
                </PrimaryButton>,
                <PrimaryButton
                    key="createClientBtn"
                    onClick={() => navigate('/client/new')}
                >
                    Create Client
                </PrimaryButton>,
            ]}
        >
            <Clients />
        </DoulaPortalLayout>
    );
};

const Clients = () => {
    return (
        <FlexCol alignItems="center" gap={PXSTR.S} overflowY="scroll">
            <Box width="90%" maxWidth="800px" height="fit-content">
                <AllClientsTable />
            </Box>
        </FlexCol>
    );
};

export default Page;
