import { useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { TextInput, TextInputProps } from 'ui/components/TextInput';
import {
    FieldErrors,
    FieldValues,
    UseFormRegister,
    UseFormSetValue,
    ValidationRule,
} from 'react-hook-form';
import { ReadableFromCamelCase } from 'util/Utils';
import FONTS from 'ui/fonts';
import COLORS from 'ui/colors';
import PX from 'ui/px';

/**
 * Our go-to input component when building forms
 * NOTE: If using a masked field that is also secret (eg SSN), this works better when
 * passed setValue instead of register (idk why)
 * @deprecated
 */
export function FormTextInput({
    fieldName,
    minLength = 1,
    register,
    setValue,
    errors,
    required = true,
    validationRules,
    requiredWarningText,
    subLabel,
    ...inputProps
}: TextInputProps & {
    fieldName: string;
    setValue?: UseFormSetValue<FieldValues>;
    minLength?: number;
    errors: FieldErrors<FieldValues> | undefined;
    required?: boolean;
    register?: UseFormRegister<FieldValues>;
    requiredWarningText?: string;
    validationRules?: Record<string, ValidationRule>;
}) {
    const registerOptions = useMemo(() => {
        const res = {
            minLength: {
                value: minLength,
                message:
                    requiredWarningText ??
                    `Minimum length must be ${minLength}`,
            },
            required: {
                value: required,
                message:
                    requiredWarningText ??
                    `${ReadableFromCamelCase(fieldName)} is required`,
            },
        };
        if (validationRules) {
            Object.assign(res, validationRules);
        }
        return res;
    }, [minLength, required, requiredWarningText, fieldName, validationRules]);

    return (
        <FormControl flex={1} isInvalid={!!errors?.[fieldName]}>
            <FormLabel display="none" htmlFor={fieldName}>
                {inputProps.label}
            </FormLabel>
            <TextInput
                id={`${fieldName}TextInput`}
                subLabel={errors?.[fieldName] ? undefined : subLabel}
                onAccept={(_str) =>
                    setValue?.(fieldName, _str, { shouldDirty: true })
                }
                {...inputProps}
                {...(register?.(fieldName, registerOptions) ?? {})}
            />
            <FONTS.P2
                color={COLORS.PRIMARY.Error}
                minHeight={PX.SPACING.PX.M}
                mt={PX.SPACING.PX.S}
            >
                {errors?.[fieldName] &&
                    errors?.[fieldName]?.message?.toString()}
            </FONTS.P2>
        </FormControl>
    );
}
