import COLORS from 'ui/colors';
import { ColRow } from 'ui/loulaFlex';

import { Collapse } from '@chakra-ui/react';
import useScreenSize from 'hooks/useScreenSize';
import { PAGE_COLLAPSE_DURATION } from 'models/constants/animationConstants';
import { HEADER_MIN_HEIGHT } from './PageHeader';
import { PortalContext } from '../context/portalContext';
import { useInitialPortalContext } from '../context/useInitialPortalContext';
import { DoulaPortalLayoutProps } from 'doulaPortal/models/layout.models';
import { SheetComponent } from './SheetComponent';
import { NavBar } from './NavBar';

export const DoulaPortalLayout = ({
    pageHeader,
    pageHeaderComponent,
    pageActionButtons,
    children,
}: DoulaPortalLayoutProps) => {
    const { isNarrowScreen } = useScreenSize();

    const initialPortalContext = useInitialPortalContext();

    const { isFullScreenMobile, isSheetVisible, setIsSheetVisible } =
        initialPortalContext;

    return (
        <ColRow
            w="100vw"
            h="100dvh"
            position="fixed"
            overflow="hidden"
            overscrollBehavior="none"
            className="portalTopLevelLayout"
            background={COLORS.UTIL.Gray[200]}
        >
            <PortalContext.Provider value={initialPortalContext}>
                <NavBar
                    isSheetVisible={isSheetVisible}
                    setIsSheetVisible={setIsSheetVisible}
                />
                {isNarrowScreen ? (
                    <Collapse
                        startingHeight={HEADER_MIN_HEIGHT}
                        endingHeight={`calc(100% - ${isFullScreenMobile ? 0 : HEADER_MIN_HEIGHT}px)`}
                        in={isSheetVisible}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                        }}
                        transition={{
                            enter: { duration: PAGE_COLLAPSE_DURATION / 2 },
                            exit: { duration: PAGE_COLLAPSE_DURATION / 2 },
                        }}
                    >
                        <SheetComponent
                            pageActionButtons={pageActionButtons}
                            pageHeader={pageHeader}
                            pageHeaderComponent={pageHeaderComponent}
                        >
                            {children}
                        </SheetComponent>
                    </Collapse>
                ) : (
                    <SheetComponent
                        pageActionButtons={pageActionButtons}
                        pageHeader={pageHeader}
                        pageHeaderComponent={pageHeaderComponent}
                    >
                        {children}
                    </SheetComponent>
                )}
            </PortalContext.Provider>
        </ColRow>
    );
};
