import { DrawerSimpleLayout } from 'doulaPortal/layouts/DrawerLayout';
import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import { LINK } from 'ui/components/Link';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { AllOffPlatformVisitsTable } from './AllOffPlatformVisitsTable';
import { LogOffPlatformVisitModal } from './LogOffPlatformVisitModal';
import { useState } from 'react';
import { OffPlatformVisitModel } from 'models/schemas';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';
import { AlertDialog } from 'ui/AlertDialogue';
import { useSingleOffPlatformVisit } from 'hooks/dbHooks/visits.hooks';

export type OffPlatformVisitsProps = {
    else?: string;
};

export const OffPlatformVisits = (_props: OffPlatformVisitsProps) => {
    const ctx = useClientDetailsContext();
    const { client } = ctx ?? {};

    const [isLogModalOpen, setIsLogModalOpen] = useState(false);
    const [existingVisit, setExistingVisit] = useState<
        OffPlatformVisitModel | undefined
    >();

    const openToEdit = (visitId: string) => () => {
        if (!client || !client?.offPlatformVisits?.length) return;
        setExistingVisit(
            client.offPlatformVisits.find((visit) => visit.id === visitId),
        );
        setIsLogModalOpen(true);
    };

    const logNewVisit = () => {
        setExistingVisit(undefined);
        setIsLogModalOpen(true);
    };

    const [visitIdToConfirmDelete, setVisitIdToConfirmDelete] = useState<
        string | undefined
    >();

    const { deleteOffPlatformVisit } = useSingleOffPlatformVisit(
        visitIdToConfirmDelete,
    );

    const onClickDeleteButtonForVisit = (visitId: string) => () => {
        //todo show confirmation modal
        setVisitIdToConfirmDelete(visitId);
    };

    const closeModal = () => {
        setIsLogModalOpen(false);
    };

    //TODO-Mina: after delete, tell table to refrresh its data
    return (
        <DrawerSimpleLayout title="Off-platform Visits">
            {visitIdToConfirmDelete && (
                <AlertDialog
                    title="Are you sure you want to delete this visit?"
                    leastDestructiveBtnText="Cancel"
                    destructiveBtnText="Delete"
                    onClickDestructive={() => {
                        deleteOffPlatformVisit();
                        setVisitIdToConfirmDelete(undefined);
                    }}
                    isDestructiveEnabled={true}
                    isDestructiveDangerous={true}
                    onClickLeastDestructive={() => {
                        setVisitIdToConfirmDelete(undefined);
                    }}
                />
            )}
            <LogOffPlatformVisitModal
                onClose={closeModal}
                isOpen={isLogModalOpen}
                existingVisit={existingVisit}
            />
            <FlexCol gap={PXSTR.XL}>
                <AllOffPlatformVisitsTable
                    logNewVisit={logNewVisit}
                    onClickVisit={openToEdit}
                    onClickDeleteButtonForVisit={onClickDeleteButtonForVisit}
                />

                <Alert
                    visualScheme={AlertVisualScheme.periwinkle}
                    contentComponents={
                        <FlexCol gap={PXSTR.M}>
                            <FONTS.P1 color="inherit">
                                <b>
                                    You are required to log visits claimed by
                                    other doulas.
                                </b>
                                To ensure the accuracy of a client’s eligibility
                                for services, ask clients if they have ever seen
                                any other doulas prior to working with you or
                                while working with you.
                            </FONTS.P1>
                            <LINK.Large>Learn more</LINK.Large>
                        </FlexCol>
                    }
                />
            </FlexCol>
        </DrawerSimpleLayout>
    );
};
