import { ReactNode } from 'react';
import {
    Menu,
    MenuButton as BaseMenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import { DotDotDotButton } from './Button';

export const MenuButton = ({ menuItems }: { menuItems: ReactNode[] }) => {
    return (
        <Menu>
            <BaseMenuButton as={DotDotDotButton} />
            <MenuList
                style={{
                    padding: 0,
                    background: 'transparent',
                    border: 'none',
                }}
            >
                {menuItems.map((item, idx) => {
                    return (
                        <MenuItem
                            key={`menu_item_${idx}`}
                            style={{
                                padding: 0,
                                background: 'transparent',
                                border: 'none',
                            }}
                        >
                            {item}
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    );
};
