/* eslint-disable @typescript-eslint/no-explicit-any */
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import DatePicker from 'react-datepicker';
import { forwardRef, useMemo, useState } from 'react';
import PX, { PXSTR } from 'ui/px';
import { TextInput } from './TextInput';
import { isValid } from 'date-fns';
import COLORS from 'ui/colors';
import { DateInputProps } from 'ui/ui.models';

// const DEFAULT_MIN_DATE = new Date(1900, 1, 1);
// const DEFAULT_MAX_DATE = new Date(2099, 1, 1);

export const TimeInput = forwardRef<DatePicker, DateInputProps>(
    (
        {
            label,
            subLabel,
            value,
            setValue,
            customValidateDate,
            overrideErrorMsg,
            datePickerProps,
        },
        ref,
    ) => {
        const [internalValue, setInternalValue] = useState<Date | null>(value);
        const [internalError, setInternalError] = useState<string | null>(null);

        const isInvalid = !!overrideErrorMsg || !!internalError;

        const timeZone = useMemo(() => {
            const timeZoneCode =
                Intl.DateTimeFormat().resolvedOptions().timeZone;

            switch (timeZoneCode) {
                case 'America/New_York':
                    return 'Eastern Time';
                case 'America/Chicago':
                    return 'Central Time';
                case 'America/Denver':
                    return 'Mountain Time';
                case 'America/Los_Angeles':
                    return 'Pacific Time';
            }

            return timeZoneCode;
        }, []);

        const validateDateChoice = (date: Date): boolean => {
            let res = isValid(date);

            // const maxDate = datePickerProps?.maxDate ?? DEFAULT_MAX_DATE;
            // const minDate = datePickerProps?.minDate ?? DEFAULT_MIN_DATE;

            // const isBeforeMax = isBefore(date, maxDate);
            // res &&= isBeforeMax;
            // if (!isBeforeMax) {
            //     setInternalError(`Date must be before ${maxDate}`);
            // }

            // const isAfterMin = isAfter(date, minDate);
            // res &&= isAfterMin;
            // if (!isAfterMin) {
            //     setInternalError(`Date must be after ${minDate}`);
            // }

            if (!!customValidateDate) {
                const customValidationRes = customValidateDate(date);

                if (typeof customValidationRes === 'string') {
                    res = false;
                    setInternalError(customValidationRes);
                } else if (typeof customValidationRes !== 'boolean') {
                    res = false;
                }
            }

            if (res) {
                setInternalError(null);
            }

            return res;
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onChangeRaw = (val: any) => {
            const castChange = val.target.value as string;
            const newDate = new Date(castChange);
            if (!validateDateChoice(newDate)) return;
            setValue?.(newDate);
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onManualSelect = (manuallyChosenDate: any) => {
            const castChoice = manuallyChosenDate as Date | null;
            if (castChoice === null || !validateDateChoice(castChoice)) return;
            setInternalValue(castChoice);
            setValue?.(castChoice);
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleOnBlur = (e: any) => {
            const dateString = e.target.value as string;
            const date = new Date(dateString);
            if (!validateDateChoice(date)) return;
            setInternalValue(date);
        };

        return (
            <FlexCol width="fit-content" gap={PX.SPACING.PX.S}>
                <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>
                <FlexRow alignItems="center" gap={PXSTR.M}>
                    <DatePicker
                        className="datePicker"
                        ref={ref}
                        showTimeSelect
                        showTimeSelectOnly
                        selected={internalValue}
                        onChange={onManualSelect}
                        onChangeRaw={onChangeRaw}
                        onBlur={handleOnBlur}
                        dateFormat={datePickerProps?.dateFormat ?? 'h:mm aa'}
                        timeIntervals={datePickerProps?.timeIntervals ?? 15}
                        required={true}
                        customInput={
                            <TextInput
                                isInvalid={
                                    !!internalError || !!overrideErrorMsg
                                }
                                iconRight="Clock"
                                className="timeInputTextComponent"
                            />
                        }
                        {...(datePickerProps ?? {})}
                    />
                    <FONTS.P1 fontWeight={700}>{timeZone}</FONTS.P1>
                </FlexRow>

                <FONTS.P2
                    color={isInvalid ? COLORS.PRIMARY.Error : undefined}
                    minHeight={PX.SPACING.PX.M}
                    transition="all 0.3s"
                    opacity={
                        isInvalid
                            ? !!internalError || !!overrideErrorMsg
                                ? 1
                                : 0
                            : !!subLabel
                              ? 1
                              : 0
                    }
                >
                    {isInvalid ? (overrideErrorMsg ?? internalError) : subLabel}
                </FONTS.P2>
            </FlexCol>
        );
    },
);
