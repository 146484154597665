import { useMemo } from 'react';
import { VisitType } from 'models/constants/visitConstants';
import { isBefore } from 'date-fns';
import { VisitReturnType } from 'models/schemas';

/**
 * Takes all the visits for a client and determines the values we would
 * need for validation.
 */
export const useAllVisitSortValues = (
    allVisits: Record<string, VisitReturnType>,
) => {
    const vals = useMemo(() => {
        const earliestToLastSortedVisits = Object.values(allVisits ?? {}).sort(
            (visitA, visitB) =>
                isBefore(
                    new Date(visitA.dateOfService),
                    new Date(visitB.dateOfService),
                )
                    ? -1
                    : 1,
        );

        const recentToEarliestSortedVisits =
            earliestToLastSortedVisits.reverse();

        const earliestVisit =
            earliestToLastSortedVisits?.length > 0
                ? earliestToLastSortedVisits[0]
                : undefined;
        const earliestVisitDate = !!earliestVisit
            ? new Date(earliestVisit.dateOfService)
            : undefined;

        const initialVisit = Object.values(allVisits ?? {}).find(
            (visit) =>
                visit.visitType === VisitType.InitialPostpartum ||
                visit.visitType === VisitType.InitialPrenatal,
        );

        const initialVisitDate = new Date(initialVisit?.dateOfService ?? '');

        const earliestPostPartumVisit = earliestToLastSortedVisits?.find(
            (visit) =>
                visit.visitType === VisitType.ExtendedPostpartum ||
                visit.visitType === VisitType.InitialPostpartum ||
                visit.visitType === VisitType.Postpartum,
        );
        const earliestPostPartumDate = !!earliestPostPartumVisit
            ? new Date(earliestPostPartumVisit.dateOfService)
            : undefined;

        const latestPrenatalVisit = recentToEarliestSortedVisits.find(
            (visit) =>
                visit.visitType === VisitType.InitialPrenatal ||
                visit.visitType === VisitType.Prenatal,
        );
        const latestPrenatalVisitDate = !!latestPrenatalVisit
            ? new Date(latestPrenatalVisit.dateOfService)
            : undefined;

        return {
            earliestToLastSortedVisits,
            recentToEarliestSortedVisits,
            earliestVisit,
            earliestVisitDate,
            initialVisit,
            initialVisitDate,
            earliestPostPartumVisit,
            earliestPostPartumDate,
            latestPrenatalVisit,
            latestPrenatalVisitDate,
        };
    }, [allVisits]);

    return vals;
};
