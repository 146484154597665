import { FormControl, FormLabel } from '@chakra-ui/react';
import {
    FieldErrors,
    FieldValues,
    UseFormRegisterReturn,
} from 'react-hook-form';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import COLORS from 'ui/colors';
import { useRadioGroup } from '../hooks/useRadioGroup';
import { Radio, RadioOptionProp } from '../Radio';
import { forwardRef } from 'react';

export type FormRadioProps = Partial<UseFormRegisterReturn> & {
    onChangeString: (newVal: string) => void;
    options: RadioOptionProp[];
    fieldName: string;
    isDecorated?: boolean;

    label?: string;
    subLabel?: string;
    'data-cy'?: string;
    errors: FieldErrors<FieldValues> | undefined;
    isRequired?: boolean;
    defaultValue?: string;
};

export const FormRadio = forwardRef<FormRadioProps, FormRadioProps>(
    (
        {
            label,
            subLabel,
            fieldName,
            defaultValue,
            isRequired = false,
            errors,
            options,
            isDecorated,
            onChangeString,
            ...props
        },
        forwardedRef,
    ) => {
        const { getRootProps, getRadioProps } = useRadioGroup({
            defaultValue,
            onChangeString: onChangeString,
        });

        return (
            <FormControl
                flex={1}
                isInvalid={!!errors?.[fieldName]}
                isRequired={isRequired}
            >
                <FlexCol>
                    <FormLabel display="none" htmlFor={fieldName}>
                        {label}
                    </FormLabel>
                    <FlexCol gap={PX.SPACING.PX.S} {...getRootProps()}>
                        {label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
                        {subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
                        <input hidden {...props} />
                        {options.map((option, idx) => (
                            <FlexRow
                                key={`multiselect_option_${idx}`}
                                gap={PX.SPACING.PX.S}
                            >
                                <Radio
                                    isDecorated={
                                        option.isDecorated ?? isDecorated
                                    }
                                    {...getRadioProps({ value: option.value })}
                                    {...option}
                                />
                            </FlexRow>
                        ))}
                    </FlexCol>
                    <FONTS.P2
                        minHeight={PX.SPACING.REM.M}
                        mt={PX.SPACING.PX.XS}
                        color={COLORS.PRIMARY.Error}
                    >
                        {errors?.[fieldName] &&
                            errors?.[fieldName].message?.toString()}
                    </FONTS.P2>
                </FlexCol>
            </FormControl>
        );
    },
);
