import { VisitType, VisitTypeCopy } from 'models/constants/visitConstants';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { BasicModalLayout } from 'ui/components/BasicModalLayout';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { useToast } from 'ui/useToast';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';
import {
    getFormTextInputRegisterProps,
    NewFormTextInput,
} from 'ui/components/Form/NewFormTextInput';
import { Dropdown } from 'ui/components/Dropdown';
import { OffPlatformVisitModel } from 'models/schemas';
import { DateInput, getDateInputRegisterProps } from 'ui/components/DateInput';
import {
    useOffPlatformVisits,
    useSingleOffPlatformVisit,
} from 'hooks/dbHooks/visits.hooks';
import { useEffect, useMemo } from 'react';

export const LogOffPlatformVisitModal = ({
    isOpen,
    onClose,
    existingVisit,
}: {
    onClose: () => void;
    isOpen: boolean;
    existingVisit: OffPlatformVisitModel | undefined;
}) => {
    const {
        errors,
        register,
        onSubmit,
        isValid,
        onCloseForm,
        visitDateWatcher,
        setVisitDate,
    } = useLogOffPlatformVisitModal({ onClose, existingVisit });

    return (
        <form onSubmit={onSubmit}>
            <BasicModalLayout
                title={
                    !existingVisit
                        ? 'Log an off-platform visit'
                        : 'Edit off-platform visit'
                }
                isOpen={isOpen}
                onClose={onCloseForm(onClose)}
                buttons={[
                    <SecondaryButton
                        key="cancelOffPlatformVisit"
                        onClick={onCloseForm(onClose)}
                    >
                        Cancel
                    </SecondaryButton>,
                    <PrimaryButton
                        type="submit"
                        key="submitOffPlatformVisit"
                        onClick={onSubmit}
                        disabled={!isValid}
                    >
                        {!!existingVisit ? 'Save changes' : 'Log visit'}
                    </PrimaryButton>,
                ]}
            >
                <FlexCol gap={PXSTR.L}>
                    <Dropdown
                        id="visitType"
                        label="Visit Type"
                        options={Object.entries(VisitTypeCopy).map(
                            ([key, val]) => {
                                return {
                                    label: val,
                                    value: key,
                                };
                            },
                        )}
                        {...(register?.('visitType', {
                            required: true,
                            validate: (val) => {
                                return val !== '-'; //TODO-Mina: move this val into the dropdown its silly
                            },
                        }) ?? {})}
                    />

                    <DateInput
                        label="Visit Date"
                        datePickerProps={{
                            dateFormat: 'MM/dd/yyyy',
                            placeholderText: 'MM/dd/yyyy',
                        }}
                        value={visitDateWatcher ?? null}
                        setValue={setVisitDate}
                        registerProps={
                            !!register
                                ? register?.(
                                      'visitDate',
                                      getDateInputRegisterProps(),
                                  )
                                : undefined
                        }
                    />

                    <NewFormTextInput
                        label="Doula Name"
                        subLabel="Provide the doula’s first and last names"
                        fieldName="doulaName"
                        errors={errors}
                        registerProps={register?.(
                            'doulaName',
                            getFormTextInputRegisterProps({}),
                        )}
                    />
                </FlexCol>
            </BasicModalLayout>
        </form>
    );
};

type ModalFormFields = {
    doulaName: string | undefined;
    visitType: VisitType | string | undefined;
    visitDate: Date | undefined;
};

const useLogOffPlatformVisitModal = ({
    onClose,
    existingVisit,
}: {
    onClose: () => void;
    existingVisit: OffPlatformVisitModel | undefined;
}) => {
    const toast = useToast();

    const existingVisitFormFields: ModalFormFields = useMemo(() => {
        const { visitDate, visitType, doulaName } = existingVisit ?? {};

        return {
            visitDate: !!visitDate ? new Date(visitDate) : undefined,
            visitType,
            doulaName,
        };
    }, [existingVisit]);

    const formInstance = useForm({
        defaultValues: existingVisitFormFields,
    });

    useEffect(() => {
        formInstance.reset(existingVisitFormFields);
    }, [existingVisit, existingVisitFormFields, formInstance]);

    const { formState, register, setValue, watch } = formInstance ?? {};

    const visitDateWatcher = watch(
        'visitDate',
        existingVisitFormFields.visitDate,
    );

    const { errors, isValid } = formState ?? {};

    const ctx = useClientDetailsContext();
    const { client, refreshClientData } = ctx ?? {};

    const { updateOffPlatformVisit } = useSingleOffPlatformVisit(
        existingVisit?.id,
    );

    const { createVisit } = useOffPlatformVisits(client?.userId);

    const setVisitDate = (date: Date | null) => {
        if (!date) return;
        setValue?.('visitDate', date, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
        });
    };

    const onSubmit: SubmitHandler<FieldValues> = async (_data) => {
        const { doulaName, visitType, visitDate } = formInstance.getValues();
        const newObj = {
            doulaName,
            visitType: !!visitType ? (visitType as VisitType) : undefined,
            visitDate: visitDate?.toISOString(),
            patientUserId: client?.userId,
        };

        if (existingVisit) {
            await updateOffPlatformVisit(newObj);
        } else {
            await createVisit(newObj);
        }

        await refreshClientData?.();

        formInstance.reset({
            doulaName: undefined,
            visitDate: undefined,
            visitType: undefined,
        });

        onClose();
    };

    const onCloseForm = (callback: () => void) => () => {
        formInstance.reset({
            doulaName: undefined,
            visitDate: undefined,
            visitType: undefined,
        });
        callback();
    };

    return {
        errors,
        register,
        onSubmit,
        isValid,
        onCloseForm,
        setValue,

        visitDateWatcher,
        setVisitDate,
    };
};
