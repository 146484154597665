import { Box, Spacer } from '@chakra-ui/react';
import { createContext, useContext, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import COLORS from 'ui/colors';
import { SheetTab } from 'ui/components/Sheet';
import { TextTag, TextTagColorSchemes } from 'ui/components/TextTag';
import { Tooltip } from 'ui/components/Tooltip';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { useVisitCounts } from './clientDetails.hooks';
import {
    NumAdditionalPostpartumVisits,
    VisitCategory,
    VisitType,
} from 'models/constants/visitConstants';
import { useClientDetailsContext } from './clientDetails.context';

type CardTitlesWrapped = {
    initial: boolean;
    perinatal: boolean;
    delivery: boolean;
    extended: boolean;
};

type VisitOverviewContext = {
    cardTitlesWrapped: CardTitlesWrapped;
    anyCardHasWrappedTitle: boolean;
    setCardTitlesWrapped: React.Dispatch<
        React.SetStateAction<CardTitlesWrapped>
    >;
};

export const VisitOverviewContext = createContext(
    {} as VisitOverviewContext | undefined,
);

const useVisitOverviewLayout = () => {
    const [ref, bounds] = useMeasure();
    const wrapPairs = bounds.width < 341;

    const [cardTitlesWrapped, setCardTitlesWrapped] =
        useState<CardTitlesWrapped>({
            initial: false,
            perinatal: false,
            delivery: false,
            extended: false,
        });

    const anyCardHasWrappedTitle =
        Object.values(cardTitlesWrapped).filter((isWrapped) => isWrapped)
            .length > 0;

    return {
        ref,
        wrapPairs,
        initialOverviewContext: {
            cardTitlesWrapped,
            anyCardHasWrappedTitle,
            setCardTitlesWrapped,
        },
    };
};

export const VisitOverview = () => {
    const ctx = useClientDetailsContext();
    const { client } = ctx ?? {};
    const {
        countPerPerinatalCategory,
        countUsedPerCategory,
        countRemainingPerCategory,
        isAdditionalPostPartumApproved,
    } = useVisitCounts(client);

    const { initialOverviewContext, ref, wrapPairs } = useVisitOverviewLayout();

    return (
        <VisitOverviewContext.Provider value={initialOverviewContext}>
            <FlexCol gap={PXSTR.S}>
                <FlexRow justifyContent="space-between">
                    <FONTS.H6 color={COLORS.PRIMARY.Grey}>
                        Visit Overview
                    </FONTS.H6>

                    <FlexRow className="tagsContainer" gap={PXSTR.S}>
                        {/* <TextTag
                            text="Shared Client"
                            colorScheme={TextTagColorSchemes.GREY_LIGHT}
                            iconRight="Link"
                            toolTip="You may claim up to 9 additional postpartum visits for this client."
                        /> */}
                    </FlexRow>
                </FlexRow>
                <FlexRow
                    flexWrap="wrap"
                    gap={wrapPairs ? 0 : PXSTR.S}
                    height="fit-content"
                >
                    <FlexRow
                        ref={ref}
                        height={wrapPairs ? 'fit-content' : undefined}
                        wrap={wrapPairs ? 'wrap' : 'nowrap'}
                        gap="inherit"
                        flex={1}
                    >
                        <RemainingVisitCard
                            id="initial"
                            title="Initial, 90-min"
                            countUsed={
                                countUsedPerCategory[VisitCategory.Initial]
                            }
                            countRemaining={
                                countRemainingPerCategory[VisitCategory.Initial]
                            }
                        />
                        <RemainingVisitCard
                            id="perinatal"
                            title="Perinatal"
                            countUsed={
                                countUsedPerCategory[VisitCategory.Perinatal]
                            }
                            countRemaining={
                                countRemainingPerCategory[
                                    VisitCategory.Perinatal
                                ]
                            }
                            subCats={[
                                {
                                    count: countPerPerinatalCategory[
                                        VisitType.Prenatal
                                    ],
                                    category: 'Prenatal',
                                    max: isAdditionalPostPartumApproved
                                        ? NumAdditionalPostpartumVisits
                                        : undefined,
                                },
                                {
                                    count: countPerPerinatalCategory[
                                        VisitType.Postpartum
                                    ],
                                    category: 'Postpartum',
                                },
                            ]}
                            onClickInfo={() => null}
                        />
                    </FlexRow>
                    <FlexRow
                        height={wrapPairs ? 'fit-content' : undefined}
                        gap="inherit"
                        flex={1}
                        wrap={wrapPairs ? 'wrap' : 'nowrap'}
                    >
                        <RemainingVisitCard
                            id="delivery"
                            title="Labor & Delivery*"
                            countUsed={
                                countUsedPerCategory[
                                    VisitCategory.LaborDelivery
                                ]
                            }
                            countRemaining={
                                countRemainingPerCategory[
                                    VisitCategory.LaborDelivery
                                ]
                            }
                            onClickInfo={() => null}
                        />
                        <RemainingVisitCard
                            id="extended"
                            title="Postpartum, Extended"
                            countUsed={
                                countUsedPerCategory[
                                    VisitCategory.PostpartumExtended
                                ]
                            }
                            countRemaining={
                                countRemainingPerCategory[
                                    VisitCategory.PostpartumExtended
                                ]
                            }
                        />
                    </FlexRow>
                </FlexRow>
            </FlexCol>
        </VisitOverviewContext.Provider>
    );
};

const RemainingVisitCard = ({
    id,
    title,
    countUsed,
    countRemaining,
    onClickInfo,
    subCats,
}: {
    id: keyof CardTitlesWrapped;
    title: string;
    countUsed: number;
    countRemaining: number;
    onClickInfo?: () => void;
    subCats?: { count: number; category: string; max?: number }[];
}) => {
    const visitOverviewContext = useContext(VisitOverviewContext);
    const { anyCardHasWrappedTitle, cardTitlesWrapped, setCardTitlesWrapped } =
        visitOverviewContext ?? {};

    const [ref, bounds] = useMeasure();
    const wrappingCardTitle = bounds.height > 20;

    useEffect(() => {
        if (cardTitlesWrapped?.[id] != wrappingCardTitle) {
            setCardTitlesWrapped?.((prev) => {
                return { ...prev, [id]: wrappingCardTitle };
            });
        }
    }, [
        anyCardHasWrappedTitle,
        cardTitlesWrapped,
        id,
        setCardTitlesWrapped,
        wrappingCardTitle,
    ]);

    return (
        <SheetTab
            flex={1}
            flexBasis="200px"
            minWidth="166px"
            height="100%"
            minHeight="123px"
            maxHeight="140px"
            padding={PXSTR.S}
            display="flex"
            flexDirection="column"
            gap={PXSTR.SM}
        >
            <FlexRow alignItems="start" justifyContent="space-between">
                <Box minHeight={anyCardHasWrappedTitle ? '39px' : '20px'}>
                    <Box ref={ref}>
                        <Tooltip placement="bottom" label={title}>
                            <FONTS.H4>{title}</FONTS.H4>
                        </Tooltip>
                    </Box>
                </Box>

                {onClickInfo ? (
                    <Tooltip placement="bottom" label={title}>
                        <span>
                            <ICONS.Info
                                size="16px"
                                color={COLORS.UTIL.Gray[600]}
                            />
                        </span>
                    </Tooltip>
                ) : (
                    <Spacer width="20px" height="24px" />
                )}
            </FlexRow>

            <FlexRow
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                paddingLeft={PXSTR.XS}
            >
                <FONTS.H3>{countUsed}</FONTS.H3>
                <TextTag
                    text={`${countRemaining} remaining`}
                    colorScheme={TextTagColorSchemes.GREEN_LIGHT}
                />
            </FlexRow>

            <FlexCol paddingLeft={PXSTR.XS}>
                {subCats?.map((subCat, idx) => (
                    <FlexRow
                        key={`subcat_${idx}_${title}`}
                        gap={PXSTR.S}
                        alignItems="center"
                    >
                        <FONTS.P2>{subCat.count}</FONTS.P2>
                        <FONTS.TAG>{subCat.category}</FONTS.TAG>
                        {subCat.max !== undefined && (
                            <FONTS.TAG
                                fontWeight={600}
                                color={COLORS.PRIMARY.Grey}
                            >
                                ({subCat.max} Max)
                            </FONTS.TAG>
                        )}
                    </FlexRow>
                ))}
            </FlexCol>
        </SheetTab>
    );
};
