import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { defineStyle } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import COLORS from 'ui/colors';
import PX from 'ui/px';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts.keys);

const base = definePartsStyle({
    dialog: {
        bg: 'transparent',
    },
});

const sizes = {
    baseSize: definePartsStyle({
        dialogContainer: defineStyle({}),
        dialog: defineStyle({
            maxWidth: '960px',
            dropShadow: 'none',
            boxShadow: 'none',
        }),
        overlay: defineStyle({}),
    }),
};

export const drawerTheme = defineMultiStyleConfig({
    variants: { base },
    sizes,
});
