import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import PX, { PXSTR } from 'ui/px';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseVariant = definePartsStyle((_props) => {
    return {
        tab: {
            border: 'transparent',
            borderBottom: 'none',
            marginBottom: '5px',
            _selected: {
                borderColor: 'inherit',
                marginBottom: 0,
                borderBottom: `5px solid ${COLORS.UTIL.Gray[900]}`,
            },

            //H6
            textTransform: 'uppercase',
            fontSize: PX.SPACING.REM.S,
            lineHeight: 1.2,
            letterSpacing: 0.7,
            font: 'DM Sans',
            fontWeight: 'semibold',
            padding: PXSTR.S,
        },
        tablist: {
            borderBottom: `1px solid ${COLORS.UTIL.Gray[500]}`,
        },
        tabpanel: {
            border: 'transparent',
        },
    };
});

// define custom sizes
const sizes = {
    base: definePartsStyle({
        // define the parts that will change for each size
        tab: {},
        tabpanel: {},
    }),
};

const variants = {
    base: baseVariant,
};

export const tabsTheme = defineMultiStyleConfig({ variants, sizes });
