import { createContext, useContext } from 'react';
import { CreateClientContextType } from './createClient.models';

export const CreateClientContext = createContext(
    {} as CreateClientContextType | undefined,
);

export const useCreateClientContext = () => {
    const ctx = useContext(CreateClientContext);
    return ctx;
};
