/* eslint-disable @typescript-eslint/no-explicit-any */
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import DatePicker from 'react-datepicker';
import { forwardRef, ReactElement, useEffect, useState } from 'react';
import PX from 'ui/px';
import { TextInput, TextInputProps } from './TextInput';
import React from 'react';
import { isValid, isBefore, isAfter, parse } from 'date-fns';
import InputMask from 'react-input-mask';
import COLORS from 'ui/colors';
import { DateInputProps } from 'ui/ui.models';
import { UseFormRegisterReturn } from 'react-hook-form';

const DEFAULT_MIN_DATE = new Date(1900, 1, 1);
const DEFAULT_MAX_DATE = new Date(2099, 1, 1);

export const getDateInputRegisterProps = (requiredWarningText?: string) => {
    return {
        validate: (x: Date | null | undefined) => {
            return (
                (!!x && isValid(x)) ||
                (requiredWarningText ?? `Date selection is required`)
            );
        },
    };
};

export const DateInput = forwardRef<
    DatePicker,
    DateInputProps & Partial<UseFormRegisterReturn>
>(
    (
        {
            label,
            value,
            setValue,
            customValidateDate,
            allowInvalidDate,
            overrideErrorMsg,
            datePickerProps,
            onChange,
            onBlur,
            name,
        },
        ref,
    ) => {
        const [isInitialized, setIsInitialized] = useState<boolean>(false);
        const [internalValue, setInternalValue] = useState<Date | null>(value);
        const [internalError, setInternalError] = useState<string | null>(null);
        const [errorComponent, setErrorComponent] =
            useState<ReactElement | null>(null);

        const dateFormat = datePickerProps?.dateFormat ?? 'MM/dd/yyyy';
        const dateFormatForMask =
            typeof dateFormat === 'string' ? dateFormat : dateFormat[0];
        const dateFormatMask = dateFormatForMask.replaceAll(/[a-zA-Z]/g, '9');

        useEffect(
            function initializeToExternal() {
                if (!isInitialized && !internalValue && !!value) {
                    setInternalValue(value);
                    setIsInitialized(true);
                }
            },
            [internalValue, isInitialized, value],
        );

        const validateDateChoice = (date: Date): boolean => {
            let res = isValid(date);

            if (!res) {
                setInternalError('Invalid Date');
                return false;
            }

            const maxDate = datePickerProps?.maxDate ?? DEFAULT_MAX_DATE;
            const minDate = datePickerProps?.minDate ?? DEFAULT_MIN_DATE;

            const isBeforeMax = isBefore(date, maxDate);
            res &&= isBeforeMax;
            if (!isBeforeMax) {
                setInternalError(`Date must be before ${maxDate}`);
                return false;
            }

            const isAfterMin = isAfter(date, minDate);
            res &&= isAfterMin;
            if (!isAfterMin) {
                setInternalError(`Date must be after ${minDate}`);
                return false;
            }

            if (res && !!customValidateDate) {
                const customValidationRes = customValidateDate(date);
                if (typeof customValidationRes === 'string') {
                    res = false;
                    setInternalError(customValidationRes);
                    setErrorComponent(null);
                } else if (typeof customValidationRes !== 'boolean') {
                    res = false;
                    setErrorComponent(customValidationRes);
                }
            }

            if (res) {
                setInternalError(null);
                setErrorComponent(null);
            }

            return res;
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onChangeRaw = (val: any) => {
            onChange?.(val);
            const castChange = val.target.value as string | undefined;
            if (!castChange) return;
            const newDate = parse(castChange, dateFormatForMask, new Date());
            if (!validateDateChoice(newDate) && !allowInvalidDate) return;
            setValue?.(newDate);
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onManualSelect = (manuallyChosenDate: any) => {
            const castChoice = manuallyChosenDate as Date | null;
            if (
                castChoice === null ||
                (!validateDateChoice(castChoice) && !allowInvalidDate)
            )
                return;
            setInternalValue(castChoice);
            setValue?.(castChoice);
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //const handleOnBlur = (e: any) => {
        //const dateString = e.target.value as string;
        // const date = parse(
        //     dateString,
        //     dateFormatForMask,
        //     value ?? new Date(),
        // );
        // console.log('BLUR', dateFormatForMask, date, internalValue, value);
        // if (!validateDateChoice(date)) return;
        // setInternalValue(date);
        //};

        return (
            <FlexCol width="fit-content" gap={PX.SPACING.PX.S}>
                <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>
                <DatePicker
                    className="datePicker"
                    ref={ref}
                    selected={internalValue}
                    onChange={onManualSelect}
                    onChangeRaw={onChangeRaw}
                    onBlur={onBlur}
                    name={name}
                    dateFormat={datePickerProps?.dateFormat ?? 'MM/dd/yyyy'}
                    required={true}
                    customInput={
                        <DateMaskedTextInput
                            dateFormatMask={dateFormatMask}
                            isInvalid={
                                !!internalError ||
                                !!overrideErrorMsg ||
                                !!errorComponent
                            }
                            className="dateInputTextComponent"
                        />
                    }
                    {...(datePickerProps ?? {})}
                />
                {errorComponent}
                {!errorComponent && (
                    <FONTS.P2
                        color={COLORS.PRIMARY.Error}
                        minHeight={PX.SPACING.PX.M}
                        transition="all 0.3s"
                        opacity={!!internalError || !!overrideErrorMsg ? 1 : 0}
                    >
                        {overrideErrorMsg ?? internalError}
                    </FONTS.P2>
                )}
            </FlexCol>
        );
    },
);

const DateMaskedTextInput = React.forwardRef<
    TextInputProps,
    TextInputProps & { dateFormatMask: string }
>(({ height, size, width, color, dateFormatMask, ...props }, ref) => {
    return (
        <InputMask mask={dateFormatMask} maskChar={null} {...props}>
            {
                ((inputProps: any) => (
                    <TextInput
                        ref={ref}
                        {...props}
                        {...inputProps}
                        {...{ height, size, width, color }}
                        iconRight="Calendar"
                    />
                )) as any
            }
        </InputMask>
    );
});
