import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export const NumBadge = ({
    num,
    background = COLORS.PRIMARY.Blue,
}: {
    num?: number | string;
    background?: string;
}) => {
    return (
        <FlexRow
            justifyContent="center"
            alignItems="center"
            minW={PX.SPACING.REM.XL}
            background={background}
            borderRadius={PX.RADII.ROUNDED}
        >
            <FONTS.P1 fontWeight="bold" color={COLORS.UTIL.Gray.WARM}>
                {num?.toString() ?? '...'}
            </FONTS.P1>
        </FlexRow>
    );
};
