import { createContext, useContext } from 'react';
import { SubmitVisitContextType } from './submitVisit.models';

export const SubmitVisitContext = createContext(
    {} as SubmitVisitContextType | undefined,
);

export const useSubmitVisitContext = () => {
    const ctx = useContext(SubmitVisitContext);
    return ctx;
};
