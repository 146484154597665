import { Flex } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { useOnboardingState } from './hooks/useOnboardingState';

import React from 'react';
import useScreenSize from 'hooks/useScreenSize';

import {
    DesktopStepWrapper,
    MobileStepWrapper,
} from './components/StepsWrapper';
import { OnboardingCompleteScreen } from './components/OnboardingCompleteScreen';
import { Helmet } from 'react-helmet-async';
import {
    OnboardingContext,
    OnboardingStatusContext,
} from './OnboardingContext';
import { DesktopSideBar, MobileNavBar } from './components/NavBar';

import { OnboardingLandingPage } from './landing/OnboardingLandingPage';
import { useOnboardingStatus } from 'hooks/dbHooks';
import Loading from 'components/Loading';

//TODO-Mina: Some element of this loading logic (spinner vs showing loading states) is
//what should be top-level for the whole app, but for now this component is the whole app
export function OnboardingFlow(): React.ReactElement {
    const practitionerOnboardingStatusContext = useOnboardingStatus();
    const {
        isStarted,
        isCompleted,
        refreshStatus,
        isLoaded: isPractitionerStatusLoaded,
    } = practitionerOnboardingStatusContext ?? {};

    const onboardingState = useOnboardingState({ onComplete: refreshStatus });
    const { isLoaded: isOnboardingStateLoaded, activeStepIdx } =
        onboardingState ?? {};

    const [showHelpTag, setShowHelpTag] = React.useState(false);

    const { isNarrowScreen } = useScreenSize();

    const isFlowLoading = !isOnboardingStateLoaded || activeStepIdx === -1;

    if (!isPractitionerStatusLoaded) {
        return (
            <div className="page-layout">
                <Loading />
            </div>
        );
    }

    if (isCompleted) {
        return (
            <OnboardingContext.Provider value={onboardingState}>
                <OnboardingStatusContext.Provider
                    value={practitionerOnboardingStatusContext}
                >
                    <OnboardingCompleteScreen />
                </OnboardingStatusContext.Provider>
            </OnboardingContext.Provider>
        );
    }

    return (
        <Flex
            width="100vw"
            height="100dvh"
            position="fixed"
            className="onboardingWrapperPage"
            background={COLORS.BRAND.Periwinkle[100]}
            direction={['column', null, null, 'row']}
            justifyContent="space-between"
            role="main"
        >
            <OnboardingContext.Provider value={onboardingState}>
                <Helmet>
                    <title>
                        Onboarding |{' '}
                        {onboardingState.activeStepOrSubstep.title ?? 'Loula'}
                    </title>
                </Helmet>

                {isStarted ? (
                    <>
                        {isNarrowScreen ? (
                            <MobileNavBar showHelpTag={showHelpTag} />
                        ) : (
                            <DesktopSideBar isLoading={isFlowLoading} />
                        )}

                        {isNarrowScreen ? (
                            <MobileStepWrapper
                                setShowHelpTag={setShowHelpTag}
                            />
                        ) : (
                            <DesktopStepWrapper isLoading={isFlowLoading} />
                        )}
                    </>
                ) : null}
            </OnboardingContext.Provider>

            {!isFlowLoading && !isStarted && !isCompleted && (
                <OnboardingStatusContext.Provider
                    value={practitionerOnboardingStatusContext}
                >
                    <OnboardingLandingPage />
                </OnboardingStatusContext.Provider>
            )}
        </Flex>
    );
}
