import COLORS from 'ui/colors';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { Sheet } from './Sheet';
import { PXSTR } from 'ui/px';
import FONTS from 'ui/fonts';
import { IconButton } from './IconButton';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { Zyg } from 'util/Utils';

export const BasicModalLayout = ({
    title,
    isOpen,
    onClose,
    children,
    buttons,
}: {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    buttons: React.ReactNode[];
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay>
                <ModalContent marginY="auto">
                    <Sheet
                        display="flex"
                        flexDir="column"
                        background={COLORS.UTIL.Gray.SHEET}
                        minWidth={Zyg('90%', '500px')}
                    >
                        <FlexRow
                            alignItems="center"
                            paddingY={PXSTR.M}
                            width="100%"
                            className="basicModalHeader"
                            justifyContent="space-between"
                            borderBottom={`1px solid ${COLORS.UTIL.Gray[500]}`}
                            paddingX={PXSTR.L}
                        >
                            <FONTS.H3>{title}</FONTS.H3>
                            <IconButton icon="Close" onClick={onClose} />
                        </FlexRow>
                        <FlexCol
                            className="basicModalContentWrapper"
                            paddingY={PXSTR.L}
                            paddingX={PXSTR.L}
                            maxH="460px"
                            overflowY="scroll"
                        >
                            {children}
                        </FlexCol>
                        <FlexRow
                            className="basicModalButtonsWrapper"
                            width="100%"
                            paddingY={PXSTR.M}
                            paddingX={PXSTR.L}
                            gap={PXSTR.S}
                            alignItems="center"
                            justifyContent="end"
                            borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}
                        >
                            {buttons}
                        </FlexRow>
                    </Sheet>
                </ModalContent>
            </ModalOverlay>
        </Modal>
    );
};
