import { useCallback, useState } from 'react';
import { PortalContextType } from './portalContext.model';
import { useLocation, useNavigate } from 'react-router-dom';
import { OpenDrawerProps } from 'doulaPortal/drawers/drawers';

/**
 * Portal-wide context, use sparingly
 * Uses:
 *  - Drawer mechanics
 *  - Layout state
 *  - ...
 */
export const useInitialPortalContext = (): PortalContextType => {
    const drawerProps = useDrawer();
    const mobileLayoutProps = useMobileLayoutState();

    return {
        ...drawerProps,
        ...mobileLayoutProps,
    };
};

/**
 * Handles the app-wide mobile layout state, eg if the page is collapsed for navbar
 * or if the page is full-screen
 */
const useMobileLayoutState = () => {
    const [isSheetVisible, setIsSheetVisible] = useState<boolean>(true); //TODO: put me in the portal context?
    const [isFullScreenMobile, setIsFullScreenMobile] =
        useState<boolean>(false);

    return {
        isSheetVisible,
        setIsSheetVisible,
        isFullScreenMobile,
        setIsFullScreenMobile,
    };
};

/**
 * Utilizing a single drawer component across the app, so that it can be triggered from
 * anywhere within context
 *
 * ALL_DRAWERS contains every drawer component, and can also pass in props when opening a drawer
 *
 * @returns
 */
const useDrawer = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false); //whether the drawer is open or not
    const [drawerProps, setDrawerProps] = useState<OpenDrawerProps['props']>(); //when calling to open the drawer (not from url) can pass props
    const [activeBaseUrl, setActiveBaseUrl] = useState<string | undefined>(); //for returning to specific url after closing drawer

    const openDrawer = useCallback(
        ({
            drawerId,
            shouldNavigate = true,
            baseUrl,
            props,
        }: OpenDrawerProps) => {
            if (shouldNavigate) {
                navigate((baseUrl ?? pathname) + '/' + drawerId, {
                    replace: true,
                });
            }

            setActiveBaseUrl(baseUrl ?? pathname);
            setDrawerProps(props);
            setIsDrawerOpen(true);
        },
        [navigate, pathname],
    );

    const closeDrawer = useCallback(() => {
        if (!!activeBaseUrl) {
            navigate(activeBaseUrl, { replace: true });
        }
        setIsDrawerOpen(false);
    }, [activeBaseUrl, navigate]);

    return { openDrawer, closeDrawer, isDrawerOpen, drawerProps };
};
