import { DoulaPortalLayout } from 'doulaPortal/layouts/DoulaPortalLayout';
import { useParams } from 'react-router-dom';
import { ColRow, FlexCol, FlexRow } from 'ui/loulaFlex';
import { useSingleVisit } from 'hooks/dbHooks/visits.hooks';
import { Sheet } from 'ui/components/Sheet';
import { PXSTR } from 'ui/px';
import { VisitTypeCopy } from 'models/constants/visitConstants';
import { Breadcrumbs } from 'ui/components/Breadcrumbs';
import { useClient } from 'hooks/dbHooks/clients.hooks';
import { getNameToShow } from 'util/Utils';
import { VisitMainData } from './VisitMainData';
import { VisitMetaData } from './VisitMetaData';

const Page = () => {
    return (
        <DoulaPortalLayout>
            <VisitDetails />
        </DoulaPortalLayout>
    );
};

const VisitDetails = () => {
    const { visitId } = useParams();
    const { visit } = useSingleVisit(visitId);
    const { client } = useClient(visit?.patientUserId);

    if (!visit) return;

    return (
        <FlexRow justifyContent="center" height="100%" overflowY="scroll">
            <FlexCol alignItems="start" gap={PXSTR.M} padding={PXSTR.M}>
                <Breadcrumbs
                    crumbs={[
                        {
                            label: getNameToShow(client?.user),
                            navLink: `/clients/${visit.patientUserId}`,
                        },
                        { label: VisitTypeCopy[visit.visitType], navLink: '#' },
                    ]}
                />

                <Sheet width={`calc(100% - ${PXSTR.M})`} maxW="700px">
                    <ColRow>
                        <VisitMainData visit={visit} />
                        <VisitMetaData visit={visit} />
                    </ColRow>
                </Sheet>
            </FlexCol>
        </FlexRow>
    );
};

export default Page;
