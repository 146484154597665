import { FlexCol } from 'ui/loulaFlex';
import { FormRadio } from 'ui/components/Form/FormRadio';
import FileUploader from 'components/FileUpload/FileUploader';
import { FormBoolRadio } from 'ui/components/Form/FormBoolRadio';
import { useMemo } from 'react';
import {
    CredentialingExternalMapping,
    CredentiallingStatus,
    StatusToColorSchemeMapping,
} from 'models/constants';
import { useCreateClientContext } from '../createClient.context';
import { Dropdown } from 'ui/components/Dropdown';
import { Divider } from '@chakra-ui/react';
import FONTS from 'ui/fonts';
import COLORS from 'ui/colors';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';
import {
    getFormTextInputRegisterProps,
    NewFormTextInput,
} from 'ui/components/Form/NewFormTextInput';
import { FileUpload } from 'models/schemas';
import { PXSTR } from 'ui/px';
import {
    Reset_Back_Text,
    Reset_Front_Text,
} from 'models/constants/clientConstants';

export const InsuranceCardStep = () => {
    const {
        standardRadioRegisterProps,
        errors,
        primaryPayerId,
        setPrimaryPayerId,
        primaryIsMediCal,
        setPrimaryIsMediCal,
        healthPlanOptions,
        secondaryInsuranceDropdownOptions,
        planCategory,
        setHasSecondaryInsurance,
        hasSecondaryInsurance,
        selectedSecondaryPayerId,
        register,
        primaryCardBack,
        primaryCardFront,
        secondaryCardFront,
        secondaryCardBack,
        setCard,
    } = useInsuranceCardStep();

    const fileUploadRegisterProps = {
        required: true,
        validate: (val: FileUpload[] | undefined) => {
            return !!val?.length;
        },
    };

    return (
        <FlexCol gap={PXSTR.XXL}>
            <FormRadio
                fieldName="primary.payerId"
                label="Which health plan does this client have?"
                subLabel="Clients must be insured with a plan you are actively credentialed with via Loula to bill for their visits."
                errors={errors}
                defaultValue={primaryPayerId}
                onChangeString={setPrimaryPayerId}
                options={healthPlanOptions}
                isRequired={true}
                isDecorated
                {...(register?.(
                    'primary.payerId',
                    standardRadioRegisterProps,
                ) ?? {})}
            />

            <FileUploader
                label="Upload the front of the client’s insurance card."
                maxFiles={1}
                fieldValue={primaryCardFront}
                onChange={setCard(true, true)}
                showPreviewUponUpload
                registerProps={register?.(
                    'primary.cardFront',
                    fileUploadRegisterProps,
                )}
                resetText={Reset_Front_Text}
            />

            <FileUploader
                label="Upload the back of the client’s insurance card."
                maxFiles={1}
                fieldValue={primaryCardBack}
                onChange={setCard(true, false)}
                showPreviewUponUpload
                registerProps={register?.(
                    'primary.cardBack',
                    fileUploadRegisterProps,
                )}
                resetText={Reset_Back_Text}
            />

            {planCategory === 'Mixed' && (
                <FormBoolRadio
                    fieldName="primary.isMediCal"
                    label="Is this plan a Medi-Cal plan? "
                    errors={errors}
                    defaultValue={primaryIsMediCal}
                    onChangeBool={setPrimaryIsMediCal}
                    isDecorated
                    isRequired={true}
                    {...(register?.(
                        'primary.isMediCal',
                        standardRadioRegisterProps,
                    ) ?? {})}
                />
            )}

            {(planCategory === 'MC' || primaryIsMediCal) && (
                <FormBoolRadio
                    fieldName="hasSecondaryInsurance"
                    label="Does this client have any other health insurance plans?"
                    subLabel="For example, a client may also have health insurance through their employer, their spouse, their guardian, or as a military/veteran benefit (i.e. TRICARE)."
                    errors={errors}
                    defaultValue={hasSecondaryInsurance}
                    onChangeBool={setHasSecondaryInsurance}
                    isDecorated
                    isRequired={true}
                    {...(register?.(
                        'hasSecondaryInsurance',
                        standardRadioRegisterProps,
                    ) ?? {})}
                />
            )}

            {hasSecondaryInsurance && (
                <>
                    <Divider />

                    <FONTS.H6 color={COLORS.PRIMARY.Grey}>
                        Additional Insurance
                    </FONTS.H6>

                    <Dropdown
                        id="secondaryInsuranceId"
                        subLabel="If you can’t find the health plan in the dropdown, select “Other” and input the health plan name in the text field that appears. "
                        options={secondaryInsuranceDropdownOptions}
                        {...(register?.('secondary.payerId', {
                            required: true,
                            validate: (val) => {
                                return val !== '-';
                            },
                        }) ?? {})}
                    />

                    {selectedSecondaryPayerId === 'other' && (
                        <NewFormTextInput
                            label="What is the name of the other health plan?"
                            fieldName="secondary.payerName"
                            errors={errors}
                            registerProps={register?.(
                                'secondary.payerName',
                                getFormTextInputRegisterProps({}),
                            )}
                        />
                    )}

                    <FileUploader
                        label="Upload the front of the insurance card for this plan. "
                        maxFiles={1}
                        fieldValue={secondaryCardFront}
                        onChange={setCard(false, true)}
                        showPreviewUponUpload
                        registerProps={register?.(
                            'secondary.cardFront',
                            fileUploadRegisterProps,
                        )}
                    />

                    <FileUploader
                        label="Upload the back of the insurance card for this plan. "
                        maxFiles={1}
                        fieldValue={secondaryCardBack}
                        onChange={setCard(false, false)}
                        showPreviewUponUpload
                        registerProps={register?.(
                            'secondary.cardBack',
                            fileUploadRegisterProps,
                        )}
                    />
                </>
            )}
        </FlexCol>
    );
};

const useInsuranceCardStep = () => {
    const ctx = useCreateClientContext();

    const { stepOneForm, providerContractData } = ctx ?? {};
    const { formState, watch, setValue, register, unregister } =
        stepOneForm ?? {};
    const { errors } = formState ?? {};

    const [
        primaryPayerId,
        primaryIsMediCal,
        hasSecondaryInsurance,
        selectedSecondaryPayerId,
        primaryCardFront,
        primaryCardBack,
        secondaryCardFront,
        secondaryCardBack,
    ] =
        watch?.([
            'primary.payerId',
            'primary.isMediCal',
            'hasSecondaryInsurance',
            'secondary.payerId',
            'primary.cardFront',
            'primary.cardBack',
            'secondary.cardFront',
            'secondary.cardBack',
        ]) ?? [];

    const setterProps = {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
    };

    const setPrimaryPayerId = (val: string) => {
        setValue?.('primary.payerId', val, setterProps);
        unregister?.('primary.isMediCal');
    };

    const setPrimaryIsMediCal = (val: boolean) => {
        setValue?.('primary.isMediCal', val, setterProps);
        unregister?.('hasSecondaryInsurance');
    };

    const setHasSecondaryInsurance = (val: boolean) => {
        setValue?.('hasSecondaryInsurance', val, setterProps);
        unregister?.('secondary');
    };

    const setCard =
        (isPrimary: boolean, isFront: boolean) =>
        (val: FileUpload[] | undefined) => {
            setValue?.(
                `${isPrimary ? 'primary' : 'secondary'}.card${isFront ? 'Front' : 'Back'}`,
                val,
                setterProps,
            );
        };

    const { planCategory } = useMemo(() => {
        const primaryPayerData = providerContractData?.find(
            (contractData) =>
                contractData.payers.externalPayerId === primaryPayerId,
        );

        const planCategory = primaryPayerData?.payers.planCategories;

        return { primaryPayerData, planCategory };
    }, [primaryPayerId, providerContractData]);

    const { healthPlanOptions, secondaryInsuranceDropdownOptions } =
        useMemo(() => {
            const healthPlanOptions =
                providerContractData?.map((contractData) => {
                    const credStatus =
                        contractData.practitioner_contract_assignments
                            .credentiallingStatus;
                    return {
                        label: contractData.payers.name,
                        value: contractData.payers.externalPayerId,
                        decorationTagContent:
                            CredentialingExternalMapping[credStatus],
                        decorationTagScheme:
                            StatusToColorSchemeMapping[
                                CredentialingExternalMapping[credStatus]
                            ],
                        disabled:
                            credStatus !== CredentiallingStatus.Credentialed,
                    };
                }) ?? [];

            const secondaryInsuranceDropdownOptions = healthPlanOptions
                .filter((opt) => opt.value !== primaryPayerId)
                .map((decoratedOptions) => {
                    return {
                        label: decoratedOptions.label,
                        value: decoratedOptions.value,
                    };
                })
                .concat([{ label: 'Other', value: 'other' }]);

            return { healthPlanOptions, secondaryInsuranceDropdownOptions };
        }, [primaryPayerId, providerContractData]);

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();

    return {
        standardRadioRegisterProps,
        errors,
        primaryPayerId,
        setPrimaryPayerId,
        primaryIsMediCal,
        setPrimaryIsMediCal,
        healthPlanOptions,
        secondaryInsuranceDropdownOptions,
        planCategory,
        setHasSecondaryInsurance,
        hasSecondaryInsurance,
        selectedSecondaryPayerId,
        register,
        stepOneForm,
        primaryCardBack,
        primaryCardFront,
        secondaryCardFront,
        secondaryCardBack,
        setCard,
    };
};
