//TODO below enums are dupes from server, how oh how do we avoid having to do this

export enum InsurerRelationship {
    Self = 'Self',
    Spouse = 'Spouse',
    Child = 'Child',
    Other = 'Other',
}

export enum Gender {
    Man = 'man',
    Woman = 'woman',
    NonBinary = 'nonbinary',
    Other = 'other',
    PreferNotToSay = 'prefer_not_to_say',
}

export enum Sex {
    Female = 'female',
    Male = 'male',
    Other = 'other',
}

export enum PregnancyStatus {
    Prenatal = 'Prenatal',
    PostPartum = 'Postpartum',
    Inactive = 'Inactive',
}

export enum VerificationStatus {
    UnderReview = 'Under Review',
    Approved = 'Approved',
    Inactive = 'Inactive',
    Unverified = 'Unverified',
}

//Above enums are dupes from server :(

export const InsurerRelationshipDesc = {
    [InsurerRelationship.Self]:
        'The client is the primary account holder. With Medi-Cal plans, the client is generally self-insured.',
    [InsurerRelationship.Spouse]:
        'This client’s spouse is the primary account holder.',
    [InsurerRelationship.Child]:
        'This client’s parent is the primary account holder.',
    [InsurerRelationship.Other]: undefined,
};

export const Reset_Front_Text = 'Re-upload front of insurance card';
export const Reset_Back_Text = 'Re-upload back of insurance card';
