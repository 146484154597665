import { FlexCol } from 'ui/loulaFlex';
import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import { FormRadio } from 'ui/components/Form/FormRadio';
import { useCreateClientContext } from '../createClient.context';
import { useEffect, useMemo } from 'react';
import {
    InsurerRelationship,
    InsurerRelationshipDesc,
    Sex,
} from 'models/constants/clientConstants';
import { Divider } from 'ui/components/Divider';
import FONTS from 'ui/fonts';
import { DateInput, getDateInputRegisterProps } from 'ui/components/DateInput';
import AddressForm from 'components/AddressForm';
import { RadioOptionProp } from 'ui/components/Radio';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';
import { isInStringEnum } from 'util/Utils';
import {
    getFormTextInputRegisterProps,
    NewFormTextInput,
} from 'ui/components/Form/NewFormTextInput';
import { FormBoolRadio } from 'ui/components/Form/FormBoolRadio';
import { PXSTR } from 'ui/px';
import { isAnyPlanNotThroughSelf } from 'util/patient.utils';

export const InsuredDetailsStep = () => {
    const {
        standardRadioRegisterProps,
        setRelationToInsured,
        setSex,
        setIsGroupIDOnCard,
        errors,
        primaryRelationToInsured,
        secondaryRelationToInsured,
        insuredDetails,
        register,
        sexOptions,
        primaryRelationshipOptions,
        hasSecondaryInsurance,
        secondary,
        primaryPayerData,
        insurerRelationshipOptions,
        watch,
        setValue,
        isAnyPlanNonSelf,
        setInsuredDOB,
    } = useInsuredDetailsStep();
    return (
        <FlexCol gap={PXSTR.XXL}>
            <FormRadio
                fieldName="primaryRelationToInsured"
                label={`For ${primaryPayerData?.name}, what is the client’s relationship to the insured?`}
                errors={errors}
                defaultValue={primaryRelationToInsured}
                onChangeString={setRelationToInsured(true)}
                options={primaryRelationshipOptions}
                isRequired={true}
                isDecorated
                {...(register?.(
                    'primaryRelationToInsured',
                    standardRadioRegisterProps,
                ) ?? {})}
            />

            {primaryPayerData?.planCategories === 'MC' && (
                <Alert
                    content="Medi-Cal clients are self-insured. "
                    visualScheme={AlertVisualScheme.periwinkle}
                />
            )}

            {hasSecondaryInsurance && (
                <FormRadio
                    fieldName="secondaryRelationToInsured"
                    label={`For ${secondary?.payerName}, what is the client’s relationship to the insured?`}
                    errors={errors}
                    defaultValue={secondaryRelationToInsured}
                    onChangeString={setRelationToInsured(false)}
                    options={insurerRelationshipOptions}
                    isRequired={true}
                    isDecorated
                    {...(register?.(
                        'secondaryRelationToInsured',
                        standardRadioRegisterProps,
                    ) ?? {})}
                />
            )}

            {isAnyPlanNonSelf && (
                <FlexCol gap={PXSTR.M}>
                    <Divider />

                    <FONTS.H3>Insured’s Details</FONTS.H3>
                    <FONTS.P1>
                        Please provide the following information for the primary
                        account holder of this health insurance plan.
                    </FONTS.P1>

                    <NewFormTextInput
                        label="Insured’s First Name"
                        fieldName="insuredDetails.firstName"
                        errors={errors}
                        registerProps={register?.(
                            'insuredDetails.firstName',
                            getFormTextInputRegisterProps({}),
                        )}
                    />

                    <NewFormTextInput
                        label="Insured’s Middle Initial (optional)"
                        fieldName="insuredDetails.middleInitial"
                        errors={errors}
                        registerProps={register?.(
                            'insuredDetails.middleInitial',
                            getFormTextInputRegisterProps({ required: false }),
                        )}
                    />

                    <NewFormTextInput
                        label="Insured’s Last Name"
                        fieldName="insuredDetails.lastName"
                        errors={errors}
                        registerProps={register?.(
                            'insuredDetails.lastName',
                            getFormTextInputRegisterProps({}),
                        )}
                    />

                    <DateInput
                        label="Insured’s Date of Birth"
                        datePickerProps={{
                            dateFormat: 'MM/dd/yyyy',
                            placeholderText: 'MM/dd/yyyy',
                        }}
                        customValidateDate={undefined}
                        value={insuredDetails?.dob ?? null}
                        setValue={setInsuredDOB}
                        registerProps={
                            !!register
                                ? register?.(
                                      'insuredDetails.dob',
                                      getDateInputRegisterProps(),
                                  )
                                : undefined
                        }
                    />

                    <NewFormTextInput
                        label="Insured’s Member ID Number"
                        fieldName="insuredDetails.memberId"
                        errors={errors}
                        registerProps={register?.(
                            'insuredDetails.memberId',
                            getFormTextInputRegisterProps({}),
                        )}
                    />

                    <FormBoolRadio
                        fieldName="insuredDetails.isGroupIDOnCard"
                        label="Is a group ID listed on insured’s insurance card?"
                        errors={errors}
                        defaultValue={insuredDetails?.isGroupIDOnCard}
                        onChangeBool={setIsGroupIDOnCard}
                        isDecorated
                        isRequired={true}
                        {...(register?.(
                            'insuredDetails.isGroupIDOnCard',
                            standardRadioRegisterProps,
                        ) ?? {})}
                    />

                    {insuredDetails?.isGroupIDOnCard && (
                        <NewFormTextInput
                            label="Client’s Group ID Number"
                            fieldName="insuredDetails.groupId"
                            errors={errors}
                            registerProps={register?.('insuredDetails.groupId')}
                        />
                    )}

                    <FormRadio
                        fieldName="insuredDetails.sex"
                        label="Insured’s Sex"
                        subLabel="Sex is required to bill with health plans. Make sure the sex give matches the sex listed on the insurance record."
                        errors={errors}
                        defaultValue={insuredDetails?.sex}
                        onChangeString={setSex}
                        options={sexOptions}
                        isRequired={true}
                        isDecorated
                        {...(register?.(
                            'insuredDetails.sex',
                            standardRadioRegisterProps,
                        ) ?? {})}
                    />

                    <NewFormTextInput
                        label="Insured's Phone Number"
                        fieldName="insuredDetails.phone"
                        mask="(000) 000-0000"
                        placeholder="(123) 456-7890"
                        minLength={14}
                        errors={errors}
                        registerProps={register?.(
                            'insuredDetails.phone',
                            getFormTextInputRegisterProps({}),
                        )}
                    />

                    <AddressForm
                        label="Insured’s Address"
                        namePrefix="insuredDetails.address"
                        requiredWarningText="Please enter a valid mailing address."
                        watch={watch}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                    />
                </FlexCol>
            )}
        </FlexCol>
    );
};

const useInsuredDetailsStep = () => {
    const ctx = useCreateClientContext();

    const { stepOneForm, stepThreeForm, providerContractData } = ctx ?? {};

    const [primary, hasSecondaryInsurance, secondary] =
        stepOneForm?.watch(['primary', 'hasSecondaryInsurance', 'secondary']) ??
        [];

    const { primaryPayerData } = useMemo(() => {
        const primaryPayerData = providerContractData?.find(
            (contractData) =>
                contractData.payers.externalPayerId === primary?.payerId,
        )?.payers;

        return { primaryPayerData };
    }, [primary?.payerId, providerContractData]);

    const insurerRelationshipOptions: RadioOptionProp[] = Object.entries(
        InsurerRelationshipDesc,
    ).map(([valLabel, desc]) => {
        {
            return {
                value: valLabel,
                label: valLabel,
                decorationDesc: desc,
            };
        }
    });

    const isPrimaryMediCal = primaryPayerData?.planCategories === 'MC';

    const primaryRelationshipOptions = insurerRelationshipOptions.map((opt) => {
        return {
            ...opt,
            disabled: isPrimaryMediCal
                ? opt.value !== InsurerRelationship.Self
                : false,
        };
    });

    const sexOptions: RadioOptionProp[] = Object.values(Sex)
        .filter((sex) => sex !== Sex.Other) //stupid insurance companies
        .map((sex) => {
            return { label: sex, value: sex };
        });

    const { formState, watch, setValue, register, unregister } =
        stepThreeForm ?? {};
    const [
        primaryRelationToInsured,
        secondaryRelationToInsured,
        insuredDetails,
    ] =
        watch?.([
            'primaryRelationToInsured',
            'secondaryRelationToInsured',
            'insuredDetails',
        ]) ?? [];

    useEffect(() => {
        if (
            isPrimaryMediCal &&
            primaryRelationToInsured !== InsurerRelationship.Self
        ) {
            setValue?.('primaryRelationToInsured', InsurerRelationship.Self);
        }
    }, [isPrimaryMediCal, primaryRelationToInsured, setValue]);

    const { errors } = formState ?? {};

    const setterProps = {
        shouldValidate: true,
        shouldDirty: true,
    };

    const setSex = (val: string) => {
        if (!isInStringEnum(val, Sex)) return;
        setValue?.('insuredDetails.sex', val as Sex, setterProps);
    };

    const setRelationToInsured = (isPrimary: boolean) => (val: string) => {
        if (!isInStringEnum(val, InsurerRelationship)) return;

        setValue?.(
            `${isPrimary ? 'primary' : 'secondary'}RelationToInsured`,
            val as InsurerRelationship,
            setterProps,
        );

        if (val === InsurerRelationship.Self) {
            unregister?.('insuredDetails');
        }
    };

    const setIsGroupIDOnCard = (val: boolean) => {
        setValue?.('insuredDetails.isGroupIDOnCard', val, setterProps);
    };

    const setInsuredDOB = (val: Date | null | undefined) => {
        setValue?.('insuredDetails.dob', val ?? undefined, setterProps);
    };

    const isAnyPlanNonSelf = isAnyPlanNotThroughSelf([
        primaryRelationToInsured,
        secondaryRelationToInsured,
    ]);

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();
    return {
        standardRadioRegisterProps,
        setRelationToInsured,
        setSex,
        setIsGroupIDOnCard,
        errors,
        primaryRelationToInsured,
        secondaryRelationToInsured,
        insuredDetails,
        register,
        sexOptions,
        primaryRelationshipOptions,
        hasSecondaryInsurance,
        secondary,
        primaryPayerData,
        insurerRelationshipOptions,
        watch,
        setValue,
        setInsuredDOB,
        isAnyPlanNonSelf,
    };
};
