import { DoulaPortalLayout } from 'doulaPortal/layouts/DoulaPortalLayout';
import { PrimaryButton } from 'ui/components/Button';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { ClientInfo } from './ClientInfo';
import { VisitsInfo } from './VisitsInfo';
import { PXSTR } from 'ui/px';
import FONTS from 'ui/fonts';
import COLORS from 'ui/colors';
import useMeasure from 'react-use-measure';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useMobileFullScreenBehavior } from 'doulaPortal/hooks/useMobileFullScreenBehavior';
import { getNameToShow } from 'util/Utils';
import { ClientDetailsContext } from './clientDetails.context';
import { Avatar } from 'ui/components/Avatar';
import { getInitials } from 'util/name.utils';
import { DrawerLayout } from 'doulaPortal/layouts/DrawerLayout';
import { useInitialClientDetailsContext } from './useInitialClientDetailsContext';
import { MenuButton } from 'ui/components/MenuButton';
import { ClientDetailsHeaderMenuButtonItem } from './ClientDetailsHeaderMenuButtonItem';
import useScreenSize from 'hooks/useScreenSize';
import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import {
    isInsuranceVerificationStatusUnderReview,
    useIsInsuranceVerificationUnderReview,
} from 'hooks/dbHooks/clients.hooks';

const Page = () => {
    const initialContext = useInitialClientDetailsContext();
    const { isNarrowScreen } = useScreenSize();

    return (
        <ClientDetailsContext.Provider value={initialContext}>
            <DoulaPortalLayout
                pageHeaderComponent={
                    <FlexRow gap={PXSTR.S} padding={PXSTR.S}>
                        <Avatar
                            initials={getInitials(initialContext.client?.user)}
                            size="xs"
                        />
                        <FONTS.H3>
                            {getNameToShow(initialContext.client?.user)}
                        </FONTS.H3>
                    </FlexRow>
                }
                pageActionButtons={[
                    <PrimaryButton
                        key="submit_btn"
                        onClick={initialContext.goToSubmitVisit}
                        //TODO: this should be reactivated after dev
                        // disabled={isInsuranceVerificationStatusUnderReview(
                        //     initialContext.client,
                        // )}
                    >
                        Submit visit
                    </PrimaryButton>,
                    <MenuButton
                        key="btn_2"
                        menuItems={[<ClientDetailsHeaderMenuButtonItem />]}
                    />,
                ]}
            >
                <ClientDetails />
                <DrawerLayout />
            </DoulaPortalLayout>
        </ClientDetailsContext.Provider>
    );
};

const ClientAlert = () => {
    const isInsuranceVerificationStatusUnderReview =
        useIsInsuranceVerificationUnderReview();

    //TODO-Mina: gotta track dismissal here and not show it again unless it changes...

    if (isInsuranceVerificationStatusUnderReview) {
        return (
            <Alert
                visualScheme={AlertVisualScheme.periwinkle}
                contentComponents={
                    <FONTS.P1 color={COLORS.PRIMARY.Blue}>
                        <b>This client’s insurance is under review.</b> Our team
                        is working to verify this client’s insurance. We will
                        email you an update about this client’s eligibility
                        within 2 business days.
                    </FONTS.P1>
                }
                isDismissable
            />
        );
    }

    return null;
};

const ClientDetails = () => {
    const [ref, bounds] = useMeasure();
    const showInTabs = bounds.width < 840;
    //TODO-Mina instead of all these calls to useMeasure
    //and magic number checks,
    //systematize bc this is gonna be everywhere

    return (
        <FlexCol paddingX={PXSTR.L} gap={PXSTR.L} height="100%">
            <ClientAlert />

            <FlexRow
                ref={ref}
                className="clientDetailsWrapper"
                gap={PXSTR.M}
                height="100%"
            >
                {showInTabs ? (
                    <TabViewOfClientDetails />
                ) : (
                    <ColViewOfClientDetails />
                )}
            </FlexRow>
        </FlexCol>
    );
};

const TabViewOfClientDetails = () => {
    const { scrollElementRef } = useMobileFullScreenBehavior();

    return (
        <Tabs
            variant="base"
            width="100%"
            flex={1}
            display="flex"
            flexDir="column"
        >
            <TabList>
                <Tab>Visits</Tab>
                <Tab>Client Information</Tab>
            </TabList>
            <TabPanels
                flex={1}
                maxHeight="100%"
                overflowY="scroll"
                overscrollBehavior="none"
                ref={scrollElementRef}
            >
                <TabPanel flex={1}>
                    <VisitsInfo />
                </TabPanel>
                <TabPanel>
                    <ClientInfo />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

const ColViewOfClientDetails = () => {
    return (
        <>
            <FlexCol gap={PXSTR.S} height="100%" minWidth="300px">
                <FONTS.H6 color={COLORS.PRIMARY.Grey}>
                    Client Information
                </FONTS.H6>
                <ClientInfo />
            </FlexCol>

            <FlexCol maxWidth={`calc(100% - 300px)`} gap={PXSTR.S} flex={1}>
                <VisitsInfo />
            </FlexCol>
        </>
    );
};

export default Page;
