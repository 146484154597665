import FONTS from 'ui/fonts';
import { FlexRow } from '../loulaFlex';
import PX from 'ui/px';
import COLORS from 'ui/colors';
import { FlexProps } from '@chakra-ui/react';
import { IconButton } from 'ui/components/IconButton';
import { ICONS } from 'ui/icons';
import { Tooltip } from './Tooltip';

export enum TextTagColorSchemes {
    DEFAULT = 'default',
    GREY_LIGHT = 'grey_light',
    GREY_HEAVY = 'grey_heavy',
    GREEN_LIGHT = 'green_light',
    GREEN_HEAVY = 'green_heavy',
    ORANGE_LIGHT = 'orange_light',
    ORANGE_HEAVY = 'orange_heavy',
    BLUE_LIGHT = 'blue_light',
    BLUE_HEAVY = 'blue_heavy',
    RED_LIGHT = 'red_light',
    RED_HEAVY = 'red_heavy',
}

const ColorSchemeFields = {
    [TextTagColorSchemes.DEFAULT]: {
        borderColor: COLORS.PRIMARY.Grey,
        background: COLORS.UTIL.Gray.WARM,
        color: COLORS.UTIL.Gray[900],
        iconColor: COLORS.PRIMARY.Grey,
    },
    [TextTagColorSchemes.GREEN_HEAVY]: {
        borderColor: COLORS.UTIL.Green[700],
        background: COLORS.UTIL.Green[700],
        color: COLORS.UTIL.Gray.WARM,
        iconColor: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.BLUE_HEAVY]: {
        borderColor: COLORS.BRAND.Periwinkle[800],
        background: COLORS.BRAND.Periwinkle[800],
        color: COLORS.UTIL.Gray.WARM,
        iconColor: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.GREY_HEAVY]: {
        borderColor: COLORS.UTIL.Gray[800],
        background: COLORS.UTIL.Gray[800],
        color: COLORS.UTIL.Gray.WARM,
        iconColor: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.ORANGE_HEAVY]: {
        borderColor: COLORS.UTIL.WarningOrange[700],
        background: COLORS.UTIL.WarningOrange[700],
        color: COLORS.UTIL.Gray.WARM,
        iconColor: COLORS.UTIL.Gray.WARM,
    },
    [TextTagColorSchemes.GREEN_LIGHT]: {
        borderColor: COLORS.UTIL.Green[500],
        background: COLORS.UTIL.Green[200],
        color: COLORS.UTIL.Green[800],
        iconColor: COLORS.UTIL.Green[600],
    },
    [TextTagColorSchemes.ORANGE_LIGHT]: {
        borderColor: COLORS.UTIL.WarningOrange[500],
        background: COLORS.UTIL.WarningOrange[200],
        color: COLORS.UTIL.WarningOrange[800],
        iconColor: COLORS.UTIL.WarningOrange[700],
    },
    [TextTagColorSchemes.BLUE_LIGHT]: {
        borderColor: COLORS.BRAND.Periwinkle[500],
        background: COLORS.BRAND.Periwinkle[200],
        color: COLORS.BRAND.Periwinkle[800],
        iconColor: COLORS.BRAND.Periwinkle[600],
    },
    [TextTagColorSchemes.GREY_LIGHT]: {
        borderColor: COLORS.UTIL.Gray[500],
        background: COLORS.UTIL.Gray[200],
        color: COLORS.UTIL.Gray[800],
        iconColor: COLORS.PRIMARY.Grey,
    },
    [TextTagColorSchemes.RED_LIGHT]: {
        borderColor: COLORS.UTIL.Red[500],
        background: COLORS.UTIL.Red[200],
        color: COLORS.UTIL.Red[800],
        iconColor: COLORS.UTIL.Red[700],
    },
    [TextTagColorSchemes.RED_HEAVY]: {
        borderColor: COLORS.UTIL.Red[700],
        background: COLORS.UTIL.Red[700],
        color: COLORS.UTIL.Gray.WARM,
        iconColor: COLORS.UTIL.Gray.WARM,
    },
};

type TextTagProps = FlexProps & {
    text: string | undefined;
    onClickRight?: () => void;
    onClickLeft?: () => void;
    iconLeft?: keyof typeof ICONS;
    iconRight?: keyof typeof ICONS;
    colorScheme?: TextTagColorSchemes;
    toolTip?: string;
};

export const TextTag = ({ toolTip, ...props }: TextTagProps) => {
    if (!!toolTip) {
        return (
            <Tooltip label="toolTip">
                <span>
                    <TagComponent {...props} />
                </span>
            </Tooltip>
        );
    } else {
        return <TagComponent {...props} />;
    }
};

const TagComponent = ({
    text,
    onClickRight,
    onClickLeft,
    iconLeft,
    iconRight,
    colorScheme = TextTagColorSchemes.DEFAULT,
    ...props
}: TextTagProps) => {
    const { iconColor, ...colorSchemeData } = ColorSchemeFields[colorScheme];
    return (
        <FlexRow
            align="center"
            justify="center"
            height="fit-content"
            gap={PX.SPACING.PX.XS}
            borderRadius={PX.RADII.SM}
            padding={PX.SPACING.PX.XS}
            border={`1px solid ${COLORS.STROKES.HEAVY}`}
            {...colorSchemeData}
            {...props}
        >
            {(onClickLeft || iconLeft) && (
                <IconButton
                    padded={false}
                    icon={iconLeft ?? 'Close'}
                    color={iconColor}
                    onClick={onClickLeft}
                    size={16}
                />
            )}

            <FONTS.TAG color={ColorSchemeFields[colorScheme].color}>
                {text}
            </FONTS.TAG>

            {(onClickRight || iconRight) && (
                <IconButton
                    padded={false}
                    icon={iconRight ?? 'Close'}
                    color={iconColor}
                    onClick={onClickRight}
                    size={16}
                />
            )}
        </FlexRow>
    );
};
