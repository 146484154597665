import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import {
    FieldErrors,
    FieldValues,
    UseFormRegisterReturn,
} from 'react-hook-form';
import { CheckBox, FullCheckboxProps } from '../Checkbox';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export function FormCheckboxInput({
    fieldName,
    label,
    errors,

    value,
    setValue,

    isRequired = true,
    blockingOnCheckedTrue,

    registerProps,

    ...props
}: Omit<FullCheckboxProps, 'value'> & {
    label: string;
    fieldName: string;
    errors: FieldErrors<FieldValues> | undefined;
    value: boolean;
    setValue: (_val: boolean) => void;
    isRequired?: boolean;
    blockingOnCheckedTrue?: () => void; //For when you need to run some process before allowing user to check
    'data-cy'?: string;
    registerProps?: UseFormRegisterReturn;
}) {
    return (
        <FormControl
            flex={1}
            isInvalid={!!errors?.[fieldName]}
            isRequired={isRequired}
        >
            <FormLabel display="none" htmlFor={fieldName}>
                {label}
            </FormLabel>
            <FlexRow gap={PX.SPACING.PX.S}>
                <input hidden {...registerProps} />
                <CheckBox
                    data-cy={`${props['data-cy']}_checkbox`}
                    isChecked={value}
                    onChange={(e) => {
                        if (e.target.checked && blockingOnCheckedTrue) {
                            blockingOnCheckedTrue();
                            return;
                        }
                        props?.onChange?.(e);
                        setValue(e.target.checked);
                    }}
                    {...props}
                >
                    <FONTS.P1>{label}</FONTS.P1>
                </CheckBox>
            </FlexRow>

            <FormErrorMessage>
                {errors?.[fieldName] && errors?.[fieldName].message?.toString()}
            </FormErrorMessage>
        </FormControl>
    );
}
