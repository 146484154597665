import { AllDrawersEnum } from 'doulaPortal/drawers/drawers';
import { useOpenDrawer } from 'doulaPortal/drawers/useDrawers';
import COLORS from 'ui/colors';
import { NumBadge } from 'ui/components/NumBadge';
import { ClickableSheetItem } from 'ui/components/sheet/ClickableSheetItem';
import FONTS from 'ui/fonts';
import { useNumOffPlatformVisits } from './clientDetails.hooks';

export const ClientDetailsHeaderMenuButtonItem = () => {
    const openDrawer = useOpenDrawer({
        drawerId: AllDrawersEnum.offPlatformVisits,
        props: {},
    });

    const numOffPlatformVisits = useNumOffPlatformVisits();

    return (
        <ClickableSheetItem width="240px" onClick={openDrawer}>
            <FONTS.P1>Off-platform Visits</FONTS.P1>
            <NumBadge
                background={COLORS.PRIMARY.Warning}
                num={numOffPlatformVisits}
            />
        </ClickableSheetItem>
    );
};
