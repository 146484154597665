import { useCallback, useMemo, useState } from 'react';
import { TextTagColorSchemes } from 'ui/components/TextTag';
import { useClientDetailsContext } from './clientDetails.context';
import {
    useClientVisits,
    useOffPlatformVisits,
} from 'hooks/dbHooks/visits.hooks';
import {
    NumAdditionalPostpartumVisits,
    NumVisitsPerVisitCategory,
    VisitCategory,
    VisitType,
    VisitTypeToCategory,
} from 'models/constants/visitConstants';
import { usePortalContext } from 'doulaPortal/context/usePortalContext';
import { AllDrawersEnum } from 'doulaPortal/drawers/drawers';
import { PatientReturnType } from 'models/schemas';
import { GetPregnancy } from 'util/patient.utils';

export const usePregnancyInfo = (
    client: PatientReturnType | null | undefined,
) => {
    const {
        pregnancyStatus,
        postPartumStatus,
        statusColorScheme,
        additionalPostPartumColorScheme,
        pregnancy,
    } = useMemo(() => {
        //TOOD: these two records shouild be constants
        const statusTagColorSchemes: Record<
            typeof pregnancyStatus,
            TextTagColorSchemes
        > = {
            Prenatal: TextTagColorSchemes.BLUE_LIGHT,
            Postpartum: TextTagColorSchemes.GREEN_LIGHT,
            Inactive: TextTagColorSchemes.GREY_LIGHT,
            Unknown: TextTagColorSchemes.RED_LIGHT,
        };

        const additionalPostPartumColorSchemes: Record<
            typeof postPartumStatus,
            TextTagColorSchemes
        > = {
            None: TextTagColorSchemes.GREY_LIGHT,
            'Under Review': TextTagColorSchemes.ORANGE_LIGHT,
            Approved: TextTagColorSchemes.GREEN_HEAVY,
        };

        const pregnancy = GetPregnancy(client);
        const pregnancyStatus = pregnancy?.status ?? 'Unknown';
        const postPartumStatus =
            pregnancy?.additionalPostPartumStatus ?? 'None';

        const additionalPostPartumColorScheme =
            additionalPostPartumColorSchemes[postPartumStatus];
        const statusColorScheme = statusTagColorSchemes[pregnancyStatus];

        return {
            pregnancy,
            pregnancyStatus,
            postPartumStatus,
            additionalPostPartumColorScheme,
            statusColorScheme,
        };
    }, [client]);

    const [isAddtlPostPartumReccModalOpen, setIsAddtlPostPartumReccModalOpen] =
        useState(false);

    const onClickAdditionalPostpartumRecc = useCallback(() => {
        // eslint-disable-next-line no-console
        console.log(
            'should ' +
                (postPartumStatus === 'None'
                    ? 'SHOW UPLOAD FILE MODAL'
                    : 'OPEN FILE'),
        );
        setIsAddtlPostPartumReccModalOpen(true);
    }, [postPartumStatus]);

    return {
        pregnancyStatus,
        deliveryDate: pregnancy?.deliveryDate,
        isPrenatal:
            pregnancyStatus === 'Prenatal' || pregnancyStatus === 'Unknown',
        postPartumStatus,
        statusColorScheme,
        additionalPostPartumColorScheme,
        onClickAdditionalPostpartumRecc,
        isAddtlPostPartumReccModalOpen,
        setIsAddtlPostPartumReccModalOpen,
    };
};

export const useClientUserDetails = () => {
    const clientDetailsContext = useClientDetailsContext();
    const { client } = clientDetailsContext ?? {};

    return {
        ...(client?.user ?? {}),
    };
};

export const useClientInsurance = () => {
    const clientDetailsContext = useClientDetailsContext();
    const { client } = clientDetailsContext ?? {};

    const { primaryInsurance, secondaryInsurance } = useMemo(() => {
        const primaryInsurance = client?.patientInsurance.find(
            (insuranceData) => insuranceData.type === 'primary',
        );

        const secondaryInsurance = client?.patientInsurance.find(
            (insuranceData) => insuranceData.type === 'secondary',
        );

        return {
            primaryInsurance,
            secondaryInsurance,
        };
    }, [client?.patientInsurance]);

    return {
        primaryInsurance,
        secondaryInsurance,
    };
};

export const useVisitCounts = (
    client: PatientReturnType | null | undefined,
) => {
    const { allVisits } = useClientVisits(client?.userId);
    const { allVisits: allOffPlatforms } = useOffPlatformVisits(client?.userId);
    const isAdditionalPostPartumApproved =
        GetPregnancy(client)?.additionalPostPartumStatus === 'Approved';

    const {
        countUsedPerCategory,
        countPerPerinatalCategory,
        countRemainingPerCategory,
    } = useMemo(() => {
        const countUsedPerCategory: Record<VisitCategory, number> = {
            [VisitCategory.Initial]: 0,
            [VisitCategory.Perinatal]: 0,
            [VisitCategory.LaborDelivery]: 0,
            [VisitCategory.PostpartumExtended]: 0,
        };

        const countPerPerinatalCategory = {
            [VisitType.Prenatal]: 0,
            [VisitType.Postpartum]: 0,
        };

        const combinedVisits = { ...allVisits, ...allOffPlatforms };

        //TODO: only count remaining should account for offPlatformVisits
        //the countUsed should only count platform visits
        Object.values(combinedVisits).forEach((visit) => {
            countUsedPerCategory[
                VisitTypeToCategory[visit.visitType as VisitType]
            ] += 1;

            if ((visit.visitType as VisitType) === VisitType.Prenatal) {
                countPerPerinatalCategory[VisitType.Prenatal] += 1;
            } else if (
                (visit.visitType as VisitType) === VisitType.Postpartum
            ) {
                countPerPerinatalCategory[VisitType.Postpartum] += 1;
            }
        });

        const countRemainingPerCategory: Record<VisitCategory, number> = {
            [VisitCategory.Initial]:
                NumVisitsPerVisitCategory[VisitCategory.Initial] -
                countUsedPerCategory[VisitCategory.Initial],
            [VisitCategory.Perinatal]:
                NumVisitsPerVisitCategory[VisitCategory.Perinatal] +
                (isAdditionalPostPartumApproved
                    ? NumAdditionalPostpartumVisits
                    : 0) -
                countUsedPerCategory[VisitCategory.Perinatal],
            [VisitCategory.LaborDelivery]:
                NumVisitsPerVisitCategory[VisitCategory.LaborDelivery] -
                countUsedPerCategory[VisitCategory.LaborDelivery],
            [VisitCategory.PostpartumExtended]:
                NumVisitsPerVisitCategory[VisitCategory.PostpartumExtended] -
                countUsedPerCategory[VisitCategory.PostpartumExtended],
        };

        return {
            countPerPerinatalCategory,
            countUsedPerCategory,
            countRemainingPerCategory,
        };
    }, [allOffPlatforms, allVisits, isAdditionalPostPartumApproved]);

    return {
        allVisits,
        countPerPerinatalCategory,
        countUsedPerCategory,
        countRemainingPerCategory,
        isAdditionalPostPartumApproved,
    };
};

export const useOpenEditClientDrawer = (tabId: string) => {
    const portalContext = usePortalContext();

    const openDrawer = () => {
        portalContext?.openDrawer({
            drawerId: AllDrawersEnum.editClient,
            props: {
                initialTabId: tabId,
            },
        });
    };

    return { openDrawer };
};

export const useNumOffPlatformVisits = () => {
    const ctx = useClientDetailsContext();
    const { client } = ctx ?? {};
    return client?.offPlatformVisits?.length ?? 0;
};
