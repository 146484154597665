import { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    SimpleFlowContextType,
    StepDefType,
    StepStatus,
} from './simpleFlow.models';

export const useInitialSimpleFlowContext = ({
    flowName,
    stepDefinitions,
    completeFlow,
    getIsNextEnabled,
}: {
    flowName: string;
    stepDefinitions: StepDefType[];
    completeFlow: () => Promise<void>;
    getIsNextEnabled: (idx: number) => boolean;
}): SimpleFlowContextType => {
    const navigate = useNavigate();

    const [activeStepIdx, setActiveStepIdx] = useState(0);
    const [stepStatuses, setStepStatuses] = useState<
        Record<number, StepStatus>
    >(
        Object.fromEntries(
            Object.keys(stepDefinitions).map((a, idx) => [
                idx,
                { seen: idx === 0 },
            ]),
        ),
    );

    const activeStep = useMemo(
        () => stepDefinitions[activeStepIdx],
        [stepDefinitions, activeStepIdx],
    );

    const closeFlow = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const { numSteps, isLastStep } = useMemo(() => {
        const numSteps = stepDefinitions.length;
        return {
            numSteps,
            isLastStep: activeStepIdx === numSteps - 1,
        };
    }, [activeStepIdx, stepDefinitions.length]);

    const unSeeSteps = (indexes: number[]) => {
        setStepStatuses((prev) => {
            const res = { ...prev };
            indexes.map((idx) => {
                res[idx] = { seen: false };
            });
            return res;
        });
    };

    const goToStep = (idx: number, unSeeFollowingSteps?: boolean) => {
        if (idx < 0 || idx > numSteps - 1) return;

        setStepStatuses((prev) => {
            const res = { ...prev };

            if (unSeeFollowingSteps) {
                for (let i = idx + 1; i < numSteps; i++) {
                    res[i] = { seen: false };
                }
            }

            res[idx] = { seen: true };
            return res;
        });

        setActiveStepIdx(idx);
    };

    const goToNextStep = () => {
        if (isLastStep) return;

        setStepStatuses((prev) => {
            return { ...prev, [activeStepIdx + 1]: { seen: true } };
        });
        setActiveStepIdx((prev) => prev + 1);
    };

    const goToPrevStep = () => {
        if (activeStepIdx === 0) return;

        setStepStatuses((prev) => {
            return { ...prev, [activeStepIdx - 1]: { seen: true } };
        });

        setActiveStepIdx((prev) => prev - 1);
    };

    const isNextEnabled = useMemo(
        () => getIsNextEnabled(activeStepIdx),
        [getIsNextEnabled, activeStepIdx],
    );

    return {
        isNextEnabled,
        completeFlow,
        goToPrevStep,
        goToNextStep,
        goToStep,
        unSeeSteps,
        closeFlow,
        activeStep,
        stepStatuses,
        activeStepIdx,
        flowName,
        isLastStep,
    };
};
