import { DrawerTabsLayout } from 'doulaPortal/layouts/DrawerLayout';
import { EditClientDrawerContext } from './editClientDrawer.context';
import { PersonalTab } from './PersonalTab';
import { ContactTab } from './ContactTab';
import { PregnancyTab } from './PregnancyTab';
import { FlexCol } from 'ui/loulaFlex';
import { Spinner } from '@chakra-ui/react';
import { useInitialEditClientDrawerContext } from './useInitialEditClientDrawerContext';
import { InsuranceTab } from './InsuranceTab';

export type EditClientDrawerProps = {
    initialTabId: string;
};

export const EditClientDrawer = ({ initialTabId }: EditClientDrawerProps) => {
    const initialContext = useInitialEditClientDrawerContext();

    //TODO-Mina-Polish: this loading should be done ahead of time somewhere
    if (!initialContext.isClientDataLoaded) {
        return (
            <FlexCol height="100%" justifyContent="center" alignItems="center">
                <Spinner />
            </FlexCol>
        );
    }

    return (
        <EditClientDrawerContext.Provider value={initialContext}>
            <DrawerTabsLayout
                initialTabId={initialTabId}
                tabs={[
                    {
                        tabId: 'pregnancy',
                        tabTitle: 'Pregnancy',
                        tabPanel: <PregnancyTab key={`pregancy_tab`} />,
                    },
                    {
                        tabId: 'insurance',
                        tabTitle: 'Insurance',
                        tabPanel: <InsuranceTab key={`insurance_tab`} />,
                    },
                    {
                        tabId: 'personal',
                        tabTitle: 'Personal',
                        tabPanel: <PersonalTab key={`personal_tab`} />,
                    },
                    {
                        tabId: 'contact',
                        tabTitle: 'Contact',
                        tabPanel: <ContactTab key={`contact_tab`} />,
                    },
                ]}
            />
        </EditClientDrawerContext.Provider>
    );
};
