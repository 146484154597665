import { useAuth0 } from '@auth0/auth0-react';
import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ErrorPage } from './pages/ErrorPage';
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import { VerifyEmail } from './pages/VerifyEmail';
import { OnboardingFlow } from 'onboarding/OnboardingFlow';
import 'react-datepicker/dist/react-datepicker.css';
import DevRoute from 'components/DevRoute';
import Intercom from '@intercom/messenger-js-sdk';
import useScreenSize from 'hooks/useScreenSize';

import './App.css';
import { useIsDev } from 'hooks/useIsDev';
import Home from 'doulaPortal/pages/Home';
import Clients from 'doulaPortal/pages/Clients/Clients';
import Visits from 'doulaPortal/pages/Visits';
import ClientDetails from 'doulaPortal/pages/ClientDetails/ClientDetails';
import SubmitVisit from 'doulaPortal/pages/SubmitVisit/SubmitVisit';
import CreateClient from 'doulaPortal/pages/CreateClient/CreateClient';
import VisitDetails from 'doulaPortal/pages/VisitDetails/VisitDetails';
import Payments from 'doulaPortal/pages/Payments';
import Settings from 'doulaPortal/pages/Settings';

const useIntercom = () => {
    const { isNarrowScreen } = useScreenSize();

    useEffect(() => {
        Intercom({
            app_id: 'ocd9l3z9',
            vertical_padding: 100,
            hide_default_launcher: isNarrowScreen,
        });
    }, [isNarrowScreen]);
};

export function App(): JSX.Element | null {
    useIntercom();

    const { isLoading } = useAuth0();
    const isDev = useIsDev();

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route element={<PrivateRoute />}>
                    {/* TODO: remove redirect */}
                    {isDev ? (
                        <Route path="/" element={<Navigate to="/home" />} />
                    ) : (
                        <Route
                            path="/"
                            element={<Navigate to="/onboarding" />}
                        />
                    )}

                    {/*Onboarding Flow*/}
                    <Route path="/onboarding" element={<OnboardingFlow />} />
                    <Route
                        path="/onboarding/:stepSlug"
                        element={<OnboardingFlow />}
                    />
                    <Route
                        path="/onboarding/:stepSlug/:subStepSlug"
                        element={<OnboardingFlow />}
                    />

                    {/* Routes only accessible to developers, see DevRoute and useIsDev for more info */}
                    <Route element={<DevRoute />}>
                        {/*Provider Portal - TODO:Will eventually come out of dev routes*/}
                        <Route path="/home" element={<Home />} />

                        <Route path="/clients" element={<Clients />} />
                        <Route path="/clients/all" element={<Clients />} />
                        <Route
                            path="/clients/all/:drawerId"
                            element={<Clients />}
                        />

                        <Route
                            path="/clients/:clientId"
                            element={<ClientDetails />}
                        />
                        <Route
                            path="/clients/:clientId/:drawerId"
                            element={<ClientDetails />}
                        />
                        <Route
                            path="/clients/:clientId/:drawerId/:drawerTabId"
                            element={<ClientDetails />}
                        />

                        <Route path="/client/new" element={<CreateClient />} />

                        <Route path="/visits" element={<Visits />} />
                        <Route path="/visit/new" element={<SubmitVisit />} />
                        <Route
                            path="/visit/new/:clientId"
                            element={<SubmitVisit />}
                        />
                        <Route
                            path="/visit/:visitId"
                            element={<VisitDetails />}
                        />

                        <Route path="/payments" element={<Payments />} />
                        <Route path="/settings" element={<Settings />} />
                    </Route>
                </Route>

                {/*Public Routes*/}
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Suspense>
    );
}
