import {
    DeliveryTypeCopy,
    DeliveryTypes,
    PregnancyConclusionOptions,
    PregnancyConclusionTypes,
} from 'models/constants/visitConstants';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Alert } from 'ui/components/Alert';
import { BasicModalLayout } from 'ui/components/BasicModalLayout';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import { FormDateInput } from 'ui/components/Form/FormDateInput';
import { FormRadio } from 'ui/components/Form/FormRadio';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { useToast } from 'ui/useToast';
import { getNameToShow } from 'util/Utils';
import { FormBoolRadio } from 'ui/components/Form/FormBoolRadio';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';

type ModalFormFields = {
    wasBillable: boolean | undefined;
    pregnancyConclusion: PregnancyConclusionTypes | undefined;
    dateOfPregnancyConclusion: string | undefined;
};

const DeliveryTypeOptions = [
    {
        value: DeliveryTypes.Vaginal,
        label: DeliveryTypeCopy[DeliveryTypes.Vaginal],
    },
    {
        value: DeliveryTypes.VaginalAfterCSection,
        label: DeliveryTypeCopy[DeliveryTypes.VaginalAfterCSection],
    },
    {
        value: DeliveryTypes.CSection,
        label: DeliveryTypeCopy[DeliveryTypes.CSection],
    },
];

const useMoveToPostPartumModal = ({ onClose }: { onClose: () => void }) => {
    const toast = useToast();

    const formInstance = useForm({ mode: 'all' });
    const { formState, register, setValue, watch, handleSubmit, trigger } =
        formInstance ?? {};

    const { errors, isValid } = formState ?? {};

    const ctx = useClientDetailsContext();
    const { client, refreshClientData, clientHookUpdaters } = ctx ?? {};
    const { updatePatientPregnancy } = clientHookUpdaters ?? {};

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (isInitialized) return;
        formInstance.reset({
            defaultValues: {
                wasBillable: undefined,
                pregnancyConclusion: undefined,
                dateOfPregnancyConclusion: undefined,
                deliveryType: undefined,
            } as ModalFormFields,
        });
        setIsInitialized(true);
    }, [formInstance, isInitialized]);

    const wasBillableWatcher = watch('wasBillable');
    const pregnancyConclusionWatcher = watch('pregnancyConclusion');
    const deliveryTypeWatcher = watch('deliveryType');

    const onSubmit: SubmitHandler<FieldValues> = async (_data) => {
        const formVals = formInstance.getValues();

        await updatePatientPregnancy?.({
            status: 'Postpartum',
            deliveryDate: formVals.dateOfPregnancyConclusion,
            conclusionType: formVals.pregnancyConclusion,
            deliveryType: formVals.deliveryType,
        });

        toast({ description: 'Changes saved to profile' });

        refreshClientData?.();

        onClose();
    };

    const onChangeWrapper =
        (fieldName: string, reformatFn?: (potentialVal: unknown) => unknown) =>
        (newVal: unknown) => {
            setValue(fieldName, !!reformatFn ? reformatFn(newVal) : newVal);
            trigger();
        };

    const onCloseForm = (callback: () => void) => () => {
        formInstance.reset();
        callback();
    };

    const setWasBillable = (boolVal: boolean) => {
        setValue?.('wasBillable', boolVal, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();

    return {
        wasBillableWatcher,
        setWasBillable,
        pregnancyConclusionWatcher,
        deliveryTypeWatcher,
        errors,
        register,
        onChangeWrapper,
        onSubmit: handleSubmit(onSubmit),
        isValid,
        onCloseForm,
        setValue,
        watch,
        standardRadioRegisterProps,
        client,
    };
};

export const MoveToPostPartumModal = ({
    isOpen,
    onClose,
}: {
    onClose: () => void;
    isOpen: boolean;
}) => {
    const {
        wasBillableWatcher,
        setWasBillable,
        pregnancyConclusionWatcher,
        deliveryTypeWatcher,
        errors,
        register,
        onSubmit,
        isValid,
        onChangeWrapper,
        onCloseForm,
        setValue,
        watch,
        standardRadioRegisterProps,
        client,
    } = useMoveToPostPartumModal({ onClose });

    return (
        <form onSubmit={onSubmit}>
            <BasicModalLayout
                title="Move to Postpartum"
                isOpen={isOpen}
                onClose={onCloseForm(onClose)}
                buttons={[
                    <SecondaryButton
                        key="cancelMoveToPostPartum"
                        onClick={onCloseForm(onClose)}
                    >
                        Cancel
                    </SecondaryButton>,
                    <PrimaryButton
                        type="submit"
                        key="submitAddtlPostPartumModal"
                        onClick={onSubmit}
                        disabled={!isValid || wasBillableWatcher}
                    >
                        Save Changes
                    </PrimaryButton>,
                ]}
            >
                <FlexCol gap={PXSTR.L}>
                    <FormBoolRadio
                        fieldName="wasBillable"
                        label={`Did you provide billable doula support for the end of ${getNameToShow(client?.user)}’s pregnancy?`}
                        subLabel="This includes doula support for labor and delivery, miscarriage support, and abortion support. "
                        {...register('wasBillable', standardRadioRegisterProps)}
                        errors={errors}
                        defaultValue={wasBillableWatcher}
                        onChangeBool={setWasBillable}
                        options={[
                            {
                                value: true,
                                labelBoldPrefix: 'Yes',
                                label: 'I provided billable doula support. ',
                                isDecorated: true,
                            },
                            {
                                value: false,
                                labelBoldPrefix: 'No',
                                label: 'I did not provide billable doula support. ',
                                isDecorated: true,
                            },
                        ]}
                        isRequired={true}
                    />

                    {!wasBillableWatcher ? (
                        <>
                            <FormRadio
                                isRequired={true}
                                {...register(
                                    'pregnancyConclusion',
                                    standardRadioRegisterProps,
                                )}
                                label="How did this pregnancy end? "
                                fieldName="pregnancyConclusion"
                                errors={errors}
                                defaultValue={pregnancyConclusionWatcher}
                                onChangeString={onChangeWrapper(
                                    'pregnancyConclusion',
                                )}
                                options={PregnancyConclusionOptions}
                            />
                            {pregnancyConclusionWatcher ===
                                PregnancyConclusionTypes.LaborDelivery && (
                                <FormRadio
                                    label="What type of delivery did this client have?"
                                    fieldName="deliveryType"
                                    isRequired={false}
                                    {...register(
                                        'deliveryType',
                                        standardRadioRegisterProps,
                                    )}
                                    errors={errors}
                                    defaultValue={deliveryTypeWatcher}
                                    onChangeString={onChangeWrapper(
                                        'deliveryType',
                                    )}
                                    options={DeliveryTypeOptions}
                                />
                            )}
                            <FormDateInput
                                fieldName="dateOfPregnancyConclusion"
                                label="On what date did this pregnancy end? "
                                minDate={new Date(1900, 1, 1)}
                                maxDate={new Date()}
                                required={true}
                                errors={errors}
                                setValue={setValue}
                                register={register}
                                watch={watch}
                            />
                        </>
                    ) : (
                        <Alert
                            contentComponents={
                                <FlexCol gap={PXSTR.L}>
                                    <FONTS.P1>
                                        Submit a <b>Labor & Delivery</b> visit
                                        instead.
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        Once you submit the Labor & Delivery
                                        visit, this client will automatically be
                                        moved to postpartum.
                                    </FONTS.P1>
                                </FlexCol>
                            }
                        />
                    )}
                </FlexCol>
            </BasicModalLayout>
        </form>
    );
};
