import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from '@chakra-ui/react';
import FileUploader from 'components/FileUpload/FileUploader';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { ReactElement, useContext } from 'react';
import { Radio } from 'ui/components/Radio';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import { Zyg } from 'util/Utils';
import { Alert } from 'ui/components/Alert';
import { useRadioGroup } from 'ui/components/hooks/useRadioGroup';
import { FileUpload } from 'models/schemas';

export const DocumentationSubstepTwo = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData } = onboardingContext ?? {};

    const { formState, setValue, watch, register } = formInstance ?? {};
    const { errors } = formState ?? {};

    const pathwayChoice: string = watch?.(
        'proofOfTrainingPathway',
        formData?.proofOfTrainingPathway,
    );

    const uploadedTrainingSyllabus = watch?.('syllabus', formData?.syllabus);

    const trainingSyllabusMeetsProofReq: boolean = watch?.(
        'trainingSyllabusMeetsProofReq',
        formData?.trainingSyllabusMeetsProofReq,
    );

    const {
        getRadioProps: pathwayGetRadioProps,
        getRootProps: pathwayRootProps,
    } = useRadioGroup({
        loadingDependency: formData,
        defaultValue: formData?.proofOfTrainingPathway,
        onChangeString: (newVal: string) =>
            setValue?.('proofOfTrainingPathway', newVal, {
                shouldDirty: true,
            }),
    });

    const {
        getRadioProps: trainingAttestGetRadioProps,
        getRootProps: trainingAttestGetRootProps,
    } = useRadioGroup({
        loadingDependency: formData,
        defaultValue: trainingSyllabusMeetsProofReq,
        onChangeBool: (newVal: boolean) => {
            setValue?.('trainingSyllabusMeetsProofReq', newVal, {
                shouldDirty: true,
            });
        },
    });

    //TODO-Mina: switch this to use the new FormRadio component

    //TODO-Mina clean up just need this PR in
    const onChangeFile =
        (fieldName: string) => (val: FileUpload[] | undefined) => {
            setValue?.(fieldName, val, {
                shouldDirty: true,
                shouldValidate: true,
            });
        };

    return (
        <Flex
            {...commonStyling}
            height={Zyg('100vh', '100%')}
            aria-label="proof of training documentation"
        >
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <FONTS.H2>
                    Which pathway did you select when you enrolled as a Medi-Cal
                    provider?
                </FONTS.H2>
                <FlexCol {...pathwayRootProps()} gap={PX.SPACING.REM.S}>
                    <input
                        hidden
                        {...(register?.('proofOfTrainingPathway') ?? {})}
                    />

                    <Radio
                        {...pathwayGetRadioProps({ value: 'training' })}
                        value="training"
                    >
                        <FONTS.P1 flex={1}>
                            <b>Training Pathway </b> - In the PAVE portal, I
                            uploaded a Certificate of Completion and/or Course
                            Syllabi.
                        </FONTS.P1>
                    </Radio>
                    <Radio
                        {...pathwayGetRadioProps({ value: 'xp' })}
                        value="xp"
                    >
                        <FONTS.P1 flex={1}>
                            <b>Experience Pathway </b>- In the PAVE portal, I
                            uploaded letters of recommendation.
                        </FONTS.P1>
                    </Radio>
                </FlexCol>

                {pathwayChoice === 'training' && (
                    <>
                        <FormControl
                            isInvalid={!!errors?.syllabus}
                            mt={4}
                            isRequired
                        >
                            <FormLabel display="none">Syllabus</FormLabel>

                            <FileUploader
                                label="Upload your Certificate(s) of Completion for doula training."
                                subLabel="You may upload multiple documents."
                                data-cy="syllabus"
                                maxFiles={10}
                                fieldValue={formData?.['syllabus'] ?? []}
                                onChange={onChangeFile('syllabus')}
                            />

                            <FormErrorMessage>
                                {errors?.syllabus &&
                                    errors.syllabus.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>

                        {uploadedTrainingSyllabus &&
                            uploadedTrainingSyllabus.length > 0 && (
                                <FlexCol gap={PX.SPACING.PX.M}>
                                    <FONTS.H2>
                                        Does your uploaded certificate show at
                                        least <u>16 hours of training</u> in the
                                        following <u>5 categories</u>?
                                    </FONTS.H2>
                                    <FONTS.P1>
                                        <li>Lactation support</li>
                                        <li>Childbirth education</li>
                                        <li>
                                            Foundations on anatomy or pregnancy
                                            and childbirth
                                        </li>
                                        <li>
                                            Nonmedical comfort measures,
                                            prenatal support, and labor support
                                            techniques
                                        </li>
                                        <li>
                                            Developing a community resource list
                                        </li>
                                    </FONTS.P1>

                                    <FlexCol
                                        {...trainingAttestGetRootProps()}
                                        gap={PXSTR.S}
                                    >
                                        <Radio
                                            {...trainingAttestGetRadioProps({
                                                value: true,
                                            })}
                                            value={true}
                                        >
                                            <FONTS.P1 flex={1}>
                                                <b>Yes</b>, the certificate I
                                                have uploaded specifies the
                                                number of hours completed and
                                                topics covered, and meets the
                                                above requirements.
                                            </FONTS.P1>
                                        </Radio>
                                        <Radio
                                            {...trainingAttestGetRadioProps({
                                                value: false,
                                            })}
                                            value={false}
                                        >
                                            <FONTS.P1 flex={1}>
                                                <b>No</b>, the certificate I
                                                have uploaded does not meet the
                                                above requirements.
                                            </FONTS.P1>
                                        </Radio>
                                    </FlexCol>
                                </FlexCol>
                            )}

                        {uploadedTrainingSyllabus &&
                            uploadedTrainingSyllabus.length > 0 &&
                            trainingSyllabusMeetsProofReq === false && (
                                <FormControl
                                    isRequired
                                    isInvalid={!!errors?.hipaaCertificate}
                                >
                                    <FormLabel display="none">
                                        Upload syllabi or any other training
                                        materials that demonstrates 16 hours of
                                        training in the above 5 categories.
                                    </FormLabel>

                                    <FileUploader
                                        label="Upload syllabi or any other training materials that demonstrates 16 hours of training in the above 5 categories. "
                                        sublabelComponent={
                                            <FlexCol gap={PX.SPACING.PX.M}>
                                                <FONTS.P2>
                                                    You may upload multiple
                                                    documents.
                                                </FONTS.P2>
                                                <FONTS.P2>
                                                    <b>Note</b>: This level of
                                                    specificity was not required
                                                    when you applied to become a
                                                    Medi-Cal provider on the
                                                    DHCS website. However, this
                                                    level of specificity{' '}
                                                    <b>is required</b> by the
                                                    Managed Care Plans you want
                                                    to be credentialed with.
                                                </FONTS.P2>
                                            </FlexCol>
                                        }
                                        fieldValue={
                                            formData?.['proofOfTraining'] ?? []
                                        }
                                        onChange={onChangeFile(
                                            'proofOfTraining',
                                        )}
                                        maxFiles={5}
                                    />

                                    <FormErrorMessage>
                                        {errors?.proofOfTraining &&
                                            errors.proofOfTraining.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                    </>
                )}

                {pathwayChoice === 'xp' && (
                    <>
                        <FormControl
                            isRequired
                            isInvalid={!!errors?.hipaaCertificate}
                        >
                            <FormLabel display="none">
                                Upload 3 letters of recommendation{' '}
                            </FormLabel>

                            <FileUploader
                                label="Upload 3 letters of recommendation"
                                sublabelComponent={
                                    <FlexCol gap={PX.SPACING.PX.M}>
                                        <FONTS.P1>
                                            Upload three client testimonial
                                            letters or professional letters of
                                            recommendation written in the last
                                            seven years from any of the
                                            following:
                                        </FONTS.P1>
                                        <FONTS.P1>
                                            <li>
                                                A physician, nurse practitioner,
                                                nurse midwife, or licensed
                                                midwife
                                            </li>
                                            <li>An enrolled doula</li>
                                            <li>
                                                A community-based organization
                                            </li>
                                        </FONTS.P1>
                                        <FONTS.P1>
                                            At least one of the letters must be
                                            from a licensed provider, an
                                            enrolled doula, or a community-based
                                            organization.
                                        </FONTS.P1>
                                    </FlexCol>
                                }
                                onChange={onChangeFile(
                                    'lettersOfRecommendation',
                                )}
                                fieldValue={
                                    formData?.['lettersOfRecommendation'] ?? []
                                }
                                maxFiles={5}
                            />

                            <FormErrorMessage>
                                {errors?.hipaaCertificate &&
                                    errors.hipaaCertificate.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            isInvalid={!!errors?.syllabus}
                            mt={4}
                            isRequired
                        >
                            <FormLabel display="none">Syllabus</FormLabel>

                            <FileUploader
                                label="Upload certificate(s) of completion and/or course syllabi for doula training (optional)"
                                sublabelComponent={
                                    <FlexCol
                                        gap={PX.SPACING.PX.M}
                                        mb={PX.SPACING.PX.M}
                                    >
                                        <Alert
                                            contentComponents={
                                                <FlexCol gap={PX.SPACING.PX.M}>
                                                    <FONTS.P1>
                                                        Some Managed Care Plans
                                                        require proof of doula
                                                        training to credential
                                                        doula providers, even if
                                                        you were approved as a
                                                        Medi-Cal doula via the
                                                        Experience Pathway.
                                                    </FONTS.P1>

                                                    <FONTS.P1>
                                                        The more documentation
                                                        we have on file, the
                                                        easier it will be to get
                                                        you credentialed.
                                                    </FONTS.P1>
                                                </FlexCol>
                                            }
                                        />

                                        <FONTS.P1>
                                            Upload any certificates of
                                            completion and/or course syllabi for
                                            doula training you have.
                                            Documentation should include the{' '}
                                            <u>hours spent in training</u> and
                                            covers the <u>following topics</u>:
                                        </FONTS.P1>
                                        <FONTS.P1>
                                            <li>Lactation support</li>
                                            <li>Childbirth education</li>
                                            <li>
                                                Foundations on anatomy or
                                                pregnancy and childbirth
                                            </li>
                                            <li>
                                                Nonmedical comfort measures,
                                                prenatal support, and labor
                                                support techniques
                                            </li>
                                            <li>
                                                Developing a community resource
                                                list
                                            </li>
                                        </FONTS.P1>
                                    </FlexCol>
                                }
                                data-cy="syllabus"
                                onChange={onChangeFile('syllabus')}
                                maxFiles={10}
                                fieldValue={formData?.['syllabus'] ?? []}
                            />

                            <FormErrorMessage>
                                {errors?.proofOfLiabilityInsurance &&
                                    errors.proofOfLiabilityInsurance.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>
                    </>
                )}
            </FlexCol>
        </Flex>
    );
};
