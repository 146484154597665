import { InsurerRelationship } from 'models/constants/clientConstants';
import { PatientReturnType, PregnancyModel } from 'models/schemas';

//ATM we are just 'assuming' one pregnancy per client on the frontend, but eventuallyyy
export const GetPregnancy = (
    client: PatientReturnType | undefined | null,
): PregnancyModel | undefined => {
    if (!client || !client.pregnancies || !client.pregnancies.length) return;
    return client.pregnancies[0];
};

export const isAnyPlanNotThroughSelf = (
    arr: (InsurerRelationship | undefined)[],
) => {
    for (let i = 0; i < arr.length; i++) {
        if (!!arr[i] && arr[i] !== InsurerRelationship.Self) {
            return true;
        }
    }

    return false;
};
