import { DoulaPortalLayout } from 'doulaPortal/layouts/DoulaPortalLayout';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import { FlexCol } from 'ui/loulaFlex';

const Page = () => {
    const navigate = useNavigate();

    return (
        <DoulaPortalLayout
            pageHeader="Home Header"
            pageActionButtons={[
                <PrimaryButton
                    key="submitVisitBtn"
                    onClick={() => navigate('/visit/new')}
                >
                    Submit Visit
                </PrimaryButton>,
                <PrimaryButton
                    key="createClientBtn"
                    onClick={() => navigate('/client/new')}
                >
                    Create Client
                </PrimaryButton>,
                <SecondaryButton
                    key="onboarding_btn"
                    onClick={() => navigate('/onboarding')}
                >
                    Onboarding
                </SecondaryButton>,
            ]}
        >
            <Home />
        </DoulaPortalLayout>
    );
};

const Home = () => {
    return (
        <FlexCol
            className="homeWrapper"
            width="100%"
            minH="1000px"
            justifyContent="space-between"
        ></FlexCol>
    );
};

export default Page;
