import { format } from 'date-fns';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';
import { useNumOffPlatformVisits } from 'doulaPortal/pages/ClientDetails/clientDetails.hooks';
import { VisitTypeCopy } from 'models/constants/visitConstants';
import COLORS from 'ui/colors';
import { PrimaryButton } from 'ui/components/Button';
import { NumBadge } from 'ui/components/NumBadge';
import {
    Table,
    TableCell,
    TableCellTypes,
    ActionTypes,
} from 'ui/components/Table';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';

export const AllOffPlatformVisitsTable = ({
    onClickDeleteButtonForVisit,
    onClickVisit,
    logNewVisit,
}: {
    onClickDeleteButtonForVisit: (visitId: string) => () => void;
    onClickVisit: (visitId: string) => () => void;
    logNewVisit: () => void;
}) => {
    const ctx = useClientDetailsContext();
    const { client } = ctx ?? {};
    const { offPlatformVisits } = client ?? {};

    const data: TableCell[][] =
        offPlatformVisits?.map((offPlatformVisit) => [
            {
                cellType: TableCellTypes.DEFAULT,
                content: format(
                    new Date(offPlatformVisit.visitDate),
                    'MM/dd/yy',
                ),
            },
            {
                cellType: TableCellTypes.HEADING,
                content: VisitTypeCopy[offPlatformVisit.visitType],
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: offPlatformVisit.doulaName,
                actions: [
                    {
                        onClick: onClickVisit(offPlatformVisit.id),
                        type: ActionTypes.ICON_BUTTON,
                        icon: 'EditPage',
                    },
                    {
                        onClick: onClickDeleteButtonForVisit(
                            offPlatformVisit.id,
                        ),
                        type: ActionTypes.ICON_BUTTON,
                        icon: 'Delete',
                    },
                ],
            },
        ]) ?? [];

    const headers: string[] = ['Visit Date', 'Visit Type', 'Doula Name'];

    const numOffPlatformVisits = useNumOffPlatformVisits();

    return (
        <FlexCol
            width="100%"
            height="100%"
            overflow="hidden"
            className="allOffPlatformVisitsTableWrapper"
            gap={PXSTR.M}
        >
            <FlexRow justifyContent="space-between">
                <FlexRow alignItems="center" gap={PXSTR.XS}>
                    <NumBadge
                        background={COLORS.PRIMARY.Warning}
                        num={numOffPlatformVisits}
                    />
                    <FONTS.H3>visit</FONTS.H3>
                </FlexRow>
                <PrimaryButton onClick={logNewVisit}>+ Log visit</PrimaryButton>
            </FlexRow>
            <Table
                id="all_off_platform_visits"
                variant="base"
                headers={headers}
                data={data}
            />
        </FlexCol>
    );
};
