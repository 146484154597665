import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { IconButton } from 'ui/components/IconButton';
import COLORS from 'ui/colors';
import { PXSTR } from 'ui/px';
import { Zyg } from 'util/Utils';
import { ICONS } from 'ui/icons';
import { useSimpleFlowContext } from './simpleFlow.context';
import { MinimalProgressIndicator } from './MinimalProgressIndicator';

export const SimpleFlowProgressIndicator = () => {
    const ctx = useSimpleFlowContext();
    const { stepStatuses, activeStepIdx, goToStep } = ctx ?? {};

    return (
        <FlexCol width="100%" paddingX={Zyg(PXSTR.M, 0)} paddingY={PXSTR.M}>
            <ProgressIndicatorHeader />
            <MinimalProgressIndicator
                activeStepIdx={activeStepIdx}
                stepStatuses={stepStatuses}
                onClickStep={goToStep}
            />
        </FlexCol>
    );
};

const ProgressIndicatorHeader = () => {
    const ctx = useSimpleFlowContext();
    const { closeFlow, activeStep, flowName } = ctx ?? {};

    return (
        <FlexRow
            width="100%"
            alignItems="center"
            justifyContent="space-between"
        >
            <FlexRow gap={PXSTR.S}>
                <FONTS.H6 color={COLORS.PRIMARY.Grey}>{flowName}</FONTS.H6>
                <ICONS.ChevronRight color={COLORS.PRIMARY.Grey} size={16} />
                <FONTS.H6 color={COLORS.PRIMARY.Blue}>
                    {activeStep?.title}
                </FONTS.H6>
            </FlexRow>
            <IconButton
                color={COLORS.PRIMARY.Grey}
                icon="Close"
                onClick={closeFlow}
            />
        </FlexRow>
    );
};
