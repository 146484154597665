import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useOnboardingFormData } from './useOnboardingFormData';

import { OnboardingState, ProgressableStep } from 'onboarding/onboarding.model';
import { useForm, UseFormReturn } from 'react-hook-form';
import { OnboardingFormDataModel } from 'models/schemas';
import { useToast } from 'ui/useToast';
import { STEPS_METADATA, ALL_STEPS } from 'onboarding/StepsDefinitions';

export const useOnboardingState = ({
    onComplete,
}: {
    onComplete: () => Promise<void>;
}): OnboardingState => {
    const { stepSlug, subStepSlug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();

    const [isCollapsed, setIsCollapsed] = useState(true);

    const { formData, saveField, saveForm, ...otherFormDataHookVals } =
        useOnboardingFormData({ onComplete });

    const [activeStepIdx, setActiveStepIdx] = useState(
        STEPS_METADATA.findIndex((step) => step.slug === stepSlug),
    );

    const [activeSubStepIdx, setActiveSubstepIdx] = useState(
        STEPS_METADATA[activeStepIdx]?.subStepMetadata?.findIndex(
            (subStep) => subStep.slug === subStepSlug,
        ),
    );

    const [hasInitializedForm, setHasInitializedForm] =
        useState<boolean>(false);

    const formInstance: UseFormReturn = useForm({
        mode: 'onSubmit',
        resetOptions: {
            keepDirtyValues: true,
            keepDirty: true,
            keepErrors: true,
        },
    });

    //-----Vals that are helpful to have memoized-------

    const activeStepOrSubstep: ProgressableStep = useMemo(() => {
        if (activeStepIdx === -1) return ALL_STEPS[0];
        const activeStep = ALL_STEPS[activeStepIdx];

        const res = !activeStep?.subSteps
            ? activeStep
            : activeStep.subSteps[activeSubStepIdx ?? 0];

        return res;
    }, [activeStepIdx, activeSubStepIdx]);

    const allStepFields = useMemo(() => {
        const res: (keyof OnboardingFormDataModel)[] = [];
        ALL_STEPS.forEach((step) => {
            res.push(...step.stepFields);
            step.subSteps?.forEach((subStep) => {
                res.push(...subStep.stepFields);
            });
        });
        return res;
    }, []);

    const onStepFullyLoaded = useCallback(
        async (isInitializingForm: boolean = false) => {
            if (!hasInitializedForm && !isInitializingForm) return;

            const firstTimeOnThisStep = !(
                activeStepOrSubstep.slug in (formData?.openedSteps ?? {})
            );

            if (firstTimeOnThisStep) {
                const updatedOpenedSteps = { ...(formData?.openedSteps ?? {}) };
                updatedOpenedSteps[activeStepOrSubstep.slug] = true;
                await saveField('openedSteps', updatedOpenedSteps);
            } else {
                await formInstance.handleSubmit(
                    () => {},
                    () => {},
                )();
            }
        },
        [
            hasInitializedForm,
            activeStepOrSubstep.slug,
            formData?.openedSteps,
            saveField,
            formInstance,
        ],
    );

    useEffect(() => {
        //Bc FormData sometimes/most times wont be finished loading before useForm gets called
        const initializeForm = async () => {
            formInstance.reset(
                Object.fromEntries(
                    allStepFields.map((fieldName) => [
                        fieldName,
                        formData?.[fieldName],
                    ]),
                ),
                { keepDirty: true },
            );

            await onStepFullyLoaded(true);
            setHasInitializedForm(true);
        };
        if (hasInitializedForm || !otherFormDataHookVals.isLoaded) return;

        initializeForm();
    }, [
        allStepFields,
        formData,
        formInstance,
        otherFormDataHookVals.isLoaded,
        hasInitializedForm,
        onStepFullyLoaded,
    ]);

    const saveValuesFromStep = async () => {
        if (
            Object.keys(formInstance.formState.dirtyFields ?? {}).length === 0
        ) {
            return;
        }
        const enteredValuesToSave: Partial<OnboardingFormDataModel> = {};

        activeStepOrSubstep.stepFields.forEach((element) => {
            if (!formInstance.formState.dirtyFields[element]) return;
            const valueEntered = formInstance.getValues(element);
            if (valueEntered !== undefined) {
                enteredValuesToSave[element] = formInstance.getValues(element);
                formInstance.resetField(element, {
                    defaultValue: valueEntered,
                    keepDirty: false,
                });
            }
        });

        if (Object.values(enteredValuesToSave).length === 0) return;

        const savePromise = saveForm(enteredValuesToSave);
        toast.promise(savePromise, {
            loading: {
                description: 'Saving changes',
            },
            success: {
                description: 'Changes saved',
                duration: 3000,
                isClosable: true,
            },
            error: {
                description: 'Error saving changes',
            },
        });

        await savePromise;
    };

    //----Step status checkers-------

    const isStepStarted = useCallback(
        (step: ProgressableStep): boolean => {
            if (!step) return false;

            if (step.subSteps?.length ?? 0 > 0) {
                return (
                    step.subSteps?.some((subStep) => isStepStarted(subStep)) ??
                    false
                );
            }

            if (!formData?.openedSteps) return false;
            return formData?.openedSteps[step.slug] ?? false;
        },
        [formData],
    );

    const isStepComplete = useCallback(
        (step: ProgressableStep): boolean => {
            if (step.subSteps?.length ?? 0 > 0) {
                return (
                    step.subSteps?.every((subStep) =>
                        isStepComplete(subStep),
                    ) ?? true
                );
            }

            if (step.customIsStepComplete !== undefined) {
                return step.customIsStepComplete(formData);
            }

            let isComplete = true;
            step.stepFields.forEach((fieldName) => {
                if (!formData?.[fieldName]) {
                    isComplete = false;
                }
            });

            return isComplete;
        },
        [formData],
    );

    const areAllStepsComplete = useMemo(() => {
        let complete = true;
        ALL_STEPS.forEach((step) => {
            if (step.slug !== 'review' && !isStepComplete(step)) {
                complete = false;
            }
        });
        return complete;
    }, [isStepComplete]);

    const numComplete = useMemo(() => {
        let count = 0;
        ALL_STEPS.forEach((step) => {
            if (isStepComplete(step)) {
                count++;
            }
        });
        return count;
    }, [isStepComplete]);

    const numSubstepsInStep = useCallback((stepIdx: number): number => {
        return ALL_STEPS[stepIdx]?.subSteps?.length ?? 1;
    }, []);

    //------Navigation methods------

    const goToStep = (index: number) => async () => {
        await saveValuesFromStep();

        let subStepIdx = activeSubStepIdx;
        if (index !== activeStepIdx) {
            subStepIdx = 0;
            setActiveSubstepIdx(0);
        }
        setActiveStepIdx(index);
        setIsCollapsed(true);

        saveField('activeStepIdx', index);
        saveField('activeSubStepIdx', subStepIdx);
    };

    const goToSubStep = (stepIdx: number, subStepIdx: number) => async () => {
        await saveValuesFromStep();

        setActiveSubstepIdx(subStepIdx);
        setActiveStepIdx(stepIdx);
        setIsCollapsed(true);

        saveField('activeStepIdx', stepIdx);
        saveField('activeSubStepIdx', subStepIdx);
    };

    const goToNextStep = async () => {
        if (
            !!ALL_STEPS[activeStepIdx].subSteps &&
            activeSubStepIdx <
                (ALL_STEPS[activeStepIdx].subSteps.length ?? 0) - 1
        ) {
            goToSubStep(activeStepIdx, activeSubStepIdx + 1)();
        } else {
            goToStep(Math.min(activeStepIdx + 1, ALL_STEPS.length - 1))();
        }
    };

    const goToPreviousStep = () => {
        if (!!ALL_STEPS[activeStepIdx].subSteps && activeSubStepIdx > 0) {
            goToSubStep(activeStepIdx, activeSubStepIdx - 1)();
            return;
        } else {
            goToStep(Math.max(activeStepIdx - 1, 0))();
        }
    };

    useEffect(() => {
        if (!otherFormDataHookVals.isLoaded) return;

        //If came to the root url without a step, find it in the form data
        //or send em to the first step
        if (activeStepIdx === -1) {
            const idx = formData?.activeStepIdx || 0;
            setActiveStepIdx(idx);
            return;
        }

        if (activeSubStepIdx === -1) {
            const idx = formData?.activeSubStepIdx || 0;
            setActiveSubstepIdx(idx);
            return;
        }

        if (activeStepIdx >= 0 && activeStepIdx < ALL_STEPS.length) {
            let newUrl = `/onboarding/${ALL_STEPS[activeStepIdx].slug}`;

            if (
                activeSubStepIdx >= 0 &&
                activeSubStepIdx <
                    (ALL_STEPS[activeStepIdx].subSteps?.length ?? 0)
            ) {
                newUrl += `/${ALL_STEPS[activeStepIdx].subSteps?.[activeSubStepIdx].slug}`;
            }

            if (newUrl === location.pathname) return;

            navigate(newUrl, {
                replace: true,
            });
        }
    }, [
        location,
        activeStepIdx,
        activeSubStepIdx,
        otherFormDataHookVals.isLoaded,
        formData?.activeStepIdx,
        formData?.activeSubStepIdx,
        navigate,
    ]);

    return {
        steps: ALL_STEPS,
        numComplete,

        isStepComplete,
        isStepStarted,
        areAllStepsComplete,
        numSubstepsInStep,

        activeStepIdx,
        activeSubStepIdx,

        goToNextStep,
        goToPreviousStep,
        onStepFullyLoaded,

        isCollapsed,
        setIsCollapsed,

        isLastStep: activeStepIdx === ALL_STEPS.length - 1,
        isFirstStep: activeStepIdx === 0,

        formData,
        saveField,
        saveForm,
        formInstance,

        goToStep,
        goToSubStep,

        activeStepOrSubstep,

        ...otherFormDataHookVals,
    };
};
