import { formatDate } from 'date-fns';
import { usePortalContext } from 'doulaPortal/context/usePortalContext';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { GetPregnancy } from 'util/patient.utils';
import { EditClientDrawerContextType } from './editClientDrawer.models';

export const useInitialEditClientDrawerContext =
    (): EditClientDrawerContextType => {
        const ctx = useClientDetailsContext();
        const {
            client,
            refreshClientData,
            isClientDataLoaded,
            clientHookUpdaters,
        } = ctx ?? {};

        const { updatePatientUser, updatePatientPregnancy } =
            clientHookUpdaters ?? {};

        const portalContext = usePortalContext();
        const { closeDrawer } = portalContext ?? {};

        const [hasInitialized, setHasInitialized] = useState(false);
        const form = useForm({});
        const { reset, formState, getValues } = form;

        const pregnancy = useMemo(() => GetPregnancy(client), [client]);

        useEffect(() => {
            if (!isClientDataLoaded || hasInitialized || !client) return;
            reset({
                status: GetPregnancy(client)?.status,
                user: client.user,
                deliveryDate: !!pregnancy?.deliveryDate
                    ? formatDate(new Date(pregnancy.deliveryDate), 'MM/dd/yyyy')
                    : undefined,
            });
            setHasInitialized(true);
        }, [
            client,
            reset,
            hasInitialized,
            isClientDataLoaded,
            pregnancy?.deliveryDate,
        ]);

        const submitForm = useCallback(async () => {
            const updatedUserFields = Object.keys(
                formState.dirtyFields['user'] ?? {},
            );

            if (updatedUserFields.length > 0) {
                const userSaveData: Record<string, unknown> = {};

                updatedUserFields.forEach((fieldName) => {
                    userSaveData[fieldName] = getValues(`user.${fieldName}`);
                });

                await updatePatientUser?.(userSaveData);
            }

            if (formState.dirtyFields['deliveryDate']) {
                await updatePatientPregnancy?.({
                    deliveryDate: getValues('deliveryDate'),
                });
            }

            refreshClientData?.();

            closeDrawer?.();
        }, [
            formState.dirtyFields,
            refreshClientData,
            closeDrawer,
            updatePatientUser,
            getValues,
            updatePatientPregnancy,
        ]);

        return {
            isClientDataLoaded: isClientDataLoaded ?? false,
            client,
            submitForm,
            ...form,
        };
    };
