import { FlexCol } from 'ui/loulaFlex';
import { useSubmitVisitContext } from '../submitVisit.context';
import { FormRadio } from 'ui/components/Form/FormRadio';
import { useMemo } from 'react';
import {
    VisitLocation,
    VisitLocationCopy,
    VisitMedium,
    VisitMediumCopy,
} from 'models/constants/visitConstants';
import { FormTextInput } from 'ui/components/Form/FormTextInput';
import NPISearch from 'components/NPISearch/NPISearch';
import { isInStringEnum } from 'util/Utils';
import { FormBoolRadio } from 'ui/components/Form/FormBoolRadio';
import { NPIFacilityType } from 'components/NPISearch/npiSearch.models';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';

export const VisitLocationStep = () => {
    const {
        errors,
        mediumOfService,
        setMedium,
        visitMediumOptions,
        register,
        telehealthInHome,
        setWasInHome,
        visitInClientHomeOptions,
        locationOfService,
        setLocationOfService,
        visitLocationOptions,
        facilityNPI,
        setFacilityNPI,
    } = useVisitLocation();

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();
    const telehealthInHomeRegisterProps = useGetFormRadioRegisterProps(
        'telehealthInHome',
        undefined,
        (val) => val !== undefined,
    );

    return (
        <FlexCol>
            <FormRadio
                fieldName="mediumOfService"
                label="How was this visit conducted?"
                errors={errors}
                defaultValue={mediumOfService}
                onChangeString={setMedium}
                options={visitMediumOptions}
                isRequired={true}
                {...(register?.(
                    'mediumOfService',
                    standardRadioRegisterProps,
                ) ?? {})}
            />

            {(mediumOfService === VisitMedium.Audio ||
                mediumOfService === VisitMedium.Video) && (
                <FormBoolRadio
                    label="Was the client in their home during this telehealth visit?"
                    subLabel="Though your response to this question does not affect how much you’re paid, this information is needed to bill for this visit."
                    fieldName="telehealthInHome"
                    isRequired={true}
                    errors={errors}
                    defaultValue={telehealthInHome}
                    onChangeBool={setWasInHome}
                    options={visitInClientHomeOptions}
                    {...(register?.(
                        'telehealthInHome',
                        telehealthInHomeRegisterProps,
                    ) ?? {})}
                />
            )}

            {mediumOfService === VisitMedium.InPerson && (
                <>
                    <FormRadio
                        label="Where did this visit take place?"
                        subLabel="Though your response to this question does not affect how much you’re paid, this information is needed to bill for this visit."
                        fieldName="locationOfService"
                        isRequired={true}
                        defaultValue={locationOfService}
                        options={visitLocationOptions}
                        errors={errors}
                        onChangeString={setLocationOfService}
                        {...(register?.(
                            'locationOfService',
                            standardRadioRegisterProps,
                        ) ?? {})}
                    />

                    {locationOfService === VisitLocation.MedicalFacility && (
                        <NPISearch
                            initialValue={facilityNPI}
                            setValue={setFacilityNPI}
                            label="Use the look-up to find the medical facility in which this visit took place"
                            facilityType={NPIFacilityType.FACILITY}
                        />
                    )}

                    {locationOfService === VisitLocation.Other && (
                        <FormTextInput
                            label="Where did this visit take place?"
                            fieldName="locationSelfDescription"
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            register={register as any} //TODO: small refactor FormTextInput to take the spread, not regiter method itself
                            errors={errors}
                            required={true}
                        />
                    )}
                </>
            )}
        </FlexCol>
    );
};

const useVisitLocation = () => {
    const ctx = useSubmitVisitContext();
    const { stepFourForm } = ctx ?? {};

    const { watch, setValue, formState, register } = stepFourForm ?? {};
    const { errors } = formState ?? {};

    const [mediumOfService, telehealthInHome, locationOfService, facilityNPI] =
        watch?.([
            'mediumOfService',
            'telehealthInHome',
            'locationOfService',
            'facilityNPI',
            'locationSelfDescription',
        ]) ?? [];

    const setValueOptions = {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
    };

    const setMedium = (strVal: string) => {
        if (isInStringEnum(strVal, VisitMedium)) {
            setValue?.(
                'mediumOfService',
                strVal as VisitMedium,
                setValueOptions,
            );

            if (strVal === VisitMedium.InPerson) {
                stepFourForm?.unregister(['telehealthInHome']);
            } else {
                stepFourForm?.unregister([
                    'locationOfService',
                    'locationSelfDescription',
                    'facilityNPI',
                ]);
            }
        }
    };

    const setFacilityNPI = (npi: string) => {
        setValue?.('facilityNPI', npi, setValueOptions);
    };

    const setLocationOfService = (strVal: string) => {
        if (isInStringEnum(strVal, VisitLocation)) {
            setValue?.(
                'locationOfService',
                strVal as VisitLocation,
                setValueOptions,
            );

            if (strVal !== VisitLocation.MedicalFacility) {
                stepFourForm?.unregister('facilityNPI');
            } else {
                stepFourForm?.register('facilityNPI', { required: true });
            }
        }
    };

    const setWasInHome = (boolVal: boolean) => {
        setValue?.('telehealthInHome', boolVal, setValueOptions);
    };

    const {
        visitMediumOptions,
        visitLocationOptions,
        visitInClientHomeOptions,
    } = useMemo(() => {
        const visitMediumOptions = Object.entries(VisitMediumCopy).map(
            ([medium, { label, desc }]) => {
                return {
                    label: label,
                    value: medium,
                    isDecorated: true,
                    decorationDesc: desc,
                };
            },
        );

        const visitLocationOptions = Object.entries(VisitLocationCopy).map(
            ([location, { label, desc }]) => {
                return {
                    label: label,
                    value: location,
                    isDecorated: true,
                    decorationDesc: desc,
                };
            },
        );

        const visitInClientHomeOptions = [
            {
                labelBoldPrefix: 'Yes',
                label: 'the client was in their home.',
                value: true,
                isDecorated: true,
            },
            {
                labelBoldPrefix: 'No',
                label: 'the client was not in their home.',
                value: false,
                isDecorated: true,
            },
        ];

        return {
            visitMediumOptions,
            visitLocationOptions,
            visitInClientHomeOptions,
        };
    }, []);

    return {
        errors,
        mediumOfService,
        setMedium,
        visitMediumOptions,
        register,
        telehealthInHome,
        setWasInHome,
        visitInClientHomeOptions,
        locationOfService,
        setLocationOfService,
        visitLocationOptions,
        facilityNPI,
        setFacilityNPI,
    };
};
