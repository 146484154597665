import { formatDate } from 'date-fns';
import COLORS from 'ui/colors';
import { ActionTypes, Table, TableCellTypes } from 'ui/components/Table';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { useClientDetailsContext } from './clientDetails.context';
import { useClientVisits } from 'hooks/dbHooks/visits.hooks';
import { useNavigate } from 'react-router-dom';

export const SingleClientVisitsTable = () => {
    const clientDetailsContext = useClientDetailsContext();
    const { client } = clientDetailsContext ?? {};
    const { allVisits } = useClientVisits(client?.userId);

    const navigate = useNavigate();

    const data = Object.values(allVisits).map((visitData) => [
        {
            cellType: TableCellTypes.TEXT_TAG,
            content: 'UNKNOWN',
        },
        {
            cellType: TableCellTypes.DEFAULT,
            content: formatDate(
                new Date(visitData.dateOfService),
                'MM/dd/yyyy',
            ),
        },
        {
            cellType: TableCellTypes.HEADING,
            content: visitData.visitType,
        },
        {
            cellType: TableCellTypes.TEXT_TAG,
            content: 'UNKNOWN',
        },
        {
            cellType: TableCellTypes.DEFAULT,
            content: 'Client home',
            actions: [
                {
                    text: 'View Details',
                    onClick: () => {
                        navigate(`/visit/${visitData.id}`);
                    },
                    type: ActionTypes.LINK,
                },
            ],
        },
        {
            cellType: TableCellTypes.DEFAULT,
            content: formatDate(new Date(visitData.updatedAt), 'MM/dd/yyyy'),
        },
    ]);

    const headers: string[] = [
        'Status',
        'Visit Date',
        'Type',
        'Mode',
        'Location',
        'Last Edited',
    ];

    return (
        <FlexCol
            overflowX="scroll"
            width="100%"
            className="singleClientVisitsTableWrapper"
            gap={PXSTR.S}
        >
            <FONTS.H6 color={COLORS.PRIMARY.Grey}>Visits</FONTS.H6>
            <Table
                id="single_client_visits"
                variant="base"
                headers={headers}
                data={data}
                cardCutoff={659}
            />
        </FlexCol>
    );
};
