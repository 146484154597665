import useDetectScroll from '@smakss/react-scroll-direction';
import { useEffect, useRef, useState } from 'react';

export enum Direction {
    /**
     * The up direction represents the scroll direction moving towards the top.
     */
    Up = 'up',
    /**
     * The down direction represents the scroll direction moving towards the bottom.
     */
    Down = 'down',
    /**
     * The left direction represents the scroll direction moving towards the left.
     */
    Left = 'left',
    /**
     * The right direction represents the scroll direction moving towards the right.
     */
    Right = 'right',
    /**
     * The still direction represents the scroll direction when the user is not scrolling.
     */
    Still = 'still',
}

export type ScrollPosition = {
    /**
     * The top position represents the distance from the top edge of the page.
     */
    top: number;
    /**
     * The bottom position represents the distance from the bottom edge of the page.
     */
    bottom: number;
    /**
     * The left position represents the distance from the left edge of the page.
     */
    left: number;
    /**
     * The right position represents the distance from the right edge of the page.
     */
    right: number;
};

/**
 * To detect scroll direction & postiion on a custom component (as opposed to full page)
 * Assign the ref to the component that has overflowy=scroll
 */
export const useCustomElementScrollDetect = () => {
    const customElementRef = useRef<HTMLDivElement>(null);
    const [customElement, setCustomElement] = useState<HTMLDivElement>();

    const { scrollDir, scrollPosition } = useDetectScroll({
        target: customElement,
    });

    useEffect(() => {
        if (customElementRef.current) {
            setCustomElement(customElementRef.current);
        }
    }, [customElementRef]);

    return {
        customElementRef,
        scrollDir,
        scrollPosition,
    };
};
