import { FlexCol } from 'ui/loulaFlex';
import { Sheet } from 'ui/components/Sheet';
import { Collapse } from '@chakra-ui/react';
import useScreenSize from 'hooks/useScreenSize';
import { PAGE_COLLAPSE_DURATION } from 'models/constants/animationConstants';
import { HEADER_MIN_HEIGHT, PageHeader } from './PageHeader';
import { PageFooter } from './PageFooter';
import { PXSTR } from 'ui/px';
import { GetZyg } from 'util/Utils';
import { SheetComponentProps } from 'doulaPortal/models/layout.models';
import { usePortalContext } from 'doulaPortal/context/usePortalContext';
import { useMobileFullScreenBehavior } from 'doulaPortal/hooks/useMobileFullScreenBehavior';

const Zyg = GetZyg('lg');

export const SheetComponent = ({
    pageActionButtons,
    pageHeader,
    pageHeaderComponent,
    children,
}: SheetComponentProps) => {
    const portalContext = usePortalContext();

    const { isFullScreenMobile, isSheetVisible } = portalContext ?? {};

    const { isNarrowScreen } = useScreenSize();

    const { scrollElementRef, toggleIsFullScreen } =
        useMobileFullScreenBehavior();

    const hasHeader =
        !!pageHeader || !!pageHeaderComponent || !!pageActionButtons;

    return (
        <Sheet
            role="main"
            className="mainPageSheet"
            height="100%"
            width={Zyg('100%', `calc(100% - 180px)`, 'lg')}
            borderRadius={PXSTR.SM}
        >
            <FlexCol
                borderRadius="inherit"
                width="100%"
                height="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                {hasHeader && (
                    <PageHeader
                        headerText={pageHeader}
                        headerComponent={pageHeaderComponent}
                        actionBtns={pageActionButtons}
                        onMobileClick={toggleIsFullScreen}
                    />
                )}

                <FlexCol
                    ref={scrollElementRef}
                    position={Zyg('absolute', 'relative')}
                    width="100%"
                    maxHeight="100%"
                    flex={1}
                    transition={`all ${PAGE_COLLAPSE_DURATION / 2}s`}
                    bottom={isFullScreenMobile ? 0 : '80px'}
                    top={Zyg(hasHeader ? `${HEADER_MIN_HEIGHT}px` : 0, 0)}
                    overflowY="hidden"
                    overscrollBehavior="none"
                    justifyContent="start"
                    className="scrollPageWrapper"
                    paddingTop={Zyg('10px', PXSTR.L)}
                >
                    {children}
                    {hasHeader && (
                        <PageFooter
                            transition={`all ${PAGE_COLLAPSE_DURATION / 2}s`}
                            opacity={isFullScreenMobile ? 1 : 0}
                            height={isFullScreenMobile ? '100%' : 0}
                            actionBtns={pageActionButtons}
                        />
                    )}
                </FlexCol>

                {isNarrowScreen && isSheetVisible && (
                    <Collapse
                        startingHeight={0}
                        animateOpacity
                        endingHeight={HEADER_MIN_HEIGHT}
                        in={!isFullScreenMobile}
                        style={{
                            //position: 'absolute',
                            bottom: 0,
                            width: '100%',
                        }}
                        transition={{
                            enter: { duration: PAGE_COLLAPSE_DURATION / 2 },
                            exit: { duration: PAGE_COLLAPSE_DURATION / 2 },
                        }}
                    >
                        <PageFooter actionBtns={pageActionButtons} />
                    </Collapse>
                )}
            </FlexCol>
        </Sheet>
    );
};
