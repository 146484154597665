import { TabPanel, Box } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { SecondaryButton, PrimaryButton } from 'ui/components/Button';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { useEditClientDrawerContext } from './editClientDrawer.context';

export const DrawerFormLayout = ({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode | React.ReactNode[];
}) => {
    const ctxt = useEditClientDrawerContext();
    const { submitForm, formState } = ctxt ?? {};

    return (
        <TabPanel
            display="flex"
            flexDirection="column"
            className="drawerFormLayout"
            height="100%"
            padding={0}
            justifyContent="space-between"
        >
            <Box
                display="flex"
                flexDir="column"
                height="100%"
                overflowY="scroll"
                paddingX={PXSTR.L}
                paddingY={PXSTR.XL}
                gap={PXSTR.L}
            >
                <FONTS.H3>{title}</FONTS.H3>
                {children}
            </Box>
            <FlexRow
                justifySelf="end"
                background={COLORS.UTIL.Gray.WARM}
                borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}
                justify="end"
                paddingY={PXSTR.S}
                paddingX={PXSTR.L}
                gap={PXSTR.S}
            >
                <SecondaryButton>
                    {formState?.isDirty ? 'Discard Changes' : 'Cancel'}
                </SecondaryButton>
                <PrimaryButton
                    onClick={submitForm}
                    disabled={!formState?.isDirty}
                >
                    Save
                </PrimaryButton>
            </FlexRow>
        </TabPanel>
    );
};
