import {
    Flex,
    FlexProps,
    ResponsiveValue,
    ThemeTypings,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { Zyg } from 'util/Utils';

export const FlexRow = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
    <Flex ref={ref} {...props} direction="row" />
));

export const FlexCol = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
    <Flex ref={ref} {...props} direction="column" />
));

export const RowCol = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
    <RespFlex ref={ref} {...props} loulaDir={Zyg('row', 'column')} />
));

export const ColRow = forwardRef<
    HTMLDivElement,
    FlexProps & { switchSize?: 'sm' | 'md' | 'lg' | 'xl' }
>(({ switchSize, ...props }, ref) => (
    <RespFlex
        ref={ref}
        {...props}
        loulaDir={Zyg('column', 'row', switchSize)}
    />
));

type RespFlexT = FlexProps & {
    loulaDir: Partial<ResponsiveStyling>;
};

const RespFlex = forwardRef<HTMLDivElement, RespFlexT>(
    ({ loulaDir, ...props }, ref) => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Flex ref={ref} {...props} flexDirection={loulaDir as any} />
    ),
);

export type ResponsiveStyling = {
    base: string | number | null;
    sm: string | number | null;
    md: string | number | null;
    lg: string | number | null;
    xl: string | number | null;
    '2xl': string | number | null;
};

export type Token<
    CSSType,
    ThemeKey = unknown,
> = ThemeKey extends keyof ThemeTypings
    ? ResponsiveValue<CSSType | ThemeTypings[ThemeKey]>
    : ResponsiveValue<CSSType>;
