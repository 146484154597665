import { createContext, useContext } from 'react';
import { ClientDetailsContextType } from './clientDetails.models';

export const ClientDetailsContext = createContext(
    {} as ClientDetailsContextType | undefined,
);

export const useClientDetailsContext = () => {
    const ctx = useContext(ClientDetailsContext);
    return ctx;
};
