import { Table, Thead, Th, Tbody, Tr, Td } from '@chakra-ui/react';
import useScreenSize from 'hooks/useScreenSize';
import COLORS from 'ui/colors';
import { PrimaryButton } from 'ui/components/Button';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import {
    FormatProviderNPIName,
    FormatNPIAddress,
    FormatFacilityAddress,
    FormatFacilityTaxonomy,
    FormatFacilityNPIName,
} from 'util/npi.utils';
import { useNPISearchContext } from './npiSearch.context';
import { VisitField } from 'doulaPortal/pages/VisitDetails/VisitField';

export const IndividualProviderResult = () => {
    const ctx = useNPISearchContext();
    const { individualNPIData, selectNpi } = ctx ?? {};
    const { isNarrowScreen } = useScreenSize();

    return (
        <FlexCol overflowY="scroll" maxHeight="320px">
            {(individualNPIData?.length ?? 0) > 0 && isNarrowScreen ? (
                <FlexCol>
                    {individualNPIData?.map((npi, idx) => (
                        <FlexCol
                            key={`npi_res_${npi.number}_${idx}`}
                            gap={PX.SPACING.PX.S}
                            borderY={`1px solid ${COLORS.UTIL.Gray[500]}`}
                            paddingY={PX.SPACING.PX.SM}
                        >
                            <FONTS.P1>{npi.number}</FONTS.P1>

                            <FONTS.P1>{FormatProviderNPIName(npi)}</FONTS.P1>

                            <FONTS.P1>
                                {npi.addresses[0].address_1}
                                {FormatNPIAddress(npi)}
                            </FONTS.P1>

                            <PrimaryButton
                                onClick={selectNpi?.(npi.number)}
                                id={`select-npi-${idx}`}
                            >
                                Use
                            </PrimaryButton>
                        </FlexCol>
                    ))}
                </FlexCol>
            ) : (
                <Table>
                    <Thead>
                        <Th padding={0}>
                            <FONTS.H6>NPI</FONTS.H6>
                        </Th>
                        <Th padding={0}>
                            <FONTS.H6>NAME</FONTS.H6>
                        </Th>
                        <Th padding={0} width="45%">
                            <FONTS.H6>Primary Practice Address</FONTS.H6>
                        </Th>
                        <Th></Th>
                    </Thead>
                    <Tbody>
                        {individualNPIData?.map((npi, index) => (
                            <Tr key={`npi_res_${index}`}>
                                <Td
                                    data-cy={`npi_res_number_${index}`}
                                    padding={0}
                                    paddingRight={PX.SPACING.PX.M}
                                >
                                    {npi.number}
                                </Td>
                                <Td padding={0} paddingRight={PX.SPACING.PX.M}>
                                    {FormatProviderNPIName(npi)}
                                </Td>
                                <Td padding={0}>
                                    <FONTS.P1>
                                        {npi.addresses[0].address_1}
                                    </FONTS.P1>
                                    <FONTS.P1>{FormatNPIAddress(npi)}</FONTS.P1>
                                </Td>
                                <Td padding={PX.SPACING.PX.S}>
                                    <PrimaryButton
                                        onClick={selectNpi?.(npi.number)}
                                        id={`select-npi-${index}`}
                                    >
                                        Use
                                    </PrimaryButton>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </FlexCol>
    );
};

export const FacilityProviderResult = () => {
    const ctx = useNPISearchContext();
    const { facilityNPIData, selectNpi } = ctx ?? {};

    return (
        <FlexCol overflowY="scroll" maxHeight="320px">
            {(facilityNPIData?.length ?? 0) && (
                <FlexCol>
                    {facilityNPIData?.map((npi, idx) => (
                        <FlexCol
                            key={`npi_res_${npi.number}_${idx}`}
                            gap={PX.SPACING.PX.S}
                            borderY={`1px solid ${COLORS.UTIL.Gray[500]}`}
                            paddingY={PX.SPACING.PX.SM}
                        >
                            <FlexRow justifyContent="space-between">
                                <FONTS.H3>
                                    {FormatFacilityNPIName(npi)}
                                </FONTS.H3>
                                <PrimaryButton
                                    onClick={selectNpi?.(npi.number)}
                                >
                                    Select
                                </PrimaryButton>
                            </FlexRow>

                            <FlexRow flexWrap="wrap" gap={PXSTR.M}>
                                <VisitField
                                    flex={1}
                                    flexBasis="100%"
                                    title="Primary Practice Address"
                                    simpleText={FormatFacilityAddress(npi)}
                                />

                                <VisitField
                                    flex={1}
                                    title="NPI"
                                    simpleText={npi.number}
                                />

                                <VisitField
                                    flex={1}
                                    title="Primary Taxonomy"
                                    simpleText={FormatFacilityTaxonomy(npi)}
                                />
                            </FlexRow>
                        </FlexCol>
                    ))}
                </FlexCol>
            )}
        </FlexCol>
    );
};
