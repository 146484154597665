import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { SimpleFlowProgressIndicator } from './SimpleFlowProgressIndicator';
import { SimpleFullScreenLayout } from 'doulaPortal/layouts/SimpleFullScreenLayout';
import { useSimpleFlowContext } from './simpleFlow.context';
import { PXSTR } from 'ui/px';
import COLORS from 'ui/colors';
import { SecondaryButton, PrimaryButton } from 'ui/components/Button';
import { Sheet } from 'ui/components/Sheet';
import { useEffect, useRef, useState } from 'react';

export const SimpleFlow = () => {
    return (
        <SimpleFullScreenLayout>
            <FlexCol marginX="auto" width="100%" height="100%" maxWidth="800px">
                <SimpleFlowProgressIndicator />
                <StepWrapper />
            </FlexCol>
        </SimpleFullScreenLayout>
    );
};

const StepWrapper = () => {
    const ctx = useSimpleFlowContext();
    const { activeStep, activeStepIdx } = ctx ?? {};
    const { Component } = activeStep ?? {};

    const scrollableWrapperRef = useRef<HTMLDivElement>(null);

    const [lastActiveStepIdx, setLastActiveStepIdx] = useState<
        number | undefined
    >(0);

    useEffect(() => {
        if (lastActiveStepIdx !== activeStepIdx) {
            if (scrollableWrapperRef.current) {
                scrollableWrapperRef.current.scrollTop = 0;
                setLastActiveStepIdx(activeStepIdx);
            }
        }
    }, [activeStepIdx, lastActiveStepIdx]);

    return (
        <Sheet
            className="stepWrapperSheet"
            display="flex"
            flexDirection="column"
            width="100%"
            flex={1}
            justifyContent="space-between"
        >
            <FlexCol
                ref={scrollableWrapperRef}
                className="stepComponentWrapper"
                flex={1}
                flexBasis={0}
                overflowY="scroll"
                paddingY={PXSTR.XL}
                paddingX={PXSTR.L}
            >
                {!!Component && <Component />}
            </FlexCol>
            <FlowNavButtons />
        </Sheet>
    );
};

const FlowNavButtons = () => {
    const ctx = useSimpleFlowContext();
    const {
        goToNextStep,
        goToPrevStep,
        completeFlow,
        activeStepIdx,
        isNextEnabled,
        isLastStep,
    } = ctx ?? {};

    const isFirstStep = activeStepIdx === 0;

    return (
        <FlexRow
            width="100%"
            justifyContent="space-between"
            padding={PXSTR.M}
            borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}
        >
            <SecondaryButton
                disabled={isFirstStep}
                opacity={isFirstStep ? 0 : 1}
                onClick={goToPrevStep}
            >
                Back
            </SecondaryButton>

            <PrimaryButton
                disabled={!isNextEnabled}
                onClick={isLastStep ? completeFlow : goToNextStep}
            >
                {isLastStep ? 'Submit' : 'Next'}
            </PrimaryButton>
        </FlexRow>
    );
};
