import {
    NpiFacilityResponseItem,
    NpiProviderResponseItem,
    NpiResistryAddress,
} from 'models/schemas';
import { FormatNameCapitalization } from './Utils';

export const FormatProviderNPIName = (npi: NpiProviderResponseItem) => {
    return FormatNameCapitalization(
        npi.basic.first_name +
            (!!npi.basic.middle_name ? ' ' + npi.basic.middle_name : '') +
            ' ' +
            npi.basic.last_name,
    );
};

export const FormatFacilityNPIName = (npi: NpiFacilityResponseItem): string => {
    return npi.basic.organization_name;
};

export const FormatNPIAddress = (npi: NpiProviderResponseItem): string => {
    return FormatAddy(npi.addresses[0]);
};

export const FormatFacilityAddress = (npi: NpiFacilityResponseItem): string => {
    return FormatAddy(npi.addresses[0]);
};

export const FormatFacilityTaxonomy = (
    npi: NpiFacilityResponseItem,
): string => {
    return npi.taxonomies[0].desc;
};

const FormatAddy = (addy: NpiResistryAddress): string => {
    return (
        addy.address_1 +
        ', ' +
        addy.city +
        ', ' +
        addy.state +
        ' ' +
        addy.postal_code.slice(0, 5) +
        '-' +
        addy.postal_code.slice(5)
    );
};
