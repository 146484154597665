import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from 'api/API';
import { OnboardingFormDataModel } from 'models/schemas';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'ui/useToast';

export const useOnboardingFormData = ({
    onComplete,
}: {
    onComplete: () => Promise<void>;
}) => {
    const [formData, setFormData] = useState<OnboardingFormDataModel | null>(
        null,
    );
    const [isLoaded, setIsLoaded] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const navigate = useNavigate();
    const toast = useToast();

    const fetchData = async () => {
        const token = await getAccessTokenSilently();
        await authorize(token)
            .get('/providers/onboard/form')
            .then((response) => {
                setIsLoaded(true);
                if (response.data) {
                    setFormData(response.data.formData);
                } else {
                    setFormData(null);
                }
            });
    };

    const saveForm = async (
        data: Partial<OnboardingFormDataModel>,
    ): Promise<void> => {
        //console.log('SAVING THIS DATA', data);
        const token = await getAccessTokenSilently();

        await authorize(token)
            .post('/providers/onboard/form', data)
            .then(() => {
                fetchData();
            });
    };

    const saveField = async (
        field: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
    ) => {
        const token = await getAccessTokenSilently();
        const newFormData = { [field]: value };
        await authorize(token)
            .post('/providers/onboard/form', newFormData)
            .then(() => {
                fetchData();
            });
    };

    const submitForm = async () => {
        //console.log('Submitting form');
        const token = await getAccessTokenSilently();
        const submitPromise = authorize(token)
            .get(`/providers/onboard`)
            .then(() => {
                //console.log('Onboarding form submitted');
                navigate('/onboarding/complete');
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error('Error creating client', err);
                if (err.response.status === 400) {
                    toast({
                        title: 'Something went wrong. Please reach out to support@joinloula.com if this issue persists.',
                        description: 'Client already exists',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }

                toast({
                    title: 'Something went wrong. Please reach out to support@joinloula.com if this issue persists.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });

        toast.promise(submitPromise, {
            loading: {
                description: 'Submitting Form',
            },
            success: {
                description: 'Success!',
                duration: 3000,
                isClosable: true,
            },
            error: {
                description: 'Error saving changes',
            },
        });

        await submitPromise;
        await onComplete();
    };

    useEffect(() => {
        fetchData();
    }, []);

    return {
        isLoaded,
        formData,
        setFormData,
        saveForm,
        saveField,
        submitForm,
    };
};
