import { usePortalContext } from 'doulaPortal/context/usePortalContext';
import { OpenDrawerProps } from './drawers';

export const useOpenDrawer = (props: OpenDrawerProps) => {
    const portalContext = usePortalContext();

    const openDrawer = () => {
        portalContext?.openDrawer(props);
    };

    return openDrawer;
};

export const useCloseDrawer = () => {
    const portalContext = usePortalContext();

    const closeDrawer = () => {
        portalContext?.closeDrawer();
    };

    return closeDrawer;
};
