import { Box, chakra, RadioProps, useRadio } from '@chakra-ui/react';
import { useChildInputRef } from 'hooks/useChildInputRef';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import { TextTag, TextTagColorSchemes } from './TextTag';
import { DefaultFocusStyle } from 'ui/styling';

export type DecoratedRadioProps = {
    isDecorated?: boolean;
    decorationDesc?: string;
    decorationTagContent?: string;
    decorationTagScheme?: TextTagColorSchemes;
};

export type RadioOptionProp = DecoratedRadioProps & {
    label?: string;
    value?: string | boolean;
    labelBoldPrefix?: string;
    disabled?: boolean;
};

export const DecoratedTopLevelContainerStyling = {
    minWidth: '330px',
    width: '100%',
    transition: 'all 0.3s',
    borderRadius: PX.RADII.MD,
    border: `1px solid ${COLORS.UTIL.Gray[500]}`,
    background: COLORS.UTIL.Gray.WHITE,
    padding: PXSTR.S,
    paddingLeft: PXSTR.M,
    gap: PXSTR.M,
    _hover: {
        background: COLORS.BRAND.Periwinkle[100],
    },
    _focusVisible: DefaultFocusStyle,
};

export const Radio = ({
    label,
    value,
    labelBoldPrefix,
    isDecorated = false,
    decorationTagScheme,
    decorationDesc,
    decorationTagContent,
    disabled,
    ...props
}: Omit<RadioProps, 'value'> & RadioOptionProp) => {
    const { state, htmlProps, getInputProps, getRadioProps, getLabelProps } =
        useRadio({
            ...props,
            value: value?.toString(),
            'aria-describedby': 'radioLabelElement',
        });

    const radioProps = getRadioProps();

    const { childInputRef, topLevelProps } = useChildInputRef();

    return (
        <chakra.label
            display="flex"
            flexDirection="row"
            alignItems="center"
            {...htmlProps}
            cursor={disabled ? 'default' : 'pointer'}
            tabIndex={0}
            opacity={disabled ? 0.5 : 1}
            {...(isDecorated
                ? DecoratedTopLevelContainerStyling
                : { gap: PXSTR.S })}
            {...topLevelProps}
        >
            <input
                {...getInputProps({})}
                ref={childInputRef}
                hidden
                disabled={disabled}
            />
            <FlexRow
                width={PX.SPACING.PX.L}
                height={PX.SPACING.PX.L}
                background="transparent"
                borderRadius={PX.RADII.ROUNDED}
                justify="center"
                align="center"
                transition="all 0.3s"
                _hover={{
                    background: COLORS.BRAND.Periwinkle[200],
                }}
            >
                <FlexRow
                    {...radioProps}
                    className="outerEdge"
                    width={PX.SPACING.PX.M}
                    height={PX.SPACING.PX.M}
                    alignItems="center"
                    justify="center"
                    border={`1px solid ${COLORS.STROKES.HEAVY}`}
                    rounded="full"
                    transition="all 0.3s"
                    _active={{
                        border: `1px solid ${COLORS.BRAND.Periwinkle[700]}`,
                    }}
                >
                    <Box
                        className="innerEdge"
                        {...getLabelProps()}
                        transition="all 0.2s"
                        background={
                            state.isChecked
                                ? COLORS.UTIL.Gray[900]
                                : COLORS.UTIL.Gray.WARM
                        }
                        zIndex={1}
                        width={PX.SPACING.PX.S}
                        height={PX.SPACING.PX.S}
                        borderRadius="100px"
                    />
                </FlexRow>
            </FlexRow>
            <FlexCol width="100%">
                <FlexRow justifyContent="space-between">
                    {isDecorated ? (
                        !!labelBoldPrefix ? (
                            <FONTS.P1>
                                {!!labelBoldPrefix ? (
                                    <b>{labelBoldPrefix},</b>
                                ) : null}{' '}
                                {label ?? value ?? props.children}
                            </FONTS.P1>
                        ) : (
                            <FONTS.H4 id="decoratedRadioLabelElement">
                                {label ?? value ?? props.children}
                            </FONTS.H4>
                        )
                    ) : !!label ? (
                        <FONTS.P1 id="radioLabelElement">{label}</FONTS.P1>
                    ) : !!props.children ? (
                        props.children
                    ) : !!value ? (
                        <FONTS.P1 id="radioLabelElement">{value}</FONTS.P1>
                    ) : null}

                    {isDecorated && !!decorationTagContent && (
                        <TextTag
                            text={decorationTagContent}
                            colorScheme={decorationTagScheme}
                        />
                    )}
                </FlexRow>
                {isDecorated && !!decorationDesc && (
                    <FONTS.P2 color={COLORS.UTIL.Gray[800]}>
                        {decorationDesc}
                    </FONTS.P2>
                )}
            </FlexCol>
        </chakra.label>
    );
};
