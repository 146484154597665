import { FlexCol } from 'ui/loulaFlex';
import { useSubmitVisitContext } from '../submitVisit.context';
import { FormRadio } from 'ui/components/Form/FormRadio';
import { TextTagColorSchemes } from 'ui/components/TextTag';
import { useCallback, useMemo } from 'react';
import {
    DeliveryConclusionDesc,
    PerinatalVisitTypes,
    PregnancyConclusionOptions,
    PregnancyConclusionTypes,
    VisitCategory,
    VisitCategoryDescription,
} from 'models/constants/visitConstants';
import { useAllClients } from 'hooks/dbHooks/clients.hooks';
import { useVisitCounts } from 'doulaPortal/pages/ClientDetails/clientDetails.hooks';
import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import { PXSTR } from 'ui/px';
import FONTS from 'ui/fonts';
import COLORS from 'ui/colors';
import { LINK } from 'ui/components/Link';
import { isInStringEnum } from 'util/Utils';
import { FormBoolRadio } from 'ui/components/Form/FormBoolRadio';
import { DateInput, getDateInputRegisterProps } from 'ui/components/DateInput';
import { StepTwoFields } from '../submitVisit.models';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';
import { GetPregnancy } from 'util/patient.utils';
import { useSimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.context';

export const VisitTypeStep = () => {
    const {
        visitCategory,
        setVisitCategory,

        perinatalType,
        setPerinatalType,

        deliveryConclusionDesc,
        setDeliveryConclusion,

        wasConclusionBillable,
        setWasBillable,

        pregnancyConclusionType,
        setPregnancyConclusionType,

        dateOfPregnancyConclusion,
        setPregnancyEndDate,

        visitTypeOptions,
        deliveryConclusionOptions,
        perinatalTypeOptions,

        errors,
        register,

        isClientAlreadyPostPartum,
    } = useVisitTypeStep();

    const isPostPartum =
        visitCategory === VisitCategory.PostpartumExtended ||
        (visitCategory === VisitCategory.Perinatal &&
            perinatalType === PerinatalVisitTypes.Postpartum);

    const validateConclusionBillable = useCallback((val: unknown) => {
        return val === false;
    }, []);

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();

    const conclusionBillableRegisterProps =
        useGetFormRadioRegisterProps<StepTwoFields>(
            'conclusionBillable',
            '',
            validateConclusionBillable,
        );

    return (
        <FlexCol width="100%">
            <FormRadio
                fieldName="visitCategory"
                label="Select a visit type"
                errors={errors}
                defaultValue={visitCategory}
                onChangeString={setVisitCategory}
                options={visitTypeOptions}
                isRequired={true}
                {...(register?.('visitCategory', standardRadioRegisterProps) ??
                    {})}
            />

            {(visitCategory === VisitCategory.Initial ||
                visitCategory === VisitCategory.Perinatal) && (
                <FormRadio
                    fieldName="perinatalType"
                    label="Was this a prenatal or postpartum visit?"
                    isRequired={true}
                    errors={errors}
                    defaultValue={perinatalType}
                    onChangeString={setPerinatalType}
                    options={perinatalTypeOptions}
                    {...(register?.(
                        'perinatalType',
                        standardRadioRegisterProps,
                    ) ?? {})}
                />
            )}

            {visitCategory === VisitCategory.LaborDelivery && (
                <FormRadio
                    label="What type of support did you provide? "
                    fieldName="deliveryConclusionDesc"
                    isRequired={true}
                    errors={errors}
                    defaultValue={deliveryConclusionDesc}
                    onChangeString={setDeliveryConclusion} //onChangeWrapper('deliveryType')}
                    options={deliveryConclusionOptions}
                    {...(register?.(
                        'deliveryConclusionDesc',
                        standardRadioRegisterProps,
                    ) ?? {})}
                />
            )}

            {isPostPartum && (
                <Alert
                    contentComponents={
                        <FlexCol gap={PXSTR.M}>
                            <FONTS.P1 color={COLORS.PRIMARY.Blue}>
                                Note: There are two types of postpartum
                                visits--Perinatal, Postpartum and Postpartum,
                                Extended.
                            </FONTS.P1>
                            <FONTS.P1 color={COLORS.PRIMARY.Blue}>
                                You’ve selected{' '}
                                <b>
                                    {visitCategory ===
                                    VisitCategory.PostpartumExtended
                                        ? 'Postpartum, Extended'
                                        : 'Perinatal, Postpartum'}
                                </b>
                                , which{' '}
                                {visitCategory ===
                                VisitCategory.PostpartumExtended ? (
                                    <span>
                                        is billed in <b>15-minute increments</b>
                                        , up to <b>3 hours</b>.
                                    </span>
                                ) : (
                                    <span>
                                        has a standard rate that does <b>not</b>{' '}
                                        change with visit duration.
                                    </span>
                                )}
                            </FONTS.P1>
                            <LINK.Large>Learn more</LINK.Large>{' '}
                            {/**TODO: this needs a link */}
                        </FlexCol>
                    }
                    visualScheme={AlertVisualScheme.periwinkle}
                />
            )}

            {isPostPartum && !isClientAlreadyPostPartum && (
                <FormBoolRadio
                    fieldName="wasConclusionBillable"
                    label={`Did you provide billable doula support for the end of ${'CLIENTNAME'}’s pregnancy?`}
                    subLabel="This includes doula support for labor and delivery, miscarriage support, and abortion support. "
                    errors={errors}
                    defaultValue={wasConclusionBillable}
                    onChangeBool={setWasBillable}
                    options={[
                        {
                            value: true,
                            labelBoldPrefix: 'Yes',
                            label: 'I provided billable doula support. ',
                            isDecorated: true,
                        },
                        {
                            value: false,
                            labelBoldPrefix: 'No',
                            label: 'I did not provide billable doula support.',
                            isDecorated: true,
                        },
                    ]}
                    isRequired={true}
                    {...(register?.(
                        'wasConclusionBillable',
                        conclusionBillableRegisterProps,
                    ) ?? {})}
                />
            )}

            {isPostPartum &&
                !isClientAlreadyPostPartum &&
                wasConclusionBillable && (
                    <Alert
                        contentComponents={
                            <FlexCol gap={PXSTR.M}>
                                <FONTS.P1 color="inherit">
                                    Submit a <b>Labor & Delivery</b> visit
                                    first.
                                </FONTS.P1>
                                <FONTS.P1 color="inherit">
                                    After you submit a Labor & Delivery visit,
                                    you’ll be able to submit a postpartum visit.
                                </FONTS.P1>
                            </FlexCol>
                        }
                        visualScheme={AlertVisualScheme.default_no_icon}
                    />
                )}

            {isPostPartum &&
                !isClientAlreadyPostPartum &&
                wasConclusionBillable === false && (
                    <FlexCol gap="inherit">
                        <FormRadio
                            isRequired={true}
                            {...(register?.(
                                'pregnancyConclusionType',
                                standardRadioRegisterProps,
                            ) ?? {})}
                            label="How did this pregnancy end? "
                            fieldName="pregnancyConclusionType"
                            errors={errors}
                            defaultValue={pregnancyConclusionType}
                            onChangeString={setPregnancyConclusionType}
                            options={PregnancyConclusionOptions}
                            isDecorated
                        />

                        <DateInput
                            label={`On what date did this pregnancy end?`}
                            datePickerProps={{
                                dateFormat: 'MM/dd/yy',
                                placeholderText: 'MM/dd/yy',
                                minDate: new Date(2025, 0, 1),
                            }}
                            value={dateOfPregnancyConclusion ?? null}
                            setValue={setPregnancyEndDate}
                            registerProps={
                                !!register
                                    ? register?.(
                                          'dateOfPregnancyConclusion',
                                          getDateInputRegisterProps(),
                                      )
                                    : undefined
                            }
                        />
                    </FlexCol>
                )}
        </FlexCol>
    );
};

const useVisitTypeForm = () => {
    const flowCtx = useSimpleFlowContext();
    const ctx = useSubmitVisitContext();
    const { stepTwoForm } = ctx ?? {};
    const { unSeeSteps } = flowCtx ?? {};

    const { watch, setValue, register, formState } = stepTwoForm ?? {};
    const { errors } = formState ?? {};

    const [
        visitCategory,
        perinatalType,
        deliveryConclusionDesc,
        wasConclusionBillable,
        pregnancyConclusionType,
        dateOfPregnancyConclusion,
    ] =
        watch?.([
            'visitCategory',
            'perinatalType',
            'deliveryConclusionDesc',
            'wasConclusionBillable',
            'pregnancyConclusionType',
            'dateOfPregnancyConclusion',
        ]) ?? [];

    const setValueOptions = {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
    };

    const unregisterPostPartumExtraQs = () => {
        stepTwoForm?.unregister([
            'wasConclusionBillable',
            'dateOfPregnancyConclusion',
            'pregnancyConclusionType',
        ]);
    };

    const unregisterLaborDeliveryExtraQs = () => {
        stepTwoForm?.unregister(['deliveryConclusionDesc']);
    };

    const unregisterPerinatalTypeExtraQs = () => {
        stepTwoForm?.unregister(['perinatalType']);
    };

    const resetDependentForms = () => {
        ctx?.stepFiveForm?.reset();

        unSeeSteps?.([4, 5]);
    };

    const setVisitCategory = (strVal: string) => {
        if (isInStringEnum(strVal, VisitCategory)) {
            setValue?.(
                'visitCategory',
                strVal as VisitCategory,
                setValueOptions,
            );

            if (strVal !== VisitCategory.PostpartumExtended) {
                unregisterPostPartumExtraQs();
            } else {
                unregisterPerinatalTypeExtraQs();
            }

            if (strVal !== VisitCategory.LaborDelivery) {
                unregisterLaborDeliveryExtraQs();
            } else {
                unregisterPerinatalTypeExtraQs(); //TODO: dont like this duplicate
            }

            resetDependentForms();
        }
    };

    const setPregnancyConclusionType = (strVal: string) => {
        if (isInStringEnum(strVal, PregnancyConclusionTypes)) {
            setValue?.(
                'pregnancyConclusionType',
                strVal as PregnancyConclusionTypes,
                setValueOptions,
            );
        }
    };

    const setPregnancyEndDate = (date: Date | null) => {
        if (!date) return;
        setValue?.('dateOfPregnancyConclusion', date, setValueOptions);
    };

    const setPerinatalType = (strVal: string) => {
        if (isInStringEnum(strVal, PerinatalVisitTypes)) {
            setValue?.(
                'perinatalType',
                strVal as PerinatalVisitTypes,
                setValueOptions,
            );

            if (strVal !== PerinatalVisitTypes.Postpartum) {
                unregisterPostPartumExtraQs();
            }

            resetDependentForms();
        }
    };

    const setDeliveryConclusion = (strVal: string) => {
        if (strVal in DeliveryConclusionDesc) {
            setValue?.(
                'deliveryConclusionDesc',
                strVal as keyof typeof DeliveryConclusionDesc,
                setValueOptions,
            );
        }
    };

    const setWasBillable = (boolVal: boolean) => {
        setValue?.('wasConclusionBillable', boolVal, setValueOptions);
    };

    return {
        visitCategory,
        setVisitCategory,

        perinatalType,
        setPerinatalType,

        deliveryConclusionDesc,
        setDeliveryConclusion,

        wasConclusionBillable,
        setWasBillable,

        pregnancyConclusionType,
        setPregnancyConclusionType,

        dateOfPregnancyConclusion,
        setPregnancyEndDate,

        errors,
        register,
    };
};

const useVisitTypeStep = () => {
    const ctx = useSubmitVisitContext();
    const { stepOneForm } = ctx ?? {};
    const { allClients } = useAllClients();

    const client = useMemo(() => {
        let clientId = stepOneForm?.getValues('patientUserId');
        if (!clientId) clientId = Object.keys(allClients)[0];
        return allClients[clientId];
    }, [allClients, stepOneForm]);

    const { countRemainingPerCategory } = useVisitCounts(client);
    const isClientAlreadyPostPartum =
        GetPregnancy(client)?.status === 'Postpartum';

    const {
        visitCategory,
        setVisitCategory,
        perinatalType,
        setPerinatalType,
        deliveryConclusionDesc,
        setDeliveryConclusion,
        pregnancyConclusionType,
        setPregnancyConclusionType,
        dateOfPregnancyConclusion,
        setPregnancyEndDate,
        errors,
        register,
        setWasBillable,
        wasConclusionBillable,
    } = useVisitTypeForm();

    const {
        visitTypeOptions,
        deliveryConclusionOptions,
        perinatalTypeOptions,
    } = useMemo(() => {
        const visitTypeOptions = Object.entries(VisitCategoryDescription).map(
            ([categoryLabel, categoryDesc]) => {
                return {
                    label: categoryLabel,
                    value: categoryLabel,
                    isDecorated: true,
                    decorationDesc: categoryDesc,
                    decorationTagContent: `${countRemainingPerCategory[categoryLabel as VisitCategory]} Remaining`,
                    decorationTagScheme: TextTagColorSchemes.GREEN_LIGHT,
                };
            },
        );

        const deliveryConclusionOptions = Object.entries(
            DeliveryConclusionDesc,
        ).map(([label, desc]) => {
            return {
                label: label,
                value: label,
                isDecorated: true,
                decorationDesc: desc,
            };
        });

        const perinatalTypeOptions = Object.values(PerinatalVisitTypes).map(
            (value) => {
                return {
                    value: value,
                    isDecorated: true,
                };
            },
        );

        return {
            visitTypeOptions,
            deliveryConclusionOptions,
            perinatalTypeOptions,
        };
    }, [countRemainingPerCategory]);

    return {
        visitCategory,
        setVisitCategory,

        perinatalType,
        setPerinatalType,

        deliveryConclusionDesc,
        setDeliveryConclusion,

        wasConclusionBillable,
        setWasBillable,

        visitTypeOptions,
        deliveryConclusionOptions,
        perinatalTypeOptions,

        pregnancyConclusionType,
        setPregnancyConclusionType,
        dateOfPregnancyConclusion,
        setPregnancyEndDate,

        isClientAlreadyPostPartum,

        errors,
        register,
    };
};
