import {
    Table as BaseTable,
    Collapse,
    TableProps,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import FONTS from 'ui/fonts';
import { TextTag, TextTagColorSchemes } from './TextTag';
import { PayerTag, UserTag } from './AvatarTag';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { LINK } from './Link';
import { PrimaryButton } from './Button';
import { variants } from 'ui/theme/tableTheme';
import { IconButton, IconButtonType } from './IconButton';
import { useMemo, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';
import { SheetTab } from './Sheet';
import COLORS from 'ui/colors';
import { PAGE_COLLAPSE_DURATION } from 'models/constants/animationConstants';
import { PayerModel, UserModel } from 'models/schemas';
import { ICONS } from 'ui/icons';

//Cell type = status, gets perma-visibility at bottom right
//cell can be marked as frozen, so it won't be in the collapsed portion

export enum TableCellTypes {
    DEFAULT = 'default',
    HEADING = 'heading',
    TEXT_TAG = 'tag',
    USER_TAG = 'user_tag',
    PAYER_TAG = 'payer_tag',
}

export enum ActionTypes {
    LINK = 'link',
    BUTTON = 'button',
    ICON_BUTTON = 'icon_button',
}

export type TableCellAction = {
    text?: string;
    type?: ActionTypes;
    icon?: keyof typeof ICONS;
    onClick: () => void;
};

export type TableCell = {
    //Info bout the type of cell and how to render it
    cellType: TableCellTypes;
    isTimeStamp?: boolean;
    isStatus?: boolean;
    isFrozen?: boolean;

    //Data
    content?: string; //for most types, this is the text that shows, for client tag it needs to be id
    actions?: TableCellAction[];
    user?: UserModel; //For TableCellTypes.USER_TAG
    payer?: PayerModel; //For TableCellTypes.PAYER_TAG
    tagColorScheme?: TextTagColorSchemes; //For TableCellTypes.TEXT_TAG
};

export const TableCell = ({ cellData }: { cellData: TableCell }) => {
    const { cellType, content, actions, user, payer, tagColorScheme } =
        cellData;

    return (
        <FlexRow
            gap={PXSTR.SM}
            alignItems="center"
            justifyContent="space-between"
        >
            {cellType === TableCellTypes.DEFAULT && (
                <FONTS.P2>{content}</FONTS.P2>
            )}

            {cellType === TableCellTypes.HEADING && (
                <FONTS.H5>{content}</FONTS.H5>
            )}

            {cellType === TableCellTypes.TEXT_TAG && (
                <TextTag text={content} colorScheme={tagColorScheme} />
            )}

            {cellType === TableCellTypes.USER_TAG && <UserTag user={user} />}

            {cellType === TableCellTypes.PAYER_TAG && (
                <PayerTag payer={payer} />
            )}

            <FlexRow gap={PXSTR.S}>
                {actions?.map((action) => {
                    if (!action.type || action.type === ActionTypes.LINK) {
                        return (
                            <LINK.Small onClick={action?.onClick}>
                                {action?.text}
                            </LINK.Small>
                        );
                    } else if (action.type === ActionTypes.BUTTON) {
                        return (
                            <PrimaryButton
                                onClick={action?.onClick}
                                paddingY={PXSTR.XS}
                                paddingX={PXSTR.M}
                            >
                                {action?.text}
                            </PrimaryButton>
                        );
                    } else if (
                        action.type === ActionTypes.ICON_BUTTON &&
                        !!action.icon
                    ) {
                        return (
                            <IconButton
                                iconBtnType={IconButtonType.decorated}
                                icon={action?.icon}
                                onClick={action?.onClick}
                            />
                        );
                    }
                })}
            </FlexRow>
        </FlexRow>
    );
};

const RowCardItem = ({
    header,
    cellData,
}: {
    header: string;
    cellData: TableCell;
}) => {
    return (
        <FlexCol flex={1} flexBasis="40%" gap={PXSTR.XS}>
            <FONTS.H5 color={COLORS.UTIL.Gray[800]}>{header}</FONTS.H5>
            <TableCell cellData={cellData} />
        </FlexCol>
    );
};

export const RowCard = ({
    rowData,
    headers,
}: {
    rowData: TableCell[];
    headers: string[];
}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const headingCellIdx =
        rowData.findIndex((cell) => cell.cellType === TableCellTypes.HEADING) ??
        rowData[0];

    const timeStampCellIdx = rowData.findIndex((cell) => cell.isTimeStamp);

    const statusCellIdx = rowData.findIndex((cell) => cell.isStatus);

    const hasFrozenCells = rowData.some((cell) => cell.isFrozen);

    return (
        <SheetTab padding={PXSTR.M} minW={200}>
            <FlexRow justifyContent="space-between">
                <FONTS.H4>{rowData[headingCellIdx]?.content}</FONTS.H4>
                <IconButton
                    onClick={() => setIsCollapsed((prev) => !prev)}
                    icon={isCollapsed ? 'ChevronDown' : 'ChevronUp'}
                    size={16}
                    color={COLORS.PRIMARY.Grey}
                />
            </FlexRow>
            {hasFrozenCells && (
                <FlexRow mt={PXSTR.M} maxW="100%" wrap="wrap" gap={PXSTR.M}>
                    {rowData.map((cell, idx) => {
                        if (!cell.isFrozen) return null;
                        return (
                            <RowCardItem
                                key={`frozen_cell_${idx}`}
                                header={headers[idx]}
                                cellData={cell}
                            />
                        );
                    })}
                </FlexRow>
            )}

            <Collapse
                startingHeight={1}
                in={!isCollapsed}
                transition={{
                    enter: { duration: PAGE_COLLAPSE_DURATION / 2 },
                    exit: { duration: PAGE_COLLAPSE_DURATION / 2 },
                }}
            >
                <FlexRow mt={PXSTR.M} maxW="100%" wrap="wrap" gap={PXSTR.M}>
                    {rowData.map((cell, idx) => {
                        if (
                            cell.cellType === TableCellTypes.HEADING ||
                            cell.isTimeStamp ||
                            cell.isStatus ||
                            cell.isFrozen
                        )
                            return null;

                        return (
                            <RowCardItem
                                key={`table_card_row_${idx}`}
                                cellData={cell}
                                header={headers[idx]}
                            />
                        );
                    })}
                </FlexRow>
            </Collapse>
            <FlexRow
                width="100%"
                alignItems="end"
                justifyContent="space-between"
            >
                {timeStampCellIdx > -1 && (
                    <FONTS.P2 marginTop={PXSTR.M} color={COLORS.PRIMARY.Grey}>
                        {headers[timeStampCellIdx]} on{' '}
                        {rowData[timeStampCellIdx]?.content}
                    </FONTS.P2>
                )}

                {statusCellIdx > -1 && (
                    <TableCell cellData={rowData[statusCellIdx]} />
                )}
            </FlexRow>
        </SheetTab>
    );
};

export const Table = ({
    id,
    variant,
    headers,
    data,
    cardMode,
    cardCutoff,
}: {
    id: string;
    variant: keyof typeof variants | undefined;
    headers: string[];
    data: TableCell[][];
    cardMode?: boolean;
    cardCutoff?: number;
}) => {
    const minColWidth = 125;
    const minHeadingColWidth = 180;

    const [isSortAscending, setIsSortAscending] = useState<boolean>(false);
    const [activeSortColumnIdx, setActiveSortColumnIdx] = useState<number>(
        headers.length - 1,
    );

    const sortedData = useMemo(() => {
        return data.sort((rowA, rowB) => {
            const sortRes =
                (rowA[activeSortColumnIdx]?.content ?? '') >
                (rowB[activeSortColumnIdx]?.content ?? '')
                    ? -1
                    : 1;

            return isSortAscending ? sortRes : sortRes * -1;
        });
    }, [activeSortColumnIdx, data, isSortAscending]);

    const [ref, bounds] = useMeasure();

    if (cardMode || bounds.width < (cardCutoff ?? 0)) {
        return (
            <FlexCol ref={ref} width="100%" gap={PXSTR.S}>
                {sortedData.map((rowData, idx) => (
                    <RowCard
                        key={`table_${id}_card_${idx}`}
                        headers={headers}
                        rowData={rowData}
                    />
                ))}
            </FlexCol>
        );
    }

    return (
        <BaseTable ref={ref} variant={variant}>
            <Thead>
                <Tr>
                    {headers.map((header, idx) => (
                        <Th
                            //TODO: width filling here is the tricky part minW={idx === 0 ? minHeadingColWidth : minColWidth}
                            key={`${id}_header_${idx}_${header}`}
                        >
                            <FlexRow
                                alignItems="center"
                                width="100%"
                                justifyContent="space-between"
                                onClick={() =>
                                    idx === activeSortColumnIdx
                                        ? setIsSortAscending((prev) => !prev)
                                        : setActiveSortColumnIdx(idx)
                                }
                                gap={PXSTR.XS}
                            >
                                {header}
                                <IconButton
                                    opacity={
                                        idx === activeSortColumnIdx ? 1 : 0
                                    }
                                    icon={
                                        isSortAscending
                                            ? 'CaretUp'
                                            : 'CaretDown'
                                    }
                                    size={12}
                                />
                            </FlexRow>
                        </Th>
                    ))}
                </Tr>
            </Thead>
            <Tbody>
                {sortedData.map((row, rowIdx) => (
                    <Tr key={`${id}_full_table_row_${rowIdx}`}>
                        {row.map((cellData, colIdx) => (
                            <Td key={`${id}_table_cell_${rowIdx}_${colIdx}`}>
                                <TableCell cellData={cellData} />
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </BaseTable>
    );
};

type TableStorybookProps = TableProps & {
    variant?: keyof typeof variants;
    cardMode?: boolean;
};

export const TableForStoryBook = ({
    variant,
    cardMode,
}: TableStorybookProps) => {
    if (variant !== undefined && !(variant in (variants ?? {}))) {
        variant = undefined;
    }

    const headers: string[] = [
        'Name',
        'Status',
        'Due Date',
        'Birth Date',
        'Visits',
        'Last Logged',
    ];

    const data: TableCell[][] = [
        [
            {
                cellType: TableCellTypes.HEADING,
                content: 'Mina Sow',
            },
            {
                cellType: TableCellTypes.TEXT_TAG,
                content: 'prenatal',
                isStatus: true,
            },
            {
                isFrozen: true,
                cellType: TableCellTypes.DEFAULT,
                content: '12/1/2025',
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: '12/1/1995',
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: '0/8 visits',
                actions: [
                    {
                        text: 'View',
                        onClick: () => {
                            // eslint-disable-next-line no-console
                            console.log('this should open a view of the visit');
                        },
                    },
                ],
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: '4/2/24',
                isTimeStamp: true,
            },
        ],
        [
            {
                cellType: TableCellTypes.HEADING,
                content: 'Mackenzie Lee',
            },
            {
                isStatus: true,
                cellType: TableCellTypes.TEXT_TAG,
                content: 'post-partum',
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: '12/1/2025',
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: '12/1/1995',
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: '3/8 visits',
                actions: [
                    {
                        text: 'View',
                        onClick: () => {
                            // eslint-disable-next-line no-console
                            console.log('this should open a view of the visit');
                        },
                        type: ActionTypes.BUTTON,
                    },
                ],
            },
            {
                cellType: TableCellTypes.DEFAULT,
                content: '1/2/24',
                isTimeStamp: true,
            },
        ],
    ];

    return (
        <Table
            id="storybook_test_table"
            variant={variant}
            headers={headers}
            data={data}
            cardMode={cardMode}
        />
    );
};
