import { FlexCol } from 'ui/loulaFlex';
import { DateInput, getDateInputRegisterProps } from 'ui/components/DateInput';
import { Divider } from 'ui/components/Divider';
import FONTS from 'ui/fonts';
import { FormRadio } from 'ui/components/Form/FormRadio';
import { PregnancyStatus, Sex } from 'models/constants/clientConstants';
import { useCreateClientContext } from '../createClient.context';
import { RadioOptionProp } from 'ui/components/Radio';
import { isInStringEnum } from 'util/Utils';
import { useGetFormRadioRegisterProps } from 'ui/components/hooks/useRegisterProps';
import {
    getFormTextInputRegisterProps,
    NewFormTextInput,
} from 'ui/components/Form/NewFormTextInput';
import AddressForm from 'components/AddressForm';
import { StepFourFields } from '../createClient.models';
import { FormCheckboxInput } from 'ui/components/Form/FormCheckBoxInput';
import { PXSTR } from 'ui/px';
import { Pronouns } from 'models/constants';
import {
    FormMultiselect,
    MultiselectOptionType,
} from 'ui/components/Form/FormMultiselect';

export const AdditionalDetailsStep = () => {
    const ctx = useCreateClientContext();
    const { stepFourForm, stepThreeForm } = ctx ?? {};
    const { formState, watch, register, setValue } = stepFourForm ?? {};
    const { errors } = formState ?? {};

    //TODO: all these sex props are the exact same as InsuredDetailsStep how can i abstract it up
    const setterProps = {
        shouldValidate: true,
        shouldDirty: true,
    };
    const sexOptions: RadioOptionProp[] = Object.values(Sex)
        .filter((sex) => sex !== Sex.Other) //stupid insurance companies
        .map((sex) => {
            return { label: sex, value: sex };
        });
    const [
        sex,
        isAddressSameAsInsured,
        pregnancyStatus,
        dateOfPregnancyConclusion,
        address,
        pronouns,
    ] =
        watch?.([
            'sex',
            'isAddressSameAsInsured',
            'pregnancyStatus',
            'dateOfPregnancyConclusion',
            'address',
            'pronouns',
        ]) ?? [];

    const hasNonSelfInsured =
        stepThreeForm?.watch('insuredDetails') !== undefined;

    const insuredAddress = stepThreeForm?.watch?.('insuredDetails.address');
    const setSex = (val: string) => {
        if (!isInStringEnum(val, Sex)) return;
        setValue?.('sex', val as Sex, setterProps);
    };

    const pregnancyStatusOptions = Object.values(PregnancyStatus)
        .filter((statusOption) => statusOption !== PregnancyStatus.Inactive)
        .map((option) => {
            return { value: option };
        });
    const setPregnancyStatus = (val: string) => {
        if (!isInStringEnum(val, PregnancyStatus)) return;
        setValue?.('pregnancyStatus', val as PregnancyStatus, setterProps);
    };

    const setBool = (fieldName: keyof StepFourFields) => (val: boolean) => {
        setValue?.(fieldName, val, setterProps);
    };

    const setDateOfConclusion = (date: Date | null) => {
        if (!date) return;
        setValue?.('dateOfPregnancyConclusion', date, setterProps);
    };

    const setPronouns = (record: Record<string, boolean>) => {
        setValue?.('pronouns', record, setterProps);
    };

    const standardRadioRegisterProps = useGetFormRadioRegisterProps();

    return (
        <FlexCol gap={PXSTR.XXL}>
            <NewFormTextInput
                label="Client’s Preferred First Name (optional)"
                subLabel="If given, this will be the first name we use for this client on Loula. This name will not be shared with health plans."
                fieldName="preferredName"
                errors={errors}
                registerProps={register?.(
                    'preferredName',
                    getFormTextInputRegisterProps({ required: false }),
                )}
            />

            <FormRadio
                fieldName="insuredDetails.sex"
                label="Insured’s Sex"
                subLabel="Sex is required to bill with health plans. Make sure the sex give matches the sex listed on the insurance record."
                errors={errors}
                defaultValue={sex}
                onChangeString={setSex}
                options={sexOptions}
                isRequired={true}
                isDecorated
                {...(register?.('sex', standardRadioRegisterProps) ?? {})}
            />

            <FormMultiselect
                data-cy="pronouns"
                fieldName="user.pronouns"
                label="Client’s Pronoun(s) (optional)"
                value={pronouns}
                setValue={setPronouns}
                errors={errors}
                subLabel="We strive to use gender-inclusive language. 
                We will default to gender-neutral language if no pronouns are listed."
                isRequired={false}
                options={Object.keys(Pronouns).map(
                    (pronounKey): MultiselectOptionType => {
                        return {
                            label: pronounKey,
                            value: pronounKey,
                            isDecorated: true,
                        };
                    },
                )}
                registerProps={
                    !!register
                        ? {
                              ...register('pronouns'),
                          }
                        : undefined
                }
            />

            <Divider />

            {hasNonSelfInsured && (
                <FormCheckboxInput
                    fieldName="isAddressSameAsInsured"
                    label="The client’s address is the same as the insured’s address"
                    value={isAddressSameAsInsured ?? false}
                    setValue={setBool('isAddressSameAsInsured')}
                    errors={errors}
                    registerProps={register?.('isAddressSameAsInsured')}
                />
            )}

            <AddressForm
                isDisabled={isAddressSameAsInsured}
                label="Client’s Address"
                namePrefix="address"
                requiredWarningText="Please enter a valid mailing address."
                watch={watch}
                errors={errors}
                register={register}
                value={isAddressSameAsInsured ? insuredAddress : address}
                setValue={setValue}
            />

            <NewFormTextInput
                label="Client’s Email Address"
                fieldName="email"
                errors={errors}
                registerProps={register?.(
                    'email',
                    getFormTextInputRegisterProps({}),
                )}
            />

            <NewFormTextInput
                label="Insured's Phone Number"
                fieldName="phone"
                mask="(000) 000-0000"
                placeholder="(123) 456-7890"
                minLength={14}
                errors={errors}
                registerProps={register?.(
                    'phone',
                    getFormTextInputRegisterProps({}),
                )}
            />

            <Divider />

            <FONTS.H3>Pregnancy</FONTS.H3>

            <FormRadio
                fieldName="pregnancyStatus"
                label="What is the client’s current pregnancy status?"
                errors={errors}
                defaultValue={pregnancyStatus}
                onChangeString={setPregnancyStatus}
                options={pregnancyStatusOptions}
                isRequired={true}
                isDecorated
                {...(register?.(
                    'pregnancyStatus',
                    standardRadioRegisterProps,
                ) ?? {})}
            />

            <DateInput
                label={
                    pregnancyStatus === PregnancyStatus.Prenatal
                        ? 'Estimated Due Date'
                        : 'Date Pregnancy Ended'
                }
                subLabel="This date is just for our record-keeping and will not be shared with health plans--an approximated expected due date is fine."
                datePickerProps={{
                    dateFormat: 'MM/dd/yyyy',
                    placeholderText: 'MM/dd/yyyy',
                    maxDate:
                        pregnancyStatus === PregnancyStatus.PostPartum
                            ? new Date()
                            : undefined,
                    minDate:
                        pregnancyStatus === PregnancyStatus.Prenatal
                            ? new Date()
                            : undefined,
                }}
                value={dateOfPregnancyConclusion ?? null}
                setValue={setDateOfConclusion}
                registerProps={
                    !!register
                        ? register?.(
                              'dateOfPregnancyConclusion',
                              getDateInputRegisterProps(),
                          )
                        : undefined
                }
            />
        </FlexCol>
    );
};
