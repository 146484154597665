import COLORS from 'ui/colors';
import { Box } from '@chakra-ui/react';
import { PortalContext } from '../context/portalContext';
import { useInitialPortalContext } from '../context/useInitialPortalContext';
import { DrawerLayout } from './DrawerLayout';
import { ReactNode } from 'react';

export const SimpleFullScreenLayout = ({
    children,
}: {
    children: ReactNode;
}) => {
    const initialPortalContext = useInitialPortalContext();

    return (
        <Box
            w="100vw"
            h="100dvh"
            position="fixed"
            overflow="hidden"
            overscrollBehavior="none"
            className="portalSimpleTopLevelLayout"
            background={COLORS.UTIL.Gray[400]}
        >
            <PortalContext.Provider value={initialPortalContext}>
                {children}
                <DrawerLayout />
            </PortalContext.Provider>
        </Box>
    );
};
