import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import { UploadFileCard } from 'components/FileUpload/FileUploader';
import {
    ProgressableStep,
    StepComponentProps,
} from 'onboarding/onboarding.model';
import { ReactElement, useContext, useState } from 'react';
import COLORS from 'ui/colors';
import { SecondaryButton } from 'ui/components/Button';
import { SheetTab } from 'ui/components/Sheet';
import { TextTag, TextTagColorSchemes } from 'ui/components/TextTag';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import { FlexCol, FlexRow, RowCol, ColRow } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import { ResumeItemCard } from '../ResumeItemCard';
import { useAllPayers, useProviderContracts, useStripe } from 'hooks/dbHooks';
import {
    generateAddressInReadableString,
    StringBoolMapHasAtleastOne,
    Zyg,
} from 'util/Utils';
import {
    OnboardingProofOfTrainingPathway,
    ProofOfTrainingPathway,
} from 'models/constants';
import {
    OnboardingContext,
    OnboardingStatusContext,
} from 'onboarding/OnboardingContext';
import { CredentialStatusTable } from '../CredentialStatusTable';
import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import { formatDate } from 'date-fns';

export const ReviewStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);
    const statusContext = useContext(OnboardingStatusContext);

    const { isCompleted, onboardingStatus, practitionerData } =
        statusContext ?? {};

    const { steps, formData, isStepComplete, goToStep, goToSubStep } =
        onboardingContext ?? {};

    const { allPayers } = useAllPayers();

    const { providerContractData, hasActionRequired, updateContractStatus } =
        useProviderContracts();

    const {
        stripeAccount,
        stripeIsLoading,
        onboardStripeAccount,
        loginToStripeExpress,
    } = useStripe();

    const enableStripe = false; // TODO: turn stripe onbaording on!

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                {!isCompleted && (
                    <FONTS.H2>
                        Review the following sections to make sure the
                        information inputted is accurate.
                    </FONTS.H2>
                )}

                {isCompleted && onboardingStatus !== 'approved' && (
                    <SheetTab padding={PX.SPACING.PX.L}>
                        <FlexCol gap={PX.SPACING.PX.M}>
                            <FlexRow justify="space-between">
                                <FONTS.H3>Onboarding Status</FONTS.H3>
                                <TextTag
                                    text={onboardingStatus?.replace('_', ' ')}
                                />
                            </FlexRow>

                            <FONTS.P2>
                                We’re reviewing your onboarding packet. If any
                                additional materials are needed, we’ll contact
                                you at
                                <b>
                                    {' ' +
                                        (practitionerData?.user.email ??
                                            'email')}
                                </b>
                            </FONTS.P2>
                        </FlexCol>
                    </SheetTab>
                )}

                {onboardingStatus === 'approved' && (
                    <SheetTab padding={PX.SPACING.PX.L}>
                        <FlexCol gap={PX.SPACING.PX.M}>
                            <FlexRow justify="space-between">
                                <FONTS.H3>Onboarding Status</FONTS.H3>
                                <TextTag
                                    colorScheme={
                                        TextTagColorSchemes.GREEN_LIGHT
                                    }
                                    text={onboardingStatus.replace('_', ' ')}
                                />
                            </FlexRow>

                            <FONTS.P2>
                                Your application has been reviewed and you have
                                been approved to join Loula!
                            </FONTS.P2>

                            <FONTS.P2>
                                When we launch our platform, we will notify you
                                via email and you can start getting reimbursed
                                right away.
                            </FONTS.P2>
                        </FlexCol>
                    </SheetTab>
                )}

                {onboardingStatus === 'approved' &&
                    enableStripe &&
                    !stripeIsLoading &&
                    !stripeAccount?.payouts_enabled && (
                        <Alert
                            visualScheme={AlertVisualScheme.periwinkle}
                            contentComponents={
                                <ColRow
                                    maxW="100%"
                                    justify="space-between"
                                    align="center"
                                    wrap="wrap"
                                >
                                    <RowCol gap={PX.SPACING.PX.S}>
                                        <FONTS.P1>
                                            <b>Set up your payment.</b> We use a
                                            third-party application, Stripe, to
                                            manage your payments. Use the link
                                            below to setup your direct deposit
                                            so that you can get started.
                                        </FONTS.P1>
                                    </RowCol>
                                    <RowCol gap={PX.SPACING.PX.S}>
                                        <SecondaryButton
                                            iconRight="Launch"
                                            onClick={() => {
                                                onboardStripeAccount();
                                            }}
                                        >
                                            Set-up direct deposit
                                        </SecondaryButton>
                                    </RowCol>
                                </ColRow>
                            }
                        />
                    )}

                {onboardingStatus === 'approved' &&
                    enableStripe &&
                    !stripeIsLoading &&
                    stripeAccount?.payouts_enabled && (
                        <Alert
                            visualScheme={AlertVisualScheme.periwinkle}
                            contentComponents={
                                <ColRow
                                    maxW="100%"
                                    justify="space-between"
                                    align="center"
                                    wrap="wrap"
                                >
                                    <RowCol gap={PX.SPACING.PX.S}>
                                        <FONTS.P1>
                                            <b>
                                                Thanks for setting up your
                                                Stripe account.
                                            </b>{' '}
                                            We use a third-party application,
                                            Stripe, to manage your payments. Use
                                            the link below to view the Express
                                            dashboard and manage your Stipe
                                            account
                                        </FONTS.P1>
                                    </RowCol>
                                    <RowCol gap={PX.SPACING.PX.S}>
                                        <SecondaryButton
                                            iconRight="Launch"
                                            onClick={() => {
                                                loginToStripeExpress();
                                            }}
                                        >
                                            Manage Stripe account
                                        </SecondaryButton>
                                    </RowCol>
                                </ColRow>
                            }
                        />
                    )}

                {onboardingStatus === 'approved' && (
                    <SheetTab
                        background={COLORS.UTIL.Gray.WARM}
                        padding={PX.SPACING.PX.L}
                    >
                        <FlexCol gap={PX.SPACING.PX.M}>
                            <FONTS.H3>Credentialing Status</FONTS.H3>

                            <FONTS.P1>
                                We’re working to get you credentialed with the
                                following Managed Care Plans. As we hear back
                                from the plans, we will update your plan status,
                                which is visible in real-time. You will also
                                receive an email whenever there is an update to
                                your credentialing status.
                            </FONTS.P1>

                            {hasActionRequired && (
                                <Alert
                                    contentComponents={
                                        <FONTS.P1>
                                            <b>Action needed!</b> Some Managed
                                            Care Plans require additional
                                            signatures and attestations. We will
                                            not be able to complete your
                                            credentialing process for those
                                            plans until you complete this final
                                            step.{' '}
                                        </FONTS.P1>
                                    }
                                />
                            )}

                            <CredentialStatusTable
                                providerContractData={providerContractData}
                                updateContractStatus={updateContractStatus}
                            />
                        </FlexCol>
                    </SheetTab>
                )}

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[0])) ??
                        false
                    }
                    step={steps?.[0]}
                    onClickEdit={goToStep?.(0)}
                    isFlowComplete={isCompleted}
                >
                    <StepField fieldName="Legal First Name">
                        {formData?.firstName && (
                            <FONTS.P1>{formData?.firstName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Legal Last Name">
                        {formData?.lastName && (
                            <FONTS.P1>{formData?.lastName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField isRequired={false} fieldName="Middle Name">
                        {formData?.middleName && (
                            <FONTS.P1>{formData?.middleName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField isRequired={false} fieldName="Preferred Name">
                        {formData?.preferredName && (
                            <FONTS.P1>{formData?.preferredName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField
                        isRequired={formData?.hasOtherNames}
                        fieldName="Formerly Known As"
                    >
                        {formData?.otherNames && (
                            <FONTS.P1>{formData?.otherNames}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Pronouns">
                        {Object.entries(formData?.pronouns ?? {}).filter(
                            ([, selected]) => !!selected,
                        ).length > 0 && (
                            <FONTS.P1>
                                {Object.entries(formData?.pronouns ?? {})
                                    .filter(([, selected]) => !!selected)
                                    .map(([key]) => key)
                                    .join(', ')}
                            </FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Date of Birth">
                        {formData?.dob && (
                            <FONTS.P1>
                                {formatDate(
                                    new Date(formData?.dob),
                                    'MM/dd/yyyy',
                                )}
                            </FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Social Security Number">
                        {formData?.tin && (
                            <HiddenTextField
                                rawText={formData?.tin.toString()}
                            />
                        )}
                    </StepField>
                    <StepField fieldName="NPI Number">
                        {formData?.npi && <FONTS.P1>{formData?.npi}</FONTS.P1>}
                    </StepField>
                    <StepField fieldName="Phone Number">
                        {formData?.phone && (
                            <FONTS.P1>{formData?.phone}</FONTS.P1>
                        )}
                    </StepField>

                    <StepField fieldName="Personal Mailing Address">
                        {(formData?.personalAddress?.address1?.length ?? 0) >
                            0 && (
                            <FONTS.P1>
                                {generateAddressInReadableString(
                                    formData?.personalAddress,
                                )}
                            </FONTS.P1>
                        )}
                    </StepField>

                    <StepField isRequired={false} fieldName="Languages">
                        {Object.entries(formData?.languages ?? {}).map(
                            ([language, proficiency], idx) => (
                                <FONTS.P1
                                    key={`languge_prof_${idx}`}
                                >{`${language} (${proficiency ?? 'unknown'})`}</FONTS.P1>
                            ),
                        )}
                    </StepField>

                    <StepField
                        fieldName="Race/Ethnicity"
                        isFlowComplete={isCompleted}
                    >
                        {StringBoolMapHasAtleastOne(formData?.race) && (
                            <FONTS.P1>
                                {Object.entries(formData?.race ?? {})
                                    .filter(([, selected]) => selected)
                                    .map(([race]) => race)
                                    .join('; ')}
                            </FONTS.P1>
                        )}
                    </StepField>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[1])) ??
                        false
                    }
                    step={steps?.[1]}
                    onClickEdit={goToStep?.(1)}
                    isFlowComplete={isCompleted}
                >
                    <StepField fieldName="Business Name">
                        {formData?.isNameSameAsPersonal ? (
                            <FONTS.P1>
                                {formData?.firstName} {formData?.lastName}
                            </FONTS.P1>
                        ) : (
                            <FONTS.P1>{formData?.businessName}</FONTS.P1>
                        )}
                    </StepField>

                    <StepField fieldName="TIN or EIN">
                        {formData?.hasNoBusinessTin ? (
                            <FONTS.P1>I do not have a TIN or EIN</FONTS.P1>
                        ) : (formData?.businessTIN?.length ?? 0) > 0 ? (
                            <FONTS.P1>{formData?.businessTIN}</FONTS.P1>
                        ) : null}
                    </StepField>

                    <StepField fieldName="Business Mailing Address">
                        {formData?.isAddressSameAsPersonal
                            ? generateAddressInReadableString(
                                  formData.personalAddress,
                              )
                            : generateAddressInReadableString(
                                  formData?.businessAddress,
                              ) && (
                                  <FONTS.P1>
                                      {generateAddressInReadableString(
                                          formData?.businessAddress,
                                      )}
                                  </FONTS.P1>
                              )}
                    </StepField>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[2])) ??
                        false
                    }
                    step={steps?.[2]}
                    onClickEdit={goToStep?.(2)}
                    isFlowComplete={isCompleted}
                >
                    <StepField fieldName="Counties serving" flexBasis="100%">
                        <FlexRow gap={PX.SPACING.PX.S} wrap="wrap">
                            {formData?.serviceCounties?.map(
                                (countyName, idx) => (
                                    <TextTag
                                        key={`county_${countyName}_${idx}`}
                                        width="fit-content"
                                        text={countyName}
                                    />
                                ),
                            )}
                        </FlexRow>
                    </StepField>

                    <StepField fieldName="Managed Care Plans Selected for Credentialing">
                        {formData?.contractedPayerIds && (
                            <FlexCol gap={PX.SPACING.PX.XS}>
                                {Object.entries(formData?.contractedPayerIds)
                                    .filter(([, selected]) => selected)
                                    .map(([payerId]) => (
                                        <FONTS.P1 key={payerId}>
                                            {allPayers[payerId]?.name}
                                        </FONTS.P1>
                                    ))}
                            </FlexCol>
                        )}
                    </StepField>

                    <StepField fieldName="Existing Contracts Managed Care Plan">
                        {formData?.preExistingPayerIds &&
                        formData?.preExistingPayerIds.length > 0 ? (
                            <FlexCol gap={PX.SPACING.PX.XS}>
                                {formData?.preExistingPayerIds?.map(
                                    (payerId) => (
                                        <FONTS.P1 key={`payer_name_${payerId}`}>
                                            {allPayers[payerId]?.name}
                                        </FONTS.P1>
                                    ),
                                )}
                            </FlexCol>
                        ) : (
                            <FlexCol gap={PX.SPACING.PX.XS}>
                                <FONTS.P1>
                                    {'I do not have any existing contracts'}
                                </FONTS.P1>
                            </FlexCol>
                        )}
                    </StepField>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[3])) ??
                        false
                    }
                    step={steps?.[3]}
                    onClickEdit={goToStep?.(3)}
                    isFlowComplete={isCompleted}
                >
                    <SubStepReviewInfo
                        subStep={steps?.[3].subSteps?.[0]}
                        onClickEdit={goToSubStep?.(3, 0)}
                        isFlowComplete={isCompleted}
                    >
                        <StepField
                            isUpload={true}
                            fieldName="Medi-Cal Provider Enrollment Approval Letter"
                        >
                            {formData?.mediCalEnrollmentFile &&
                                formData?.mediCalEnrollmentFile.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <FileCardWrapper
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                            tempFileName={temporaryFileName}
                                        />
                                    ),
                                )}
                        </StepField>

                        <StepField isUpload={true} fieldName="CPR Certificate">
                            {formData?.cprCertificate &&
                                formData?.cprCertificate.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <FileCardWrapper
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                    </SubStepReviewInfo>

                    <SubStepReviewInfo
                        subStep={steps?.[3].subSteps?.[1]}
                        onClickEdit={goToSubStep?.(3, 1)}
                        isFlowComplete={isCompleted}
                    >
                        <StepField
                            isUpload={false}
                            fieldName="Pathway"
                            flexBasis="100%"
                        >
                            {formData?.proofOfTrainingPathway && (
                                <FONTS.P1>
                                    {
                                        OnboardingProofOfTrainingPathway[
                                            formData?.proofOfTrainingPathway ??
                                                ''
                                        ]
                                    }
                                </FONTS.P1>
                            )}
                        </StepField>

                        {formData?.proofOfTrainingPathway && (
                            <StepField
                                isUpload={true}
                                fieldName="Documentation"
                                flexBasis="100%"
                            >
                                {(formData?.proofOfTrainingPathway === 'xp'
                                    ? formData?.lettersOfRecommendation?.concat(
                                          formData?.syllabus ?? [],
                                      )
                                    : formData?.syllabus?.concat(
                                          formData?.proofOfTraining ?? [],
                                      )
                                )?.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <FileCardWrapper
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                            </StepField>
                        )}

                        {formData?.proofOfTrainingPathway ===
                            ProofOfTrainingPathway.TRAINING && (
                            <StepField fieldName="Does the certificate(s) of completion meet hours and topics requirements?">
                                {formData?.trainingSyllabusMeetsProofReq ===
                                true ? (
                                    <FONTS.P1>Yes</FONTS.P1>
                                ) : formData?.trainingSyllabusMeetsProofReq ===
                                  false ? (
                                    (formData?.proofOfTraining?.length ?? 0) >
                                    0 ? (
                                        <FONTS.P1>
                                            No, but I have uploaded additional
                                            documentation.
                                        </FONTS.P1>
                                    ) : (
                                        <FlexCol gap={PXSTR.XS}>
                                            <FONTS.P1>No</FONTS.P1>
                                            <ValueMissingWarning textOverride="Please upload additional documentation that meets the hours and training requirements. " />
                                        </FlexCol>
                                    )
                                ) : null}
                            </StepField>
                        )}
                    </SubStepReviewInfo>

                    <SubStepReviewInfo
                        subStep={steps?.[3].subSteps?.[2]}
                        onClickEdit={goToSubStep?.(3, 2)}
                        isFlowComplete={isCompleted}
                    >
                        <StepField
                            isUpload={true}
                            fieldName="Professional Liability Coverage"
                        >
                            {formData?.proofOfLiabilityInsurance &&
                                formData?.proofOfLiabilityInsurance.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <FileCardWrapper
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                        <StepField isUpload={true} fieldName="HIPAA Training">
                            {formData?.proofOfHIPAATraining &&
                                formData?.proofOfHIPAATraining.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <FileCardWrapper
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                    </SubStepReviewInfo>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[4])) ??
                        false
                    }
                    step={steps?.[4]}
                    onClickEdit={goToStep?.(4)}
                    isFlowComplete={isCompleted}
                >
                    {(formData?.resumeFile?.length ?? 0) > 0 && (
                        <StepField isUpload={true} fieldName="Resume">
                            {formData?.resumeFile &&
                                formData?.resumeFile.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <FileCardWrapper
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                    )}
                    {(formData?.resumeItems?.length ?? 0) > 0 && (
                        <FlexRow width="100%" gap={PX.SPACING.PX.S} wrap="wrap">
                            {formData?.resumeItems?.map((resumeItem, idx) => (
                                <ResumeItemCard
                                    key={`resume_item_card_${resumeItem.title}_${idx}`}
                                    flex={1}
                                    flexBasis="100%"
                                    item={resumeItem}
                                />
                            ))}
                        </FlexRow>
                    )}
                    {(formData?.resumeFile?.length ?? 0) === 0 &&
                        (formData?.resumeItems?.length ?? 0) === 0 && (
                            <ValueMissingWarning />
                        )}
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[5])) ??
                        false
                    }
                    step={steps?.[5]}
                    onClickEdit={goToStep?.(5)}
                    isFlowComplete={isCompleted}
                >
                    <FlexRow gap={PX.SPACING.PX.S}>
                        {formData?.identityVerificationStatus &&
                        formData?.identityVerificationStatus !==
                            'needs_input' &&
                        formData?.identityVerificationStatus !== 'abandoned' ? (
                            <ICONS.CircleCheckmark
                                size={16}
                                color={COLORS.UTIL.Green[700]}
                            />
                        ) : (
                            <ICONS.ErrorCircle
                                size={16}
                                color={COLORS.UTIL.Red[700]}
                            />
                        )}
                        <FONTS.P2>
                            {formData?.identityVerificationStatus ===
                                'verified' && 'Identity verified via Stripe'}
                            {formData?.identityVerificationStatus ===
                                'needs_input' &&
                                'Identity verification needs input'}
                            {formData?.identityVerificationStatus ===
                                'initiated' &&
                                'Identity verification initiated'}
                            {(!formData?.identityVerificationStatus ||
                                formData?.identityVerificationStatus ===
                                    'abandoned') &&
                                'Identity not yet verified via Stripe'}
                        </FONTS.P2>
                    </FlexRow>
                </StepReviewInfo>
            </FlexCol>
        </Flex>
    );
};

const ValueMissingWarning = ({
    isUpload = false,
    textOverride,
}: {
    isUpload?: boolean;
    textOverride?: string;
}) => {
    return (
        <FlexRow gap={PX.SPACING.PX.S} align="center">
            <ICONS.WarningCircle color={COLORS.UTIL.Red[700]} size={16} />
            <FONTS.P1 color={COLORS.UTIL.Red[700]}>
                {textOverride ?? `${isUpload ? 'Upload' : 'Response'} Required`}
            </FONTS.P1>
        </FlexRow>
    );
};

const StepField = ({
    fieldName,
    isRequired = true,
    children,
    isUpload = false,
    isFlowComplete = false,
    ...props
}: FlexProps & {
    isUpload?: boolean;
    fieldName: string;
    isRequired?: boolean;
    isFlowComplete?: boolean;
}) => {
    return (
        <FlexCol
            minHeight={PX.SPACING.PX.XXL}
            flex={1}
            flexBasis={'40%'}
            gap={PX.SPACING.PX.XS}
            {...props}
        >
            <FONTS.P2 color={COLORS.STROKES.HEAVY}>
                {fieldName} {isRequired ? '' : '(optional)'}
            </FONTS.P2>
            {!isFlowComplete && isRequired && !children ? (
                <ValueMissingWarning isUpload={isUpload} />
            ) : isUpload ? (
                <FlexRow maxW="100%" gap={PXSTR.S} wrap="wrap">
                    {children}
                </FlexRow>
            ) : (
                children
            )}
        </FlexCol>
    );
};

const StepReviewInfo = ({
    isComplete,
    step,
    onClickEdit,
    children,
    isFlowComplete = false,
    ...props
}: BoxProps & {
    onClickEdit: (() => void) | undefined;
    isComplete: boolean;
    step: ProgressableStep | undefined;
    isFlowComplete?: boolean;
}) => {
    const hasSubsteps = step?.subSteps?.length ?? 0 > 0;
    return (
        <SheetTab padding={PX.SPACING.PX.L} {...props}>
            <FlexCol gap={PX.SPACING.PX.M}>
                {!isComplete && !isFlowComplete && (
                    <FlexRow
                        background={COLORS.UTIL.Red[200]}
                        borderRadius={PX.RADII.MD}
                        padding={PX.SPACING.PX.M}
                        gap={PX.SPACING.PX.M}
                    >
                        <ICONS.WarningCircle
                            style={{ marginTop: PXSTR.XS }}
                            color={COLORS.UTIL.Red[700]}
                            size={16}
                        />
                        <FONTS.P1 color={COLORS.UTIL.Red[800]}>
                            This section is incomplete
                        </FONTS.P1>
                    </FlexRow>
                )}

                <FlexRow justify="space-between">
                    <FONTS.H3>{step?.title}</FONTS.H3>
                    {!hasSubsteps && !isFlowComplete && (
                        <SecondaryButton onClick={onClickEdit}>
                            Edit
                        </SecondaryButton>
                    )}
                </FlexRow>

                <FlexRow wrap="wrap" rowGap={PX.SPACING.PX.M}>
                    {children}
                </FlexRow>
            </FlexCol>
        </SheetTab>
    );
};

const SubStepReviewInfo = ({
    subStep,
    onClickEdit,
    children,
    isFlowComplete = false,
    ...props
}: BoxProps & {
    onClickEdit: (() => void) | undefined;
    subStep: ProgressableStep | undefined;
    isFlowComplete?: boolean;
}) => {
    if (!subStep) return null;

    return (
        <FlexCol gap={PX.SPACING.PX.L} width="100%" {...props}>
            <FlexRow align="center" justify="space-between">
                <FONTS.H4>{subStep.title}</FONTS.H4>
                {!isFlowComplete && (
                    <SecondaryButton onClick={onClickEdit}>
                        Edit
                    </SecondaryButton>
                )}
            </FlexRow>

            <FlexRow wrap="wrap" gap={PX.SPACING.PX.M}>
                {children}
            </FlexRow>
        </FlexCol>
    );
};

export const HiddenTextField = ({ rawText }: { rawText: string }) => {
    const [isShowing, setIsShowing] = useState(false);
    const hiddenText = rawText.replaceAll(/[^\s]/g, '•');

    return (
        <FlexRow align="center" gap={PX.SPACING.PX.S}>
            <FONTS.P1>{isShowing ? rawText : hiddenText}</FONTS.P1>
            <FONTS.P2
                cursor="pointer"
                textDecorationLine="underline"
                color={COLORS.PRIMARY.Blue}
                onClick={() => setIsShowing((prev) => !prev)}
            >
                {isShowing ? 'Hide' : 'View'}
            </FONTS.P2>
        </FlexRow>
    );
};

//TODO-Mina: FileCard should probably be a DS component to standardize
const FileCardWrapper = ({
    tempFileName,
    fileName,
    fileSize,
    key,
}: {
    tempFileName: string;
    fileName: string;
    fileSize: number;
    key: string;
}) => {
    return (
        <Box key={key} flex={1} maxW={Zyg('300px', '380px')}>
            <UploadFileCard
                fileName={fileName}
                fileSize={fileSize}
                tempFileName={tempFileName}
            />
        </Box>
    );
};
