import { useAllClients } from 'hooks/dbHooks/clients.hooks';
import { useNavigate } from 'react-router-dom';
import COLORS from 'ui/colors';
import {
    ActionTypes,
    Table,
    TableCell,
    TableCellTypes,
} from 'ui/components/Table';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';

export const AllClientsTable = () => {
    const { allClients } = useAllClients();

    const navigate = useNavigate();

    const goToClientDetails = (userId: string) => {
        navigate(`/clients/${userId}`);
    };

    const data: TableCell[][] = Object.values(allClients ?? {}).map(
        (clientData) => [
            { cellType: TableCellTypes.USER_TAG, user: clientData.user },
            {
                cellType: TableCellTypes.DEFAULT,
                actions: [
                    {
                        text: 'Go To Client Profile',
                        onClick: () => goToClientDetails(clientData.userId),
                        type: ActionTypes.BUTTON,
                    },
                ],
            },
        ],
    );

    const headers: string[] = ['Client', 'Actions'];

    return (
        <FlexCol
            width="100%"
            height="100%"
            overflow="hidden"
            className="singleClientVisitsTableWrapper"
            gap={PXSTR.S}
        >
            <FONTS.H6 color={COLORS.PRIMARY.Grey}>Visits</FONTS.H6>
            <Table
                id="single_client_visits"
                variant="base"
                headers={headers}
                data={data}
                cardCutoff={659}
            />
        </FlexCol>
    );
};
