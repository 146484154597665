import { useCallback, useState } from 'react';
import { Direction, ScrollPosition } from 'hooks/useCustomElementScrollDetect';
import { usePortalContext } from 'doulaPortal/context/usePortalContext';
import { useOnScrollDirectionChange } from 'hooks/useOnScrollDirectionChange';

/**
 * Gives a ref that should be assigned to a scrollable component if scroll direction change
 * on that component should affect the full-screen behavior of the page
 * Also returns a callback that can directly toggle the full-screen behavior
 */
export const useMobileFullScreenBehavior = () => {
    const portalContext = usePortalContext();
    const { setIsFullScreenMobile, setIsSheetVisible } = portalContext ?? {};

    //once a change is made to direction of scroll, dont accept another one for Xms
    const debounceMS = 250;

    const [scrollTimeout, setScrollTimeout] = useState<number | null>(null);
    const [headerClickTimeout, setHeaderClickTimeout] = useState<number | null>(
        null,
    );
    const onScrollDirectionChange = useCallback(
        (newDirection: Direction, _newPosition: ScrollPosition) => {
            if (scrollTimeout !== null) return;

            setScrollTimeout(
                setTimeout(() => {
                    setScrollTimeout(null);
                }, debounceMS),
            );

            setIsFullScreenMobile?.(newDirection === Direction.Down);
        },
        [scrollTimeout, setIsFullScreenMobile],
    );

    const { customElementRef } = useOnScrollDirectionChange(
        onScrollDirectionChange,
    );

    const toggleIsFullScreen = useCallback(() => {
        if (headerClickTimeout !== null) return;
        setHeaderClickTimeout(
            setTimeout(() => {
                setHeaderClickTimeout(null);
            }, debounceMS),
        );

        setIsSheetVisible?.(true);
        setIsFullScreenMobile?.((prev) => !prev);
    }, [headerClickTimeout, setIsFullScreenMobile, setIsSheetVisible]);

    return { scrollElementRef: customElementRef, toggleIsFullScreen };
};
