import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from 'api/API';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';
import { VerificationStatus } from 'models/constants/clientConstants';
import {
    InsuranceModel,
    PatientModel,
    PatientReturnType,
    PractionerPatientReturnType,
    PregnancyModel,
    UserModel,
} from 'models/schemas';
import { useState, useEffect, useCallback } from 'react';

export type ClientUpdaters = {
    updatePatientPregnancy: (
        updatePatientData: Partial<PregnancyModel>,
    ) => Promise<void>;
    updatePatientInsurance: (
        updatePatientUserData: Partial<UserModel>,
    ) => Promise<void>;
    updatePatientUser: (
        updatePatientUserData: Partial<UserModel>,
    ) => Promise<void>;
};

export function useAllClients() {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoaded, setIsLoaded] = useState(false);
    const [allClients, setAllClients] = useState<
        Record<string, PatientReturnType>
    >({});

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/patients`)
                .then((res): void => {
                    const patientsArr = res.data;
                    const patientsMap: Record<string, PatientReturnType> = {};
                    patientsArr.forEach(
                        (patient: PractionerPatientReturnType) => {
                            patientsMap[patient.patientUserId] =
                                patient.patient;
                        },
                    );
                    setAllClients(patientsMap);
                    setIsLoaded(true);
                });
        })();
    }, [getAccessTokenSilently]);

    return {
        allClients,
        isLoaded,
    };
}

export function useClient(clientId: string | undefined) {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoaded, setIsLoaded] = useState(false);
    const [client, setClient] = useState<PatientReturnType | null>(null);

    const updatePatientUser = useCallback(
        async (updatePatientUserData: Partial<UserModel>) => {
            if (!clientId) return;

            const token = await getAccessTokenSilently({});
            await authorize(token)
                .patch(`/patients/${clientId}/user`, updatePatientUserData)
                .then((_res): void => {
                    // console.log('SERVER RESPONSE', res);
                });
        },
        [clientId, getAccessTokenSilently],
    );

    const updatePatientPregnancy = useCallback(
        async (updatePatientData: Partial<PregnancyModel>) => {
            if (!clientId) return;
            // console.log('updated', updatePatientData);

            const token = await getAccessTokenSilently({});
            await authorize(token)
                .patch(`/patients/${clientId}/pregnancy`, updatePatientData)
                .then((_res): void => {
                    //console.log('SERVER RESPONSE', res);
                });
        },
        [clientId, getAccessTokenSilently],
    );

    const updatePatientInsurance = useCallback(
        async (updatePatientUserData: Partial<UserModel>) => {
            if (!clientId) return;

            const token = await getAccessTokenSilently({});
            await authorize(token)
                .patch(`/patients/${clientId}/insurance`, updatePatientUserData)
                .then((_res): void => {
                    //console.log('SERVER RESPONSE', res);
                });
        },
        [clientId, getAccessTokenSilently],
    );

    const refreshClientData = useCallback(async () => {
        if (!clientId) return;

        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/patients/${clientId}`)
            .then((res): void => {
                setClient(res.data);
                setIsLoaded(true);
            });
    }, [clientId, getAccessTokenSilently]);

    useEffect(() => {
        (async () => {
            if (isLoaded) return;
            await refreshClientData();
        })();
    }, [isLoaded, refreshClientData]);

    return {
        client,
        refreshClientData,
        isLoaded,
        updatePatientPregnancy,
        updatePatientInsurance,
        updatePatientUser,
    };
}

export type createPatientDTO = Omit<
    PatientModel,
    'createdAt' | 'userId' | 'externalPatientId'
>;

export type createUserDTO = Partial<
    Omit<
        UserModel,
        | 'id'
        | 'externalId'
        | 'stripePayoutsEnabled'
        | 'createdAt'
        | 'stripeAccountId'
    >
>;

export type createPregnancyDTO = Partial<
    Omit<PregnancyModel, 'id' | 'createdAt' | 'userId'>
>;

export type createInsuranceDTO = Partial<
    Omit<InsuranceModel, 'id' | 'patientUserId' | 'externalPatientId'>
>;

export const useCreateClient = () => {
    const { getAccessTokenSilently } = useAuth0();

    const createClient = useCallback(
        async (
            user: createUserDTO,
            patient: createPatientDTO,
            pregnancy: createPregnancyDTO,
            insurances: createInsuranceDTO[],
        ): Promise<{ newClientId?: string; errorMsg?: string }> => {
            const token = await getAccessTokenSilently({});
            return authorize(token)
                .post(`/patients`, {
                    patient,
                    user,
                    pregnancy,
                    insurances,
                })
                .then((res) => {
                    return { newClientId: res.data };
                })
                .catch((e) => {
                    throw new Error(e.response.data.message);
                });
        },
        [getAccessTokenSilently],
    );

    return {
        createClient,
    };
};

export const useIsInsuranceVerificationUnderReview = () => {
    const ctx = useClientDetailsContext();
    const { client } = ctx ?? {};

    const isUnderReview = isInsuranceVerificationStatusUnderReview(client);

    return isUnderReview;
};

export const isInsuranceVerificationStatusUnderReview = (
    client: PatientReturnType | undefined | null,
) => {
    return (
        client?.patientInsurance.some(
            (insurance) =>
                insurance.verificationStatus === VerificationStatus.UnderReview,
        ) ?? false
    );
};
