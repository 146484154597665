import { createContext, useContext } from 'react';
import { SimpleFlowContextType } from './simpleFlow.models';

export const SimpleFlowContext = createContext(
    {} as SimpleFlowContextType | undefined,
);

export const useSimpleFlowContext = () => {
    const ctx = useContext(SimpleFlowContext);
    return ctx;
};
