import {
    EditClientDrawer,
    EditClientDrawerProps,
} from './EditClient/EditClientDrawer';
import {
    OffPlatformVisits,
    OffPlatformVisitsProps,
} from './OffPlatformVisits/OffPlatformVisits';
import { TestDrawer, TestDrawerProps } from './TestDrawer';

/*✨ A few ez steps to get typescript magic if your drawer needs props ✨
    When you add a drawer, give it an id in AllDrawersEnum (eg 'createVisit')
    Then add the component to ALL_DRAWERS
    Import the drawer's props if any
    Define the interface with the same structure as the others, and add to the FullDrawerUnion

    Then, when you call openDrawer({drawerId: someGreatId, props: ...}) typescript will make sure you pass
    the props that someGreatIdDrawer expects!
*/

export enum AllDrawersEnum {
    editClient = 'editClient',
    offPlatformVisits = 'offPlatformVisits',
    test = 'test',
}

export const ALL_DRAWERS = {
    [AllDrawersEnum.editClient]: EditClientDrawer,
    [AllDrawersEnum.test]: TestDrawer,
    [AllDrawersEnum.offPlatformVisits]: OffPlatformVisits,
};

interface OffPlatformVisitInterface {
    drawerId: AllDrawersEnum.offPlatformVisits;
    props: OffPlatformVisitsProps;
}

interface EditClientInterface {
    drawerId: AllDrawersEnum.editClient;
    props: EditClientDrawerProps;
}

interface TestDrawerInterface {
    drawerId: AllDrawersEnum.test;
    props: TestDrawerProps;
}

export type FullDrawerUnion =
    | EditClientInterface
    | TestDrawerInterface
    | OffPlatformVisitInterface;

export type OpenDrawerProps = FullDrawerUnion & {
    shouldNavigate?: boolean;
    baseUrl?: string;
};
