import { Center, Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

interface LoadingProps {
    // Add any props you need for your component here
}

const Loading: React.FC<LoadingProps> = () => {
    return (
        <Flex
            className="loading-screen"
            width={'100vw'}
            height={'100dvh'}
            alignContent={'center'}
            justifyContent={'center'}
        >
            <Center>
                <Spinner size="xl" />
            </Center>
        </Flex>
    );
};

export default Loading;
