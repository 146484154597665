import { forwardRef } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { TextInput, TextInputProps } from 'ui/components/TextInput';
import {
    FieldErrors,
    FieldValues,
    UseFormRegisterReturn,
    ValidationRule,
} from 'react-hook-form';
import { ReadableFromCamelCase } from 'util/Utils';
import FONTS from 'ui/fonts';
import COLORS from 'ui/colors';
import PX from 'ui/px';

export const getFormTextInputRegisterProps = ({
    fieldName,
    required = true,
    minLength = 1,
    requiredWarningText,
    validationRules,
}: {
    fieldName?: string;
    required?: boolean;
    minLength?: number;
    requiredWarningText?: string;
    validationRules?: Record<string, ValidationRule>;
}) => {
    const res = {
        minLength: {
            value: minLength,
            message:
                requiredWarningText ?? `Minimum length must be ${minLength}`,
        },
        required: {
            value: required ?? false,
            message:
                requiredWarningText ??
                `${ReadableFromCamelCase(fieldName)} is required`,
        },
    };
    if (validationRules) {
        Object.assign(res, validationRules);
    }
    return res;
};

type FormTextInputProps = TextInputProps & {
    registerProps: UseFormRegisterReturn | undefined;
    fieldName: string;
    errors: FieldErrors<FieldValues> | undefined;
};
/**
 * Our go-to input component when building forms
 * NOTE: If using a masked field that is also secret (eg SSN), pass setValue
 * (TODO-Mina: separate out masked form input from free-input)
 */
export const NewFormTextInput = forwardRef<TextInputProps, FormTextInputProps>(
    ({ fieldName, errors, subLabel, registerProps, ...inputProps }, ref) => {
        return (
            <FormControl flex={1} isInvalid={!!errors?.[fieldName]}>
                <FormLabel display="none" htmlFor={fieldName}>
                    {inputProps.label}
                </FormLabel>
                <TextInput
                    id={`${fieldName}TextInput`}
                    subLabel={errors?.[fieldName] ? undefined : subLabel}
                    {...registerProps}
                    {...inputProps}
                />
                <FONTS.P2
                    color={COLORS.PRIMARY.Error}
                    minHeight={PX.SPACING.PX.M}
                    mt={PX.SPACING.PX.S}
                >
                    {errors?.[fieldName] &&
                        errors?.[fieldName]?.message?.toString()}
                </FONTS.P2>
            </FormControl>
        );
    },
);
