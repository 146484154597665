//TODO a lot of these enums are duplicated from backend visit.models.ts,
//how can we easily share enums bc this will get outta hand? me no likey

export enum VisitType {
    InitialPrenatal = 'initial_prenatal',
    InitialPostpartum = 'initial_postpartum',
    Prenatal = 'prenatal',
    Postpartum = 'postpartum',
    ExtendedPostpartum = 'extended_postpartum',
    VaginalDelivery = 'vaginal_delivery',
    VaginalDeliveryAfterCSection = 'vaginal_delivery_after_csection',
    CSection = 'csection',
    Miscarriage = 'miscarriage',
    Abortion = 'abortion',
}

export enum VisitMedium {
    InPerson = 'In Person',
    Audio = 'Audio',
    Video = 'Video',
}

export enum VisitLocation {
    ClientHome = 'Client Home',
    Office = 'Office',
    MedicalFacility = 'Medical Facility',
    Other = 'Other',
}

export enum VisitService {
    PerinatalSupport = 'Perinatal Support & Guidance',
    HealthNavigation = 'Health Navigation',
    EducationAndPractices = 'Evidence-based education and practices',
    BirthPlan = 'Development of Birth Plan',
    CommunityResources = 'Community Resource',
    Comfort = 'Label/Delivery Comfort measures',
    NonMedicalSupport = 'Nonmedical Support',
    LactationSupport = 'Lactation Support',
}

export enum PregnancyConclusionTypes {
    LaborDelivery = 'Labor & Delivery',
    Miscarriage = 'Miscarriage',
    Abortion = 'Abortion',
}

export enum DeliveryTypes {
    Vaginal = 'Vaginal_Delivery',
    VaginalAfterCSection = 'Vaginal_Delivery_After_CSection',
    CSection = 'Caesarean_Section',
}

//Above enums are dupes of whats in visit.models.ts in backend, me no likey

export enum VisitCategory {
    Initial = 'Initial, 90-min',
    Perinatal = 'Perinatal',
    LaborDelivery = 'Labor & Delivery',
    PostpartumExtended = 'Postpartum, Extended',
}

export enum PerinatalVisitTypes {
    Prenatal = 'Prenatal Visit',
    Postpartum = 'Postpartum Visit',
}

export const DeliveryTypeCopy = {
    [DeliveryTypes.Vaginal]: 'Vaginal delivery',
    [DeliveryTypes.VaginalAfterCSection]:
        'Vaginal delivery after previous Caesarean section',
    [DeliveryTypes.CSection]: 'Caesarean Section',
};

export const VisitTypeCopy = {
    [VisitType.InitialPrenatal]: 'Initial Visit, Prenatal',
    [VisitType.InitialPostpartum]: 'Initial Visit, Postpartum ',
    [VisitType.Prenatal]: 'Perinatal Visit, Prenatal',
    [VisitType.Postpartum]: 'Perinatal Visit, Postpartum',
    [VisitType.ExtendedPostpartum]: 'Extended Postpartum Visit',
    [VisitType.VaginalDelivery]: 'Vaginal Delivery',
    [VisitType.VaginalDeliveryAfterCSection]:
        'Vaginal Delivery After C-Section',
    [VisitType.CSection]: 'C-Section',
    [VisitType.Miscarriage]: 'Miscarriage',
    [VisitType.Abortion]: 'Abortion',
};

export const VisitMediumCopy = {
    [VisitMedium.Audio]: { label: 'Audio-only call', desc: 'Explanation here' },
    [VisitMedium.Video]: {
        label: 'Audio & Video call',
        desc: 'Explanation here',
    },
    [VisitMedium.InPerson]: { label: 'In-person', desc: 'Explanation here' },
};

export const VisitLocationCopy = {
    [VisitLocation.ClientHome]: {
        label: 'Client’s Home',
        desc: 'Explanation here',
    },
    [VisitLocation.Office]: {
        label: 'My Office',
        desc: 'Explanation here',
    },
    [VisitLocation.MedicalFacility]: {
        label: 'Medical Facility (including hospital or birthing center)',
        desc: 'Explanation here',
    },
    [VisitLocation.Other]: {
        label: 'Other',
        desc: 'Explanation here',
    },
};

type DecoratedCopyData = {
    label: string;
    desc: string;
};

type ServicesCopyDict = Partial<Record<VisitService, DecoratedCopyData>>;

const BaseServices: ServicesCopyDict = {
    [VisitService.NonMedicalSupport]: {
        label: 'Nonmedical Support',
        desc: 'Physical, emotional, and other nonmedical support',
    },
};

export const PerinatalServices: ServicesCopyDict = {
    ...BaseServices,
    [VisitService.PerinatalSupport]: {
        label: 'Perinatal support and guidance',
        desc: 'Explanation',
    },
    [VisitService.HealthNavigation]: {
        label: 'Health Navigation',
        desc: 'Explanation',
    },
    [VisitService.EducationAndPractices]: {
        label: 'Evidence-based education and practices',
        desc: 'Explanation',
    },
    [VisitService.CommunityResources]: {
        label: 'Linkages to community-based resources',
        desc: 'Explanation',
    },
};

const PostPartumServicesCopy: ServicesCopyDict = {
    ...PerinatalServices,
    [VisitService.LactationSupport]: {
        label: 'Lactation support measures',
        desc: 'Description here',
    },
};

const PrenatalServicesCopy: ServicesCopyDict = {
    ...PerinatalServices,
    [VisitService.BirthPlan]: {
        label: 'Development of a birth plan',
        desc: 'Explanation',
    },
};

const LaborDeliveryServicesCopy: ServicesCopyDict = {
    ...BaseServices,
    [VisitService.Comfort]: {
        label: 'Comfort measures',
        desc: 'Comfort measures during L&D / during or after abortion / during or after miscarriage',
    },
};

export const VisitServicesCopy = {
    [PerinatalVisitTypes.Prenatal]: PrenatalServicesCopy,
    [PerinatalVisitTypes.Postpartum]: PostPartumServicesCopy,
    [VisitCategory.LaborDelivery]: LaborDeliveryServicesCopy,
    [VisitCategory.PostpartumExtended]: PostPartumServicesCopy,
};

export const DeliveryConclusionDesc = {
    [PregnancyConclusionTypes.Miscarriage]: 'Miscarriage Desc',
    [PregnancyConclusionTypes.Abortion]: 'Abortion Desc',
    [DeliveryTypes.Vaginal]: 'Desc',
    [DeliveryTypes.VaginalAfterCSection]: 'Desc',
    [DeliveryTypes.CSection]: 'Desc',
};

export const PregnancyConclusionOptions = [
    {
        value: PregnancyConclusionTypes.LaborDelivery,
        label: 'Labor & Delivery (including stillbirth)',
    },
    {
        value: PregnancyConclusionTypes.Miscarriage,
        label: 'Miscarriage',
    },
    {
        value: PregnancyConclusionTypes.Abortion,
        label: 'Abortion',
    },
];

export const VisitCategoryDescription: Record<VisitCategory, string> = {
    [VisitCategory.Initial]:
        '90-minute initial visit. If claimed, must be the earliest visit claimed. ',
    [VisitCategory.Perinatal]:
        'Prenatal and Postpartum visits. Duration of visit does not affect pay. ',
    [VisitCategory.LaborDelivery]:
        'Support during labor & delivery; support during or after abortion or miscarriage',
    [VisitCategory.PostpartumExtended]:
        'Timed postpartum visits, up to 3 hours in 15 minute increments. ',
};

export const VisitTypeToCategory: Record<VisitType, VisitCategory> = {
    [VisitType.Abortion]: VisitCategory.LaborDelivery,
    [VisitType.VaginalDelivery]: VisitCategory.LaborDelivery,
    [VisitType.CSection]: VisitCategory.LaborDelivery,
    [VisitType.VaginalDeliveryAfterCSection]: VisitCategory.LaborDelivery,
    [VisitType.Miscarriage]: VisitCategory.LaborDelivery,

    [VisitType.InitialPrenatal]: VisitCategory.Initial,
    [VisitType.InitialPostpartum]: VisitCategory.Perinatal,

    [VisitType.Prenatal]: VisitCategory.Perinatal,
    [VisitType.Postpartum]: VisitCategory.Perinatal,
    [VisitType.ExtendedPostpartum]: VisitCategory.PostpartumExtended,
};

export const NumVisitsPerVisitCategory: Record<VisitCategory, number> = {
    [VisitCategory.Initial]: 1,
    [VisitCategory.Perinatal]: 8,
    [VisitCategory.LaborDelivery]: 1,
    [VisitCategory.PostpartumExtended]: 2,
};

export const NumAdditionalPostpartumVisits = 8;
