import { Box } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { FlexRow } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import { StepStatus } from './simpleFlow.models';

export const MinimalProgressIndicator = ({
    stepStatuses,
    activeStepIdx,
    onClickStep,
}: {
    activeStepIdx: number | undefined;
    stepStatuses: Record<number, StepStatus> | undefined;
    onClickStep: ((idx: number) => void) | undefined;
}) => {
    return (
        <FlexRow gap={PXSTR.XS} paddingY={PXSTR.S}>
            {Object.values(stepStatuses ?? {}).map((status, idx) => (
                <ThinPill
                    key={`thin_pill_progress_step_${idx}`}
                    color={
                        idx <= (activeStepIdx ?? 0)
                            ? COLORS.PRIMARY.Blue
                            : status.seen
                              ? COLORS.UTIL.Gray[600]
                              : COLORS.UTIL.Gray[100]
                    }
                    onClick={
                        idx !== activeStepIdx && status.seen
                            ? () => onClickStep?.(idx)
                            : undefined
                    }
                />
            ))}
        </FlexRow>
    );
};

const ThinPill = ({
    color = COLORS.UTIL.Gray[100],
    onClick,
}: {
    color?: string;
    onClick?: () => void;
}) => {
    return (
        <Box
            borderRadius={PX.RADII.MD}
            height={PXSTR.S}
            background={color}
            transition="all 0.3s"
            flex={1}
            cursor={!!onClick ? 'pointer' : 'default'}
            onClick={onClick}
        />
    );
};
