import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { ICONS } from 'ui/icons';
import { Link } from 'react-router-dom';
import FONTS from 'ui/fonts';

export const Breadcrumbs = ({
    crumbs,
}: {
    crumbs: { label: string; navLink: string }[];
}) => {
    return (
        <Breadcrumb
            separator={<ICONS.ChevronRight color={COLORS.PRIMARY.Grey} />}
        >
            {crumbs.map((crumb, idx) => (
                <BreadcrumbItem
                    key={`breadcrumb_${idx}`}
                    isCurrentPage={idx === crumbs.length - 1}
                >
                    <BreadcrumbLink as={Link} to={crumb.navLink}>
                        <FONTS.P2
                            color={
                                idx === crumbs.length - 1
                                    ? COLORS.PRIMARY.Blue
                                    : undefined
                            }
                            fontWeight={idx === crumbs.length - 1 ? 700 : 400}
                        >
                            {crumb.label}
                        </FONTS.P2>
                    </BreadcrumbLink>
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};
