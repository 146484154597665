import { Image, Spinner } from '@chakra-ui/react';
import { UploaderFile } from './fileUpload.models';
import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from 'api/API';
import { FlexCol } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import COLORS from 'ui/colors';
import { SecondaryButton } from 'ui/components/Button';
import { FileUpload } from 'models/schemas';

export const usePreviewUploadedFile = ({
    files,
    preloadedPreviewSrc,
    showFirst = true,
}: {
    files: FileUpload[] | undefined;
    preloadedPreviewSrc?: string | undefined;
    showFirst?: boolean;
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [previewSrc, setPreviewSrc] = useState<string | undefined>(
        preloadedPreviewSrc,
    );

    const getLink = useCallback(async () => {
        if (files?.length === 0 || !showFirst) return null;

        const fileName = files?.[0].temporaryFileName;

        if (!fileName) return;

        const token = await getAccessTokenSilently();

        await authorize(token)
            .get('/providers/view-temp-file', {
                params: {
                    fileName,
                },
            })
            .then((res) => setPreviewSrc(res.data.url));
    }, [files, getAccessTokenSilently, showFirst]);

    const resetSrc = () => {
        setPreviewSrc(undefined);
    };

    useEffect(() => {
        getLink();
    }, [getLink]);

    return {
        previewSrc,
        resetSrc,
    };
};

export const UploadedFilePreview = ({
    files,
    preloadedPreviewSrc,
    onReset,
    showFirst = true,
    resetText = 'Re-upload',
}: {
    files: UploaderFile[];
    preloadedPreviewSrc?: string | undefined;
    onReset?: () => void;
    showFirst?: boolean; //TODO: if false, show multiples but needs designs
    resetText?: string;
}) => {
    const { previewSrc, resetSrc } = usePreviewUploadedFile({
        files,
        preloadedPreviewSrc,
        showFirst,
    });

    const reset = () => {
        resetSrc();
        onReset?.();
    };

    const isLoading = !previewSrc && !preloadedPreviewSrc;

    if (!preloadedPreviewSrc && !files.length) return null;

    return (
        <FlexCol
            borderRadius={PX.RADII.MD}
            background={COLORS.BRAND.Periwinkle[200]}
            paddingY={PXSTR.L}
            gap={PXSTR.M}
            alignItems="center"
        >
            {isLoading ? (
                <Spinner size="xl" />
            ) : (
                <>
                    <Image
                        src={preloadedPreviewSrc ?? previewSrc}
                        width="200px"
                        height="200px"
                    />
                    {onReset && (
                        <SecondaryButton onClick={reset}>
                            {resetText}
                        </SecondaryButton>
                    )}
                </>
            )}
        </FlexCol>
    );
};
