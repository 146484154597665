import { FormTextInput } from 'ui/components/Form/FormTextInput';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { DrawerFormLayout } from './DrawerFormLayout';
import { useEditClientDrawerContext } from './editClientDrawer.context';

const useContactTab = () => {
    const ctxt = useEditClientDrawerContext();
    const { register, formState, setValue, watch } = ctxt ?? {};
    const { errors } = formState ?? {};
    return {
        register,
        setValue,
        watch,
        errors,
    };
};

export const ContactTab = () => {
    const { errors, register } = useContactTab();

    return (
        <DrawerFormLayout title="Contact Information">
            <FlexCol gap={PXSTR.L} maxWidth="320px">
                <FormTextInput
                    maxWidth="320px"
                    label="Phone Number"
                    fieldName="user.phone"
                    register={register}
                    errors={errors}
                />
                <FormTextInput
                    label="Email Address"
                    fieldName="user.email"
                    register={register}
                    errors={errors}
                />
            </FlexCol>
        </DrawerFormLayout>
    );
};
