import {
    CreateToastFnReturn,
    Spacer,
    useToast as useStandardToast,
    UseToastOptions,
} from '@chakra-ui/react';
import { FlexRow } from './loulaFlex';
import FONTS from './fonts';
import COLORS from './colors';
import PX from './px';
import { IconButton } from './components/IconButton';
import useScreenSize from 'hooks/useScreenSize';
import { ReactNode } from 'react';

export const useToast = (options?: UseToastOptions): CreateToastFnReturn => {
    const { isNarrowScreen } = useScreenSize();

    const standardToast = useStandardToast({
        ...options,
        containerStyle: isNarrowScreen
            ? {
                  marginBottom: '75px',
              }
            : undefined,
        render: (renderProps) => (
            <StyledToast
                description={renderProps.description}
                onClose={renderProps.onClose}
            />
        ),
    });

    return standardToast;
};

export const StyledToast = ({
    description,
    onClose,
}: {
    description: ReactNode;
    onClose: () => void;
}) => {
    return (
        <FlexRow
            borderRadius={PX.RADII.MD}
            padding={PX.SPACING.REM.SM}
            background={COLORS.UTIL.Gray[900]}
            align="center"
            justify="space-between"
        >
            <FONTS.P1 fontWeight={600} color={COLORS.UTIL.Gray.WARM}>
                {description}
            </FONTS.P1>
            <Spacer minW={PX.SPACING.PX.M} flex={1} />
            <IconButton
                color={COLORS.UTIL.Gray.WHITE}
                hoverColor={COLORS.UTIL.Gray[300]}
                activeColor={COLORS.UTIL.Gray[400]}
                aria-label="Close"
                icon="Close"
                onClick={onClose}
            />
        </FlexRow>
    );
};

export const StyledToastForSB = ({
    description,
    onClose,
}: {
    description: string;
    onClose: () => void;
}) => {
    return <StyledToast description={description} onClose={onClose} />;
};
