import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from 'api/API';
import {
    OffPlatformVisitModel,
    VisitModel,
    VisitReturnType,
} from 'models/schemas';
import { useState, useEffect, useCallback } from 'react';

export function useClientVisits(clientId: string | undefined) {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoaded, setIsLoaded] = useState(false);
    const [allVisits, setAllVisits] = useState<Record<string, VisitReturnType>>(
        {},
    );

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            if (!clientId) return;

            authorize(token)
                .get(`/patients/${clientId}/visits`)
                .then((res): void => {
                    const visitsArr = res.data;
                    const visitsMap: Record<string, VisitReturnType> = {};
                    visitsArr.forEach((visit: VisitReturnType) => {
                        visitsMap[visit.id] = visit;
                    });
                    setAllVisits(visitsMap);
                    setIsLoaded(true);
                });
        })();
    }, [clientId, getAccessTokenSilently]);

    return {
        allVisits,
        isLoaded,
    };
}

export const useVisits = () => {
    const { getAccessTokenSilently } = useAuth0();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [allVisits, setAllVisits] = useState<Record<string, VisitModel>>({});
    const [isLoaded, setIsLoaded] = useState(false);

    const refreshVisitsData = useCallback(async () => {
        //TODO: pull the visits data for this practictioner I guess
    }, []);

    const createVisit = useCallback(
        async (newVisit: Partial<VisitModel>): Promise<string> => {
            const token = await getAccessTokenSilently();

            const resp = await authorize(token).post(
                '/visits/create',
                newVisit,
            );

            refreshVisitsData();
            return resp.data;
        },
        [getAccessTokenSilently, refreshVisitsData],
    );

    useEffect(() => {
        if (isLoaded) return;
        (async () => {
            await refreshVisitsData();
            setIsLoaded(true);
        })();
    }, [isLoaded, refreshVisitsData]);

    return {
        isLoaded,
        allVisits,
        createVisit,
    };
};

export const useSingleVisit = (visitId: string | undefined) => {
    const { getAccessTokenSilently } = useAuth0();

    const [visit, setVisit] = useState<VisitModel | null>(null);

    const refreshVisitData = useCallback(async () => {
        if (!visitId) return;

        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/visits/${visitId}`)
            .then((res): void => {
                setVisit(res.data);
            });
    }, [getAccessTokenSilently, visitId]);

    useEffect(() => {
        if (!visitId) return;
        (async () => {
            await refreshVisitData();
        })();
    }, [refreshVisitData, visitId]);

    return {
        isLoaded: visit !== null,
        visit,
    };
};

export function useOffPlatformVisits(clientId: string | undefined) {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoaded, setIsLoaded] = useState(false);
    const [allVisits, setAllVisits] = useState<
        Record<string, OffPlatformVisitModel>
    >({});

    const refreshVisitsData = useCallback(async () => {
        const token = await getAccessTokenSilently({});
        if (!clientId) return;

        authorize(token)
            .get(`/patients/${clientId}/externalVisits`)
            .then((res): void => {
                const visitsArr = res.data;
                const visitsMap: Record<string, OffPlatformVisitModel> = {};
                visitsArr.forEach((visit: OffPlatformVisitModel) => {
                    visitsMap[visit.id] = visit;
                });
                setAllVisits(visitsMap);
                setIsLoaded(true);
            });
    }, [clientId, getAccessTokenSilently]);

    useEffect(() => {
        if (isLoaded) return;
        refreshVisitsData();
    }, [clientId, getAccessTokenSilently, isLoaded, refreshVisitsData]);

    const createVisit = useCallback(
        async (newVisit: Partial<OffPlatformVisitModel>): Promise<string> => {
            const token = await getAccessTokenSilently();

            const resp = await authorize(token).post(
                '/visits/createExternal',
                newVisit,
            );

            refreshVisitsData();
            return resp.data;
        },
        [getAccessTokenSilently, refreshVisitsData],
    );

    return {
        allVisits,
        isLoaded,
        createVisit,
    };
}

export const useSingleOffPlatformVisit = (visitId: string | undefined) => {
    const { getAccessTokenSilently } = useAuth0();

    const [offPlatformVisit, setOffPlatformVisit] =
        useState<OffPlatformVisitModel | null>(null);

    const refreshVisitData = useCallback(async () => {
        if (!visitId) return;

        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/visits/external/${visitId}`)
            .then((res): void => {
                setOffPlatformVisit(res.data);
            });
    }, [getAccessTokenSilently, visitId]);

    const updateOffPlatformVisit = async (
        data: Partial<OffPlatformVisitModel>,
    ) => {
        const token = await getAccessTokenSilently();
        const resp = await authorize(token).patch(
            `/visits/external/${visitId}`,
            data,
        );

        refreshVisitData();
        return resp.data;
    };

    const deleteOffPlatformVisit = async () => {
        const token = await getAccessTokenSilently();
        authorize(token).post(`/visits/deleteExternal/${visitId}`);
    };

    useEffect(() => {
        if (!visitId) return;
        (async () => {
            await refreshVisitData();
        })();
    }, [refreshVisitData, visitId]);

    return {
        isLoaded: offPlatformVisit !== null,
        offPlatformVisit,
        updateOffPlatformVisit,
        deleteOffPlatformVisit,
    };
};
