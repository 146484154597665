import { VerificationStatus } from 'models/constants/clientConstants';
import { TextTag, TextTagColorSchemes } from 'ui/components/TextTag';
import { isInStringEnum } from 'util/Utils';

const VariantMapping = {
    [VerificationStatus.Approved]: TextTagColorSchemes.GREEN_HEAVY,
    [VerificationStatus.Unverified]: TextTagColorSchemes.DEFAULT,
    [VerificationStatus.UnderReview]: TextTagColorSchemes.ORANGE_LIGHT,
    [VerificationStatus.Inactive]: TextTagColorSchemes.RED_LIGHT,
};

export const InsuranceVerificationStatusTag = ({
    status,
}: {
    status: string | undefined;
}) => {
    if (!status) return null;
    if (!isInStringEnum(status, VerificationStatus)) return;

    return (
        <TextTag
            text={status}
            colorScheme={VariantMapping[status as VerificationStatus]}
        />
    );
};
