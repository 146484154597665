import { BoxProps, chakra } from '@chakra-ui/react';
import { useState } from 'react';
import COLORS from 'ui/colors';
import { ICONS } from 'ui/icons';
import PX, { PXSTR } from 'ui/px';
import { DefaultFocusStyle } from 'ui/styling';

export enum IconButtonType {
    base = 'base',
    decorated = 'decorated',
}

export const IconButtonSizing = {
    [IconButtonType.decorated]: {
        background: COLORS.UTIL.Gray.WHITE,
        width: `32px`,
        height: `32px`,
        border: `1px solid ${COLORS.UTIL.Gray[600]}`,
        size: '16px',
        padding: '7px',
        color: COLORS.PRIMARY.Blue,
        _hover: {
            background: COLORS.BRAND.Periwinkle[100],
        },
    },
    [IconButtonType.base]: {
        background: 'transparent',
        width: `32px`,
        height: `32px`,
        border: 'transparent',
        size: `20px`,
        padding: PXSTR.XS,
        color: COLORS.UTIL.Gray[900],
    },
};

export const IconButton = ({
    iconBtnType = IconButtonType.base,
    padded = true,
    icon,
    color,
    hoverColor,
    activeColor,
    size,
    ...props
}: BoxProps & {
    iconBtnType?: IconButtonType;
    size?: number;
    icon: keyof typeof ICONS;
    color?: string;
    hoverColor?: string;
    activeColor?: string;
    padded?: boolean;
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);

    const focusStyles = {
        background: `${COLORS.BRAND.Periwinkle[800]}20`,
        ...DefaultFocusStyle,
    };

    const IconComponent = ICONS[icon];

    return (
        <chakra.button
            display="flex"
            _hover={focusStyles}
            _focusVisible={focusStyles}
            role="button"
            tabIndex={0}
            borderRadius={PX.RADII.ROUNDED}
            transition="all 0.3s"
            cursor="pointer"
            css={{ WebkitTapHighlightColor: 'transparent' }}
            justifyContent="center"
            alignItems="center"
            {...IconButtonSizing[iconBtnType]}
            {...(!padded
                ? { padding: 0, width: `${size}px`, height: `${size}px` }
                : {})}
            _active={{ transform: 'scale(0.9)' }}
            {...props}
        >
            <IconComponent
                size={size ?? IconButtonSizing[iconBtnType].size}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => {
                    setIsHovered(false);
                    setIsActive(false);
                }}
                onMouseDown={() => setIsActive(true)}
                onMouseUp={() => setIsActive(false)}
                color={color ?? IconButtonSizing[iconBtnType].color}
                style={{
                    transition: 'all 0.3s',
                    background: 'transparent',
                    ...(isActive
                        ? {
                              color: activeColor ?? color,
                          }
                        : isHovered
                          ? { color: hoverColor ?? color }
                          : {
                                color: color,
                            }),
                }}
            />
        </chakra.button>
    );
};
