import useScreenSize from 'hooks/useScreenSize';
import { ReactElement } from 'react';
import COLORS from 'ui/colors';
import { Avatar } from 'ui/components/Avatar';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';

export const HEADER_MIN_HEIGHT = 80;

export const PageHeader = ({
    headerText,
    headerComponent,
    actionBtns,
    onMobileClick,
}: {
    headerText?: string;
    headerComponent?: ReactElement;
    actionBtns?: ReactElement[];
    onMobileClick: () => void;
}) => {
    const { isNarrowScreen } = useScreenSize();

    return (
        <FlexRow
            width="100%"
            className="pageHeader"
            height="56px"
            justifyContent="space-between"
            alignSelf="start"
            alignContent="center"
            paddingX={PXSTR.L}
            paddingY={PXSTR.S}
            onClick={isNarrowScreen ? onMobileClick : undefined}
            onTouchStart={isNarrowScreen ? onMobileClick : undefined}
            background={COLORS.UTIL.Gray.WHITE}
            borderTopRadius="inherit"
            borderBottom={`1px solid ${COLORS.UTIL.Gray[500]}`}
        >
            {headerComponent}
            {headerText && <FONTS.H3 alignSelf="center">{headerText}</FONTS.H3>}
            {!isNarrowScreen && <FlexRow gap={PXSTR.S}>{actionBtns}</FlexRow>}
        </FlexRow>
    );
};
