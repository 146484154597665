import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { useSubmitVisitContext } from '../submitVisit.context';
import { FormCheckboxInput } from 'ui/components/Form/FormCheckBoxInput';
import FONTS from 'ui/fonts';
import { SheetTab } from 'ui/components/Sheet';
import { Divider } from 'ui/components/Divider';
import { PXSTR } from 'ui/px';
import COLORS from 'ui/colors';
import { VisitLocation, VisitMedium } from 'models/constants/visitConstants';
import { TextTag } from 'ui/components/TextTag';
import { UserTag } from 'ui/components/AvatarTag';
import { Box } from '@chakra-ui/react';
import { format } from 'date-fns';
import { getFullServicesStringInDict } from 'util/visit.utils';
import { getNameToShow } from 'util/Utils';
import { usePractitioner } from 'hooks/dbHooks';
import {
    ReviewStepPageLayout,
    StepReviewField,
    StepReviewLayout,
} from 'doulaPortal/layouts/SimpleFlow/ReviewStepLayout';

export const VisitReviewStep = () => {
    const {
        practitionerData,
        attestationComplete,
        setAttestationComplete,
        errors,
        registerProps,
    } = useAttestation();

    return (
        <FlexCol gap={PXSTR.L}>
            <FlexCol>
                <ReviewStepPageLayout>
                    <StepOneReview />
                    <Divider />
                    <StepTwoReview />
                    <Divider />
                    <StepThreeReview />
                    <Divider />
                    <StepFourReview />
                    <Divider />
                    <StepFiveReview />
                </ReviewStepPageLayout>
                <BillingInfoSummary />
            </FlexCol>

            <FONTS.H3>Attest to the following to submit the visit</FONTS.H3>

            <FormCheckboxInput
                data-cy={`submit-visit-review-attestation`}
                fieldName="attestationComplete"
                label={`I, ${getNameToShow(practitionerData?.user)}, attest that the information above is correct.`}
                value={attestationComplete}
                setValue={setAttestationComplete}
                errors={errors}
                isDecorated={true}
                registerProps={registerProps}
                decorationDesc="I swear!"
            />
        </FlexCol>
    );
};

const BillingInfoSummary = () => {
    return (
        <SheetTab
            padding={PXSTR.L}
            display="flex"
            flexDirection="column"
            background={COLORS.UTIL.Gray[300]}
            gap={PXSTR.L}
        >
            <FONTS.H6 color={COLORS.PRIMARY.Grey}>Billing Information</FONTS.H6>
            <StepReviewField title="Managed Care Plan">
                <FONTS.P1>TODO: MCP Tag</FONTS.P1>
            </StepReviewField>
            <FlexRow justifyContent="space-between">
                <StepReviewField title="Payment cycle">
                    <TextTag text="TODO PAY CYCLE" />
                </StepReviewField>
                <StepReviewField title="Amount Paid">
                    <FONTS.P2 fontWeight={700}>$XX.XX</FONTS.P2>
                </StepReviewField>
            </FlexRow>
        </SheetTab>
    );
};

const StepOneReview = () => {
    const ctx = useSubmitVisitContext();
    const { client, stepDefinitions } = ctx ?? {};

    return (
        <StepReviewLayout title={stepDefinitions?.[0].title} idx={0}>
            <StepReviewField title="Client">
                <Box width="fit-content">
                    <UserTag user={client?.user} />
                </Box>
            </StepReviewField>
        </StepReviewLayout>
    );
};

const StepTwoReview = () => {
    const ctx = useSubmitVisitContext();
    const { stepTwoForm, stepDefinitions } = ctx ?? {};
    const fields = stepTwoForm?.getValues();

    return (
        <StepReviewLayout title={stepDefinitions?.[1].title} idx={1}>
            <StepReviewField
                title="Visit Type"
                textValue={fields?.visitCategory}
            />
        </StepReviewLayout>
    );
};

const StepThreeReview = () => {
    const ctx = useSubmitVisitContext();
    const { stepThreeForm, stepDefinitions } = ctx ?? {};
    const fields = stepThreeForm?.getValues();
    const { dateOfService, timeOfService, durationMinutes } = fields ?? {};

    return (
        <StepReviewLayout title={stepDefinitions?.[2].title} idx={2}>
            <StepReviewField
                title="Visit Date"
                textValue={
                    !!dateOfService ? format(dateOfService, 'M/d/yy') : ''
                }
            />
            <StepReviewField
                title="Visit Start Time"
                textValue={!!timeOfService ? format(timeOfService, 'p') : ''}
            />
            <StepReviewField
                title="Visit Duration"
                textValue={`${durationMinutes} mins`}
            />
        </StepReviewLayout>
    );
};

const StepFourReview = () => {
    const ctx = useSubmitVisitContext();
    const { stepFourForm, stepDefinitions } = ctx ?? {};
    const fields = stepFourForm?.getValues();

    const location =
        fields?.locationOfService === VisitLocation.Other
            ? fields?.locationSelfDescription
            : fields?.locationOfService;

    return (
        <StepReviewLayout title={stepDefinitions?.[3].title} idx={3}>
            <StepReviewField
                title="Visit Mode"
                textValue={fields?.mediumOfService}
            />

            {fields?.mediumOfService === VisitMedium.InPerson && (
                <StepReviewField title="Visit Location" textValue={location} />
            )}

            {fields?.locationOfService === VisitLocation.MedicalFacility && (
                <StepReviewField
                    title="Medical Facility"
                    textValue={fields?.facilityNPI?.toString()}
                >
                    <FONTS.P2>FACILITY ADDRESS HERE</FONTS.P2>
                </StepReviewField>
            )}
        </StepReviewLayout>
    );
};

const StepFiveReview = () => {
    const ctx = useSubmitVisitContext();
    const { stepFiveForm, stepDefinitions } = ctx ?? {};
    const fields = stepFiveForm?.getValues();
    const { services } = fields ?? {};

    return (
        <StepReviewLayout title={stepDefinitions?.[4].title} idx={4}>
            <StepReviewField
                title="Services Provided"
                textValue={getFullServicesStringInDict(services)}
            />
        </StepReviewLayout>
    );
};

const useAttestation = () => {
    const ctx = useSubmitVisitContext();
    const { stepSixForm } = ctx ?? {};
    const { watch, setValue, register, formState } = stepSixForm ?? {};
    const { errors } = formState ?? {};

    const { practitionerData } = usePractitioner();

    const attestationComplete = watch?.('attestationComplete') ?? false;

    const setAttestationComplete = (val: boolean) => {
        setValue?.('attestationComplete', val, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
    };

    const registerProps = !!register
        ? {
              ...register('attestationComplete', {
                  validate: (val) => val,
              }),
          }
        : undefined;

    return {
        practitionerData,
        attestationComplete,
        setAttestationComplete,
        errors,
        registerProps,
    };
};
