import { Box, Image } from '@chakra-ui/react';
import PX from 'ui/px';

export type LogoType =
    | 'grounded'
    | 'joy'
    | 'nourished'
    | 'peri'
    | 'playtime'
    | 'soother';

export const Logo = ({
    size = 90,
    subMark = false,
    logoType = 'grounded',
    fillColor,
    onClick,
}: {
    size?: number;
    subMark?: boolean;
    logoType?: LogoType;
    fillColor?: string;
    onClick?: () => void;
}) => {
    const boxSize = { width: size, height: size }; //ratio 1:1
    const fullSize = { width: size * 1.8, height: size }; //ratio 16:9
    const boxPadding = {
        paddingX: `${subMark ? boxSize.width * 0.2 : fullSize.width * 0.1}px`,
        paddingY: `${(subMark ? boxSize.width : fullSize.height) * 0.1333}px`,
    };

    return (
        <Box
            {...(subMark ? boxSize : fullSize)}
            {...boxPadding}
            background={fillColor}
            borderRadius={PX.RADII.LG}
            onClick={onClick}
            onTouchStart={onClick}
        >
            <Image
                width="100%"
                height="100%"
                src={`/logo/${subMark ? 'submark' : 'primary'}/${logoType}.png`}
                alt={'Loula Logo'}
            />
        </Box>
    );
};
