import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { Divider } from 'ui/components/Divider';
import {
    ReviewStepPageLayout,
    StepReviewField,
    StepReviewLayout,
} from 'doulaPortal/layouts/SimpleFlow/ReviewStepLayout';
import { PXSTR } from 'ui/px';
import FONTS from 'ui/fonts';
import { FormCheckboxInput } from 'ui/components/Form/FormCheckBoxInput';
import { useCreateClientContext } from '../createClient.context';
import { StepFiveFields } from '../createClient.models';
import { useSimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.context';
import { UploadedFilePreview } from 'components/FileUpload/UploadedFilePreview';
import { format } from 'date-fns';
import { generateAddressInReadableString } from 'util/Utils';
import { PregnancyStatus } from 'models/constants/clientConstants';
import { isAnyPlanNotThroughSelf } from 'util/patient.utils';

/**
 * Dont show the re-upload button on preview in the step
 *
 * ALSO fix that bug on the editclientdrawer not saving changes
 * @returns
 */

export const ReviewClientStep = () => {
    const ctx = useCreateClientContext();
    const { stepFiveForm } = ctx ?? {};
    const { setValue, formState, watch, register } = stepFiveForm ?? {};
    const { errors } = formState ?? {};

    const setterProps = {
        shouldValidate: true,
        shouldDirty: true,
    };

    const [attestationOfNotIndependentlyBilling, attestionOfAccuracy] =
        watch?.([
            'attestationOfNotIndependentlyBilling',
            'attestionOfAccuracy',
        ]) ?? [];

    const setAttestatonVal =
        (fieldName: keyof StepFiveFields) => (val: boolean) => {
            setValue?.(fieldName, val, setterProps);
        };

    return (
        <FlexCol gap={PXSTR.XXL}>
            <ReviewStepPageLayout>
                <StepOneReview />
                <Divider />
                <StepTwoReview />
                <Divider />
                <StepThreeReview />
                <Divider />
                <StepFourReview />
                <Divider />
            </ReviewStepPageLayout>

            <FONTS.H3>
                Attest to these following statements to create this client on
                Loula:
            </FONTS.H3>

            <FormCheckboxInput
                data-cy={`submit-visit-review-attestation`}
                fieldName="attestationOfNotIndependentlyBilling"
                label="I have not billed for this client independent of Loula."
                decorationDesc="We are only able to support new clients whom you have not already billed for independently. "
                value={attestationOfNotIndependentlyBilling ?? false}
                setValue={setAttestatonVal(
                    'attestationOfNotIndependentlyBilling',
                )}
                errors={errors}
                isDecorated={true}
                registerProps={register?.(
                    'attestationOfNotIndependentlyBilling',
                    { required: true },
                )}
            />

            <FormCheckboxInput
                data-cy={`submit-visit-review-attestation`}
                fieldName="attestionOfAccuracy"
                label="I confirm that the information inputted is accurate and matches what’s on the client’s insurance card."
                value={attestionOfAccuracy ?? false}
                setValue={setAttestatonVal('attestionOfAccuracy')}
                errors={errors}
                isDecorated={true}
                registerProps={register?.('attestionOfAccuracy', {
                    required: true,
                })}
            />
        </FlexCol>
    );
};

const useStepOnePayers = () => {
    const ctx = useCreateClientContext();
    const {
        stepDefinitions,
        stepOneForm,
        providerContractData,
        cardPreviewSrcs,
    } = ctx ?? {};
    const vals = stepOneForm?.getValues();
    const { primary, hasSecondaryInsurance, secondary } = vals ?? {};
    const primaryPayer = providerContractData?.find(
        (data) => data.payers.externalPayerId === primary?.payerId,
    );
    const secondaryPayer = providerContractData?.find(
        (data) => data.payers.externalPayerId === secondary?.payerId,
    );

    return {
        stepDefinitions,
        cardPreviewSrcs,
        primaryPayer,
        secondaryPayer,
        hasSecondaryInsurance,
        isPrimaryMedical: primary?.isMediCal,
    };
};

const StepOneReview = () => {
    const {
        stepDefinitions,
        cardPreviewSrcs,
        primaryPayer,
        secondaryPayer,
        hasSecondaryInsurance,
        isPrimaryMedical,
    } = useStepOnePayers();

    const flowCtx = useSimpleFlowContext();
    const { goToStep } = flowCtx ?? {};
    const goBackToInsuranceStep = () => {
        goToStep?.(0);
    };

    return (
        <StepReviewLayout title={stepDefinitions?.[0]?.title} idx={0}>
            <FlexCol gap={PXSTR.M}>
                <StepReviewField
                    title="Health Plan"
                    textValue={primaryPayer?.payers.name}
                />

                <StepReviewField title="Front of Insurance Card">
                    <UploadedFilePreview
                        files={[]}
                        preloadedPreviewSrc={cardPreviewSrcs?.primaryFrontSrc}
                    />
                </StepReviewField>

                <StepReviewField title="Back of Insurance Card">
                    <UploadedFilePreview
                        files={[]}
                        preloadedPreviewSrc={cardPreviewSrcs?.primaryBackSrc}
                    />
                </StepReviewField>

                <StepReviewField
                    title="Is this a Medi-Cal Plan?"
                    textValue={isPrimaryMedical ? 'Yes' : 'No'}
                />

                <StepReviewField
                    title="Additional Health Plan?"
                    textValue={hasSecondaryInsurance ? 'Yes' : 'No'}
                />

                {hasSecondaryInsurance && (
                    <>
                        <StepReviewField
                            title="Additional Health Plan"
                            textValue={secondaryPayer?.payers.name}
                        />

                        <StepReviewField title="Front of Insurance Card">
                            <UploadedFilePreview
                                files={[]}
                                preloadedPreviewSrc={
                                    cardPreviewSrcs?.secondaryFrontSrc
                                }
                                onReset={goBackToInsuranceStep}
                            />
                        </StepReviewField>

                        <StepReviewField title="Back of Insurance Card">
                            <UploadedFilePreview
                                files={[]}
                                preloadedPreviewSrc={
                                    cardPreviewSrcs?.secondaryBackSrc
                                }
                                onReset={goBackToInsuranceStep}
                            />
                        </StepReviewField>
                    </>
                )}
            </FlexCol>
        </StepReviewLayout>
    );
};

const StepTwoReview = () => {
    const ctx = useCreateClientContext();
    const { stepDefinitions, stepTwoForm } = ctx ?? {};
    const vals = stepTwoForm?.getValues();
    const {
        firstName,
        lastName,
        middleInitial,
        dob,
        primaryInsurance,
        secondaryInsurance,
    } = vals ?? {};

    const { primaryPayer, secondaryPayer, hasSecondaryInsurance } =
        useStepOnePayers();

    return (
        <StepReviewLayout title={stepDefinitions?.[1]?.title} idx={1}>
            <FlexCol gap={PXSTR.XL}>
                <FlexRow
                    justifyContent="space-between"
                    gap="inherit"
                    flexWrap="wrap"
                >
                    <StepReviewField
                        title="Client’s Legal First Name"
                        textValue={firstName}
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title="Client’s Legal Middle Initial"
                        textValue={middleInitial}
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title="Client’s Legal Last Name"
                        textValue={lastName}
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title="Client’s Date of Birth"
                        textValue={!!dob ? format(dob, 'MM/dd/yy') : undefined}
                        flexBasis="40%"
                    />
                </FlexRow>

                <ShortDivider />

                <FONTS.H6>{primaryPayer?.payers.name}</FONTS.H6>

                <FlexRow justifyContent="space-between">
                    <StepReviewField
                        title="Client’s Member ID"
                        textValue={primaryInsurance?.memberId}
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title={`Group ID${primaryInsurance?.isGroupIDOnCard ? '' : '?'}`}
                        textValue={
                            primaryInsurance?.isGroupIDOnCard
                                ? primaryInsurance.groupId
                                : 'No'
                        }
                        flexBasis="40%"
                    />
                </FlexRow>

                {hasSecondaryInsurance && (
                    <>
                        <ShortDivider />

                        <FONTS.H6>{secondaryPayer?.payers.name}</FONTS.H6>

                        <FlexRow justifyContent="space-between">
                            <StepReviewField
                                title="Client’s Member ID"
                                textValue={secondaryInsurance?.memberId}
                                flexBasis="40%"
                            />

                            <StepReviewField
                                title={`Group ID${secondaryInsurance?.isGroupIDOnCard ? '' : 's'}`}
                                textValue={
                                    !secondaryInsurance?.isGroupIDOnCard
                                        ? 'No'
                                        : secondaryInsurance.groupId
                                }
                                flexBasis="40%"
                            />
                        </FlexRow>
                    </>
                )}
            </FlexCol>
        </StepReviewLayout>
    );
};

const StepThreeReview = () => {
    const ctx = useCreateClientContext();
    const { stepDefinitions, stepThreeForm } = ctx ?? {};
    const vals = stepThreeForm?.getValues();
    const {
        primaryRelationToInsured,
        secondaryRelationToInsured,
        insuredDetails,
    } = vals ?? {};

    const { primaryPayer, secondaryPayer, hasSecondaryInsurance } =
        useStepOnePayers();

    const oneIsNotSelf = isAnyPlanNotThroughSelf([
        primaryRelationToInsured,
        secondaryRelationToInsured,
    ]);

    return (
        <StepReviewLayout title={stepDefinitions?.[2]?.title} idx={2}>
            <FlexCol gap={PXSTR.M}>
                <FONTS.H6>{primaryPayer?.payers.name}</FONTS.H6>

                <StepReviewField
                    title="Client’s relationship to the insured"
                    textValue={primaryRelationToInsured}
                />

                {hasSecondaryInsurance && (
                    <>
                        <ShortDivider />
                        <FONTS.H6>{secondaryPayer?.payers.name}</FONTS.H6>
                        <StepReviewField
                            title="Client’s relationship to the insured"
                            textValue={secondaryRelationToInsured}
                        />
                    </>
                )}

                {oneIsNotSelf && (
                    <>
                        <FlexRow
                            gap="inherit"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <StepReviewField
                                title="Insured’s Legal First Name"
                                textValue={insuredDetails?.firstName}
                                flexBasis="40%"
                            />

                            <StepReviewField
                                title="Insured’s Legal Middle Initial"
                                textValue={insuredDetails?.middleInitial}
                                flexBasis="40%"
                            />

                            <StepReviewField
                                title="Insured’s Legal Last Name"
                                textValue={insuredDetails?.lastName}
                                flexBasis="40%"
                            />

                            <StepReviewField
                                title="Insured’s Date of Birth"
                                textValue={
                                    !!insuredDetails?.dob
                                        ? format(insuredDetails.dob, 'MM/dd/yy')
                                        : undefined
                                }
                                flexBasis="40%"
                            />

                            <StepReviewField
                                title="Client's Member ID"
                                textValue={insuredDetails?.memberId}
                                flexBasis="40%"
                            />

                            <StepReviewField
                                title="Insured's Sex"
                                textValue={insuredDetails?.sex}
                                flexBasis="40%"
                            />

                            <StepReviewField
                                title="Insured's Phone Number"
                                textValue={insuredDetails?.phone}
                                flexBasis="40%"
                            />
                        </FlexRow>

                        <StepReviewField
                            title="Insured's Address"
                            textValue={
                                generateAddressInReadableString(
                                    insuredDetails?.address,
                                ) ?? undefined
                            }
                        />
                    </>
                )}
            </FlexCol>
        </StepReviewLayout>
    );
};

const StepFourReview = () => {
    const ctx = useCreateClientContext();
    const { stepDefinitions, stepFourForm, stepThreeForm } = ctx ?? {};
    const vals = stepFourForm?.getValues();
    const stepThreeVals = stepThreeForm?.getValues();
    const { insuredDetails } = stepThreeVals ?? {};
    const { address: insuredAddress } = insuredDetails ?? {};

    const {
        preferredName,
        sex,
        isAddressSameAsInsured,
        address,
        email,
        phone,
        pregnancyStatus,
        dateOfPregnancyConclusion,
    } = vals ?? {};

    return (
        <StepReviewLayout title={stepDefinitions?.[3]?.title} idx={3}>
            <FlexCol gap={PXSTR.M}>
                <FlexRow
                    justifyContent="space-between"
                    flexWrap="wrap"
                    gap="inherit"
                >
                    <StepReviewField
                        title="Client’s Preferred First Name"
                        textValue={preferredName}
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title="Client’s Sex"
                        textValue={sex}
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title="Client’s Address"
                        textValue={
                            isAddressSameAsInsured
                                ? generateAddressInReadableString(
                                      insuredAddress,
                                  )
                                : generateAddressInReadableString(address)
                        }
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title="Client’s Email Address"
                        textValue={email}
                        flexBasis="40%"
                    />
                    <StepReviewField
                        title="Client’s Phone Number"
                        textValue={phone}
                        flexBasis="40%"
                    />
                </FlexRow>
                <ShortDivider />

                <FlexRow
                    justifyContent="space-between"
                    flexWrap="wrap"
                    gap="inherit"
                >
                    <StepReviewField
                        title="Client’s Pregnancy Status"
                        textValue={pregnancyStatus}
                        flexBasis="40%"
                    />

                    <StepReviewField
                        title={
                            pregnancyStatus === PregnancyStatus.Prenatal
                                ? 'Estimated Due Date'
                                : 'Date Pregnancy Ended'
                        }
                        textValue={
                            !!dateOfPregnancyConclusion
                                ? format(dateOfPregnancyConclusion, 'MM/dd/yy')
                                : undefined
                        }
                        flexBasis="40%"
                    />
                </FlexRow>
            </FlexCol>
        </StepReviewLayout>
    );
};

const ShortDivider = () => <Divider width="50" />;
