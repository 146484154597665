import { FlexCol, FlexRow } from 'ui/loulaFlex';
import FONTS from 'ui/fonts';
import { SheetTab } from 'ui/components/Sheet';
import { ReactElement, ReactNode } from 'react';
import { PXSTR } from 'ui/px';
import COLORS from 'ui/colors';
import { IconButton, IconButtonType } from 'ui/components/IconButton';
import { useSimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.context';
import { FlexProps } from '@chakra-ui/react';

export const ReviewStepPageLayout = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    return (
        <FlexCol gap={PXSTR.L}>
            <FONTS.H3>
                Review this information and ensure it is correct:
            </FONTS.H3>

            <FlexCol>
                <SheetTab
                    padding={PXSTR.L}
                    gap={PXSTR.XL}
                    display="flex"
                    flexDirection="column"
                >
                    {children}
                </SheetTab>
            </FlexCol>
        </FlexCol>
    );
};

export const StepReviewLayout = ({
    idx,
    title,
    children,
}: {
    idx: number;
    title: string | undefined;
    children?: ReactNode | ReactNode[];
}) => {
    const flowCtx = useSimpleFlowContext();
    const { goToStep } = flowCtx ?? {};

    return (
        <FlexCol width="100%">
            <FlexRow width="100%" justifyContent="space-between">
                <FlexRow gap={PXSTR.S}>
                    <FONTS.H6 color={COLORS.PRIMARY.Grey}>
                        Step {idx + 1}
                    </FONTS.H6>
                    <FONTS.H6>{title}</FONTS.H6>
                </FlexRow>
                <IconButton
                    icon="EditPage"
                    iconBtnType={IconButtonType.decorated}
                    onClick={() => goToStep?.(idx, true)}
                />
            </FlexRow>
            {children}
        </FlexCol>
    );
};

export const StepReviewField = ({
    title,
    children,
    textValue,
    ...props
}: FlexProps & {
    title: string;
    children?: ReactElement;
    textValue?: string | null;
}) => {
    return (
        <FlexCol gap={PXSTR.S} {...props}>
            <FONTS.P2 color={COLORS.PRIMARY.Grey} fontWeight={600}>
                {title}
            </FONTS.P2>
            {textValue && <FONTS.P1 fontWeight={700}>{textValue}</FONTS.P1>}
            {children}
        </FlexCol>
    );
};
