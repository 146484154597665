import { StepDefType } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.models';
import {
    InsurerRelationship,
    PregnancyStatus,
    Sex,
} from 'models/constants/clientConstants';
import {
    AddressInfo,
    FileUpload,
    ProviderContractReturnType,
} from 'models/schemas';
import { UseFormReturn } from 'react-hook-form';

export type CreateClientContextType = {
    createClientFromFlow: () => Promise<void>;
    stepDefinitions: StepDefType[];
    getIsNextEnabled: (idx: number) => boolean;

    stepOneForm: UseFormReturn<StepOneFields>;
    stepTwoForm: UseFormReturn<StepTwoFields>;
    stepThreeForm: UseFormReturn<StepThreeFields>;
    stepFourForm: UseFormReturn<StepFourFields>;
    stepFiveForm: UseFormReturn<StepFiveFields>;

    cardPreviewSrcs: {
        primaryFrontSrc: string | undefined;
        primaryBackSrc: string | undefined;
        secondaryFrontSrc: string | undefined;
        secondaryBackSrc: string | undefined;
    };

    providerContractData: ProviderContractReturnType[];
};

export type StepOneFields = {
    primary?: {
        payerId?: string;
        cardFront?: FileUpload[];
        cardBack?: FileUpload[];
        isMediCal?: boolean;
    };
    hasSecondaryInsurance?: boolean;
    secondary?: {
        payerId?: string;
        payerName?: string;
        cardFront?: FileUpload[];
        cardBack?: FileUpload[];
    };
};

export type StepTwoFields = {
    firstName?: string;
    lastName?: string;
    middleInitial?: string;
    dob: Date | undefined;

    primaryInsurance?: {
        memberId?: string;
        isGroupIDOnCard?: boolean;
        groupId?: string;
    };

    secondaryInsurance?: {
        memberId?: string;
        isGroupIDOnCard?: boolean;
        groupId?: string;
    };
};

export type StepThreeFields = {
    primaryRelationToInsured?: InsurerRelationship;
    secondaryRelationToInsured?: InsurerRelationship;

    insuredDetails?: {
        firstName?: string;
        lastName?: string;
        middleInitial?: string;
        dob?: Date;
        memberId?: string;
        isGroupIDOnCard?: boolean;
        groupId?: string;
        sex?: Sex;
        phone?: string;
        address?: AddressInfo;
    };
};

export type StepFourFields = {
    preferredName?: string;
    sex?: Sex;
    pronouns?: Record<string, boolean>;
    isAddressSameAsInsured?: boolean;
    address?: AddressInfo;
    email?: string;
    phone?: string;
    pregnancyStatus?: PregnancyStatus;
    dateOfPregnancyConclusion?: Date;
};

export type StepFiveFields = {
    attestationOfNotIndependentlyBilling?: boolean;
    attestionOfAccuracy?: boolean;
};

export type FullCreateClientFlowFields = StepOneFields &
    StepTwoFields &
    StepThreeFields &
    StepFourFields;

export const PRIMARY_CARD_FRONT_KEY = 'primaryCardFront';
export const PRIMARY_CARD_BACK_KEY = 'primaryCardBack';
export const SECONDARY_CARD_FRONT_KEY = 'secondaryCardFront';
export const SECONDARY_CARD_BACK_KEY = 'secondaryCardBack';
