import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
} from '@chakra-ui/react';
import { TextInput } from 'ui/components/TextInput';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import COLORS from 'ui/colors';
import PX from 'ui/px';
import SHADOWS from 'ui/shadows';
import FONTS from 'ui/fonts';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import { IconButton } from 'ui/components/IconButton';
import { Alert } from 'ui/components/Alert';
import { FormCheckboxInput } from 'ui/components/Form/FormCheckBoxInput';
import { NPISearchContext, useNPISearchContext } from './npiSearch.context';
import { NPIFacilityType, NPISearchProps } from './npiSearch.models';
import { FacilityForm, IndividualForm } from './NPISearchForms';
import {
    IndividualProviderResult,
    FacilityProviderResult,
} from './NPISearchResults';
import { useInitialNPISearchContext } from './useInitialNPISearchContext';

const NPISearch = ({
    facilityType = NPIFacilityType.INDIVIDUAL,
    ...props
}: NPISearchProps) => {
    const initialContext = useInitialNPISearchContext({
        facilityType,
        ...props,
    });
    const { selectedNpi, onOpen } = initialContext ?? {};

    return (
        <NPISearchContext.Provider value={initialContext}>
            <TextInput
                value={selectedNpi}
                isReadOnly={true}
                label={props.label}
                subLabel={props.subLabel}
                iconRight="Search"
                data-cy="npi-select-input"
                onClick={onOpen}
            />

            <NPIModal />
        </NPISearchContext.Provider>
    );
};

const NPIModal = () => {
    const ctx = useNPISearchContext();

    const {
        isOpen,
        closeAndReset,
        showResults,
        onClose,
        isLoading,
        search,
        back,
        resultsHeader,
        error,
        isLimitedToStateWatch,
        setIsLimitedToState,
        individualForm,
        facilityForm,
        isIndividual,
    } = ctx ?? {};

    return (
        <Modal
            id="npi-search-modal"
            isOpen={isOpen ?? false}
            onClose={closeAndReset ?? (() => undefined)}
            size={'3xl'}
        >
            <ModalOverlay />
            <ModalContent
                transition="all 0.3s"
                maxWidth={showResults ? '692px' : '500px'}
            >
                <FlexCol
                    background={COLORS.UTIL.Gray.WARM}
                    borderRadius={PX.RADII.LG}
                    boxShadow={SHADOWS.elevationOne}
                    width="100%"
                >
                    <FlexRow
                        align="center"
                        paddingX={PX.SPACING.REM.L}
                        paddingY={PX.SPACING.REM.M}
                        justify="space-between"
                    >
                        <FONTS.H2>
                            {!isIndividual ? 'Facility ' : ''}
                            NPI Look-up
                        </FONTS.H2>
                        <ModalCloseButton>
                            <IconButton icon="Close" size={20} />
                        </ModalCloseButton>
                    </FlexRow>

                    {!showResults ? (
                        <FlexCol width="100%">
                            <FlexCol
                                gap={PX.SPACING.REM.M}
                                padding={PX.SPACING.REM.L}
                                borderY={`1px solid ${COLORS.UTIL.Gray[500]}`}
                            >
                                {isIndividual ? (
                                    <IndividualForm />
                                ) : (
                                    <FacilityForm />
                                )}

                                <FormCheckboxInput
                                    data-cy={`npi-search-cali-toggle`}
                                    fieldName="isLimitedToState"
                                    label="Only search for providers in California"
                                    value={isLimitedToStateWatch ?? false}
                                    setValue={
                                        setIsLimitedToState ?? (() => undefined)
                                    }
                                    errors={
                                        (isIndividual
                                            ? individualForm
                                            : facilityForm
                                        )?.formState.errors
                                    }
                                    registerProps={
                                        isIndividual
                                            ? individualForm?.register(
                                                  'isLimitedToState',
                                              )
                                            : facilityForm?.register(
                                                  'isLimitedToState',
                                              )
                                    }
                                />

                                {error && (
                                    <Alert
                                        data-cy="no_npi_results"
                                        content="No results found. Please try again."
                                    />
                                )}
                            </FlexCol>
                            <FlexRow
                                justify="end"
                                paddingX={PX.SPACING.REM.L}
                                paddingY={PX.SPACING.REM.M}
                                gap={PX.SPACING.REM.S}
                            >
                                <SecondaryButton
                                    data-cy="npiCancelBtn"
                                    onClick={onClose}
                                >
                                    Cancel
                                </SecondaryButton>
                                <PrimaryButton
                                    data-cy="npiSearchBtn"
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    onClick={search}
                                >
                                    Search
                                </PrimaryButton>
                            </FlexRow>
                        </FlexCol>
                    ) : (
                        <FlexCol
                            width="100%"
                            gap={PX.SPACING.REM.M}
                            padding={PX.SPACING.REM.L}
                            borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}
                        >
                            <FlexRow gap={PX.SPACING.PX.S} align="center">
                                <IconButton
                                    aria-label="back"
                                    onClick={back}
                                    icon="ArrowBackward"
                                />
                                <FONTS.P1>Back</FONTS.P1>
                            </FlexRow>
                            <FONTS.H3>{resultsHeader}</FONTS.H3>

                            {isIndividual ? (
                                <IndividualProviderResult />
                            ) : (
                                <FacilityProviderResult />
                            )}
                        </FlexCol>
                    )}
                </FlexCol>
            </ModalContent>
        </Modal>
    );
};

export default NPISearch;
