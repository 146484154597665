import {
    Box,
    Input,
    InputProps,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    useMergeRefs,
    chakra,
} from '@chakra-ui/react';
import { forwardRef, MouseEventHandler, ReactElement, useState } from 'react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX, { PXSTR } from 'ui/px';
import { ICONS } from 'ui/icons';
import { SpecialChars } from 'models/constants';
import { useIMask } from 'react-imask';

export const TextInputStyling = {
    background: COLORS.UTIL.Gray.WARM,
    borderRadius: PX.RADII.MD,
    border: `1px solid ${COLORS.STROKES.HEAVY}`,
    padding: PX.SPACING.PX.S,
    display: 'flex',
    fontFamily: 'DM Sans',
    fontSize: PX.NUM.PX.M,
    size: 'md',
    invalid: {
        borderColor: COLORS.PRIMARY.Error,
    },

    focusBorderColor: COLORS.PRIMARY.Blue,
    _hover: {},

    _disabled: {
        background: COLORS.UTIL.Gray[300],
        opacity: 0.7,
    },
};

export type LoulaTextProps = {
    label?: string;

    mask?: string;

    subLabel?: string;
    isSecret?: boolean;

    leftElement?: ReactElement;
    iconLeft?: keyof typeof ICONS;
    iconRight?: keyof typeof ICONS;
    iconColorLeft?: string;
    iconColorRight?: string;

    blockSpecialChars?: boolean;
    blockChars?: string;

    onAccept?: (_str: string) => void; //If you make a masked & secret input, use this to save the value (instead of saving '*** ** ****')

    'data-cy'?: string;
};

export type TextInputProps = InputProps & LoulaTextProps;

export const TextInput = forwardRef<InputProps, TextInputProps>(
    (
        {
            label,
            subLabel,
            mask,
            leftElement,
            iconLeft,
            iconRight,
            iconColorLeft,
            iconColorRight,
            isSecret,
            blockSpecialChars,
            blockChars,
            defaultValue,
            onAccept,
            onKeyDown: propKeyDown,
            isReadOnly,
            onClick,
            ...inputProps
        },
        forwardedRef,
    ) => {
        const [isSecretVisible, setIsSecretVisible] = useState(false);
        const ICONRIGHT = !!iconRight ? ICONS[iconRight] : null;
        const ICONLEFT = !!iconLeft ? ICONS[iconLeft] : null;

        const hasMasking = !!mask;

        const useIMaskProps = hasMasking
            ? {
                  mask: mask,
                  definitions: {
                      X: {
                          mask: '0',
                          displayChar: isSecretVisible ? undefined : '•',
                          placeholderChar: '#',
                      },
                  },
              }
            : {};

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { ref: iMaskRef } = useIMask(useIMaskProps as any, {
            defaultValue: defaultValue?.toString(),
            onAccept: async (value) => {
                onAccept?.(value);
            },
        });

        const mergeRef = useMergeRefs(
            forwardedRef,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            !hasMasking ? undefined : (iMaskRef as any),
        );

        return (
            <FlexCol width="100%" gap={PX.SPACING.PX.S}>
                {!!label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
                <Box className="baseTextBox" position="relative" width="100%">
                    <InputGroup>
                        {iconLeft && ICONLEFT && (
                            <InputLeftElement>
                                <ICONLEFT
                                    size={16}
                                    color={
                                        iconColorLeft ?? COLORS.STROKES.HEAVY
                                    }
                                    style={{ zIndex: 1 }}
                                />
                            </InputLeftElement>
                        )}

                        {!!leftElement && (
                            <InputLeftElement
                                paddingLeft={PXSTR.XS}
                                width="fit-content"
                            >
                                {leftElement}
                            </InputLeftElement>
                        )}

                        <Input
                            tabIndex={isReadOnly ? -1 : 0}
                            onKeyDown={(e) => {
                                if (
                                    (blockSpecialChars &&
                                        SpecialChars.includes(e.key)) ||
                                    (!!blockChars && blockChars.includes(e.key))
                                ) {
                                    e.preventDefault?.();
                                }

                                propKeyDown?.(e);
                            }}
                            onClick={onClick}
                            isReadOnly={isReadOnly}
                            {...TextInputStyling}
                            {...inputProps}
                            {...(iconLeft
                                ? { paddingLeft: PX.SPACING.PX.XL }
                                : {})}
                            ref={mergeRef}
                        />
                        {!!isSecret && (
                            <InputRightElement
                                right={
                                    !!iconRight
                                        ? PX.SPACING.REM.XL
                                        : PX.SPACING.REM.S
                                }
                            >
                                <chakra.button
                                    onClick={() =>
                                        setIsSecretVisible((prev) => !prev)
                                    }
                                    data-cy={`${inputProps['data-cy']}-toggle-secrecy-btn`}
                                >
                                    <FONTS.P1
                                        cursor="pointer"
                                        textDecorationLine="underline"
                                        color={COLORS.PRIMARY.Blue}
                                    >
                                        {isSecretVisible ? 'Hide' : 'View'}
                                    </FONTS.P1>
                                </chakra.button>
                            </InputRightElement>
                        )}
                        {iconRight && ICONRIGHT && (
                            <InputRightElement>
                                <chakra.button
                                    onClick={
                                        onClick as MouseEventHandler<HTMLButtonElement>
                                    }
                                >
                                    <ICONRIGHT
                                        color={
                                            iconColorRight ??
                                            COLORS.STROKES.HEAVY
                                        }
                                        size={16}
                                    />
                                </chakra.button>
                            </InputRightElement>
                        )}
                    </InputGroup>
                </Box>

                {!!subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
            </FlexCol>
        );
    },
);
