import { SimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.context';
import { SimpleFlow } from 'doulaPortal/layouts/SimpleFlow/SimpleFlow';
import { useInitialSimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/useInitialSimpleFlowContext';

import { useInitialCreateClientContext } from './useInitialCreateClientContext';
import { CreateClientContext } from './createClient.context';

const Page = () => {
    const initialCreateClientCtx = useInitialCreateClientContext();

    const { stepDefinitions, createClientFromFlow, getIsNextEnabled } =
        initialCreateClientCtx;

    const initialSimpleFlowContext = useInitialSimpleFlowContext({
        flowName: 'Create Client',
        stepDefinitions,
        completeFlow: createClientFromFlow,
        getIsNextEnabled,
    });

    return (
        <CreateClientContext.Provider value={initialCreateClientCtx}>
            <SimpleFlowContext.Provider value={initialSimpleFlowContext}>
                <SimpleFlow />
            </SimpleFlowContext.Provider>
        </CreateClientContext.Provider>
    );
};

export default Page;
