import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from 'api/API';
import { DBFileModel, FileUpload } from 'models/schemas';
import { useState, useCallback, useMemo, useEffect } from 'react';

export const usePatientFiles = (
    patientUserId?: string | undefined,
    formField?: string | undefined,
) => {
    const { getAccessTokenSilently } = useAuth0();

    const [files, setFiles] = useState<DBFileModel[]>([]);

    const getFilesData = useCallback(async () => {
        if (!patientUserId) return;

        const token = await getAccessTokenSilently();
        authorize(token)
            .get(`/portal-docs/files`, {
                params: {
                    patientUserId: patientUserId,
                },
            })
            .then((res): void => {
                setFiles(res.data);
            });
    }, [getAccessTokenSilently, patientUserId]);

    const deleteFile = useCallback(
        async (tempFileName: string) => {
            if (!patientUserId) return;

            const token = await getAccessTokenSilently();
            authorize(token)
                .get('/portal-docs/delete-patient-file', {
                    params: {
                        patientUserId: patientUserId,
                        fileName: tempFileName,
                    },
                })
                .then(getFilesData);
        },
        [getAccessTokenSilently, getFilesData, patientUserId],
    );

    const saveTempFileToPatientFiles = useCallback(
        async (
            fieldName: string,
            file: FileUpload,
            overridePatientUserId?: string,
        ) => {
            if (!overridePatientUserId && !patientUserId) return;

            const token = await getAccessTokenSilently();
            authorize(token)
                .get('/portal-docs/save-tmp-file', {
                    params: {
                        patientUserId: overridePatientUserId ?? patientUserId,
                        fieldName,
                        ...file,
                    },
                })
                .then(getFilesData);
        },
        [getAccessTokenSilently, getFilesData, patientUserId],
    );

    const filteredFiles: FileUpload[] = useMemo(() => {
        if (!formField) return files;

        return files
            .filter((fullFile) => fullFile.formField === formField)
            ?.map((full) => {
                return {
                    originalFileName: full.originalFileName,
                    temporaryFileName: full.temporaryFileName,
                    fileSize: full.fileSize,
                };
            });
    }, [files, formField]);

    useEffect(() => {
        (async () => {
            await getFilesData();
        })();
    }, [getFilesData]);

    return {
        files,
        filteredFiles,
        deleteFile,
        saveTempFileToPatientFiles,
    };
};
