import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { forwardRef, useEffect, useState } from 'react';
import PX, { PXSTR } from 'ui/px';
import COLORS from 'ui/colors';
import { DropdownProps } from 'ui/ui.models';
import { NumberInput } from './NumberInput';

type LengthOfTimeInputProps = {
    label?: string;
    subLabel?: string;
    value: number | null;
    setValue?: (_value: number | null) => void;
    errorMsg?: string;
    maxLength?: number;
};

const useLengthOfTimeInput = ({
    value,
    setValue,
    maxLength = 12 * 60,
    errorMsg,
}: LengthOfTimeInputProps) => {
    maxLength = maxLength ?? 0;

    const [numHours, setNumHours] = useState<number>(0);
    const [numExtraMins, setNumExtraMins] = useState<number>(0);

    const onChangeNumHours = (_valAsString: string, valAsNumber: number) => {
        setNumHours(valAsNumber);
        compileValue(valAsNumber, numExtraMins);
    };

    const onChangeNumExtraMins = (
        _valAsString: string,
        valAsNumber: number,
    ) => {
        setNumExtraMins(valAsNumber);
        compileValue(numHours, valAsNumber);
    };

    const compileValue = (hrs: number, mins: number) => {
        setValue?.(hrs * 60 + mins);
    };

    useEffect(
        function updateCountersFromExternalVal() {
            if (!value) return;
            const newNumHrs = Math.floor(value / 60);
            const newNumExtraMins = value - newNumHrs * 60;
            setNumHours(newNumHrs);
            setNumExtraMins(newNumExtraMins);
        },
        [value],
    );

    const canSelectHours = maxLength >= 60;
    const maxNumHours = canSelectHours ? Math.floor(maxLength / 60) : 0;
    const maxNumExtraMins = Math.min(maxLength - numHours * 60, 45);

    const singleHourSelected = numHours === 1;
    const singleMinuteSelected = numExtraMins === 1;

    const isInvalid = !!errorMsg;

    return {
        numHours,
        numExtraMins,
        onChangeNumExtraMins,
        onChangeNumHours,
        canSelectHours,
        maxNumHours,
        maxNumExtraMins,
        singleHourSelected,
        singleMinuteSelected,
        isInvalid,
    };
};

/**
 * For selecting a length of time (eg length of a visit), where value is in MINUTES
 */
export const LengthOfTimeInput = forwardRef<
    DropdownProps,
    LengthOfTimeInputProps
>(({ label, subLabel, value, setValue, errorMsg, maxLength }, ref) => {
    const {
        canSelectHours,
        maxNumHours,
        maxNumExtraMins,
        singleHourSelected,
        singleMinuteSelected,
        isInvalid,
        numExtraMins,
        numHours,
        onChangeNumExtraMins,
        onChangeNumHours,
    } = useLengthOfTimeInput({
        value,
        setValue,
        errorMsg,
        maxLength,
    });

    return (
        <FlexCol width="fit-content" gap={PX.SPACING.PX.S}>
            <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>
            <FlexRow alignItems="center" gap={PXSTR.XL}>
                {canSelectHours && (
                    <NumberInput
                        step={1}
                        min={0}
                        max={maxNumHours}
                        value={numHours}
                        showDropdown={true}
                        onChange={onChangeNumHours}
                        label={`hour${singleHourSelected ? '' : 's'}`}
                    />
                )}
                <NumberInput
                    step={15}
                    min={0}
                    max={maxNumExtraMins}
                    showDropdown={true}
                    value={numExtraMins}
                    onChange={onChangeNumExtraMins}
                    label={`min${singleMinuteSelected ? '' : 's'}`}
                />
            </FlexRow>

            <FONTS.P2
                color={isInvalid ? COLORS.PRIMARY.Error : undefined}
                minHeight={PX.SPACING.PX.M}
                transition="all 0.3s"
                opacity={isInvalid ? (!!errorMsg ? 1 : 0) : !!subLabel ? 1 : 0}
            >
                {isInvalid ? errorMsg : subLabel}
            </FONTS.P2>
        </FlexCol>
    );
});
