import { FlexProps } from '@chakra-ui/react';
import useScreenSize from 'hooks/useScreenSize';
import { ReactElement } from 'react';
import COLORS from 'ui/colors';
import { FlexRow } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { HEADER_MIN_HEIGHT } from './PageHeader';

export const PageFooter = ({
    actionBtns,
    ...props
}: FlexProps & { actionBtns?: ReactElement[] }) => {
    const { isNarrowScreen } = useScreenSize();

    if (!isNarrowScreen) return null;

    return (
        <FlexRow
            className="pageFooter"
            width="100%"
            marginTop="auto"
            maxH={`${HEADER_MIN_HEIGHT}px`}
            justifyContent="end"
            alignItems="center"
            gap={PXSTR.S}
            paddingY={PXSTR.M}
            paddingX={PXSTR.L}
            borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}
            {...props}
        >
            {actionBtns}
        </FlexRow>
    );
};
