import { FlexCol } from 'ui/loulaFlex';
import { DrawerFormLayout } from './DrawerFormLayout';
import { PXSTR } from 'ui/px';
import { useClientDetailsContext } from 'doulaPortal/pages/ClientDetails/clientDetails.context';
import {
    UploadedFilePreview,
    usePreviewUploadedFile,
} from 'components/FileUpload/UploadedFilePreview';
import FONTS from 'ui/fonts';
import {
    PRIMARY_CARD_BACK_KEY,
    PRIMARY_CARD_FRONT_KEY,
} from 'doulaPortal/pages/CreateClient/createClient.models';
import { useMemo } from 'react';

export const InsuranceTab = () => {
    const ctx = useClientDetailsContext();
    const { files } = ctx ?? {};

    const { frontFile, backFile } = useMemo(() => {
        const frontFile = files?.find(
            (file) => file.formField === PRIMARY_CARD_FRONT_KEY,
        );
        const backFile = files?.find(
            (file) => file.formField === PRIMARY_CARD_BACK_KEY,
        );

        return { frontFile, backFile };
    }, [files]);

    const { previewSrc: frontPreviewSrc } = usePreviewUploadedFile({
        files: !!frontFile ? [frontFile] : undefined,
    });
    const { previewSrc: backPreviewSrc } = usePreviewUploadedFile({
        files: !!backFile ? [backFile] : undefined,
    });

    return (
        <DrawerFormLayout title="Insurance">
            <FONTS.H6>Front</FONTS.H6>
            <FlexCol gap={PXSTR.L} width="100%">
                <UploadedFilePreview
                    files={[]}
                    preloadedPreviewSrc={frontPreviewSrc}
                />
            </FlexCol>
            <FONTS.H6>Back</FONTS.H6>
            <FlexCol gap={PXSTR.L} width="100%">
                <UploadedFilePreview
                    files={[]}
                    preloadedPreviewSrc={backPreviewSrc}
                />
            </FlexCol>
        </DrawerFormLayout>
    );
};
