import {
    FieldErrors,
    FieldValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { DateInput } from '../DateInput';

/**
 * Simple wrapper of DateInput for use in a form where it expects a stringified date
 */
export const FormDateInput = ({
    label,
    fieldName,
    errors,
    watch,
    setValue,
    register,
    required,
    minLength = 1,
    minDate,
    maxDate,
}: {
    label?: string;
    fieldName: string;
    watch: UseFormWatch<FieldValues> | undefined;
    setValue: UseFormSetValue<FieldValues> | undefined;
    errors: FieldErrors<FieldValues> | undefined;
    register: UseFormRegister<FieldValues> | undefined;
    required?: boolean;
    minLength?: number;
    minDate?: Date;
    maxDate?: Date;
}) => {
    const watchVal = watch?.(fieldName);

    return (
        <DateInput
            label={label}
            datePickerProps={{
                placeholderText: 'MM/DD/YYYY',
                minDate: minDate ?? new Date(1900, 0, 1),
                maxDate: maxDate ?? new Date(),
            }}
            overrideErrorMsg={errors?.[fieldName]?.message?.toString()}
            value={!!watchVal?.length ? new Date(watchVal) : null}
            setValue={(val) => {
                if (!val) return;
                setValue?.(fieldName, val.toISOString(), {
                    shouldValidate: true,
                    shouldDirty: true,
                });
            }}
            {...register?.(fieldName, {
                required,
                minLength,
            })}
        />
    );
};
