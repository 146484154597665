import { SimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/simpleFlow.context';
import { SimpleFlow } from 'doulaPortal/layouts/SimpleFlow/SimpleFlow';
import { useInitialSimpleFlowContext } from 'doulaPortal/layouts/SimpleFlow/useInitialSimpleFlowContext';

import { SubmitVisitContext } from './submitVisit.context';
import { useInitialSubmitVisitContext } from './hooks/useInitialSubmitVisitContext';

const Page = () => {
    const initialSubmitVisitContext = useInitialSubmitVisitContext();

    const { stepDefinitions, submitVisit, getIsNextEnabled } =
        initialSubmitVisitContext;

    const initialSimpleFlowContext = useInitialSimpleFlowContext({
        flowName: 'Submit A Visit',
        stepDefinitions,
        completeFlow: submitVisit,
        getIsNextEnabled,
    });

    return (
        <SimpleFlowContext.Provider value={initialSimpleFlowContext}>
            <SubmitVisitContext.Provider value={initialSubmitVisitContext}>
                <SimpleFlow />
            </SubmitVisitContext.Provider>
        </SimpleFlowContext.Provider>
    );
};

export default Page;
