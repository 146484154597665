import { FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';

export const VisitField = ({
    title,
    simpleText,
    boldText,
    children,
    ...props
}: FlexProps & {
    title: string;
    simpleText?: string;
    boldText?: boolean;
    children?: ReactNode;
}) => {
    return (
        <FlexCol gap={PXSTR.XS} {...props}>
            <FONTS.H5 color={COLORS.PRIMARY.Grey}>{title}</FONTS.H5>
            {!!simpleText && (
                <FONTS.P2 fontWeight={boldText ? 700 : undefined}>
                    {simpleText}
                </FONTS.P2>
            )}
            {children}
        </FlexCol>
    );
};
