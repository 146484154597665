import { Alert, AlertVisualScheme } from 'ui/components/Alert';
import { DateInput } from 'ui/components/DateInput';
import { LengthOfTimeInput } from 'ui/components/LengthOfTimeInput';
import { TimeInput } from 'ui/components/TimeInput';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { useSubmitVisitContext } from '../submitVisit.context';
import { useMemo } from 'react';
import { VisitCategory, VisitTypeCopy } from 'models/constants/visitConstants';
import FONTS from 'ui/fonts';
import {
    determineCycle,
    determinePrevCycle,
    determineVisitType,
} from 'util/visit.utils';
import { useVisitDateValidation } from '../hooks/useVisitDateValidation';
import { max } from 'date-fns';

const useVisitDateTimeStep = () => {
    const ctx = useSubmitVisitContext();
    const { stepThreeForm, stepTwoForm } = ctx ?? {};
    const { setValue, watch, register } = stepThreeForm ?? {};
    const { visitDateValidator, formHookWrapper } = useVisitDateValidation();

    const visitType = useMemo(() => {
        const visitType = determineVisitType(stepTwoForm?.getValues());
        if (!visitType) return;
        return VisitTypeCopy[visitType];
    }, [stepTwoForm]);

    const visitCategory = stepTwoForm?.watch('visitCategory');

    const [dateOfService, durationMinutes, timeOfService] =
        watch?.(['dateOfService', 'durationMinutes', 'timeOfService']) ?? [];

    const setValueOptions = {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
    };

    const setDateOfService = (val: Date | null) => {
        if (!val) return;
        setValue?.('dateOfService', val, setValueOptions);
    };

    const setTimeOfService = (val: Date | null) => {
        if (!val) return;
        setValue?.('timeOfService', val, setValueOptions);
    };

    const setDurationMinutes = (val: number | null) => {
        if (!val) return;
        setValue?.('durationMinutes', val, setValueOptions);
    };

    const { startDateOfPrevCycle, endDateOfCurrentCycle } = useMemo(() => {
        const today = new Date();
        const currentCycle = determineCycle(today);
        const prevCycle = determinePrevCycle(today);

        if (!prevCycle || !currentCycle)
            return {
                startDateOfPrevCycle: today,
                endDateOfCurrentCycle: today,
            };

        return {
            startDateOfPrevCycle: prevCycle[0],
            endDateOfCurrentCycle: currentCycle[1],
        };
    }, []);

    return {
        dateOfService: dateOfService ?? null,
        durationMinutes: durationMinutes ?? null,
        timeOfService: timeOfService ?? null,
        setDateOfService,
        setTimeOfService,
        setDurationMinutes,
        visitType,
        visitCategory,
        register,
        visitDateValidator,
        formHookWrapper,
        startDateOfPrevCycle,
        endDateOfCurrentCycle,
    };
};

export const VisitDateTimeStep = () => {
    const {
        dateOfService,
        durationMinutes,
        timeOfService,
        setDateOfService,
        setTimeOfService,
        setDurationMinutes,
        visitType,
        visitCategory,
        register,
        visitDateValidator,
        formHookWrapper,
        startDateOfPrevCycle,
        endDateOfCurrentCycle,
    } = useVisitDateTimeStep();

    return (
        <FlexCol gap={PXSTR.M}>
            {visitCategory === VisitCategory.LaborDelivery && (
                <Alert
                    contentComponents={
                        <FONTS.P1>
                            If you provided Labor & Delivery support over the
                            course of multiple days, select the{' '}
                            <b>latest date</b> in the date range. Your selection
                            should match the date the delivery occurred in the
                            client’s medical records.
                        </FONTS.P1>
                    }
                />
            )}

            <DateInput
                label={`On what date did you perform the "${visitType}" visit?`}
                datePickerProps={{
                    dateFormat: 'MM/dd/yyyy',
                    placeholderText: 'MM/dd/yyyy',
                    maxDate: max([endDateOfCurrentCycle, new Date()]),
                    minDate: startDateOfPrevCycle,
                }}
                customValidateDate={visitDateValidator}
                allowInvalidDate
                value={dateOfService ?? null}
                setValue={setDateOfService}
                {...(register?.('dateOfService', {
                    validate: formHookWrapper,
                }) ?? {})}
            />

            <Alert
                content="Note: Visit start time and duration won’t affect how much you will be paid, but are required for record-keeping purposes."
                visualScheme={AlertVisualScheme.periwinkle}
            />

            <TimeInput
                label="What time did this visit start?"
                subLabel="Visit time will not affect your payment. It is collected for record-keeping purposes."
                datePickerProps={{}}
                value={timeOfService ?? null}
                setValue={setTimeOfService}
                {...(register?.('timeOfService', {
                    validate: (date) =>
                        date != undefined || `Selection is required`,
                }) ?? {})}
            />

            <LengthOfTimeInput
                label="How long was this visit?"
                value={durationMinutes ?? null}
                setValue={setDurationMinutes}
                maxLength={150}
                {...(register?.('durationMinutes', {
                    required: true,
                    validate: (number) =>
                        (number ?? 0) > 0 || `Selection is required`,
                }) ?? {})}
            />
        </FlexCol>
    );
};
