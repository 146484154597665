import { useAuth0 } from '@auth0/auth0-react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import PX, { SPACING } from 'ui/px';
import { Sheet } from './Sheet';
import { useState } from 'react';
import { SlideFade } from '@chakra-ui/react';
import { shutdown } from '@intercom/messenger-js-sdk';

export enum AvatarColorSchemes {
    Orange = 'orange',
    Periwinkle = 'periwinkle',
    Plum = 'plum',
    Green = 'green',
    Red = 'red',
}

const ColorSchemeFields = {
    [AvatarColorSchemes.Orange]: {
        background: COLORS.BRAND.BloodOrange.BRAND,
        color: COLORS.UTIL.Gray.WHITE,
    },
    [AvatarColorSchemes.Periwinkle]: {
        background: COLORS.PRIMARY.Blue,
        color: COLORS.BRAND.Peach.BRAND,
    },
    [AvatarColorSchemes.Plum]: {
        background: COLORS.BRAND.Plum.BRAND,
        color: COLORS.BRAND.Periwinkle[200],
    },
    [AvatarColorSchemes.Green]: {
        background: COLORS.UTIL.Green[900],
        color: COLORS.BRAND.Peach.BRAND,
    },
    [AvatarColorSchemes.Red]: {
        background: COLORS.BRAND.BloodOrange[800],
        color: COLORS.BRAND.Periwinkle[200],
    },
};

export const AvatarSizing = {
    xs: '24px',
    sm: '32px',
    lg: '52px',
};

export const Avatar = ({
    initials,
    size = 'sm',
    colorScheme = AvatarColorSchemes.Orange,
    canLogOut = false,
}: {
    initials: string;
    size?: keyof typeof AvatarSizing;
    colorScheme?: AvatarColorSchemes;
    canLogOut?: boolean;
}) => {
    const { logout } = useAuth0();

    const [showLogout, setShowLogout] = useState(false);
    const [activeTimeout, setActiveTimeout] = useState<number | null>(null);

    const onMouseLeave = () => {
        if (activeTimeout != null) {
            clearTimeout(activeTimeout);
        }
        setActiveTimeout(
            setTimeout(() => {
                setShowLogout(false);
                setActiveTimeout(null);
            }, 2500),
        );
    };

    const InitialsText =
        size === 'sm' ? FONTS.H5 : size === 'lg' ? FONTS.H2 : FONTS.CAPTION;

    return (
        <FlexRow
            className="avatar"
            aria-label="user avatar"
            position="relative"
            justifyContent="center"
            alignItems="center"
            width={AvatarSizing[size]}
            height={AvatarSizing[size]}
            padding={PX.SPACING.REM.S}
            borderRadius={PX.RADII.FULL}
            onMouseEnter={() => setShowLogout(true)}
            onMouseLeave={onMouseLeave}
            {...ColorSchemeFields[colorScheme]}
        >
            <SlideFade
                style={{ position: 'absolute', left: 0, top: '-48px' }}
                in={canLogOut && showLogout}
                offsetX={0}
                offsetY={10}
            >
                <Sheet
                    display={canLogOut ? 'block' : 'none'}
                    aria-label="log out"
                    paddingY={SPACING.PX.S}
                    paddingX={SPACING.PX.M}
                    width="200px"
                    cursor="pointer"
                    transition="all 0.3s"
                    _hover={{
                        background: COLORS.UTIL.Gray[200],
                    }}
                    _active={{
                        background: COLORS.UTIL.Gray[500],
                    }}
                    onClick={() => {
                        shutdown();
                        logout({
                            logoutParams: {
                                returnTo: window.location.origin,
                            },
                        });
                    }}
                >
                    <FONTS.P1 color={COLORS.Brand.Orange}>Log out</FONTS.P1>
                </Sheet>
            </SlideFade>

            <InitialsText
                fontWeight={600}
                textTransform="uppercase"
                {...ColorSchemeFields[colorScheme]}
            >
                {initials}
            </InitialsText>
        </FlexRow>
    );
};
