import { Box } from '@chakra-ui/react';
import { TextInput } from 'ui/components/TextInput';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import { useNPISearchContext } from './npiSearch.context';

export const IndividualForm = () => {
    const ctx = useNPISearchContext();
    const { individualForm } = ctx ?? {};
    const { register } = individualForm ?? {};

    return (
        <FlexCol gap="inherit">
            <FONTS.H3>
                Enter your first and last names to search the Nation Provider
                Identification (NPI) database
            </FONTS.H3>

            <FlexRow
                gap={PX.SPACING.REM.L}
                justify="space-between"
                wrap="wrap"
                width="100%"
            >
                <Box flex={1}>
                    <TextInput
                        id="npiFirstName"
                        label="First Name"
                        {...(register?.('firstName') ?? {})}
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        flex={1}
                        id="npiLastName"
                        label="Last name"
                        {...(register?.('lastName') ?? {})}
                    />
                </Box>
            </FlexRow>
        </FlexCol>
    );
};

export const FacilityForm = () => {
    const ctx = useNPISearchContext();
    const { facilityForm } = ctx ?? {};
    const { register } = facilityForm ?? {};

    return (
        <FlexCol gap="inherit">
            <FONTS.H3>
                Enter the name of the medical facility and the city in which the
                facility is located to search the National Provider Identifier
                (NPI) database.
            </FONTS.H3>

            <TextInput
                id="facilityName"
                label="Facility Name"
                {...(register?.('facilityName') ?? {})}
            />

            <TextInput
                id="facilityName"
                label="City (optional)"
                {...(register?.('city') ?? {})}
            />

            <TextInput
                id="taxonomy"
                label="Primary Taxonomy (optional)"
                {...(register?.('taxonomy', { required: false }) ?? {})}
            />
        </FlexCol>
    );
};
