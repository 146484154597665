import FileUploader from 'components/FileUpload/FileUploader';
import { BasicModalLayout } from 'ui/components/BasicModalLayout';
import { SecondaryButton, PrimaryButton } from 'ui/components/Button';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import { PXSTR } from 'ui/px';
import { useClientDetailsContext } from './clientDetails.context';
import { useForm } from 'react-hook-form';
import { FileUpload } from 'models/schemas';
import { useEffect, useState } from 'react';

export const AddPostPartumReccFieldName = 'additionalPostPartumRecc';

type MiniFormType = {
    [AddPostPartumReccFieldName]: FileUpload[] | undefined;
};

const useModalForm = () => {
    const ctx = useClientDetailsContext();
    const { filteredFiles, saveTempFileToPatientFiles, deleteFile } = ctx ?? {};

    const [initialized, setInitialized] = useState(false);

    const form = useForm({
        defaultValues: {
            [AddPostPartumReccFieldName]: !!filteredFiles?.length
                ? filteredFiles[0]
                : undefined,
        } as MiniFormType,
    });

    useEffect(() => {
        if (initialized || !filteredFiles?.length) return;

        form.reset({
            [AddPostPartumReccFieldName]: filteredFiles,
        });

        setInitialized(true);
    }, [filteredFiles, form, initialized]);

    const { watch, setValue, register } = form;
    const uploadedWatcher = watch(AddPostPartumReccFieldName);

    const onChangeUploadedFile = (val: FileUpload[] | undefined) => {
        setValue(AddPostPartumReccFieldName, val, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
    };

    return {
        uploadedWatcher,
        form,
        register,
        onChangeUploadedFile,
        saveTempFileToPatientFiles,
        deleteFile,
        filteredFiles,
    };
};

export const AdditionalPostPartumReccModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const {
        form,
        uploadedWatcher,
        onChangeUploadedFile,
        register,
        saveTempFileToPatientFiles,
        deleteFile,
        filteredFiles,
    } = useModalForm();

    const ctx = useClientDetailsContext();
    const { clientHookUpdaters, refreshClientData } = ctx ?? {};
    const { updatePatientPregnancy } = clientHookUpdaters ?? {};

    const onSaveChanges = async () => {
        //TODO in reality this will be an update made on retool
        //but for bug bash, simply saving here will grant the status
        const uploadedFile = !!uploadedWatcher?.length
            ? uploadedWatcher[0]
            : undefined;

        if (!uploadedFile) {
            filteredFiles?.forEach((file) =>
                deleteFile?.(file.temporaryFileName),
            );
        } else {
            saveTempFileToPatientFiles?.(
                AddPostPartumReccFieldName,
                uploadedFile,
            );
        }

        updatePatientPregnancy?.({ additionalPostPartumStatus: 'Approved' });
        refreshClientData?.();

        form.reset({ additionalPostPartumRecc: undefined });
        onClose();
    };

    return (
        <BasicModalLayout
            isOpen={isOpen}
            title="Upload Provider Recommendation"
            onClose={onClose}
            buttons={[
                <SecondaryButton
                    key="cancelAddtlPostPartumModal"
                    onClick={onClose}
                >
                    Cancel
                </SecondaryButton>,
                <PrimaryButton
                    key="saveAddtlPostPartumModal"
                    disabled={!form.formState.isDirty}
                    onClick={onSaveChanges}
                >
                    Save Changes
                </PrimaryButton>,
            ]}
        >
            <FlexCol gap={PXSTR.SM}>
                <FONTS.P1 fontWeight={500}>
                    Upload a copy of the provider recommendation that approves
                    this client for additional postpartum visits.
                </FONTS.P1>

                <FileUploader
                    maxFiles={1}
                    fieldValue={uploadedWatcher}
                    onChange={onChangeUploadedFile}
                    registerProps={register(AddPostPartumReccFieldName, {
                        required: true,
                        validate: (val) => {
                            return !!val?.length;
                        },
                    })}
                />
            </FlexCol>
        </BasicModalLayout>
    );
};
