import { Box, Select, SelectProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import { ICONS } from 'ui/icons';
import { TextInputStyling } from './TextInput';
import { DropdownOption, DropdownProps } from 'ui/ui.models';

export const Dropdown = forwardRef<SelectProps, DropdownProps>(
    ({ label, subLabel, options, ...selectProps }, ref) => {
        return (
            <FlexCol width="100%" justifyContent="center" gap={PX.SPACING.PX.S}>
                {!!label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
                <Box className="baseTextBox" position="relative" width="100%">
                    <Select
                        className="selectRoot"
                        width="100%"
                        {...TextInputStyling}
                        padding={0}
                        icon={<ICONS.CaretDown />}
                        iconSize="16px"
                        ref={ref}
                        {...selectProps}
                    >
                        {[{ label: '-', value: undefined } as DropdownOption]
                            .concat(options)
                            .map((option, idx) => (
                                <option
                                    key={`dropdownOpt_${idx}`}
                                    value={option.value}
                                >
                                    {option.label ?? option.value}
                                </option>
                            ))}
                    </Select>
                </Box>

                {!!subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
            </FlexCol>
        );
    },
);
